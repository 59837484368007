import { Stack, Typography } from "@mui/material";

type Props = {
  icon?: any;
  title: string;
  subtitle?: string;
  content: JSX.Element;
  relatedAction?: JSX.Element;
};

const LoginPageLayout = ({ icon, title, subtitle, content, relatedAction }: Props) => {
  return (
    <Stack spacing={3} alignItems="center" textAlign="center">
      {icon}
      <Typography variant="displaysm" fontWeight="semiBold">
        {title}
      </Typography>
      {subtitle && <Typography variant="textmd">{subtitle}</Typography>}
      {content}
      {relatedAction}
    </Stack>
  );
};

export default LoginPageLayout;
