import { LoadingButton } from "@mui/lab";
import { ButtonProps as MuiButtonProps } from "@mui/material";

export type ButtonProps = {
  title: string;
  loading?: boolean;
} & MuiButtonProps;

const Button = ({ title, loading, ...rest }: ButtonProps) => (
  <LoadingButton loading={loading} {...rest}>
    {title}
  </LoadingButton>
);
export default Button;
