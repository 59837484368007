import { ExpandMore, Check } from "@mui/icons-material";
import { Stack, Typography, Popover, MenuList, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Dispatch, Org, ZitadelOrg } from "../../utils/Types";
import { useAppDispatch } from "../../redux/hooks";
import { actAfterLoginSuccess, getLocationFromOrgIdString, getOrgFromOrgIdString, getRolesFromUser, loadUserPermission } from "../../utils/Helper";
import { NavigateFunction, useNavigate } from "react-router-dom";
import RoundIconButton from "../Button/RoundIconButton";
import { User } from "oidc-client-ts";
import { ALL_LOCATIONS, SUPER_ADMIN, SUPER_USER } from "../../utils/Constants";

export const selectOrg = async ({
  orgIdString,
  orgs,
  user,
  zitadelOrg,
  dispatch,
  navigate,
}: {
  orgIdString?: string;
  orgs: Org[];
  user: any;
  zitadelOrg: ZitadelOrg;
  dispatch: Dispatch;
  navigate: NavigateFunction;
}) => {
  if (orgIdString) {
    const orgsWithCurrent: Org[] = orgs.map(i => (i!.idString === orgIdString ? { ...i!, isCurrent: true } : { ...i!, isCurrent: false }));

    const roles = (await getRolesFromUser(user, zitadelOrg?.idString)).filter(
      role => role.startsWith(orgIdString) || role.startsWith(getOrgFromOrgIdString(orgIdString) + "_" + ALL_LOCATIONS)
    );

    const roleToLoadPermission = roles[0] && !roles[0].startsWith(orgIdString) ? roles[0].replace(ALL_LOCATIONS, getLocationFromOrgIdString(orgIdString)) : roles[0]; // same short role when All_Location user access single location

    const permission = roles.includes(SUPER_ADMIN) || roles.includes(SUPER_USER) ? undefined : await loadUserPermission(user, roleToLoadPermission, zitadelOrg?.idString);

    actAfterLoginSuccess({ orgs: orgsWithCurrent, user, allRoles: roles, permission, dispatch, navigate });
  }
};

const SwitchOrg = ({ user, orgs, zitadelOrg }: { user: User; orgs: Org[]; zitadelOrg: ZitadelOrg }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentOrg: Org = orgs.find(o => o?.isCurrent);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography variant="textxs" fontWeight="semiBold">
        {currentOrg?.displayName}
      </Typography>
      <Stack display={(orgs.length || 0) > 1 ? "flex" : "none"}>
        <RoundIconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ width: 16, height: 16 }}>
          <ExpandMore sx={{ color: "mytext.tertiary" }} />
        </RoundIconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ mt: 1 }}
        >
          <MenuList dense sx={{ minWidth: 200 }}>
            <ListItemText sx={{ px: 2, py: 0.5 }}>
              <Typography variant="textsm" fontWeight="semiBold">
                Please select
              </Typography>
            </ListItemText>
            <Divider />
            {_.orderBy(orgs, "idString")?.map(i => (
              <MenuItem
                key={"org_" + i?.idString}
                onClick={() => {
                  setAnchorEl(null);
                  selectOrg({ orgIdString: i?.idString, orgs, user, zitadelOrg, dispatch, navigate });
                }}
                disabled={i?.idString === currentOrg?.idString}
              >
                {i?.idString === currentOrg?.idString && (
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                )}
                <ListItemText inset={i?.idString !== currentOrg?.idString}>
                  <Typography variant="textxs" fontWeight="semiBold">
                    {i?.displayName}
                  </Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Popover>
      </Stack>
    </Stack>
  );
};

export default SwitchOrg;
