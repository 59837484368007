import { Typography } from "@mui/material";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Chip, ContentWrapper, Hcharts, MinimalTable } from "../../../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { viewChartsMinimalTableData } from "../../../../../utils/MockData";

const ChartItem = ({
  loading,
  data,
  title,
  supplierWithFullInfo,
  xAxisName,
  yAxisName,
}: {
  loading: boolean;
  data: QueryResult | undefined;
  title: string;
  supplierWithFullInfo: LooseObject;
  xAxisName: string;
  yAxisName: string;
}) => {
  const dataToDisplay = data?.rows;
  const xAxis = data?.columns?.find(i => i.name === xAxisName);
  const yAxis = data?.columns?.find(i => i.name === yAxisName);

  const minimalTableColumns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "GRADE", header: "GRADE" },
    { accessorKey: "CARCASES", header: "CARCASES" },
    { accessorKey: "PERCENTAGE", header: "%" },
  ];
  const minimalTableData: MRT_RowData[] = viewChartsMinimalTableData;

  return (
    <ContentWrapper width="100%" spacing={2}>
      <Typography variant="textsm" fontWeight="semiBold">
        {title}
      </Typography>
      <Grid container width="100%" spacing={1}>
        <Grid>
          <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
        </Grid>
        <Grid>
          <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
        </Grid>
      </Grid>
      <Grid container width="100%" spacing={2} maxWidth="md">
        <Grid sm>
          <Typography variant="textsm" fontWeight="semiBold">
            Target Area
          </Typography>
        </Grid>
        <Grid sm>
          <Typography>+ = Y1</Typography>
        </Grid>
        <Grid sm>
          <Typography>+ = Y2</Typography>
        </Grid>
        <Grid sm>
          <Typography>+ = P</Typography>
        </Grid>
        <Grid sm minWidth={200}>
          <Typography>+ = T, F & Others</Typography>
        </Grid>
      </Grid>
      <Grid container width="100%" spacing={2}>
        <Grid xs={12} md={8}>
          <Hcharts loading={loading} type={ChartType.SCATTER_PLOT} title="" xAxis={xAxis} yAxis={yAxis} data={[{ data: dataToDisplay || [], name: "Carcase" }]} hideToolButtons />
        </Grid>
        <Grid xs={12} md={4}>
          <MinimalTable columns={minimalTableColumns} data={minimalTableData} />
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default ChartItem;
