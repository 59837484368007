import { Chip } from "../../../../../components";

const VendorType = ({ type, label }: { type: "Agent" | "Producer"; label?: any }) => {
  const color = type === "Agent" ? "utility.pink.700" : "utility.blue.700";
  const borderColor = type === "Agent" ? "utility.pink.200" : "utility.blue.200";
  const backgroundColor = type === "Agent" ? "utility.pink.50" : "utility.blue.50";
  return <Chip label={label || type} sx={{ color, borderColor, backgroundColor, fontSize: "0.75rem", lineHeight: 1.5 }} />;
};

export default VendorType;
