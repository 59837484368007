import { MRT_Row, MRT_RowData } from "material-react-table";
import { Center, Chip, CopyButton, Dialog, DialogWrapper, ImageDetails, ImageItem, NoDataView, SortBySelect } from "../../../components";
import { LooseObject, QueryResultTable } from "../../../utils/Types";
import { FORMATTED } from "../../../utils/Constants";
import { ImageList, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import _ from "lodash";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export const imgUrlKey = "AZ_URL";
export const hiddenColumnNames = ["PHOTO_DATETIME", "PHOTO_DATETIME_FORMATTED", "BARCODE"];

const AllImages = ({
  row,
  data,
  tableRows,
  onCancel,
}: {
  row: MRT_Row<MRT_RowData>;
  data: QueryResultTable | undefined;
  tableRows: MRT_RowData[];
  onCancel: (values: any) => void;
}) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg")) && !isSmallScreen;

  const [openForDialogImageDetails, setOpenForDialogImageDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});
  const [page, setPage] = useState(1);
  const [listHeight, setListHeight] = useState(0);
  const [sortBy, setSortBy] = useState<string>();

  const columnsInterested = data?.tableColumns.filter(i => i.accessorKey && !hiddenColumnNames.includes(i.accessorKey));

  const measuredRef = useCallback((node: any) => {
    if (node !== null) {
      setListHeight(Math.ceil(node.getBoundingClientRect().height));
    }
  }, []);

  const numberOfItemsPerPage = isMediumScreen ? 9 : 8;

  const sortedTableRows = sortBy ? _.orderBy(tableRows, sortBy.replace("_Desc", "").replace("_Asc", ""), sortBy.includes("_Desc") ? "desc" : "asc") : tableRows;

  const dataToDisplay = sortedTableRows.slice(0, page * numberOfItemsPerPage) || [];

  const onScroll = (event: any) => {
    if (
      // load more when reach about 70% of screen height
      Math.floor(event.target.scrollTop + listHeight * 1.3) >= event.target.scrollHeight &&
      tableRows.length > page * numberOfItemsPerPage
    ) {
      setPage(page + 1);
    }
  };

  return (
    <DialogWrapper title={`All images ${row.original["PHOTO_DATE_" + FORMATTED]}`} onCancel={onCancel}>
      {data && data.columns ? (
        <Stack spacing={2}>
          <SortBySelect columnsInterested={columnsInterested} handleChange={setSortBy} />
          <ImageList
            cols={isSmallScreen ? 2 : isMediumScreen ? 3 : 4}
            sx={{ height: "80vh", mt: 0 }} // fix height to have scroll
            gap={12}
            tabIndex={0}
            onScroll={onScroll}
            ref={measuredRef}
          >
            {dataToDisplay.map((item, index) => (
              <ImageItem
                key={`image_item_${index}`}
                item={item}
                columns={data?.tableColumns || []}
                hiddenColumnNames={hiddenColumnNames}
                dateKey={"PHOTO_DATETIME_FORMATTED"}
                idKey="BARCODE"
                imgUrlKey={imgUrlKey}
                onClick={v => {
                  setCurrentItem(v);
                  setOpenForDialogImageDetails(true);
                }}
              />
            ))}
          </ImageList>
          <Dialog open={openForDialogImageDetails} onClose={() => setOpenForDialogImageDetails(false)} isTransparent>
            <ImageDetails
              initialItem={currentItem}
              columns={data?.tableColumns || []}
              hiddenColumnNames={hiddenColumnNames}
              idKey="BARCODE"
              imgUrlKey={imgUrlKey}
              rows={dataToDisplay}
              header={image => (
                <Stack>
                  <Grid container spacing={1}>
                    <Grid>
                      <Chip label={image?.PHOTO_DATETIME_FORMATTED} />
                    </Grid>
                    <Grid>
                      <Center direction="row" spacing={1}>
                        <Chip label={image?.BARCODE} />
                        <CopyButton text={image?.BARCODE} color={theme.palette.text.secondary} size={18} sx={{ width: 23, height: 23, pt: 2 }} title="Copy Barcode" />
                      </Center>
                    </Grid>
                  </Grid>
                </Stack>
              )}
              onCancel={() => setOpenForDialogImageDetails(false)}
            />
          </Dialog>
        </Stack>
      ) : (
        <NoDataView />
      )}
    </DialogWrapper>
  );
};

export default AllImages;
