import { InputLabel, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Grid from "@mui/material/Unstable_Grid2";

import DatePicker, { DatePickerProps } from "./DatePicker";

const DateRangePicker = ({ item, values, setFieldValue, textFieldProps, format }: DatePickerProps) => (
  <Stack sx={textFieldProps.sx}>
    {item.label && (
      <Stack mb={1}>
        <InputLabel
          shrink
          sx={{
            position: "relative",
            transform: "none",
            marginBottom: "5px",
          }}
        >
          {item.label}
        </InputLabel>
      </Stack>
    )}
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="From"
            name={`${item.name}From`}
            item={item}
            values={values}
            maxDate={values[`${item.name}To`]}
            setFieldValue={setFieldValue}
            textFieldProps={textFieldProps}
            format={format}
            // showFloatingLabel
          />
        </LocalizationProvider>
      </Grid>
      {/* <Grid xs={1}>
        <Stack sx={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <Typography>-</Typography>
        </Stack>
      </Grid> */}
      <Grid xs={6}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="To"
            name={`${item.name}To`}
            item={item}
            values={values}
            minDate={values[`${item.name}From`]}
            setFieldValue={setFieldValue}
            textFieldProps={textFieldProps}
            format={format}
            // showFloatingLabel
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  </Stack>
);

export default DateRangePicker;
