import { Divider, List, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "../../redux/hooks";
import { SIDEBAR_WIDTH } from "./index";
import Logo from "./Logo";
import LeftSidebarItem from "./LeftSidebarMobileItem";
import { isStringEqualIgnoreCase, logout } from "../../utils/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import SwitchRole from "./SwitchRole";
import _ from "lodash";
import SvgIcon from "../SvgIcon";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../../utils/Constants";
import RoundIconButton from "../Button/RoundIconButton";
import { NavItem, NavSection, Org, Role, ZitadelOrg } from "../../utils/Types";
import { User } from "oidc-client-ts";
import { getCurrentNavSection } from "./LeftSidebar";

const Sidebar = ({
  user,
  currentOrg,
  orgs,
  roles,
  zitadelOrg,
  permittedSections,
  permittedPages,
  closeSidebar,
}: {
  user: User;
  currentOrg: Org;
  orgs: Org[];
  roles: Role[];
  zitadelOrg: ZitadelOrg;
  permittedSections: NavSection[];
  permittedPages: NavItem[];
  closeSidebar: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentNavSection = getCurrentNavSection(location.pathname);

  return (
    <Stack width={SIDEBAR_WIDTH * 3} height="100%" sx={{ borderRightWidth: 1, borderRightColor: "myborder.secondary", borderRightStyle: "solid" }}>
      <Stack direction="row" spacing={0.5} alignItems="center" m={DEFAULT_SPACING_MOBILE} width={SIDEBAR_WIDTH * 3 - DEFAULT_SPACING * 8 * 2}>
        <Stack>
          <Logo size={32} />
        </Stack>
        <Typography variant="textxs" fontWeight="semiBold">
          {currentOrg?.displayName}
        </Typography>
      </Stack>
      <Stack display="flex" justifyContent="space-between" flex={1}>
        <List>
          {permittedSections.map(i => (
            <LeftSidebarItem
              key={i.name}
              icon={i.icon}
              displayName={i.name}
              isActive={isStringEqualIgnoreCase(currentNavSection, _.startCase(i.name))}
              navigate={navigate}
              permittedPages={permittedPages}
              sectionName={i.name}
              closeSidebar={closeSidebar}
            />
          ))}
        </List>
        <Stack>
          <Divider />
          <Stack direction="row" justifyContent="space-between" spacing={1} m={DEFAULT_SPACING}>
            <Stack>
              <Typography variant="textxs">{user.profile.name}</Typography>
              <SwitchRole user={user} orgs={orgs} roles={roles} zitadelOrg={zitadelOrg} />
            </Stack>
            <RoundIconButton onClick={() => logout({ dispatch, zitadelOrg })}>
              <SvgIcon name="Logout" />
            </RoundIconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
