import { Stack, Typography } from "@mui/material";
import { DialogWrapper, Form, MinimalTable, SmallButton } from "../../../../../components";
import { DateFormatServer, FormInputCategory, GmpBookingStatus } from "../../../../../utils/Types";
import { Moment } from "moment";
import _ from "lodash";
import moment from "moment";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { useState } from "react";
import ToolButton from "../../../../../components/Button/ToolButton";
import { MarkEmailReadOutlined } from "@mui/icons-material";

const Send = ({
  loading,
  weeklyStartDate,
  weeklyLimit,
  bookings,
  handleSubmit,
  onCancel,
}: {
  loading: boolean;
  weeklyStartDate: Moment;
  weeklyLimit: number;
  bookings: MRT_RowData[];
  handleSubmit: (v: any) => void;
  onCancel: () => void;
}) => {
  const [bookingsUpdated, setBookingsUpdated] = useState<MRT_RowData[]>(bookings);

  const bookingsOfThisWeek = bookings.filter(i => i.year === weeklyStartDate.year() && i.week === weeklyStartDate.week());
  const totalAgreedQuantity = _.sum(bookingsOfThisWeek.map(i => i.quantity));

  const dailyQuantityColumns = Array.from(Array(7).keys()).map(i => ({ accessorKey: "day" + i, header: moment(weeklyStartDate.valueOf()).add(i, "days").format("ddd DD") }));
  const getDailyQuantityRows = () => {
    const row: { [key: string]: number } = {}; // Explicitly typing the row object
    Array.from(Array(7).keys()).forEach(i => {
      row["day" + i] = _.sumBy(
        bookings.filter(b => b.bookingDate === moment(weeklyStartDate.valueOf()).add(i, "days").format(DateFormatServer.SHORT)),
        "quantity"
      );
    });
    return [row];
  };

  const handleDayChange = ({ bookingId, day }: { bookingId: string; day: string }) => {
    const bookingsUpdatedWithDate = bookingsUpdated.map(i => (i._id === bookingId ? { ...i, bookingDate: day } : i));
    setBookingsUpdated(bookingsUpdatedWithDate);
  };

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    {
      accessorKey: "vender",
      header: "Vendor",
      accessorFn: row => `${row.vendorFirstName} ${row.vendorLastName}\n${row.vendorBusinessName}`,
      Cell: ({ renderedCellValue }) => <Stack style={{ whiteSpace: "pre-line" }}>{renderedCellValue}</Stack>,
      maxSize: 200,
    },
    { accessorKey: "quantity", header: "Head", maxSize: 90 },
    {
      accessorKey: "day",
      header: "Day",
      Cell: ({ cell }) => (
        <Stack direction="row" spacing={0.5}>
          <Form
            size="small"
            itemSx={{ width: 120 }}
            inputs={[
              {
                name: `bookingDay-${cell.row.original._id}`,
                category: FormInputCategory.SELECT,
                options: Array.from(Array(7).keys()).map(i => ({
                  label: moment(weeklyStartDate.valueOf()).add(i, "days").format("ddd Do"),
                  value: moment(weeklyStartDate.valueOf()).add(i, "days").format(DateFormatServer.SHORT),
                })),
                defaultValue: cell.row.original.bookingDate,
                exposeValue: v => handleDayChange({ bookingId: cell.row.original._id, day: v }),
                disabled: cell.row.original.status === GmpBookingStatus.Assigned || cell.row.original.status === GmpBookingStatus.Completed,
              },
            ]}
          />
          {(cell.row.original.status === GmpBookingStatus.Assigned || cell.row.original.status === GmpBookingStatus.Completed) && (
            <ToolButton title="Sent" icon={<MarkEmailReadOutlined />} />
          )}
        </Stack>
      ),
      maxSize: 150,
    },
  ];

  return (
    <DialogWrapper onCancel={onCancel}>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <Typography variant="textsm" fontWeight="semiBold">
            {`Week ${weeklyStartDate.week()}, ${weeklyStartDate.year()} (${weeklyStartDate.format("ddd MMM DD")} - ${moment(weeklyStartDate.valueOf())
              .add(6, "days")
              .format("ddd MMM DD")})`}
          </Typography>
          <Stack spacing={1}>
            <Stack spacing={1} direction="row" sx={{ bgcolor: "primary.main", p: 1 }}>
              <Typography variant="textsm" fontWeight="semiBold" color="mytext.primaryOnBrand">
                {`Weekly limit: ${weeklyLimit}`}
              </Typography>
              <Typography variant="textsm" fontWeight="semiBold" color="mytext.primaryOnBrand">
                {`(Booked: ${totalAgreedQuantity})`}
              </Typography>
            </Stack>
            <Stack>
              <MinimalTable columns={dailyQuantityColumns} data={getDailyQuantityRows()} />
            </Stack>
          </Stack>
        </Stack>
        <MinimalTable columns={columns} data={bookings} />
        <Stack alignItems="flex-end">
          <SmallButton
            variant="contained"
            title="Send Supply Agreements"
            onClick={() => handleSubmit(bookingsUpdated)}
            loading={loading}
            disabled={bookings.filter(i => i.status !== GmpBookingStatus.Assigned && i.status !== GmpBookingStatus.Completed).length === 0}
          />
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};

export default Send;
