import Grid from "@mui/material/Unstable_Grid2";
import Form from "../../Form";
import { AxisSwitchProps, FormInputCategory, QueryResultColumn } from "../../../utils/Types";
import { useState } from "react";
import { InputAdornment, Typography } from "@mui/material";

export const getDefaultAxis = ({
  options,
  defaultName,
  isY,
  defaultXAxisNameForPreventingDuplicate,
}: {
  options?: QueryResultColumn[];
  defaultName?: string;
  isY: boolean;
  defaultXAxisNameForPreventingDuplicate?: string;
}) => {
  let defaultAxis = undefined;
  if (options && options.length > 0) {
    if (defaultName) {
      defaultAxis = options.find(i => i.name === defaultName);
    } else {
      if (isY) {
        if (defaultXAxisNameForPreventingDuplicate) {
          const optionsY = options.filter(y => y.name !== defaultXAxisNameForPreventingDuplicate);
          if (optionsY.length > 0) {
            defaultAxis = optionsY[0];
          }
        } else {
          defaultAxis = options[0];
        }
      } else {
        defaultAxis = options[0];
      }
    }
  }
  return defaultAxis;
};

const AxisSwitch = ({
  optionsOfXAxis,
  optionsOfYAxis,
  defaultXAxisName,
  defaultYAxisName,
  exposeXAxis,
  exposeYAxis,
  handleChartStateChangeXAxis,
  handleChartStateChangeYAxis,
}: AxisSwitchProps) => {
  const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>(getDefaultAxis({ options: optionsOfXAxis, defaultName: defaultXAxisName, isY: false }));
  const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>(getDefaultAxis({ options: optionsOfYAxis, defaultName: defaultYAxisName, isY: true }));

  return (
    <Grid container width="100%" rowSpacing={1}>
      {optionsOfXAxis && (
        <Grid xs={12} md={6} sx={{ maxWidth: { xs: undefined, md: 200 } }}>
          <Form
            fullWidth
            isHorizontal
            inputs={[
              {
                name: "xAxis",
                category: FormInputCategory.SELECT,
                options: optionsOfXAxis?.filter(i => i.name !== yAxis?.name).map(i => ({ label: i.label, value: i.name })) || [],
                defaultValue: xAxis?.name,
                size: "small",
                inputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography fontWeight="bold">X</Typography>
                    </InputAdornment>
                  ),
                },
                exposeValue: v => {
                  const axis = optionsOfXAxis?.find(i => i.name === v);
                  setXAxis(axis);
                  exposeXAxis?.(axis);
                  handleChartStateChangeXAxis?.(axis);
                },
              },
            ]}
          />
        </Grid>
      )}
      {optionsOfYAxis && (
        <Grid xs={12} md={6} sx={{ maxWidth: { xs: undefined, md: 200 } }}>
          <Form
            fullWidth
            isHorizontal
            inputs={[
              {
                name: "yAxis",
                category: FormInputCategory.SELECT,
                options: optionsOfYAxis?.filter(i => i.name !== xAxis?.name).map(i => ({ label: i.label, value: i.name })) || [],
                defaultValue: yAxis?.name,
                size: "small",
                inputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography fontWeight="bold">Y</Typography>
                    </InputAdornment>
                  ),
                },
                exposeValue: v => {
                  const axis = optionsOfYAxis?.find(i => i.name === v);
                  setYAxis(axis);
                  exposeYAxis?.(axis);
                  handleChartStateChangeYAxis?.(axis);
                },
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AxisSwitch;
