import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { getCurrentOrg, getDateFormat, logout, postToServer } from "../../../utils/Helper";
import { LooseObject, FormData } from "../../../utils/Types";
import { Center, Dialog, SmallButton, Table } from "../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ToggleFullScreenButton } from "material-react-table";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import Add from "./Add";
import Edit from "./Edit";
import Delete from "./Delete";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<LooseObject[]>([]);
  const [openDialogForAdd, setOpenDialogForAdd] = useState<boolean>(false);
  const [openDialogForEdit, setOpenDialogForEdit] = useState<boolean>(false);
  const [openDialogForDelete, setOpenDialogForDelete] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<MRT_Row<MRT_RowData>>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const currentOrg = getCurrentOrg(orgs);

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "gmp/LoadPics",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setData(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "pic", header: "PIC" },
    { accessorKey: "businessName", header: "Business Name" },
    { accessorKey: "createdAt", header: "Created At" },
    { accessorKey: "updatedAt", header: "Updated At" },
  ];

  const dataToDisplay = _.orderBy(data, "_id", "desc");
  const rows: MRT_RowData[] = dataToDisplay.map(i => ({
    ...i,
    createdAt: moment(i.createdAt).format(getDateFormat(currentOrg, "default")),
    updatedAt: moment(i.updatedAt).format(getDateFormat(currentOrg, "default")),
  }));

  const handleAdd = async ({ pic, businessName }: FormData) => {
    if (pic && businessName) {
      setLoading(true);
      await postToServer({
        action: "gmp/AddPic",
        params: { pic, businessName },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForAdd(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleEdit = async ({ id, pic, businessName }: FormData) => {
    if (id && pic && businessName) {
      setLoading(true);
      await postToServer({
        action: "gmp/UpdatePic",
        params: { id, pic, businessName },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (id) {
      setLoading(true);
      await postToServer({
        action: "gmp/DeletePic",
        params: { id },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForDelete(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Table
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ row }) => ({ sx: { bgcolor: row.original.isValid === false ? "error.main" : undefined } })}
        enableRowSelection
        enableSelectAll={false}
        enableMultiRowSelection={false}
        renderTopToolbar={({ table }) => {
          return (
            <Stack direction="row" justifyContent="space-between" p={2} gap={1} sx={{ flexWrap: "wrap" }}>
              <Typography variant="textmd" fontWeight="semiBold" alignSelf="center">
                PIC List
              </Typography>
              <Center direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
                <SmallButton title="Add" variant="outlined" onClick={() => setOpenDialogForAdd(true)} />
                <SmallButton
                  title="Edit"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForEdit(true);
                  }}
                />
                <SmallButton
                  title="Delete"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForDelete(true);
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToggleFullScreenButton table={table} />
                </Stack>
              </Center>
            </Stack>
          );
        }}
      />
      <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
        <Add loading={loading} handleSubmit={handleAdd} onCancel={() => setOpenDialogForAdd(false)} />
      </Dialog>
      <Dialog open={openDialogForEdit} onClose={() => setOpenDialogForEdit(false)} isTransparent>
        {currentItem && <Edit loading={loading} row={currentItem} handleSubmit={handleEdit} onCancel={() => setOpenDialogForEdit(false)} />}
      </Dialog>
      <Dialog open={openDialogForDelete} onClose={() => setOpenDialogForDelete(false)} isTransparent>
        {currentItem && <Delete loading={loading} row={currentItem} handleSubmit={handleDelete} onCancel={() => setOpenDialogForDelete(false)} />}
      </Dialog>
    </Stack>
  );
};

export default Page;
