import { Typography } from "@mui/material";
import { DialogWrapper } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";

const ToggleUserState = ({
  loading,
  row,
  onCancel,
  handleSubmit,
}: {
  loading: boolean;
  row: MRT_Row<MRT_RowData>;
  onCancel: () => void;
  handleSubmit: ({ id, action }: { id: string; action: "deactivate" | "activate" }) => void;
}) => {
  const item = row.original;

  return (
    <DialogWrapper
      loading={loading}
      onCancel={onCancel}
      title={`Confirm to ${item.isValid ? "deactivate" : "activate"} ${item.displayName}`}
      displayCancelButton
      onConfirm={() => handleSubmit({ id: item.zitadelId, action: item.isValid ? "deactivate" : "activate" })}
    >
      <Typography>{`Are you sure you want to ${item.isValid ? "deactivate" : "activate"} ${item.displayName}?`}</Typography>
    </DialogWrapper>
  );
};

export default ToggleUserState;
