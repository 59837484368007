import { Typography } from "@mui/material";
import { Center } from "../components";

const Page = () => (
  <Center minHeight="50%">
    <Typography>No packages yet.</Typography>
  </Center>
);

export default Page;
