import { Options } from "highcharts";
import { HchartsProps, Trace } from "../../../utils/Types";
import commonOptions from "./CommonOptions";
import { isNotEmpty } from "../../../utils/Helper";

export const columnChartOptions = (props: HchartsProps) => {
  const { data, xData, stacking, xTitle, yTitle, xAxis, yAxis, yAxisMin, yAxisMax, valueSuffix, traceColors, height, zoomingType, hideLegend, subCharts } = props;

  let traces: Trace[] = data.map(i => ({ ...i }));

  const options: Options = {
    ...commonOptions(props),
    chart: {
      type: "column",
      height: height || 500,
      zooming: { type: zoomingType || subCharts ? "x" : undefined }, // set type to undefined to disable zooming
    },
    xAxis: {
      categories: xData,
      title: { text: xTitle || xAxis?.label || "" },
      alignTicks: false,
    },
    yAxis: {
      title: { text: yTitle || yAxis?.label || "" },
      min: yAxisMin,
      max: yAxisMax,
    },
    tooltip: {
      valueSuffix,
    },
    plotOptions: {
      column: {
        grouping: true,
        groupPadding: 0.2,
      },
      series: {
        borderWidth: 0,
        stacking,
        animation: false,
      },
    },
    legend: {
      enabled: hideLegend !== undefined ? hideLegend : traces.length !== 1,
    },
    series: traces.map((i, index) => {
      let dataInTrace = i.data;
      if (xAxis?.name) {
        dataInTrace = dataInTrace.filter(d => isNotEmpty(d[xAxis.name]));
      }
      if (yAxis?.name) {
        dataInTrace = dataInTrace.filter(d => isNotEmpty(d[yAxis.name]));
      }
      return {
        name: i.name,
        type: "column",
        data: dataInTrace.map(point => ({ y: yAxis?.name ? point[yAxis.name] : null, color: point.color })),
        color: traceColors?.[index],
        showInLegend: !!i.showInLegend,
      };
    }),
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  return options;
};
