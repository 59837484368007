import { Stack, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LooseObject, QueryResult } from "../../../../utils/Types";
import { formatNumber } from "../../../../utils/Helper";
import _ from "lodash";

const Cell = ({ cell }: { cell: any }) =>
  cell.getValue() ? (
    <Stack>
      <Typography variant="textsm">{cell.getValue().number}</Typography>
      <Typography variant="textsm">{cell.getValue().totalWeight}</Typography>
    </Stack>
  ) : (
    <Typography variant="textsm">-</Typography>
  );

const GridReport = ({ data, loading }: { data: QueryResult; loading: boolean }) => {
  const muiTableHeadCellProps = {
    sx: {
      borderWidth: 0,
      borderColor: "myborder.secondary",
      borderStyle: "solid",
      bgcolor: "mybg.secondary",
      fontWeight: "regular",
      fontSize: "0.875rem",
      lineHeight: 1.5,
      color: "mytext.tertiary",
      textOverflow: "unset",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        textOverflow: "unset",
        whiteSpace: "normal",
        wordBreak: "keep-all",
      },
    },
  };

  const muiTableBodyCellProps = {
    sx: {
      borderWidth: 0.5,
      borderColor: "myborder.secondary",
      borderStyle: "solid",
      fontSize: "0.875rem",
      lineHeight: 1.5,
      color: "mytext.tertiary",
      whiteSpace: "normal",
    },
  };

  const columns = [
    {
      header: "Grid Report",
      columns: [
        {
          accessorKey: "range",
          header: "Range",
          minSize: 100,
          maxSize: 100,
          Cell: ({ cell }: { cell: any }) =>
            cell.getValue() === "Sum" ? (
              <Stack>
                <Typography variant="textsm">Number</Typography>
                <Typography variant="textsm">Total Weight</Typography>
              </Stack>
            ) : (
              <Typography variant="textsm">{cell.getValue()}</Typography>
            ),
        },
      ],
      muiTableHeadCellProps: {
        sx: {
          ...muiTableHeadCellProps.sx,
          bgcolor: "#F6AE58",
          fontSize: "0.875rem",
          lineHeight: 1.429,
          fontWeight: "semiBold",
          color: "mytext.primaryOnBrand",
        },
      },
    },
    {
      header: "Fat Class",
      columns: [
        { accessorKey: "1", header: "1", Cell },
        { accessorKey: "2", header: "2", Cell },
        { accessorKey: "3", header: "3", Cell },
        { accessorKey: "4", header: "4", Cell },
        { accessorKey: "5", header: "5", Cell },
        { accessorKey: "total", header: "Total", Cell },
      ],
      muiTableHeadCellProps: {
        sx: {
          ...muiTableHeadCellProps.sx,
          bgcolor: "#FF8C99",
          fontSize: "0.875rem",
          lineHeight: 1.429,
          fontWeight: "semiBold",
          color: "mytext.primaryOnBrand",
        },
      },
    },
  ];

  const columnKeys = ["1", "2", "3", "4", "5"];
  const ranges = ["< 18", "18-20", "20.1-22", "22.1-24", "24.1-25", "25.1-26", "26.1-28", "28.1-30", "30.1-32", "32.1-34", "34.1+", "Sum"];

  const getRowCellData = (range: string, dataForThisRange: LooseObject[], orignalRows: LooseObject[]) => {
    const rowData: LooseObject = {};
    columnKeys.forEach(k => {
      if (dataForThisRange.length > 0) {
        const rowCellOfFatScore = dataForThisRange.find(i => i.FAT_SCORE === k);
        rowData[k] = rowCellOfFatScore ? { number: rowCellOfFatScore.NUM_LAMBS, totalWeight: rowCellOfFatScore.TOTAL_HSCW } : undefined;
      } else if (range === "Sum") {
        // sum
        const kAll = orignalRows.filter(i => {
          return i.FAT_SCORE === k;
        });

        rowData[k] = kAll && kAll.length > 0 ? { number: _.sumBy(kAll, "NUM_LAMBS"), totalWeight: formatNumber(_.sumBy(kAll, "TOTAL_HSCW"), 1) } : "-";
      }
    });
    if (dataForThisRange.length > 0) {
      rowData.total = { number: _.sumBy(dataForThisRange, "NUM_LAMBS"), totalWeight: formatNumber(_.sumBy(dataForThisRange, "TOTAL_HSCW"), 1) };
    } else if (range === "Sum") {
      rowData.total = { number: _.sumBy(orignalRows, "NUM_LAMBS"), totalWeight: formatNumber(_.sumBy(orignalRows, "TOTAL_HSCW"), 1) };
    }

    return rowData;
  };

  const getRow = (range: string) => {
    const dataForThisRange = data?.rows?.filter(i => i.RANGE === range);
    return data?.rows && ((dataForThisRange && dataForThisRange.length > 0) || range === "Sum")
      ? { range, ...getRowCellData(range, dataForThisRange!, data.rows) }
      : { range, "1": undefined, "2": undefined, "3": undefined, "4": undefined, "5": undefined, total: undefined };
  };

  const rows = ranges.map(i => getRow(i));

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableSorting: false,
    enableColumnActions: false,
    enableRowActions: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableStickyHeader: false,
    layoutMode: "grid",
    initialState: { density: "compact" },
    muiTableHeadRowProps: {
      sx: () => ({ boxShadow: "none" }),
    },
    muiTableHeadProps: {
      sx: () => ({
        opacity: 1,
      }),
    },
    muiTableBodyRowProps: {
      hover: false,
    },
    muiTableHeadCellProps: {
      sx: () => ({
        ...muiTableHeadCellProps.sx,
        // bgcolor: undefined,
      }),
      //   align: "center",
    },
    muiTableBodyCellProps: {
      sx: () => ({
        ...muiTableBodyCellProps.sx,
        bgcolor: undefined,
      }),
      //   align: "center",
    },
    state: { isLoading: loading },
  });

  return (
    <Stack width="100%" maxWidth={{ xs: `calc(100vw - 42px)`, md: `calc(100vw -  146px)` }}>
      <MaterialReactTable table={table} />
    </Stack>
  );
};

export default GridReport;
