import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Tile, TileContentText, Chip } from "../../../components";
import { LooseObject } from "../../../utils/Types";
import _ from "lodash";
import moment from "moment";

export const DateFormatBookingsLabel = "[Week] w - MMM YYYY";

const Tiles = ({ settings, bookingsOfCurrentWeek }: { settings: LooseObject; bookingsOfCurrentWeek: LooseObject[] }) => {
  const currentWeek = moment().format(DateFormatBookingsLabel);

  const totalAgreedQuantity = _.sum(bookingsOfCurrentWeek.map(i => i.quantity));

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="Limit this week"
          content={
            <Stack spacing={1}>
              <TileContentText text={settings.weeklyLimit} />
              <Chip label={currentWeek} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Space this week"
          content={
            <Stack spacing={1}>
              <TileContentText text={settings.weeklyLimit - totalAgreedQuantity} />
              <Chip label={currentWeek} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Combined total week"
          content={
            <Stack spacing={1}>
              <TileContentText text={totalAgreedQuantity} />
              <Chip label={currentWeek} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Tiles;
