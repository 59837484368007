import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { selectUser } from "../redux/reducers/userSlice";

const PrivateRoutes = () => {
  const user = useAppSelector(selectUser);

  if (!user?.access_token) return <Navigate to="/" replace />;

  return <Outlet />;
};

export default PrivateRoutes;
