import React from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";

interface CircularProgressIndicatorProps {
  value: number;
  maxValue?: number;
  size?: number;
  thickness?: number;
  borderWidth?: number;
  // color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit";
  color?: string;
  borderColor?: string;
  showLabel?: boolean;
  hidePercentage?: boolean;
}

const CircularProgressIndicator: React.FC<CircularProgressIndicatorProps> = ({
  value,
  maxValue = 100,
  size = 80,
  thickness = 10,
  borderWidth = 20,
  color,
  borderColor,
  showLabel,
  hidePercentage,
}) => {
  const progressValue = (value * 100) / maxValue;

  return (
    <Box position="relative" display="inline-flex">
      <Tooltip title={`${value || progressValue}${hidePercentage ? "" : "%"}`}>
        <CircularProgress
          variant="determinate"
          value={progressValue}
          size={size}
          thickness={thickness}
          // color={color}
          sx={theme => ({
            color: color || theme.palette.primary.main,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              border: `${borderWidth}px solid ${borderColor || theme.palette.mybg.disabled}`, // Adjust the stroke color
              zIndex: -1, // Ensure the pseudo-element is beneath the progress circle
              boxSizing: "border-box", // Center the pseudo-element to match the CircularProgress size
            },
          })}
        />
      </Tooltip>
      {showLabel && (
        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="caption" component="div" color="text.secondary">
            {`${progressValue}${hidePercentage ? "" : "%"}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CircularProgressIndicator;
