import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { Country, FormData, FormInputCategory, LabelValuePair, PkgName } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";
import { SnackbarContext } from "../../../utils/Contexts";

const Add = ({ loading, onCancel, handleSubmit }: { loading: boolean; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingZitadelOrgs, setLoadingZitadelOrgs] = useState(false);
  const [allZitadelOrgs, setAllZitadelOrgs] = useState<string[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadAllZitadelOrgs();
  }, []);

  const loadAllZitadelOrgs = _.debounce(async () => {
    setLoadingZitadelOrgs(true);
    await postToServer({
      action: "administration/GetAllZitadelOrgs",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as string[];
          setAllZitadelOrgs(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingZitadelOrgs(false);
  }, 500);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">
            Add a new organisation
          </Typography>
          <Form
            loading={loading || loadingZitadelOrgs}
            onSubmit={data => handleSubmit(data)}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "zitadelOrgIdString",
                label: "Zitadel Orgnisation",
                category: FormInputCategory.SELECT,
                options: allZitadelOrgs.map(i => ({ label: i, value: i } as LabelValuePair)),
                helperText: "All users of this new organisation will be created on this Zitadel Orgnisation. Can't be changed later.",
              },
              {
                name: "idString",
                label: "ID String",
                category: FormInputCategory.TEXT_FIELD,
                helperText: "Case sensitive. If this client has a location string, please join Client Name and Location with underscore _",
              },
              {
                name: "displayName",
                label: "Display Name",
                category: FormInputCategory.TEXT_FIELD,
              },
              {
                name: "country",
                label: "Country",
                category: FormInputCategory.SELECT,
                options: Object.keys(Country).map(i => ({ label: i, value: i })),
              },
              {
                name: "pkgs",
                label: "Packages",
                category: FormInputCategory.SELECT,
                options: Object.values(PkgName)
                  .filter(i => ![PkgName.MANAGEMENT, PkgName.ADMINISTRATION].includes(i))
                  .map(o => ({ label: o, value: o })),
                multiple: true,
              },
              {
                name: "description",
                label: "Description",
                category: FormInputCategory.TEXT_FIELD,
              },
              {
                name: "isTCRequired",
                category: FormInputCategory.CHECK_BOX,
                label: "TC Required",
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Add;
