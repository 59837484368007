import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory } from "../../../utils/Types";

const Edit = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">{`Update ${item.value}`}</Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit({ roleKey: item.value, ...data })}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "displayName",
                label: "Display Name",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.label,
              },
              {
                name: "description",
                label: "Description",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.description,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Edit;
