import { Typography } from "@mui/material";
import { MRT_ColumnDef, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import { error } from "../../../utils/Colors";
import { FormInputCategory, GmpGridPrice, GridPricingDataType } from "../../../utils/Types";
import { useEffect, useMemo, useState } from "react";
import Cell from "./Cell";
import { ContentWrapper, Form } from "../../../components";
import _ from "lodash";
import { isEvenNumber } from "../../../utils/Helper";

const GridPricing = ({
  data,
  isEditMode,
  exposeGridPricingData,
  maxWidth,
  maxColumnSize = 70,
}: {
  data: GridPricingDataType;
  isEditMode?: boolean;
  exposeGridPricingData?: (v: any) => void;
  maxWidth?: any;
  maxColumnSize?: number;
}) => {
  const [gridPricingData, setGridPricingData] = useState<GridPricingDataType>(data); // eslint-disable-line
  const [year, setYear] = useState<number>(data.year);
  const [week, setWeek] = useState<number>(isEvenNumber(data.week) ? data.week - 1 : data.week);
  const firstDayOfWeek = moment().year(year).week(week).startOf("isoWeek");

  useEffect(() => {
    setGridPricingData(data);
    setYear(data.year);
    setWeek(isEvenNumber(data.week) ? data.week - 1 : data.week);
  }, [data]);

  const muiTableHeadCellProps = {
    sx: {
      borderWidth: 0.5,
      borderColor: "myborder.secondary",
      borderStyle: "solid",
      bgcolor: "mybg.secondary",
      fontWeight: "semiBold",
      fontSize: "0.75rem",
      lineHeight: 1.5,
      color: "mytext.tertiary",
      textOverflow: "unset",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        textOverflow: "unset",
        whiteSpace: "normal",
        wordBreak: "keep-all",
      },
    },
  };

  const muiTableBodyCellProps = {
    sx: {
      borderWidth: 0.5,
      borderColor: "myborder.secondary",
      borderStyle: "solid",
      fontSize: "0.75rem",
      lineHeight: 1.5,
      whiteSpace: "normal",
      bgcolor: error[50],
    },
    style: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  };

  const columns = useMemo<MRT_ColumnDef<GmpGridPrice>[]>(
    () => [
      {
        header: `Week ${week} - ${week + 1}, ${year} (${firstDayOfWeek.format("MMM DD")} - ${moment(firstDayOfWeek.valueOf()).add(13, "days").format("MMM DD")})`,
        columns: [{ accessorKey: "hscwCategory", header: "HSCW (kg)", maxSize: maxColumnSize * 1.6 }],
      },
      {
        header: "Lean Meat Yield (%)",
        columns: [
          {
            accessorKey: "lmyCategory1",
            header: "<55",
            maxSize: maxColumnSize,
            muiTableHeadCellProps,
            muiTableBodyCellProps,
            Cell: props => <Cell {...props} isEditMode={isEditMode} handleChange={handleGridPricingDataChange} />,
          },
          {
            accessorKey: "lmyCategory2",
            header: "55-59",
            maxSize: maxColumnSize,
            muiTableHeadCellProps,
            muiTableBodyCellProps,
            Cell: props => <Cell {...props} isEditMode={isEditMode} handleChange={handleGridPricingDataChange} />,
          },
          {
            accessorKey: "lmyCategory3",
            header: "59-66",
            maxSize: maxColumnSize,
            muiTableHeadCellProps,
            muiTableBodyCellProps,
            Cell: props => <Cell {...props} isEditMode={isEditMode} handleChange={handleGridPricingDataChange} />,
          },
          {
            accessorKey: "lmyCategory4",
            header: "66-68",
            maxSize: maxColumnSize,
            muiTableHeadCellProps,
            muiTableBodyCellProps,
            Cell: props => <Cell {...props} isEditMode={isEditMode} handleChange={handleGridPricingDataChange} />,
          },
          {
            accessorKey: "lmyCategory5",
            header: ">68",
            maxSize: maxColumnSize,
            muiTableHeadCellProps,
            muiTableBodyCellProps,
            Cell: props => <Cell {...props} isEditMode={isEditMode} handleChange={handleGridPricingDataChange} />,
          },
        ],
        muiTableHeadCellProps: {
          sx: {
            ...muiTableHeadCellProps.sx,
            bgcolor: undefined,
            fontSize: "0.875rem",
            lineHeight: 1.429,
            fontWeight: "semiBold",
            minHeight: 50,
          },
        },
      },
    ],
    [isEditMode, firstDayOfWeek]
  );

  const table = useMaterialReactTable({
    columns,
    data: _.orderBy(data.prices, "rowNumber"),
    enableSorting: false,
    enableColumnActions: false,
    enableRowActions: false,
    enableTopToolbar: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableStickyHeader: false,
    muiTableContainerProps: { sx: { maxWidth } },
    layoutMode: "grid",
    // initialState: { density: "compact" },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
    muiTableHeadRowProps: {
      sx: theme => ({ boxShadow: "none", backgroundColor: theme.palette.background.default }),
    },
    muiTableHeadProps: {
      sx: () => ({
        opacity: 1,
      }),
    },
    muiTableBodyProps: {
      sx: theme => ({
        '& tr:nth-of-type(4n+3):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: `${theme.palette.background.default} !important` },
      }),
    },
    muiTableBodyRowProps: {
      hover: false,
      sx: theme => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
    muiTableHeadCellProps: {
      sx: () => ({
        ...muiTableHeadCellProps.sx,
        bgcolor: undefined,
      }),
      align: "center",
    },
    muiTableBodyCellProps: {
      sx: () => ({
        ...muiTableBodyCellProps.sx,
        bgcolor: undefined,
      }),
      align: "center",
    },
  });

  const handleGridPricingDataChange = (v: any) => {
    setGridPricingData(prev => {
      const newGridPricingData: GridPricingDataType = { ...prev };
      if (v.year) {
        newGridPricingData.year = v.year;
      }
      if (v.week) {
        newGridPricingData.week = v.week;
      }
      if (v.rowNumber) {
        const targetRow = newGridPricingData.prices.find(i => i.rowNumber === v.rowNumber);
        const newPrices = [...newGridPricingData.prices.filter(i => i.rowNumber !== v.rowNumber), { ...targetRow, ...v }];
        newGridPricingData.prices = newPrices;
      }
      exposeGridPricingData?.(newGridPricingData);
      return newGridPricingData;
    });
  };

  return (
    <ContentWrapper width="100%" spacing={2}>
      {isEditMode && (
        <ContentWrapper>
          <Form
            isHorizontal
            inputs={[
              {
                name: "gridPricingYear",
                label: "Year",
                type: "number",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: data.year,
                gridLayout: { xs: 6, sm: 4, md: 3, lg: 2, xl: 1 },
                exposeValue: v => {
                  if (v >= 2020 && v <= 2050) {
                    setYear(v);
                    handleGridPricingDataChange({ year: Number(v) });
                  }
                },
              },
              {
                name: "gridPricingWeek",
                label: "Week No.",
                type: "number",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: data.week,
                gridLayout: { xs: 6, sm: 4, md: 3, lg: 2, xl: 1 },
                exposeValue: v => {
                  if (v > 0 && v <= 52) {
                    setWeek(isEvenNumber(v) ? v - 1 : v);
                    handleGridPricingDataChange({ week: Number(v) });
                  }
                },
              },
            ]}
          />
        </ContentWrapper>
      )}
      <MaterialReactTable table={table} />
      <Typography variant="textsm">All lambs graded as GLQ5+ receive $0.80 premium per kg.</Typography>
    </ContentWrapper>
  );
};

export default GridPricing;
