import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import LambDashboard from "./Dashboard";
import BeefDashboard from "../../BeefDataAnalysis/Supplier/Dashboard";

const Page = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Stack spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Supplier Lamb Dashboard" />
          <Tab label="Supplier Beef Dashboard" />
        </Tabs>
      </Stack>
      {tab === 0 ? <LambDashboard /> : <BeefDashboard />}
    </Stack>
  );
};

export default Page;
