import { Chip, ContentWrapper, Dialog, Hcharts, SmallButton } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import { Stack, Typography, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../../components/Chart/CustomChartTraceColors";
import _ from "lodash";
import { InfoOutlined } from "@mui/icons-material";
import Glossary from "../../Glossary";
import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";
import { isDarkMode } from "../../../../../utils/Helper";

const Section = ({ data, picWithFullInfo, loading }: { data: QueryResult; picWithFullInfo: LooseObject; loading: boolean }) => {
  const [openDialogForGlossary, setOpenDialogForGlossary] = useState<boolean>(false);

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const RETAIN_RAIL = "Retain Rail";

  const allDiseasesData = allBrands.map(o =>
    _.flattenDeep(dataToDisplay?.filter(i => i.BRAND === o).map(i => [...i.DISEASE_DESCRIPTIONS])).filter(i => i?.toLowerCase() !== RETAIN_RAIL.toLowerCase())
  );

  const glq5 = dataToDisplay?.filter(i => i.BRAND === "GLQ 5+");
  const glqGraded = dataToDisplay?.filter(i => i.BRAND === "GLQ Graded");
  const gql5RetainRailPercent = glq5 && glq5.length > 0 ? (glq5.filter(i => i.DISEASE_DESCRIPTIONS?.includes(RETAIN_RAIL)).length / glq5.length) * 100 : 0;
  const gqlGradedRetainRailPercent =
    glqGraded && glqGraded.length > 0 ? ((glqGraded.filter(i => i.DISEASE_DESCRIPTIONS?.includes(RETAIN_RAIL)).length / glqGraded.length) * 100).toFixed(1) : 0;
  const allRetainRailPercent =
    dataToDisplay && dataToDisplay.length > 0 ? ((dataToDisplay.filter(i => i.DISEASE_DESCRIPTIONS?.includes(RETAIN_RAIL)).length / dataToDisplay.length) * 100).toFixed(1) : 0;

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        stacking="normal"
        title={
          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between" width="100%">
            <Typography variant="textmd" fontWeight="semiBold" textAlign="center">
              Animal Health
            </Typography>
            <SmallButton
              variant="contained"
              color="inherit"
              title="Fact Sheets"
              startIcon={<InfoOutlined />}
              sx={{
                py: 0.9,
                height: 30,
                bgcolor: "mybuttonbg.secondary",
                color: "mybuttonfg.secondary",
                borderColor: "myborder.secondary",
                borderWidth: "1px",
                borderStyle: "solid",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "mybuttonbg.secondaryHover",
                  boxShadow: "none",
                },
              }}
              onClick={() => setOpenDialogForGlossary(true)}
            />
          </Stack>
        }
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
              </Grid>
            </Grid>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Typography variant="textsm">{`Retain Rail: (GLQ 5+: ${gql5RetainRailPercent}%, GLQ Graded: ${gqlGradedRetainRailPercent}%, All: ${allRetainRailPercent}%)`}</Typography>
              </Grid>
            </Grid>
          </Stack>
        }
        xAxis={{ name: "disease", label: "Disease", type: "string", scale: null }}
        xAxisType="category"
        xCategoryOrder="sum descending"
        data={allBrands.map((o, index) => ({
          data: allDiseasesData[index].map(disease => ({ disease })),
          name: o,
        }))}
        traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        height={515}
      />
      <Dialog open={openDialogForGlossary} onClose={() => setOpenDialogForGlossary(false)} isTransparent>
        <Glossary onCancel={() => setOpenDialogForGlossary(false)} showFactSheetsOnly />
      </Dialog>
    </ContentWrapper>
  );
};

export default Section;
