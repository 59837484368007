import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgressIndicator, Tile, TileContentText } from "../../../../components";
import { LooseObject } from "../../../../utils/Types";
import { isNotEmpty } from "../../../../utils/Helper";

const Tiles = ({ data }: { data: LooseObject }) => {
  return isNotEmpty(data) ? (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="Avg HSCW Kg"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(data.AVG_HSCW_KG) ? `${data.AVG_HSCW_KG}kg` : "N/A"} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg IMF %"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(data.AVG_IMF_PERCENTAGE_OF) ? `${data.AVG_IMF_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={data.AVG_IMF_PERCENTAGE_OF} maxValue={10} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="AVG LMY %"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(data.AVG_LMY_PERCENTAGE_OF) ? `${data.AVG_LMY_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={data.AVG_LMY_PERCENTAGE_OF} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg GLQ"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(data.AVG_GLQ) ? data.AVG_GLQ : "N/A"} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  ) : undefined;
};

export default Tiles;
