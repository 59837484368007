import { LooseObject, QueryResult } from "../../../../utils/Types";
import { ContentWrapper, MinimalTable } from "../../../../components";
import { avoidJsFloatingPointPrecisionIssue, isNotEmpty } from "../../../../utils/Helper";

const GridReport = ({ data, loading, supplierWithFullInfo }: { data?: QueryResult; loading: boolean; supplierWithFullInfo: LooseObject }) => {
  const muiTableHeadCellProps = {
    sx: {
      bgcolor: "primary.main",
      fontWeight: "semiBold",
      color: "mytext.primaryOnBrand",
    },
  };

  const columns = [
    {
      accessorKey: "GRADE_NAME",
      header: "Grid Name",
      muiTableHeadCellProps,
      maxSize: 200,
    },
    {
      accessorKey: "HEAD",
      header: "Head",
      muiTableHeadCellProps,
    },
    {
      accessorKey: "HOTWEIGHT",
      header: "Hot Weight",
      muiTableHeadCellProps,
    },
  ];

  const rows = data?.rows ? [...data?.rows] : [];
  if (rows.length > 0) {
    let totalHeads = 0;
    let totalHotWeight = 0;
    rows.forEach(row => {
      totalHeads += isNotEmpty(row.HEAD) ? row.HEAD : 0;
      totalHotWeight += isNotEmpty(row.HOTWEIGHT) ? row.HOTWEIGHT : 0;
    });
    rows.push({
      GRADE_NAME: "Total",
      HEAD: avoidJsFloatingPointPrecisionIssue(totalHeads),
      HOTWEIGHT: avoidJsFloatingPointPrecisionIssue(totalHotWeight),
    });
  }

  return (
    <ContentWrapper width="100%" maxWidth={{ xs: `calc(100vw - 42px)`, md: `calc(100vw -  146px)` }} spacing={1}>
      <MinimalTable
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ staticRowIndex }) => {
          if (staticRowIndex === rows.length - 1) {
            return {
              sx: {
                "& .MuiTableCell-root": {
                  fontWeight: "semiBold",
                },
              },
            };
          }
          return {};
        }}
      />
    </ContentWrapper>
  );
};

export default GridReport;
