import { HelpOutline } from "@mui/icons-material";
import { Stack, Typography, Popover, MenuList, MenuItem, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import CopyButton from "../Button/CopyButton";

const ShowGMPContact = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <Stack display="flex" pl={1}>
      <Tooltip title="Help">
        <IconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ width: 16, height: 16 }}>
          <HelpOutline fontSize="small" sx={{ color: "mytext.tertiary" }} />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <MenuList dense sx={{ minWidth: 200 }}>
          <MenuItem
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
            disableTouchRipple
            sx={{
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent", // Remove hover background change
              },
              "&.Mui-selected": {
                backgroundColor: "transparent", // Remove selected background change
                "&:hover": {
                  backgroundColor: "transparent", // Ensure no background change on hover when selected
                },
              },
              "&:focus": {
                backgroundColor: "transparent", // Remove focus background change
              },
              cursor: "default", // Change cursor to default
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography variant="textxs" fontWeight="semiBold">
                Got questions? Reach out to us at gls@gmpgundagai.com.au.
              </Typography>
              <CopyButton title="Copy email address" text="gls@gmpgundagai.com.au" size={20} sx={{ width: 20, height: 20 }} />
            </Stack>
          </MenuItem>
        </MenuList>
      </Popover>
    </Stack>
  );
};

export default ShowGMPContact;
