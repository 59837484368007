import { Stack } from "@mui/material";
import { ChipPredefined } from "../../components";
import { GmpBookingStatus } from "../../utils/Types";
import { ChipProps } from "../../components/Chip";

export const StatusChip = ({ label, textLabel, ...rest }: ChipProps & { textLabel?: string }) => {
  let type: "error" | "warning" | "success" | "successContained" = "successContained";
  switch (label) {
    case GmpBookingStatus.NotApplicable:
      type = "error";
      break;
    case GmpBookingStatus.Scheduled:
      type = "warning";
      break;
    case GmpBookingStatus.Assigned:
      type = "success";
      break;
  }
  switch (textLabel) {
    case GmpBookingStatus.NotApplicable:
      type = "error";
      break;
    case GmpBookingStatus.Scheduled:
      type = "warning";
      break;
    case GmpBookingStatus.Assigned:
      type = "success";
      break;
  }

  return <ChipPredefined type={type} label={label} {...rest} />;
};

const StatusChips = () => (
  <Stack direction="row" spacing={{ xs: 0.2, sm: 1 }}>
    <StatusChip label={GmpBookingStatus.NotApplicable} sx={{ fontSize: "0.75rem", lineHeight: 1.5 }} tooltipTitle="Producer is not available to book on these weeks." />
    <StatusChip
      label={GmpBookingStatus.Scheduled}
      sx={{ fontSize: "0.75rem", lineHeight: 1.5 }}
      tooltipTitle="The Producer has been booked. No supply agreement has been sent yet."
    />
    <StatusChip label={GmpBookingStatus.Assigned} sx={{ fontSize: "0.75rem", lineHeight: 1.5 }} tooltipTitle="The supply agreement has been sent but not returned yet." />
    <StatusChip
      label={GmpBookingStatus.Completed}
      sx={{ fontSize: "0.75rem", lineHeight: 1.5 }}
      tooltipTitle="The supply agreement has been returned and can be viewed on the weekly overview page."
    />
  </Stack>
);

export default StatusChips;
