import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Tiles from "./Tiles";
import CalendarView from "./CalendarView";
import StatusChips from "../StatusChips";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { LooseObject } from "../../../utils/Types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { logout, postToServer } from "../../../utils/Helper";
import _ from "lodash";
import { Spinner } from "../../../components";
import moment from "moment";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);

  const [settings, setSettings] = useState<LooseObject>();
  const [bookings, setBookings] = useState<LooseObject[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSettings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchSettings.cancel();
  }, []);

  const fetchSettings = _.debounce(async () => {
    await postToServer({
      action: "gmp/GetSettings",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject;
          setSettings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
  }, 500);

  useEffect(() => {
    fetchBookings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchBookings.cancel();
  }, []);

  const fetchBookings = _.debounce(async () => {
    await postToServer({
      action: "gmp/GetBookings",
      params: { year: moment().year(), week: moment().week() },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setBookings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
  }, 500);

  return !settings || settings.length === 0 ? (
    <Spinner />
  ) : (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Grid container spacing={1} mb={0.5} justifyContent="space-between">
          <Grid xs={12} sm={6}>
            <Typography variant="textmd" fontWeight="semiBold">
              Bookings
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }} alignItems="center">
            <StatusChips />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Tiles settings={settings} bookingsOfCurrentWeek={bookings} />
      </Grid>
      <Grid>
        <CalendarView weeklyLimit={settings.weeklyLimit} />
      </Grid>
    </Grid>
  );
};

export default Page;
