import { Chip, ChipPredefined, ContentWrapper, Dialog, Hcharts, Link } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult, QueryResultColumn } from "../../../../../utils/Types";
import _ from "lodash";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../../components/Chart/CustomChartTraceColors";
import { getHchartPointFormatExtra, isDarkMode } from "../../../../../utils/Helper";
import OverlayDialog from "./OverlayDialog";
import moment from "moment";

const Section = ({
  data,
  loading,
  averages,
  averagesGlobal,
  picWithFullInfo,
  percentiles,
}: {
  data: QueryResult;
  loading: boolean;
  averages: LooseObject;
  averagesGlobal: LooseObject;
  picWithFullInfo: LooseObject;
  percentiles: QueryResult | undefined;
}) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");
  const optionsOfYAxis = optionsOfXAxis;
  // const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>(optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis[0] : undefined);
  // const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>(optionsOfYAxis && optionsOfYAxis.length > 1 ? optionsOfYAxis[1] : undefined);
  const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>(
    optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis.find(i => i.name === "LMY_PERCENTAGE_OF") : undefined
  );
  const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>(optionsOfYAxis && optionsOfYAxis.length > 1 ? optionsOfXAxis.find(i => i.name === "HSCW_KG") : undefined);

  const [openForDialog, setOpenForDialog] = useState<boolean>(false);

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.SCATTER_PLOT}
        title="xAxisLabel vs yAxisLabel"
        titleExtra={
          <ChipPredefined
            type="success"
            label={
              <Link onClick={() => setOpenForDialog(true)} sx={theme => ({ textDecoration: "none", borderBottom: `1px solid ${theme.palette.success.main}` })}>
                <Typography variant="textsm" color="success.main">
                  View Full Report
                </Typography>
              </Link>
            }
          />
        }
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        axisSwitch={{
          optionsOfXAxis,
          optionsOfYAxis,
          defaultXAxisName: "LMY_PERCENTAGE_OF",
          defaultYAxisName: "HSCW_KG",
          exposeXAxis: setXAxis,
          exposeYAxis: setYAxis,
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        pointFormatExtra={getHchartPointFormatExtra(data.columns?.filter(i => ["PIC", "LOT_REFERENCE"].includes(i.name)) || [])}
        traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        subCharts={[{ type: ChartType.SCATTER_PLOT }, { type: ChartType.HISTOGRAM, title: "xAxisLabel" }]}
        annotations={
          xAxis?.name === "LMY_PERCENTAGE_OF" && yAxis?.name === "HSCW_KG"
            ? [
                {
                  draggable: "",
                  shapes: [
                    {
                      type: "path",
                      points: [
                        // bottom-left corner, bottom-right, top-right, top-let, back to bottom-left
                        {
                          xAxis: 0,
                          yAxis: 0,
                          x: 59,
                          y: 24,
                        },
                        {
                          xAxis: 0,
                          yAxis: 0,
                          x: 66,
                          y: 24,
                        },
                        {
                          xAxis: 0,
                          yAxis: 0,
                          x: 66,
                          y: 32,
                        },
                        {
                          xAxis: 0,
                          yAxis: 0,
                          x: 59,
                          y: 32,
                        },
                        {
                          xAxis: 0,
                          yAxis: 0,
                          x: 59,
                          y: 24,
                        },
                      ],
                      strokeWidth: 0,
                      fill: "rgba(171, 239, 198, 0.3)", // Fill color with transparency
                    },
                  ],
                  zIndex: -1, // Ensure the annotation is behind the chart series
                },
              ]
            : undefined
        }
      />
      <Dialog open={openForDialog} onClose={() => setOpenForDialog(false)} isTransparent maxWidth="xl">
        {averages && percentiles && (
          <OverlayDialog
            data={data}
            picWithFullInfo={picWithFullInfo}
            averages={averages}
            averagesGlobal={averagesGlobal}
            percentiles={percentiles}
            onCancel={() => setOpenForDialog(false)}
          />
        )}
      </Dialog>
    </ContentWrapper>
  );
};

export default Section;
