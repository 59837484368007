import Grid from "@mui/material/Unstable_Grid2";
import { LooseObject, QueryResult } from "../../../../../utils/Types";
import AusMsaScatter from "./AusMsaScatter";
import Histogram from "./Histogram";

const Charts = ({ loading, data, supplierWithFullInfo }: { loading: boolean; data: QueryResult; supplierWithFullInfo: LooseObject }) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <AusMsaScatter data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <Histogram data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} />
      </Grid>
    </Grid>
  );
};

export default Charts;
