import { IconButton, IconButtonProps } from "@mui/material";

const RoundIconButton = ({ children, sx, ...rest }: IconButtonProps) => (
  <IconButton
    sx={{
      borderRadius: "50%",
      border: 0,
      "& .MuiTouchRipple-root .MuiTouchRipple-child": {
        borderRadius: "50%",
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </IconButton>
);

export default RoundIconButton;
