import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Org } from "../../utils/Types";

export interface OrgsState {
  value: Org[];
  status: "idle" | "loading" | "failed";
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem("orgs");
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + " in localStorage orgs");
}

const initialState: OrgsState = {
  value: initialValue as Org[],
  status: "idle",
};

export const orgsSlice = createSlice({
  name: "orgs",
  initialState,
  reducers: {
    setOrgs: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setOrgs } = orgsSlice.actions;

export const selectOrgs = (state: RootState) => state.orgs.value;

export default orgsSlice.reducer;
