import { Stack, Typography } from "@mui/material";
import { DialogWrapper } from "../../../components";
import { GridPricingDataType } from "../../../utils/Types";
import GridPricing from "../GridPricing";
import moment from "moment";
import { useState } from "react";
import { isValidGmpGridPricingData } from "../../../utils/Helper";

const Add = ({ loading, handleSubmit, onCancel }: { loading: boolean; handleSubmit: (v: GridPricingDataType) => void; onCancel: () => void }) => {
  const [gridPricingData, setGridPricingData] = useState<GridPricingDataType>({
    year: moment().year(),
    week: moment().week(),
    prices: [
      { rowNumber: 1, hscwCategory: "<20", lmyCategory1: "", lmyCategory2: "", lmyCategory3: "", lmyCategory4: "", lmyCategory5: "" },
      { rowNumber: 2, hscwCategory: "20-24", lmyCategory1: "", lmyCategory2: "", lmyCategory3: "", lmyCategory4: "", lmyCategory5: "" },
      { rowNumber: 3, hscwCategory: "24-32", lmyCategory1: "", lmyCategory2: "", lmyCategory3: "", lmyCategory4: "", lmyCategory5: "" },
      { rowNumber: 4, hscwCategory: "32-34", lmyCategory1: "", lmyCategory2: "", lmyCategory3: "", lmyCategory4: "", lmyCategory5: "" },
      { rowNumber: 5, hscwCategory: ">34", lmyCategory1: "", lmyCategory2: "", lmyCategory3: "", lmyCategory4: "", lmyCategory5: "" },
    ],
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  return (
    <DialogWrapper
      onCancel={onCancel}
      onConfirm={() => {
        if (isValidGmpGridPricingData(gridPricingData)) {
          handleSubmit(gridPricingData);
        } else {
          setErrorMessage("Please check your input.");
        }
      }}
      buttonsAlignment="center"
      confirmText="Submit"
      loading={loading}
    >
      <Stack spacing={2} minWidth={{ xs: "100%", sm: "90vw", md: "80vw", lg: "70vw", xl: "60vw" }}>
        <Typography variant="textlg" fontWeight="semiBold">
          Add a New Grid Pricing
        </Typography>
        <GridPricing
          data={gridPricingData}
          isEditMode
          exposeGridPricingData={v => {
            setErrorMessage("");
            setGridPricingData(v);
          }}
        />
        {errorMessage && (
          <Typography color="error" textAlign="center">
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default Add;
