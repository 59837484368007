import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { logout, postToServer } from "../../../utils/Helper";
import { DateFormat, LooseObject } from "../../../utils/Types";
import { Table, DetailPanel } from "../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<LooseObject[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "administration/GetAllActionLog",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setData(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { id: "createdAt", accessorFn: row => moment(row.createdAt).format(DateFormat.DEFAULT), header: "Created At", maxSize: 130 },
    { accessorKey: "username", header: "Username" },
    // { id: "name", accessorFn: row => `${row.firstName} ${row.lastName}`, header: "Name" },
    { accessorKey: "route", header: "Route" },
  ];

  const dataToDisplay = data;
  const rows: MRT_RowData[] = dataToDisplay;

  return (
    <Stack spacing={3}>
      <Table
        title="All Action Log"
        loading={loading}
        columns={columns}
        data={rows}
        enableExpandAll={false}
        renderDetailPanel={({ row }) => (
          <DetailPanel
            data={[
              { label: "Roles", value: row.original.roles?.join(", ") },
              { label: "Parameters", value: JSON.stringify(row.original.params) },
            ]}
          />
        )}
        initialState={{ pagination: { pageSize: 20, pageIndex: 0 } }}
      />
    </Stack>
  );
};

export default Page;
