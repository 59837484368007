import { Box, IconButton, Tooltip } from "@mui/material";
import SvgIcon from "../SvgIcon";

const CopyButton = ({ title, text, color, size, sx }: { title?: string; text: string; color?: string; size?: number; sx?: {} }) => (
  <Box>
    <Tooltip title={title || "Copy"}>
      <IconButton
        sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", ...sx }}
        aria-label={"copy button"}
        onClick={() => navigator.clipboard.writeText(text)}
      >
        <SvgIcon name="Copy" color={color} size={size} sx={{ m: 1 }} />
      </IconButton>
    </Tooltip>
  </Box>
);

export default CopyButton;
