import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { Country, FormData, FormInputCategory, PkgName } from "../../../utils/Types";

const Edit = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">{`Update ${item.displayName}`}</Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit({ idString: item.idString, ...data })}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "displayName",
                label: "Display Name",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.displayName,
              },
              {
                name: "country",
                label: "Country",
                category: FormInputCategory.SELECT,
                options: Object.keys(Country).map(i => ({ label: i, value: i })),
                defaultValue: item.country,
              },
              {
                name: "pkgs",
                label: "Packages",
                category: FormInputCategory.SELECT,
                options: Object.values(PkgName)
                  .filter(i => ![PkgName.MANAGEMENT, PkgName.ADMINISTRATION].includes(i))
                  .map(o => ({ label: o, value: o })),
                defaultValue: item.pkgs,
                multiple: true,
              },
              {
                name: "description",
                label: "Description",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.description,
              },
              {
                name: "isTCRequired",
                category: FormInputCategory.CHECK_BOX,
                label: "TC Required",
                defaultValue: item.isTCRequired,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Edit;
