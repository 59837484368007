import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, ContentWrapper, Dialog, DialogWrapper, RoundIconButton, SvgIcon } from "../../../../../../components";
import { LooseObject } from "../../../../../../utils/Types";
import _ from "lodash";
import { useEffect, useState } from "react";

const ListOfAgreements = ({
  loading,
  currentBooking,
  listOfAgreements,
  onSubmit,
  onAddAnother,
  onEdit,
  onDelete,
  shouldShowButtons,
}: {
  loading: boolean;
  currentBooking: LooseObject;
  listOfAgreements: LooseObject[];
  onSubmit: () => void;
  onAddAnother: () => void;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  shouldShowButtons: boolean;
}) => {
  const totalSupplied = _.sum(listOfAgreements.map(i => Number(i.agreedQuantity)));
  const [openDialog, setOpenDialog] = useState(false);

  const [showButtons, setShowButton] = useState(shouldShowButtons);

  useEffect(() => {
    setShowButton(shouldShowButtons);
  }, [shouldShowButtons]);

  return (
    <Grid container direction="column">
      {listOfAgreements.map((i, index) => (
        <Grid key={`agreement${index + 1}`}>
          <ContentWrapper>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="textmd" fontWeight="semiBold">
                  {currentBooking.bookingDate}
                </Typography>
                <Typography variant="textsm" fontWeight="semiBold" mt={2}>{`Agreement ${i.id}`}</Typography>
                <Typography variant="textsm">{`${i.producerFirstName} ${i.producerLastName} (${i.producerBusinessName})`}</Typography>
                <Typography variant="textsm">{`PIC: ${i.pic}`}</Typography>
                <Typography variant="textsm">{`Quantity: ${i.agreedQuantity}`}</Typography>
                {i.isMuelsed && <Typography variant="textsm">Muelsed: Yes</Typography>}
                {i.isMsaAccredited && <Typography variant="textsm">MSA Accredited: Yes</Typography>}
                {i.isComplyWithLPA && <Typography variant="textsm">Comply with LPA: Yes</Typography>}
                {i.isGrassFed && <Typography variant="textsm">Grass Fed: Yes</Typography>}
                {i.isFreeRange && <Typography variant="textsm">Free Range: Yes</Typography>}
                {i.isAntibioticFree && <Typography variant="textsm">Antibiotic Free: Yes</Typography>}
                {i.isHormoneAndGrowthPromotantFree && <Typography variant="textsm">Hormone and Growth Promotant Free: Yes</Typography>}
              </Stack>
              <Stack justifyContent="space-between">
                <RoundIconButton onClick={() => onEdit(i.id)}>
                  <SvgIcon name="Edit" />
                </RoundIconButton>
                <RoundIconButton onClick={() => onDelete(i.id)}>
                  <SvgIcon name="Trash" />
                </RoundIconButton>
              </Stack>
            </Stack>
          </ContentWrapper>
        </Grid>
      ))}
      <Grid>
        <Stack spacing={2}>
          {listOfAgreements.length > 0 && (
            <Typography variant="textsm" color={totalSupplied > currentBooking.quantity ? "error" : undefined}>{`Total Supplied: ${totalSupplied} Head`}</Typography>
          )}
          <Stack direction="row" spacing={2}>
            {listOfAgreements.length > 0 && showButtons && (
              <Button
                loading={loading}
                variant="contained"
                title="Submit all"
                onClick={() => {
                  if (totalSupplied > currentBooking.quantity) {
                    setOpenDialog(true);
                  } else {
                    onSubmit();
                  }
                }}
              />
            )}
            {showButtons && <Button variant="outlined" title={listOfAgreements.length > 0 ? "Add another" : "Add a new supply agreement"} onClick={onAddAnother} />}
          </Stack>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)} isTransparent fullScreen={false}>
            <DialogWrapper title="Supplied too many head" onCancel={() => setOpenDialog(false)} cancelText="Ok" displayCancelButton>
              <Typography>
                Your supplied head is more than booked. <br />
                Please modify it before submit.
              </Typography>
            </DialogWrapper>
          </Dialog>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ListOfAgreements;
