import { Stack, SxProps, Typography } from "@mui/material";
import { MRT_ColumnDef, MRT_Row, MRT_RowData, MRT_RowSelectionState, MRT_TableOptions, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import { OnChangeFn } from "@tanstack/table-core";
import { calculateTableColumnMaxSize, getDateFormat, isNotEmpty, isNumber } from "../../utils/Helper";
import { Org, QueryResultColumn } from "../../utils/Types";
import SvgIcon from "../SvgIcon";
import Center from "../Center";
import NoRecords from "../NoRecords";
import SmallButton from "../Button/SmallButton";
import { FileDownload } from "@mui/icons-material";

export const reSplitAlphaNumeric = /([0-9]+)/gm;

const numberFilter = (row: any, _columnIds: any, filterValue: any) => {
  const value = row.getValue(_columnIds);

  if ((isNotEmpty(filterValue[0]) || isNotEmpty(filterValue[1])) && !isNumber(value)) {
    return false;
  }

  if (filterValue[0] && (!isNumber(filterValue[0]) || Number(filterValue[0]) > (value as number))) {
    return false;
  }

  if (filterValue[1] && (!isNumber(filterValue[1]) || Number(filterValue[1]) < (value as number))) {
    return false;
  }
  return true;
};

const compareDateOnly = (aStr: string, bStr: string, org: Org) => {
  if (aStr === null || aStr === undefined) {
    return -1;
  }

  if (bStr === null || bStr === undefined) {
    return 1;
  }

  const dateA = moment(aStr, getDateFormat(org, "short"));
  const dateB = moment(bStr, getDateFormat(org, "short"));
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

const compareDatetime = (aStr: string, bStr: string, org: Org) => {
  if (aStr === null || aStr === undefined) {
    return -1;
  }

  if (bStr === null || bStr === undefined) {
    return 1;
  }

  const dateA = moment(aStr, getDateFormat(org, "default"));
  const dateB = moment(bStr, getDateFormat(org, "default"));
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

export interface TableProps<TData extends MRT_RowData> extends MRT_TableOptions<TData> {
  loading?: boolean;
  title?: string;
  columnDataTypes?: QueryResultColumn[];
  org?: Org;
  muiTableHeadSx?: SxProps;
  muiTableBodySx?: SxProps;
  muiTableHeadCellSx?: SxProps;
  muiTableBodyCellSx?: SxProps;
  muiTopToolbarSx?: SxProps;
  muiTablePaperSx?: SxProps;
  muiTableContainerSx?: SxProps;
  muiDetailPanelSx?: SxProps;
  exposeRowModel?: (flatRows: MRT_Row<TData>[]) => void;
  handleExportDataToCsv?: () => void;
  enableExpandMultiple?: boolean;
}

const Table = <TData extends MRT_RowData>({
  loading,
  title,
  columns,
  columnDataTypes,
  org,
  sortingFns,
  filterFns,
  initialState,
  renderTopToolbarCustomActions,
  renderDetailPanel,
  muiTableHeadSx,
  muiTableBodySx,
  muiTableHeadCellSx,
  muiTableBodyCellSx,
  muiTopToolbarSx,
  muiTablePaperSx,
  muiTableContainerSx,
  muiTableHeadProps,
  muiTableBodyProps,
  muiTableHeadCellProps,
  muiTableBodyRowProps,
  muiTableBodyCellProps,
  muiTopToolbarProps,
  muiTableContainerProps,
  muiDetailPanelProps,
  muiDetailPanelSx,
  state,
  enableStickyHeader,
  enableColumnFilters,
  enableColumnPinning,
  enableColumnActions,
  enableRowActions,
  enableHiding,
  enableDensityToggle,
  enableRowSelection,
  enableMultiRowSelection,
  exposeRowModel,
  handleExportDataToCsv,
  enableExpandMultiple,
  ...rest
}: TableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); // only for single row selection

  const optimisedColumns: MRT_ColumnDef<TData, any>[] = columns.map(i => {
    const i_withMoreOptions = { ...i }; // this is to make sure not overide the original options
    const columnDataType = columnDataTypes?.find(t => t.name === i.accessorKey);
    if (columnDataType?.type === "number") {
      i_withMoreOptions.filterFn = "number";
      i_withMoreOptions.filterVariant = "range";
    }
    i_withMoreOptions.maxSize = i.maxSize || calculateTableColumnMaxSize(i);

    return { ...i_withMoreOptions, ...i };
  });

  const handleRowSelectionChange: OnChangeFn<MRT_RowSelectionState> = updaterOrValue => {
    const newState = typeof updaterOrValue === "function" ? updaterOrValue({}) : updaterOrValue;
    setRowSelection(newState);
  };

  const tableOptions: MRT_TableOptions<TData> = {
    columns: optimisedColumns,
    sortingFns: {
      dateOnly: (rowA: any, rowB: any, columnId: any): number =>
        compareDateOnly(rowA.getValue(columnId)?.toString().toLowerCase(), rowB.getValue(columnId)?.toString().toLowerCase(), org),
      datetime: (rowA: any, rowB: any, columnId: any): number =>
        compareDatetime(rowA.getValue(columnId)?.toString().toLowerCase(), rowB.getValue(columnId)?.toString().toLowerCase(), org),
      ...sortingFns,
    },
    filterFns: {
      number: numberFilter,
      ...filterFns,
    },
    renderTopToolbarCustomActions:
      renderTopToolbarCustomActions ||
      (() =>
        (title || handleExportDataToCsv) && (
          <Stack direction="row" spacing={2} alignItems="center">
            {title && (
              <Typography variant="textxl" fontWeight="semiBold">
                {title}
              </Typography>
            )}
            {handleExportDataToCsv && (
              <SmallButton
                title="Export All Data to CSV"
                startIcon={<FileDownload />}
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={handleExportDataToCsv}
              />
            )}
          </Stack>
        )),
    renderEmptyRowsFallback: () => (
      <Center p={2}>
        <Typography variant="textsm">No records to display</Typography>
      </Center>
    ),
    renderDetailPanel,
    enableStickyHeader: enableStickyHeader === undefined ? true : enableStickyHeader,
    enableColumnFilters: enableColumnFilters === undefined ? false : enableColumnFilters,
    enableColumnPinning: enableColumnPinning === undefined ? false : enableColumnPinning,
    enableColumnActions: enableColumnActions === undefined ? false : enableColumnActions,
    enableRowActions: enableRowActions === undefined ? false : enableRowActions,
    enableHiding: enableHiding === undefined ? true : enableHiding,
    enableDensityToggle: enableDensityToggle === undefined ? false : enableDensityToggle,
    enableRowSelection,
    muiTableBodyProps: {
      sx: theme => ({
        ...(renderDetailPanel
          ? { '& tr:nth-of-type(4n+3):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: `${theme.palette.mybg.secondary} !important` } }
          : { '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: "mybg.secondary" } }), // otherise bgcolor of detail panel will not working
        ...(muiTableBodySx as any),
      }),
      ...muiTableBodyProps,
    },
    muiTableHeadProps: {
      sx: () => ({
        opacity: 1,
        ...(muiTableHeadSx as any),
      }),
      ...muiTableHeadProps,
    },
    muiTableHeadCellProps: {
      sx: () => ({
        borderWidth: 0,
        borderColor: "myborder.secondary",
        borderStyle: "solid",
        bgcolor: "mybg.secondary",
        fontWeight: "semiBold",
        color: "mytext.tertiary",
        ...(muiTableHeadCellSx as any),
      }),
      ...muiTableHeadCellProps,
    },
    muiTableBodyRowProps: props => (typeof muiTableBodyRowProps === "function" ? { hover: false, ...muiTableBodyRowProps(props) } : { hover: false, ...muiTableBodyRowProps }),
    muiTableBodyCellProps: {
      sx: () => ({
        borderWidth: 0,
        borderColor: "myborder.secondary",
        borderStyle: "solid",
        whiteSpace: "normal",
        opacity: 1,
        ...(muiTableBodyCellSx as any),
      }),
      ...muiTableBodyCellProps,
    },
    muiTopToolbarProps: {
      sx: () => ({
        margin: 1,
        ...(muiTopToolbarSx as any),
      }),
      ...muiTopToolbarProps,
    },
    muiTablePaperProps: {
      sx: {
        border: theme => `1px solid ${theme.palette.myborder.secondary}`,
        boxShadow: "none",
        ...(muiTablePaperSx as any),
      },
    },
    muiTableContainerProps: {
      sx: {
        ...(muiTableContainerSx as any),
      },
      ...muiTableContainerProps,
    },
    muiDetailPanelProps: {
      sx: () => ({
        bgcolor: "mybg.quarterary",
        ...(muiDetailPanelSx as any),
      }),
      ...muiDetailPanelProps,
    },
    paginationDisplayMode: "pages",
    muiExpandButtonProps: ({ row, table }) =>
      enableExpandMultiple
        ? {}
        : {
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //single expand
          },
    state: { isLoading: loading, ...state },
    icons: {
      FullscreenIcon: () => <SvgIcon size={24} name="FullScreen" />,
      FullscreenExitIcon: () => <SvgIcon size={24} name="FullScreenExit" />,
      ViewColumnIcon: () => <SvgIcon size={24} name="Column" />,
    },
    initialState: { density: "compact", showGlobalFilter: true, ...initialState },
    ...rest,
  };
  if (enableRowSelection && enableMultiRowSelection === false) {
    tableOptions.onRowSelectionChange = handleRowSelectionChange;
    tableOptions.state = { ...tableOptions.state, rowSelection };
  }

  const table = useMaterialReactTable(tableOptions);

  const currentRows = table.getPrePaginationRowModel().flatRows; // rows of all pages

  useEffect(() => {
    if (exposeRowModel) {
      exposeRowModel(currentRows);
    }
  }, [currentRows]);

  return optimisedColumns.length === 0 ? (
    <NoRecords />
  ) : (
    <MaterialReactTable
      table={table} //only pass in table instead of all table options
    />
  );
};

export default Table;
