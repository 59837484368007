import * as openpgp from "openpgp";
import {
  MessageText,
  PkgName,
  ServerRequest,
  ServerResponse,
  ServerResponseStatusCode,
  Org,
  DateFormat,
  DateFormatUSA,
  Permission,
  Role,
  QueryResult,
  LooseObject,
  JsDataType,
  QueryResultTable,
  DataPoolItem,
  Message,
  Dispatch,
  DateFormatServer,
  GridPricingDataType,
  QueryResultColumn,
  ZitadelOrg,
  ZitadelAuth,
} from "./Types";
import {
  CHART_DATA_COUNT_THRESHHOLD,
  DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS,
  DEFAULT_MESSAGE,
  FORMATTED,
  MAIN_ENTRY_PAGE,
  PERCENTAGE_OF,
  QUERY_DATA_SOURCE_ENDPOINT,
  SUPER_ADMIN,
  SUPER_USER,
  UPPER_CASE_TERMS,
  WORDS_EXCLUDING_FROM_PAGE_TITLE,
} from "./Constants";
import axios from "axios";
import _ from "lodash";
// import { Dispatch } from "@reduxjs/toolkit";
import { setUser } from "../redux/reducers/userSlice";
import { NavigateFunction } from "react-router-dom";
import { setOrgs } from "../redux/reducers/orgsSlice";
import { User } from "oidc-client-ts";
import { setRoles } from "../redux/reducers/rolesSlice";
import type { Feature, FeatureCollection, Point } from "geojson";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import moment from "moment";
import { bytesToHex as toHex } from "@noble/hashes/utils";
import { sha256 } from "@noble/hashes/sha256";
import { setDataPool } from "../redux/reducers/dataPoolSlice";
import seedrandom from "seedrandom";
import { ColumnHeader, mkConfig } from "export-to-csv";
import { Theme } from "@mui/material";
import { setZitadelOrg } from "../redux/reducers/zitadelOrgSlice";
import { createZitadelAuth, ZitadelConfig } from "@zitadel/react";

export const postToServer = async ({ action, params, token, zitadelOrgIdString }: ServerRequest) => {
  const result: ServerResponse = {
    message: DEFAULT_MESSAGE,
    serverData: null,
  };

  await axios
    .post((process.env.REACT_APP_API_ENDPOINT || `https://${window.location.hostname}/api`) + "/user/" + action, params, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token ? `Bearer ${token}` : null,
        zitadelOrgIdString,
      },
    })
    .then(async response => {
      result.statusCode = response.status;

      const { data } = response;

      result.message = {
        text: data.msg,
        type: data.code === ServerResponseStatusCode.SUCCESS ? "success" : "error",
      };

      if (data.code === ServerResponseStatusCode.SUCCESS && !_.isEmpty(data.data)) {
        result.serverData = JSON.parse(data.data);
      }
    })
    .catch(error => {
      if (error.response?.data) {
        result.message = {
          text: error.response.data.msg,
          type: "error",
        };
        result.statusCode = error.response?.status;
      } else {
        result.message = {
          text: MessageText.NETWORK_ERROR,
          type: "error",
        };
      }
    });

  return result;
};

export const getPkgFromUrl = (url: string) => {
  let pkg: string | undefined = "";
  if (url) {
    pkg = Object.values(PkgName).find(i => url.toLowerCase().indexOf(i.toLocaleLowerCase()) >= 0);
  }
  return pkg;
};

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

export const asyEncrypt = async ({ text, pubKey }: { text: string; pubKey: string }) => {
  const publicKey = await openpgp.readKey({ armoredKey: pubKey });
  const encrypted = await openpgp.encrypt({
    message: await openpgp.createMessage({ text }), // input as Message object
    encryptionKeys: publicKey,
  });
  return encrypted;
};

export const isNotEmpty = (data: any) =>
  data !== undefined && data !== null && data !== "" && JSON.stringify(data) !== "{}" && JSON.stringify(data) !== "()" && JSON.stringify(data) !== '"()"';

export const isStringEqualIgnoreCase = (str1: string, str2: string) => (str1 && str2 && str1.toLowerCase() === str2.toLowerCase() ? true : false);

export const isNumber = (value: any, acceptScientificNotation?: boolean) => {
  if (!acceptScientificNotation) {
    return /^-{0,1}\d+(\.\d+)?$/.test(value);
  }
  if (Array.isArray(value)) {
    return false;
  }
  return !isNaN(parseInt(value, 10));
};

export const isEvenNumber = (n: number): boolean => n % 2 === 0;

export const avoidJsFloatingPointPrecisionIssue = (num: number) => Math.round((num + Number.EPSILON) * 10000) / 10000;

export const formatNumber = (number: number, precision: number): number => {
  const fixedNumber = number.toFixed(precision);
  return parseFloat(fixedNumber);
};

export const getMaxDecimalsOfNumberArray = (arr: number[]) => Math.max(...arr.map(num => (num.toString().split(".")[1] || "").length));

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
};

export const getRandomString = (length = 18) => {
  const sample = "23456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz";
  return Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map(x => sample[x % sample.length])
    .join("");
};

type actAfterLoginSuccessProps = {
  orgs: Org[];
  user?: User;
  allRoles: string[];
  permission?: Permission;
  dispatch: Dispatch;
  navigate: NavigateFunction;
};
export const actAfterLoginSuccess = ({ orgs, user, allRoles, permission, dispatch, navigate }: actAfterLoginSuccessProps) => {
  if (orgs) {
    // save orgs to current browser
    localStorage.setItem("orgs", JSON.stringify(orgs));
    dispatch(
      setOrgs({
        type: "orgs/set",
        payload: orgs,
      })
    );
  }

  if (user) {
    // save user to current browser
    localStorage.setItem("user", JSON.stringify(user));
    dispatch(
      setUser({
        type: "user/set",
        payload: user,
      })
    );

    // save roles to current browser
    const defaultRoleIndex = 0;

    const roles: Role[] = allRoles.includes(SUPER_ADMIN)
      ? [{ role: SUPER_ADMIN, isCurrent: true }]
      : allRoles.includes(SUPER_USER)
      ? [{ role: SUPER_USER, isCurrent: true }]
      : allRoles.map((i, index) => ({ role: i, isCurrent: index === defaultRoleIndex ? true : false }));

    if (roles.length > 0 && roles[defaultRoleIndex]) {
      const currentRole = allRoles.includes(SUPER_ADMIN) || allRoles.includes(SUPER_USER) ? roles[0] : roles[defaultRoleIndex];
      currentRole.permission = permission;

      localStorage.setItem("roles", JSON.stringify(roles));
      dispatch(
        setRoles({
          type: "roles/set",
          payload: roles,
        })
      );
    }
  }

  if (orgs && orgs.filter(o => o?.isCurrent).length === 1) {
    navigate(MAIN_ENTRY_PAGE);
  }
};

const getZitadelRedirectUri = (reactRedirectUri: string) => {
  let redirectUri = reactRedirectUri || "https://www.meqinsights.com";
  if (process.env.REACT_APP_ENV === "development") {
    redirectUri = "http://localhost:3000";
  }
  if (window.location.hostname === "meqinsights.com") {
    // Ensure consistency with web URL to avoid ZITADEL errors
    redirectUri = "https://meqinsights.com";
  }
  return redirectUri;
};

export const getZitadelFromZitadelOrg = (zitadelOrg: ZitadelOrg) => {
  const config: ZitadelConfig = {
    authority: zitadelOrg?.authority,
    client_id: zitadelOrg?.reactClientId,
    redirect_uri: getZitadelRedirectUri(zitadelOrg!.reactRedirectUri) + "/callback",
    post_logout_redirect_uri: getZitadelRedirectUri(zitadelOrg!.reactRedirectUri),
  };
  const zitadel = createZitadelAuth(config) as unknown as ZitadelAuth;
  return zitadel;
};

export const logout = ({ dispatch, zitadelOrg }: { dispatch: Dispatch; zitadelOrg: ZitadelOrg }) => {
  getZitadelFromZitadelOrg(zitadelOrg).signout();

  dispatch(
    setUser({
      type: "user/remove",
      payload: null,
    })
  );
  dispatch(
    setOrgs({
      type: "orgs/remove",
      payload: null,
    })
  );
  dispatch(
    setZitadelOrg({
      type: "zitadelOrg/remove",
      payload: null,
    })
  );
  dispatch(
    setRoles({
      type: "roles/remove",
      payload: null,
    })
  );
  dispatch(
    setDataPool({
      type: "dataPool/remove",
      payload: null,
    })
  );

  localStorage.removeItem("user");
  localStorage.removeItem("orgs");
  localStorage.removeItem("roles");
};

export const getDateFormat = (org: Org, type: "default" | "default_with_timezone" | "short" | "full" | "day_and_time" | "day_and_month" | "unix_timestamp") => {
  let dateFormat = DateFormat.DEFAULT as string;
  const countriesUsingFormatUsa = ["USA"];

  switch (type) {
    case "default":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.DEFAULT : DateFormat.DEFAULT;
      break;
    case "default_with_timezone":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.DEFAULT_WITH_TIMEZONE : DateFormat.DEFAULT_WITH_TIMEZONE;
      break;
    case "short":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.SHORT : DateFormat.SHORT;
      break;
    case "full":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.FULL : DateFormat.FULL;
      break;
    case "day_and_time":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.DAY_AND_TIME : DateFormat.DAY_AND_TIME;
      break;
    case "day_and_month":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.DAY_AND_MONTH : DateFormat.DAY_AND_MONTH;
      break;
    case "unix_timestamp":
      dateFormat = countriesUsingFormatUsa.includes(org?.country || "") ? DateFormatUSA.UNIX_TIMESTAMP : DateFormat.UNIX_TIMESTAMP;
      break;
  }
  return dateFormat;
};

export const getOrgFromHostname = (hostname: string) => {
  let org = "";
  const orgString = hostname.split(".")[0];
  switch (orgString) {
    case "meqinsights":
    case "www":
    case "localhost":
    case "dev":
      org = "MEQ";
      break;
    case "alliance":
      org = "Alliance";
      break;
    case "gmp":
      org = "GMP";
      break;
  }

  return org;
};

export const getOrgFromRole = (role: string) => role.split("-")[0].split("_")[0];

export const getOrgFromOrgIdString = (orgIdString: string) => (orgIdString ? orgIdString.split("_")[0] : "NotValidOrg");

export const getRolesFromUser = async (user: any, zitadelOrgIdString?: string) =>
  await postToServer({ action: "UserRoles", params: {}, token: user.access_token, zitadelOrgIdString }).then(response => response.serverData as string[]);

export const getShortRoleFromRole = (role: string) => (role && role.split("-").length > 1 ? role.split("-")[1] : "NotValidRole");

export const loadUserPermission = async (user: User, role: string, zitadelOrgIdString?: string) => {
  let permission: Permission | undefined = undefined;

  if (user && role) {
    await postToServer({ action: "UserRolePermission", params: { role }, token: user.access_token, zitadelOrgIdString }).then(async response => {
      if (response.message.type === "success" && response.serverData) {
        const permissionFromServer = response.serverData as Permission;
        if (permissionFromServer) {
          permission = permissionFromServer;
        }
      }
    });
  }

  return permission;
};

export const getCurrentOrg = (orgs: Org[]) => orgs.find(i => i?.isCurrent);

export const getCurrentLocation = (orgs: Org[]) => {
  let location = "";
  const currentOrg = getCurrentOrg(orgs);
  if (currentOrg) {
    const orgWithLocation = currentOrg.idString.split("_");
    if (orgWithLocation.length > 1) {
      location = orgWithLocation[1];
    }
  }
  return location;
};

export const getLocationFromOrgIdString = (idString?: string) => {
  let location = "NotValidLocation";
  if (idString) {
    const strArray = idString.split("_");
    if (strArray.length > 1) {
      location = strArray[1];
    }
  }
  return location;
};

export const getCameraOwnerStringFromOrgIdString = (idString?: string) => (idString ? idString.toLowerCase().replaceAll("_", "-") : "");

export const getJsDataTypeFromServerDataType = (serverDataType?: string) => {
  let type: JsDataType = undefined;
  switch (serverDataType) {
    case "text":
      type = "string";
      break;
    case "fixed":
    case "real":
      type = "number";
      break;
    case "date":
      type = "date";
      break;
    case "timestamp_ntz":
    case "timestamp_tz":
      type = "datetime";
      break;
  }
  return type;
};

export const applyUpperCaseTerms = (str: string) => {
  let updatedStr = str;
  UPPER_CASE_TERMS.forEach(i => {
    updatedStr = updatedStr.replaceAll(i.toLowerCase(), i);
    updatedStr = updatedStr.replaceAll(_.startCase(i.toLowerCase()), i);
  });
  return updatedStr;
};

// transfer db data type to js data type
export const optimiseQueryResult = (queryResult?: QueryResult) => {
  if (queryResult) {
    const { columns, ...rest } = queryResult;
    return {
      columns:
        columns && columns.length > 0
          ? columns.map(i => ({
              name: i.name,
              label: applyUpperCaseTerms(_.startCase(i.name.toLowerCase()).replace(PERCENTAGE_OF, "%")),
              scale: i.scale,
              type: getJsDataTypeFromServerDataType(i.type),
            }))
          : [],
      ...rest,
    };
  }
  return undefined;
};

export const getDateRangeOptions = (optionKeys: readonly string[]) =>
  optionKeys.map(i => {
    const n = Number(i.split("-")[0]);
    const u = i.split("-")[1] === "d" ? "days" : "months";
    let datePoint: { n: number; u: "days" | "months" } = { n, u };
    return {
      key: i,
      label: `${n} ${_.startCase(n === 1 ? u.substring(0, u.length - 1) : u)}`,
      value: datePoint,
    };
  });

// for MapBox to use
export const parseArrayToFeatureCollection = (arr: { [key: string]: any }[], point: { longitudeKey: string; latitudeKey: string }): FeatureCollection<Point> => {
  const features: Feature<Point>[] = [];

  for (const obj of arr) {
    if (obj && typeof obj[point.latitudeKey] === "number" && typeof obj[point.longitudeKey] === "number") {
      const latitude = obj[point.latitudeKey];
      const longitude = obj[point.longitudeKey];

      const pointGeometry: Point = {
        type: "Point",
        coordinates: [longitude, latitude], // GeoJSON uses [longitude, latitude] order
      };

      const feature: Feature<Point> = {
        type: "Feature",
        geometry: pointGeometry,
        properties: obj, // Assigning the object as properties, you can modify this as per your requirements
      };

      features.push(feature);
    }
  }

  const featureCollection: FeatureCollection<Point> = {
    type: "FeatureCollection",
    features,
  };

  return featureCollection;
};

export const showAllAttributesWhenHoverChart = ({
  item,
  includeAttributes,
  excludeAttributes = [],
}: {
  item: LooseObject;
  includeAttributes?: string[];
  excludeAttributes?: string[];
}) =>
  Object.keys(item)
    .filter(i => (includeAttributes ? includeAttributes.includes(i) : !excludeAttributes.includes(i)))
    .sort()
    .map(i => `${applyUpperCaseTerms(_.startCase(i.toLowerCase()).replace(PERCENTAGE_OF, "%"))}: ${item[i]}`)
    .join("<br />");

export const prepareQueryResultForTable = ({
  data,
  org,
  hiddenColumnNames,
  columnParams,
}: {
  data?: QueryResult;
  org: Org;
  hiddenColumnNames?: string[];
  columnParams?: LooseObject;
}): QueryResultTable => {
  data = optimiseQueryResult(data);
  const dateColumnNames = data?.columns?.filter(i => i.type === "date").map(i => i.name);
  const dateTimeColumnNames = data?.columns?.filter(i => i.type === "datetime").map(i => i.name);

  const tableColumns: MRT_ColumnDef<MRT_RowData, any>[] =
    data?.columns
      ?.filter(i => !hiddenColumnNames?.includes(i.name))
      ?.map(i => ({
        accessorKey: dateColumnNames?.includes(i.name) ? `${i.name}_${FORMATTED}` : dateTimeColumnNames?.includes(i.name) ? `${i.name}_${FORMATTED}` : i.name,
        header: i.label,
        ...(i.type === "date" ? { sortingFn: "dateOnly" } : i.type === "datetime" ? { sortingFn: "datetime" } : {}),
        ...(columnParams && Object.keys(columnParams).includes(i.name) ? columnParams[i.name] : {}),
      })) || [];

  const tableRows: MRT_RowData[] =
    data?.rows?.map(i => {
      const optimisedRow: LooseObject = {};
      dateColumnNames?.forEach(k => {
        optimisedRow[`${k}_${FORMATTED}`] = moment(i[k]).format(getDateFormat(org, "short"));
      });
      dateTimeColumnNames?.forEach(k => {
        optimisedRow[`${k}_${FORMATTED}`] = moment(i[k], DateFormatServer.DEFAULT_WITH_TIMEZONE).format(getDateFormat(org, "default_with_timezone"));
      });

      return { ...optimisedRow, ...i };
    }) || [];

  return { ...data, tableColumns, tableRows };
};

export const calculateTableColumnMaxSize = <TData extends MRT_RowData>(column: MRT_ColumnDef<TData, any>) => (column.header ? column.header.length * 10 : undefined);

export const objectWithKeySorted = (obj: LooseObject) => {
  const objWithKeySorted: LooseObject = {};
  const keys = Object.keys(obj).sort();
  for (let i = 0; i < keys.length; i++) {
    const value = obj[keys[i]];
    if ((value && JSON.stringify(value) !== JSON.stringify({})) || value === 0) {
      objWithKeySorted[keys[i]] = value;
    }
  }
  return objWithKeySorted;
};

export const generateDataPoolId = ({ params }: { params: LooseObject }) => toHex(sha256(isNotEmpty(params) ? JSON.stringify(objectWithKeySorted(params)) : ""));

// should be only used for data not changed often, i.e, data from snowflake
export const getDataFromDataPool = async ({
  dataPool,
  params,
  token,
  dispatch,
  zitadelOrg,
  snackbar,
}: {
  dataPool: DataPoolItem[];
  params: LooseObject;
  token: string;
  dispatch: Dispatch;
  zitadelOrg: ZitadelOrg;
  snackbar: { open: (m: Message) => void; close: () => void };
}): Promise<QueryResult | undefined> => {
  const dataPoolId = generateDataPoolId({ params });
  const existingDataPoolItem = dataPool.find(i => i.id === dataPoolId);
  let isExistingDataPoolItemExpired = existingDataPoolItem && Date.now() - existingDataPoolItem.updatedAt > DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS;

  if (existingDataPoolItem && !isExistingDataPoolItemExpired) {
    return existingDataPoolItem.data;
  } else {
    let newQueryResult = undefined;

    // update dataPool
    await postToServer({
      action: QUERY_DATA_SOURCE_ENDPOINT,
      params,
      token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          newQueryResult = response.serverData as QueryResult;
          const newDataPoolItem: DataPoolItem = {
            id: dataPoolId,
            params,
            data: newQueryResult,
            updatedAt: Date.now(),
          };
          const newDataPool = [...dataPool.filter(i => (isExistingDataPoolItemExpired ? i.id !== existingDataPoolItem!.id : true)), newDataPoolItem];
          dispatch(
            setDataPool({
              type: "dataPool/set",
              payload: newDataPool,
            })
          );
        } else {
          snackbar.open(response.message);
        }
      }
    });
    return newQueryResult;
  }
};

export const seedLodash = (seed: string) => {
  const orig = Math.random;
  seedrandom(seed, { global: true });
  const lodash = _.runInContext();
  Math.random = orig;
  return lodash;
};

export const sampleData = (data: LooseObject[] | undefined, size?: number) => {
  if (data) {
    return seedLodash("123").sampleSize(data, size || CHART_DATA_COUNT_THRESHHOLD);
  }
  return data || [];
};

export const optimisePageTitle = (title?: string) => {
  let optimisedTitle = title || "";
  WORDS_EXCLUDING_FROM_PAGE_TITLE.forEach(i => (optimisedTitle = optimisedTitle.replace(i, "").trim()));
  return optimisedTitle;
};

export const isMeqUser = (email: string) => email.endsWith("meqprobe.com");

export const getCsvConfig = (filename: string, columnHeaders?: Array<ColumnHeader> | undefined) => {
  return mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: !columnHeaders,
    filename,
    columnHeaders,
  });
};

export const isValidGmpGridPricingData = (gridPricingData: GridPricingDataType) => {
  let isValid = !!gridPricingData;
  if (gridPricingData.year < 2020 || gridPricingData.year > 2050) {
    isValid = false;
  }
  if (gridPricingData.week < 1 || gridPricingData.week > 52) {
    isValid = false;
  }
  gridPricingData.prices.forEach(i => {
    if (!i.lmyCategory1 || !i.lmyCategory2 || !i.lmyCategory3 || !i.lmyCategory4 || !i.lmyCategory5) {
      isValid = false;
    }
  });
  return isValid;
};

export const sortArrayByAnotherArray = (arrayToBeSorted: any[], key: string, arrayBy: (string | number)[]) =>
  arrayToBeSorted.sort((a, b) => arrayBy.indexOf(a[key]) - arrayBy.indexOf(b[key]));

export const getHchartPointFormatExtra = (attributes: QueryResultColumn[]) =>
  attributes.length > 0 ? attributes.map(i => `<br/>${i.label}: <b>{point.${i.name}}</b>`).join("") : "";

export const isDarkMode = (theme: Theme) => theme.palette.mode === "dark";
