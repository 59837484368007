import { Routes as ReactRouter, Route, BrowserRouter } from "react-router-dom";
import PageContainer from "./PageContainer";
import PrivateRoutes from "./PrivateRoutes";
import { NAV_PAGES, OTHER_PAGES } from "../utils/NavItems";
import { getOrgFromOrgIdString, optimisePageTitle } from "../utils/Helper";
import { useAppSelector } from "../redux/hooks";
import { selectOrgs } from "../redux/reducers/orgsSlice";
import { useContext, useEffect } from "react";
import { ClientContext } from "../utils/Contexts";

const Routes = () => {
  const allRoutes = NAV_PAGES.concat(OTHER_PAGES);

  const publicRoutes = allRoutes.filter(i => i.isPublic);
  const privateRoutes = allRoutes.filter(i => !i.isPublic);

  const orgs = useAppSelector(selectOrgs);

  const clientOverides = useContext(ClientContext);
  useEffect(() => {
    if (orgs && Array.isArray(orgs)) {
      const currentOrg = orgs?.find(i => i?.isCurrent);
      if (currentOrg) {
        clientOverides.set(getOrgFromOrgIdString(currentOrg.idString));
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <ReactRouter>
        {publicRoutes.map(item => (
          <Route key={item.title} path={item.to} element={<PageContainer title={`${item.title}`} children={item.page} hideTitle={item.hideTitle} hideNav={item.hideNav} />} />
        ))}
        <Route element={<PrivateRoutes />}>
          {privateRoutes.map(item => (
            <Route
              key={item.title}
              path={item.to}
              element={<PageContainer title={optimisePageTitle(item.title)} children={item.page} hideTitle={item.hideTitle} hideNav={item.hideNav} />}
            />
          ))}
        </Route>
      </ReactRouter>
    </BrowserRouter>
  );
};

export default Routes;
