import { Chip, ContentWrapper, Hcharts } from "../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../utils/Types";
import { ALL_LOTS } from "../../../../utils/Constants";
import _ from "lodash";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../components/Chart/CustomChartTraceColors";
import { isDarkMode } from "../../../../utils/Helper";
import moment from "moment";

const Section = ({ data, picWithFullInfo, loading }: { data: QueryResult; picWithFullInfo: LooseObject; loading: boolean }) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        histnorm="percent"
        stacking="normal"
        title="xAxisLabel By Brand"
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`PIC: ${picWithFullInfo.PIC}${picWithFullInfo.businessName && ` | ${picWithFullInfo.businessName}`}`} size="small" />
              </Grid>
              <Grid>
                <Chip
                  label={`${
                    picWithFullInfo.LOT_REFERENCE === ALL_LOTS ? picWithFullInfo.KILL_DATE : moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)
                  }`}
                  size="small"
                />
              </Grid>
              <Grid>
                <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        axisSwitch={{
          optionsOfXAxis,
          defaultXAxisName: "HSCW_KG",
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        subCharts={[{ type: ChartType.HISTOGRAM }, { type: ChartType.SCATTER_PLOT, title: "xAxisLabel vs yAxisLabel", axisSwitch: { optionsOfYAxis: optionsOfXAxis } }]}
      />
    </ContentWrapper>
  );
};

export default Section;
