import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../../utils/Types";
import { Chip, CircularProgressIndicator, ContentWrapper, DialogWrapper, Hcharts, Tile, TileContentText } from "../../../../../../components";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import { HISTOGRAM_Y_AXIS_TITLE_PERCENT } from "../../../../../../utils/Constants";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { formatPercentile, getPercentile } from "../../../../../GmpLambDataAnalysis/Producer/Dashboard/Charts/HistogramTrend";
import { generateChartTraceColors } from "../../../../../../components/Hcharts/Utils";

const getPercentileRange = (percentiles: LooseObject[] | undefined, percentile: string, trait: string) => {
  let range = "";
  if (percentiles && percentile && trait) {
    const sortedPercentiles = _.orderBy(percentiles, trait);
    const percentileRow = percentiles.find(i => i.PERCENTILE === percentile);
    if (percentileRow) {
      const index = sortedPercentiles.map(i => i.PERCENTILE).indexOf(percentileRow.PERCENTILE);
      if (index === 0) {
        range = `< ${percentileRow[trait]}`;
      } else if (index === sortedPercentiles.length - 1) {
        range = `> ${percentileRow[trait]}`;
      } else {
        range = `${sortedPercentiles[index][trait]} - ${sortedPercentiles[index + 1][trait]}`;
      }
    }
  }
  return range;
};

const RankingDialog = ({
  supplierWithFullInfo,
  averages,
  percentiles,
  onCancel,
}: {
  supplierWithFullInfo: LooseObject;
  averages: LooseObject;
  percentiles: QueryResult;
  onCancel: (values: any) => void;
}) => {
  const theme = useTheme();

  const percentileImf = getPercentile(percentiles?.rows, "IMF", averages.AVG_IMF_PERCENTAGE_OF);
  const percentileLmy = getPercentile(percentiles?.rows, "LMY", averages.AVG_LMY_PERCENTAGE_OF);

  const getNumberFromPercentile = (percentile: string) => 100 - (percentile ? Number(percentile.replace("top_", "").replace("_percentage_of", "")) : 0);

  let chartData: LooseObject[] = [];
  if (percentileImf) {
    chartData.push({ percentage: getNumberFromPercentile(percentileImf) });
  }
  if (percentileLmy) {
    chartData.push({ percentage: getNumberFromPercentile(percentileLmy) });
  }

  const chartBarColors = generateChartTraceColors({
    quantity: chartData.length,
    seedColorHex: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
  });
  if ((chartBarColors?.length || 0) >= chartData.length) {
    chartData = chartData.map((i, index) => ({ ...i, color: chartBarColors?.[index] }));
  }

  const xData: string[] = [];

  if (percentileImf) {
    xData.push("IMF");
  }
  if (percentileLmy) {
    xData.push("LMY");
  }

  return (
    <DialogWrapper onCancel={onCancel} title="Ranking LMY and IMF" minWidth={{ sm: "80vw", md: "60vw", lg: "50vw" }}>
      <Stack spacing={2}>
        <Grid container spacing={1}>
          <Grid>
            <Chip label={`Supplier: ${supplierWithFullInfo.SUPPLIER_NO} | ${supplierWithFullInfo.fullName}`} />
          </Grid>
          <Grid>
            <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
          </Grid>
          <Grid>
            <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: 1.5 }} />
        <Stack direction="row" spacing={2}>
          <Tile
            minWidth={222}
            title="Lean Meat Yield %"
            content={
              percentileLmy ? (
                <Stack spacing={2} direction="row">
                  <TileContentText text={averages.AVG_LMY_PERCENTAGE_OF + "%"} />
                  <CircularProgressIndicator value={averages.AVG_LMY_PERCENTAGE_OF} maxValue={10} size={40} borderWidth={10} hidePercentage />
                </Stack>
              ) : (
                <Typography>No Data</Typography>
              )
            }
          />
          {percentileLmy && (
            <Stack spacing={1}>
              <Chip label={formatPercentile(percentileLmy)} />
              <Typography variant="textsm">{`This mob is at the ${formatPercentile(percentileLmy).toLowerCase()} of mobs for the LMY.`}</Typography>
              <Typography variant="textsm">{`Suppliers in the ${formatPercentile(percentileLmy).toLowerCase()} had an average LMY of ${getPercentileRange(
                percentiles?.rows,
                percentileLmy,
                "LMY"
              )}.`}</Typography>
            </Stack>
          )}
        </Stack>
        <Divider sx={{ borderWidth: 1.5 }} />
        <Stack direction="row" spacing={2}>
          <Tile
            minWidth={222}
            title="Intramuscular Fat %"
            content={
              percentileImf ? (
                <Stack spacing={2} direction="row">
                  <TileContentText text={averages.AVG_IMF_PERCENTAGE_OF + "%"} />
                  <CircularProgressIndicator value={averages.AVG_IMF_PERCENTAGE_OF} maxValue={10} size={40} borderWidth={10} />
                </Stack>
              ) : (
                <Typography>No Data</Typography>
              )
            }
          />
          {percentileImf && (
            <Stack spacing={1}>
              <Chip label={formatPercentile(percentileImf)} />
              <Typography variant="textsm">{`This mob is at the ${formatPercentile(percentileImf).toLowerCase()} of mobs for the Intramuscular Fat.`}</Typography>
              <Typography variant="textsm">{`Suppliers in the ${formatPercentile(percentileImf).toLowerCase()} had an average Intramuscular Fat of ${getPercentileRange(
                percentiles?.rows,
                percentileImf,
                "IMF"
              )}.`}</Typography>
            </Stack>
          )}
        </Stack>
        <Divider sx={{ borderWidth: 1.5 }} />
        <ContentWrapper>
          <Hcharts
            type={ChartType.BAR_CHART}
            title=""
            xTitle=""
            yTitle={HISTOGRAM_Y_AXIS_TITLE_PERCENT}
            xData={xData}
            valueSuffix="%"
            yAxis={{ name: "percentage", label: HISTOGRAM_Y_AXIS_TITLE_PERCENT, type: "number", scale: null }}
            yAxisMin={0}
            yAxisMax={100}
            data={[
              {
                name: "Percentage",
                data: chartData,
                showInLegend: false,
              },
            ]}
            height={300}
          />
        </ContentWrapper>
      </Stack>
    </DialogWrapper>
  );
};

export default RankingDialog;
