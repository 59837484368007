import { Typography } from "@mui/material";
import { DialogWrapper } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";

const Delete = ({
  loading,
  row,
  onCancel,
  handleSubmit,
}: {
  loading: boolean;
  row: MRT_Row<MRT_RowData>;
  onCancel: () => void;
  handleSubmit: ({ id }: { id: string }) => void;
}) => {
  const item = row.original;

  return (
    <DialogWrapper loading={loading} onCancel={onCancel} title={`Confirm to delete ${item.pic}`} displayCancelButton onConfirm={() => handleSubmit({ id: item._id })}>
      <Typography>{`Are you sure you want to delete ${item.pic}?`}</Typography>
    </DialogWrapper>
  );
};

export default Delete;
