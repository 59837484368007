import { LooseObject } from "../../utils/Types";

const hsl2Hex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = parseInt("0x" + H[1] + H[1]);
    g = parseInt("0x" + H[2] + H[2]);
    b = parseInt("0x" + H[3] + H[3]);
  } else if (H.length === 7) {
    r = parseInt("0x" + H[1] + H[2]);
    g = parseInt("0x" + H[3] + H[4]);
    b = parseInt("0x" + H[5] + H[6]);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  return [h, s, l];
};

export const generateChartTraceColors = ({ quantity, seedColorHex }: { quantity: number; seedColorHex: string }) => {
  const [h, s, l] = hexToHSL(seedColorHex);
  const colors = [seedColorHex];

  if (quantity === 1) {
    return colors;
  }

  const hRange = 360 / (quantity + 2); // Adjust hue range dynamically based on quantity
  const sRange = { min: 40, max: 100 }; // Adjust saturation range
  const lRange = { min: 20, max: 80 }; // Adjust lightness range

  const hStep = Math.trunc(hRange);
  const sStep = Math.trunc((sRange.max - sRange.min) / (quantity - 1)); // Adjust saturation step
  const lStep = Math.trunc((lRange.max - lRange.min) / (quantity - 1)); // Adjust lightness step

  for (let i = 1; i < quantity; i++) {
    let hLocal = h + i * hStep;
    if (hLocal >= 360) {
      hLocal -= 360;
    }
    let sLocal = s + (i - 1) * sStep; // Adjust saturation calculation
    if (sLocal > sRange.max) {
      sLocal = sRange.max;
    } else if (sLocal < sRange.min) {
      sLocal = sRange.min;
    }
    let lLocal = l + (i - 1) * lStep; // Adjust lightness calculation
    if (lLocal > lRange.max) {
      lLocal = lRange.max;
    } else if (lLocal < lRange.min) {
      lLocal = lRange.min;
    }
    colors.push(hsl2Hex(hLocal, sLocal, lLocal));
  }

  return colors;
};

// Define types for the linear regression result
interface LinearRegressionResult {
  slope: number;
  intercept: number;
}

// Function to calculate linear regression
export const linearRegression = (x: number[], y: number[]): LinearRegressionResult => {
  let n = y.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  for (let i = 0; i < n; i++) {
    sumX += x[i];
    sumY += y[i];
    sumXY += x[i] * y[i];
    sumXX += x[i] * x[i];
  }

  let slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  let intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
};

export const getBins = ({ xName, yName }: { xName?: string; yName?: string }) => {
  let bins: LooseObject = {};
  const binSizes = [
    { name: "IMF %", bin: { start: 0, end: 7, size: 0.5 } },
    { name: "Hotweight Kg", bin: { start: 15, end: 30, size: 2 } },
    { name: "HSCW Kg", bin: { start: 15, end: 30, size: 2 } },
    { name: "Viascan Yield %", bin: { start: 45, end: 65, size: 2.5 } },
    { name: "Viascan Gr", bin: { start: 0, end: 20, size: 2 } },
    { name: "LMY %", bin: { start: 50, end: 70, size: 1 } },
    { name: "GLQ", bin: { start: 0, end: 10, size: 0.2 } },
  ];
  binSizes.forEach(b => {
    if (xName === b.name) {
      bins.xbins = b.bin;
    }
    if (yName === b.name) {
      bins.ybins = b.bin;
    }
  });

  return bins;
};

const getPrecision = (num: number) => {
  const str = num.toString();
  if (str.includes(".")) {
    return str.split(".")[1].length;
  } else {
    return 0;
  }
};

export const calculateMaxPrecision = (data: { x: number; y: number }[]) => {
  let maxXPrecision = 0;
  let maxYPrecision = 0;

  data.forEach(point => {
    const xPrecision = getPrecision(point.x);
    const yPrecision = getPrecision(point.y);

    if (xPrecision > maxXPrecision) maxXPrecision = xPrecision;
    if (yPrecision > maxYPrecision) maxYPrecision = yPrecision;
  });

  return { xPrecision: maxXPrecision, yPrecision: maxYPrecision };
};

// Utility function to calculate IQR
const calculateIQR = (data: number[]) => {
  const sortedData = [...data].sort((a, b) => a - b);
  const q1 = sortedData[Math.floor(sortedData.length / 4)];
  const q3 = sortedData[Math.floor(sortedData.length * (3 / 4))];
  return q3 - q1;
};

const roundToPrecision = (value: number, precision: number) => {
  return parseFloat(value.toFixed(precision));
};

export const sturgesBinSize = (data: { x: number; y: number }[], precision: { xPrecision: number; yPrecision: number }, binFactor: number = 1, minBinSize: number = 1) => {
  const numBins = (Math.log2(data.length) + 1) * binFactor; // Increase the number of bins
  const xRange = Math.max(...data.map(d => d.x)) - Math.min(...data.map(d => d.x));
  const yRange = Math.max(...data.map(d => d.y)) - Math.min(...data.map(d => d.y));
  const xBinSize = Math.max(roundToPrecision(xRange / numBins, precision.xPrecision), minBinSize);
  const yBinSize = Math.max(roundToPrecision(yRange / numBins, precision.yPrecision), minBinSize);
  return { xBinSize, yBinSize };
};

export const sqrtBinSize = (data: { x: number; y: number }[], precision: { xPrecision: number; yPrecision: number }) => {
  const numBins = Math.sqrt(data.length);
  const xRange = Math.max(...data.map(d => d.x)) - Math.min(...data.map(d => d.x));
  const yRange = Math.max(...data.map(d => d.y)) - Math.min(...data.map(d => d.y));
  return {
    xBinSize: roundToPrecision(xRange / numBins, precision.xPrecision),
    yBinSize: roundToPrecision(yRange / numBins, precision.yPrecision),
  };
};

export const freedmanDiaconisBinSize = (data: { x: number; y: number }[], precision: { xPrecision: number; yPrecision: number }) => {
  const xIQR = calculateIQR(data.map(d => d.x));
  const yIQR = calculateIQR(data.map(d => d.y));
  const xBinWidth = 2 * (xIQR / Math.cbrt(data.length));
  const yBinWidth = 2 * (yIQR / Math.cbrt(data.length));
  return {
    xBinSize: roundToPrecision(xBinWidth, precision.xPrecision),
    yBinSize: roundToPrecision(yBinWidth, precision.yPrecision),
  };
};

// Adding a small jitter to points to avoid exact overlap
export const addJitter = (data: { x: number; y: number; count: number }[], jitterAmount: { x: number; y: number }, precision: { xPrecision: number; yPrecision: number }) => {
  return data.map(point => ({
    x: roundToPrecision(point.x + (Math.random() - 0.5) * jitterAmount.x, precision.xPrecision),
    y: roundToPrecision(point.y + (Math.random() - 0.5) * jitterAmount.y, precision.yPrecision),
    count: point.count,
  }));
};

export const aggregateData = (data: { x: number; y: number }[], binSize: { xBinSize: number; yBinSize: number }, precision: { xPrecision: number; yPrecision: number }) => {
  const aggregatedData: { x: number; y: number; count: number }[] = [];
  const bins: { [key: string]: { x: number; y: number; count: number } } = {};

  data.forEach(point => {
    const binX = Number((Math.round(point.x / binSize.xBinSize) * binSize.xBinSize).toFixed(precision.xPrecision));
    const binY = Number((Math.round(point.y / binSize.yBinSize) * binSize.yBinSize).toFixed(precision.yPrecision));
    const binKey = `${binX},${binY}`;

    if (!bins[binKey]) {
      bins[binKey] = { x: binX, y: binY, count: 0 };
    }
    bins[binKey].count += 1;
  });

  for (const key in bins) {
    aggregatedData.push(bins[key]);
  }

  return aggregatedData;
};
