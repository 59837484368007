import { Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment, { Moment } from "moment";
import _ from "lodash";
import { Dialog, Table } from "../../../../../components";
import { useContext, useEffect, useState } from "react";
import Add from "./Add";
import VendorType from "./VendorType";
import { DateFormat, LooseObject, FormData, GmpBookingStatus } from "../../../../../utils/Types";
import { DateFormatBookings, GMP_ROLE } from "../../..";
import { SnackbarContext } from "../../../../../utils/Contexts";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectUser } from "../../../../../redux/reducers/userSlice";
import { logout, postToServer, isNotEmpty } from "../../../../../utils/Helper";
import FilledCell from "./FilledCell";
import EmptyCell from "./EmptyCell";
import Edit from "./Edit";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import Send from "./Send";
import ToolButton from "../../../../../components/Button/ToolButton";
import { MarkEmailReadOutlined } from "@mui/icons-material";
import { selectZitadelOrg } from "../../../../../redux/reducers/zitadelOrgSlice";

const getWeeklyStartDates = () => Array.from(Array(20).keys()).map(i => moment().startOf("month").add(i, "weeks").startOf("isoWeek"));

const TableCalendar = ({ weeklyLimit }: { weeklyLimit: number }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingBookings, setLoadingBookings] = useState<boolean>(false);
  const [loadingVendors, setLoadingVendors] = useState<boolean>(false);
  const [allVendors, setAllVendors] = useState<MRT_RowData[]>([]);
  const [bookings, setBookings] = useState<LooseObject[]>([]);

  const [openDialogForAdd, setOpenDialogForAdd] = useState(false);
  const [openDialogForEdit, setOpenDialogForEdit] = useState(false);
  const [openDialogForSend, setOpenDialogForSend] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>();
  const [weeklyStartDate, setWeeklyStartDate] = useState<Moment>(moment());

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchAllVendors();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchAllVendors.cancel();
  }, []);

  const fetchAllVendors = _.debounce(async () => {
    setLoadingVendors(true);
    await postToServer({
      action: "gmp/GetAllAgentsAndProducers",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setAllVendors(_.orderBy(serverData, "gmpBusinessName", "asc"));
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingVendors(false);
  }, 500);

  useEffect(() => {
    fetchAllBookings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchAllBookings.cancel();
  }, []);

  const fetchAllBookings = _.debounce(async () => {
    setLoadingBookings(true);
    await postToServer({
      action: "gmp/GetBookings",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setBookings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingBookings(false);
  }, 500);

  const handleAdd = async ({ vendorEmail, quantity, bookingDate, status }: FormData) => {
    if (vendorEmail && isNotEmpty(quantity) && bookingDate) {
      setLoadingBookings(true);
      await postToServer({
        action: "gmp/AddBooking",
        params: { vendorEmail, quantity, bookingDate, status },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setBookings(serverData);
            setOpenDialogForAdd(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoadingBookings(false);
    }
  };

  const handleEdit = async ({ id, quantity, bookingDate, status }: FormData) => {
    if (id && isNotEmpty(quantity) && bookingDate) {
      setLoadingBookings(true);
      await postToServer({
        action: "gmp/UpdateBooking",
        params: { id, quantity, bookingDate, status },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setBookings(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoadingBookings(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (id) {
      setLoadingBookings(true);
      await postToServer({
        action: "gmp/DeleteBooking",
        params: { id },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setBookings(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoadingBookings(false);
    }
  };

  const handleSend = async (bookingsToUpdate: any) => {
    if (bookingsToUpdate && Array.isArray(bookingsToUpdate) && bookingsToUpdate.length > 0) {
      bookingsToUpdate = bookingsToUpdate.filter(i => i.status !== GmpBookingStatus.Assigned && i.status !== GmpBookingStatus.Completed);
      if (bookingsToUpdate.length > 0) {
        setLoadingBookings(true);
        await postToServer({
          action: "gmp/SendSupplyAgreements",
          params: { bookingsToUpdate },
          token: user.access_token,
          zitadelOrgIdString: zitadelOrg?.idString,
        }).then(response => {
          if (response.statusCode === 401) {
            logout({ dispatch, zitadelOrg });
          } else {
            if (response.message.type === "success" && response.serverData) {
              const serverData = response.serverData as LooseObject[];
              setBookings(serverData);
              setOpenDialogForSend(false);
            }
            snackbar.open(response.message);
          }
        });
        setLoadingBookings(false);
      }
    }
  };

  const groupedColumnHeaders = _.uniq(getWeeklyStartDates().map(i => `${i.format("MMM")} ${i.format("YYYY")}`));

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    {
      accessorKey: "vendor",
      header: "Vendor",
      accessorFn: row => `${row.firstName} ${row.lastName}\n${row.gmpBusinessName}`,
      Cell: ({ renderedCellValue }) => <Stack style={{ whiteSpace: "pre-line" }}>{renderedCellValue}</Stack>,
      maxSize: 120,
    },
    {
      accessorKey: "roles",
      header: "Type",
      accessorFn: row => (row.roles.includes(GMP_ROLE.AGENT) ? "Agent" : "Producer"),
      Cell: ({ renderedCellValue, row }) => <VendorType type={row.original.roles.includes(GMP_ROLE.AGENT) ? "Agent" : "Producer"} label={renderedCellValue} />,
      maxSize: 90,
    },
    ...groupedColumnHeaders.map((header, index) => ({
      columns: getWeeklyStartDates()
        .filter(date => header === `${date.format("MMM")} ${date.format("YYYY")}`)
        .map(i => ({
          id: i.valueOf().toString(), // passing date to cell
          accessorKey: i.format("YYYY_MM_DD"),
          header: i.format(DateFormat.DAY_AND_MONTH),
          Header: (
            <Stack spacing={0.5} direction="row">
              <Typography variant="textxs">{i.format(DateFormat.DAY_AND_MONTH)}</Typography>
              <ToolButton
                title="Distribute Supply Agreement"
                icon={<MarkEmailReadOutlined sx={{ fontSize: 16 }} />}
                onClick={() => {
                  setWeeklyStartDate(i);
                  setOpenDialogForSend(true);
                }}
                buttonProps={{ sx: { width: 20, height: 20 } }}
              />
            </Stack>
          ),
          maxSize: 100,
          muiTableBodyCellProps: ({ cell }: { cell: any }) => ({
            sx: {
              backgroundColor: index % 2 === 0 ? "mybg.secondary" : undefined,
              borderWidth: 0.5,
              borderColor: "myborder.secondary",
              borderStyle: "solid",
            },
          }),
          Cell: ({ cell }: { cell: any }) => {
            const bookingsForThisVendorThisWeek = bookings.filter(booking => {
              const firstDayOfThisWeek = moment(Number(cell.column.id)).startOf("isoWeek");
              return booking.vendorEmail === cell.row.original.email && firstDayOfThisWeek.year() === booking.year && firstDayOfThisWeek.week() === booking.week;
            });
            return (
              <Stack flex={1} sx={{ cursor: "pointer", mx: -0.9, my: -0.9, minHeight: 58, height: "100%" }}>
                {bookingsForThisVendorThisWeek.length > 0 ? (
                  <FilledCell
                    cell={cell}
                    bookings={bookingsForThisVendorThisWeek}
                    handleClick={(v: any) => {
                      setOpenDialogForEdit(true);
                      setCurrentItem(v);
                    }}
                  />
                ) : (
                  <Tooltip title={moment(Number(cell.column.id)).format(DateFormatBookings)}>
                    <Stack
                      width="100%"
                      height="100%"
                      onClick={() => {
                        setOpenDialogForAdd(true);
                        setCurrentItem(cell);
                      }}
                    >
                      <EmptyCell text={moment(Number(cell.column.id)).format("w")} />
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
            );
          },
        })),
      header,
      // minSize: 90,
    })),
  ];

  return (
    <Grid container sx={{ mt: 2, width: "100%", maxWidth: { xs: `calc(100vw - 40px)`, md: `calc(100vw - 144px)` } }}>
      <Stack width="100%">
        <Table
          loading={loadingVendors || loadingBookings}
          columns={columns}
          data={allVendors}
          initialState={{ columnPinning: { left: ["vendor", "gmpBusinessName", "roles", "GLQ_AVG"], right: [] } }}
          enableBottomToolbar={false}
          enablePagination={false}
          enableHiding={false}
          enableSorting={false}
          muiTableContainerProps={{ sx: { maxHeight: "620px" } }}
          layoutMode="grid-no-grow"
          muiTableHeadSx={{ '& th[data-pinned="true"]:before': { boxShadow: "none" }, opacity: 1 }}
          muiTableBodySx={{
            '& td[data-pinned="true"]:before': { boxShadow: "none" },
            '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: "none" },
          }}
          muiTableHeadCellSx={{ fontSize: "0.75rem", borderWidth: 0.5 }}
          muiTableBodyCellSx={{ fontSize: "0.75rem", borderWidth: 0.5 }}
          muiTableHeadCellProps={{ align: "center" }}
          muiTableBodyCellProps={{ align: "center" }}
          positionGlobalFilter="left"
        />
        <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
          <Add loading={loadingBookings} weeklyLimit={weeklyLimit} bookings={bookings} cell={currentItem} handleSubmit={handleAdd} onCancel={() => setOpenDialogForAdd(false)} />
        </Dialog>
        <Dialog open={openDialogForEdit} onClose={() => setOpenDialogForEdit(false)} isTransparent>
          <Edit
            loading={loadingBookings}
            weeklyLimit={weeklyLimit}
            bookings={bookings}
            cell={currentItem}
            handleSubmit={handleEdit}
            cancelBooking={handleDelete}
            onCancel={() => setOpenDialogForEdit(false)}
          />
        </Dialog>
        <Dialog open={openDialogForSend} onClose={() => setOpenDialogForSend(false)} isTransparent>
          <Send
            loading={loadingBookings}
            weeklyStartDate={weeklyStartDate}
            weeklyLimit={weeklyLimit}
            bookings={bookings.filter(i => i.year === weeklyStartDate.year() && i.week === weeklyStartDate.week())}
            handleSubmit={handleSend}
            onCancel={() => setOpenDialogForSend(false)}
          />
        </Dialog>
      </Stack>
    </Grid>
  );
};

export default TableCalendar;
