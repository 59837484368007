import { Stack, Typography } from "@mui/material";
import { DialogWrapper, Form, Spinner } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory, FormInputItem, LabelValuePair } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { SnackbarContext } from "../../../utils/Contexts";
import { getCurrentOrg, getOrgFromOrgIdString, logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { GMP_ROLE } from "../../GmpLambBookings";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const UpdateRoles = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  const snackbar = useContext(SnackbarContext);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingGmpBusinessNames, setLoadingGmpBusinessNames] = useState(false);
  const [allRoles, setAllRoles] = useState<LabelValuePair[]>([]);
  const [allGmpBusinessNames, setAllGmpBusinessNames] = useState<string[]>([] as string[]);
  const [roleSelected, setRoleSelected] = useState<string>(item.roles?.[0]);
  const [gmpBusinessName, setGmpBusinessName] = useState<string>("");
  const [inputs, setInputs] = useState<FormInputItem[]>([]);

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const currentOrg = getCurrentOrg(orgs);

  const orgShort = getOrgFromOrgIdString(currentOrg?.idString || "");

  useEffect(() => {
    loadAllRoles();

    return () => loadAllRoles.cancel();
  }, []);

  useEffect(() => {
    if (orgShort === "GMP") {
      fetchAllGmpBusinessNames();

      // Cleanup or clear any pending debounced function calls if necessary
      return () => fetchAllGmpBusinessNames.cancel();
    }
  }, []);

  const loadAllRoles = _.debounce(async () => {
    setLoadingRoles(true);
    await postToServer({
      action: "management/users/GetAllRoles",
      params: { orgIdString: getCurrentOrg(orgs)?.idString },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LabelValuePair[];
          setAllRoles(_.orderBy(serverData, "label"));
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingRoles(false);
  }, 500);

  const fetchAllGmpBusinessNames = _.debounce(async () => {
    setLoadingGmpBusinessNames(true);
    await postToServer({
      action: "gmp/GetAllBusinessNames",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setAllGmpBusinessNames(((response.serverData as string[]) || []).sort());
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGmpBusinessNames(false);
  }, 500);

  useEffect(() => {
    let formInputs: FormInputItem[] = [
      {
        name: "role",
        label: "Role",
        category: FormInputCategory.SELECT,
        options: allRoles,
        defaultValue: roleSelected,
        exposeValue: setRoleSelected,
      },
    ];

    if (orgShort === "GMP" && (roleSelected === GMP_ROLE.AGENT || roleSelected === GMP_ROLE.PRODUCER)) {
      formInputs = formInputs.concat([
        {
          name: "gmpBusinessName",
          label: "Business Name",
          category: FormInputCategory.AUTO_COMPLETE,
          options: allGmpBusinessNames.map(i => ({ label: i, value: i })),
          defaultValue: item.gmpBusinessName,
          loading: loadingGmpBusinessNames,
          exposeValue: v => v && setGmpBusinessName(v.value),
          autoCompleteExposeInputValue: setGmpBusinessName,
        },
      ]);
    }
    setInputs(formInputs);
  }, [roleSelected, allGmpBusinessNames]);

  const handleSubmitForm = (v: FormData) => {
    const { role } = v;
    if (orgShort === "GMP" && item.email && !item.email.endsWith("@gmpgundagai.com.au") && role === GMP_ROLE.ADMIN) {
      snackbar.open({ type: "error", text: "This user is not allowed to be admin" });
    } else {
      handleSubmit(v);
    }
  };

  return (
    <DialogWrapper onCancel={onCancel}>
      <Stack flex={1} spacing={2}>
        <Typography variant="textlg" fontWeight="semiBold">{`Update Role of ${item.displayName}`}</Typography>
        {allRoles.length === 0 ? (
          <Spinner />
        ) : (
          <Form
            loading={loading || loadingRoles}
            onSubmit={data => {
              handleSubmitForm({ id: item.zitadelId, ...data, gmpBusinessName });
            }}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={inputs}
          />
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default UpdateRoles;
