import { Stack, Typography } from "@mui/material";
import { Chip, ContentWrapper, Form, Link, MinimalTable, NoRecords } from "../../../components";
import { FormInputCategory, LooseObject } from "../../../utils/Types";
import { useState } from "react";
import { isNotEmpty } from "../../../utils/Helper";

const DailyReports = ({ title, data, handleViewReport }: { title: string; data: LooseObject; handleViewReport: (v: LooseObject) => void }) => {
  const [probe, setProbe] = useState(Object.keys(data).sort()?.[0]);

  if (!isNotEmpty(data)) {
    return <NoRecords />;
  }

  const columns = [
    { accessorKey: "date", header: "Date", maxSize: 100 },
    { accessorKey: "probed", header: "Probed", maxSize: 100 },
    { accessorKey: "missed", header: "Missed", maxSize: 100 },
    {
      accessorKey: "details",
      header: "Details",
      maxSize: 100,
      Cell: ({ cell }: { cell: any }) => (
        <Link onClick={() => handleViewReport(data[probe].find((i: LooseObject) => i.EOD_DATE_FORMATTED === cell.row.original.date))}>View Report</Link>
      ),
    },
  ];

  const rows = data[probe].map((i: LooseObject) => ({ date: i.EOD_DATE_FORMATTED, probed: i.CARCASSES_PROBED, missed: i.CARCASSES_MISSED }));

  return (
    <ContentWrapper spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="textsm" fontWeight="semiBold">
            {title}
          </Typography>
          <Chip label="Past 30 days" />
        </Stack>
        <Stack minWidth={130}>
          <Form
            fullWidth
            inputs={[
              {
                name: "probe",
                category: FormInputCategory.SELECT,
                options: Object.keys(data).map(i => ({ label: `Probe ${i}`, value: i })) || [],
                defaultValue: Object.keys(data).sort()[0],
                size: "small",
                exposeValue: v => setProbe(v),
              },
            ]}
          />
        </Stack>
      </Stack>
      <MinimalTable columns={columns} data={rows} muiTableContainerSx={{ maxHeight: "406px" }} />
    </ContentWrapper>
  );
};

export default DailyReports;
