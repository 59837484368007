import { Typography } from "@mui/material";
import { BrokenImageOutlined } from "@mui/icons-material";
import Center from "../Center";

const NoImagePlaceholder = ({ bgcolor, minHeight, minWidth }: { bgcolor?: string; minHeight?: string | number; minWidth?: string | number }) => (
  <Center flex={1} bgcolor={bgcolor} minHeight={minHeight} minWidth={minWidth}>
    <BrokenImageOutlined />
    <Typography variant="textxs">No image</Typography>
  </Center>
);

export default NoImagePlaceholder;
