import { Checkbox, FormControlLabel, RadioGroup, Stack, Typography } from "@mui/material";
import { formatPercentile } from "../HistogramTrend";
import { useState } from "react";

export type ThresholdItem = { percentile: string; trait: string };

export const thresholds: ThresholdItem[] = [
  { percentile: "top_10_percentage_of", trait: "LMY" },
  { percentile: "top_20_percentage_of", trait: "LMY" },
  { percentile: "top_90_percentage_of", trait: "LMY" },
  { percentile: "top_80_percentage_of", trait: "LMY" },
  { percentile: "top_10_percentage_of", trait: "HSCW" },
  { percentile: "top_20_percentage_of", trait: "HSCW" },
  { percentile: "top_90_percentage_of", trait: "HSCW" },
  { percentile: "top_80_percentage_of", trait: "HSCW" },
];

const Threshold = ({ handleUpdate }: { handleUpdate: (v: ThresholdItem[]) => void }) => {
  const [threshodsSelected, setThreshodsSelected] = useState<string[]>([]);

  return (
    <Stack>
      <Typography fontWeight="semiBold">Threshold - All of GMP Lamb</Typography>
      <RadioGroup>
        {thresholds.map(threshold => (
          <FormControlLabel
            sx={{ ".MuiFormControlLabel-label": { fontWeight: 500 } }}
            key={`${threshold.percentile}-${threshold.trait}`}
            control={
              <Checkbox
                sx={{ py: 0.5 }}
                onChange={v => {
                  let newSelected = [...threshodsSelected];
                  let isSelectedChanged = false;
                  if (v.target.checked) {
                    if (!newSelected.includes(`${threshold.percentile}-${threshold.trait}`)) {
                      newSelected = [`${threshold.percentile}-${threshold.trait}`];
                      isSelectedChanged = true;
                    }
                  } else {
                    if (newSelected.includes(`${threshold.percentile}-${threshold.trait}`)) {
                      newSelected = [];
                      isSelectedChanged = true;
                    }
                  }
                  if (isSelectedChanged) {
                    setThreshodsSelected(newSelected);
                    handleUpdate(newSelected.map(i => ({ percentile: i.split("-")[0], trait: i.split("-")[1] })));
                  }
                }}
                checked={threshodsSelected.includes(`${threshold.percentile}-${threshold.trait}`)}
              />
            }
            label={<Typography variant="textsm">{`${formatPercentile(threshold.percentile)} ${threshold.trait}`}</Typography>}
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};

export default Threshold;
