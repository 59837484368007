import Grid from "@mui/material/Unstable_Grid2";
import { LooseObject, QueryResult } from "../../../../../utils/Types";
import LmyHscwScatter from "./LmyHscwScatter";
import LmyImfScatter from "./LmyImfScatter";
import HscwImfScatter from "./HscwImfScatter";
import ImfHistogramTrend from "./ImfHistogramTrend";

const Charts = ({
  loading,
  data,
  supplierWithFullInfo,
  averages,
  percentiles,
}: {
  loading: boolean;
  data: QueryResult;
  supplierWithFullInfo: LooseObject;
  averages: LooseObject;
  percentiles: QueryResult | undefined;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <LmyHscwScatter data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <LmyImfScatter data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <HscwImfScatter data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ImfHistogramTrend data={data} loading={loading} supplierWithFullInfo={supplierWithFullInfo} averages={averages} percentiles={percentiles} />
      </Grid>
    </Grid>
  );
};

export default Charts;
