import { FullscreenControl, GeolocateControl, Layer, LayerProps, Marker, NavigationControl, Map as ReactMap, ScaleControl, Source } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAP_STYLE } from "../../utils/Constants";
import { getRandomString } from "../../utils/Helper";
import { useMemo } from "react";
import { LooseObject } from "../../utils/Types";
import Pin from "./Pin";
import { Box, Tooltip } from "@mui/material";

const Map = ({
  data,
  geojson,
  mapStyle,
  layers,
  width,
  height,
  showPins,
  showNaviControl = true,
  showFullScreenControl,
  showScaleControl = true,
  showGeolocateControl,
  ...rest
}: {
  data: LooseObject[];
  geojson: GeoJSON.Feature<GeoJSON.Geometry> | GeoJSON.FeatureCollection<GeoJSON.Geometry> | GeoJSON.Geometry | string | undefined;
  mapStyle: string;
  layers: LayerProps[];
  width: number | string;
  height: number | string;
  showPins: boolean;
  showNaviControl: boolean;
  showFullScreenControl: boolean;
  showScaleControl: boolean;
  showGeolocateControl: boolean;
} & any) => {
  const pins = useMemo(
    () =>
      data.map((i: LooseObject, index: number) => (
        <Marker key={`marker_${index}_${getRandomString()}`} longitude={i.longitude} latitude={i.latitude} anchor="bottom">
          <Tooltip title={i.name}>
            <Box>
              <Pin color={i.pinColor} />
            </Box>
          </Tooltip>
        </Marker>
      )),
    []
  );

  return (
    <ReactMap style={{ width: width || "100%", height: height || "100%" }} mapStyle={mapStyle || MAP_STYLE} attributionControl={false} {...rest}>
      {geojson && (
        <Source id="my-data" type="geojson" data={geojson} cluster>
          {layers.map((i: LayerProps, index: number) => (
            <Layer key={`map_layer_${i.id}_${index}_${getRandomString()}`} {...i} />
          ))}
        </Source>
      )}
      {showPins ? pins : undefined}
      {showNaviControl && <NavigationControl position="bottom-right" />}
      {showFullScreenControl && <FullscreenControl />}
      {showScaleControl && <ScaleControl />}
      {showGeolocateControl && <GeolocateControl />}
    </ReactMap>
  );
};

export default Map;
