import { createTheme } from "@mui/material/styles";
import _ from "lodash";
import { base, blue, error, gray, grayBlue, grayDarkMode, pink, success, warning } from "../Colors";
import { DEFAULT_BORDER_RADIUS } from "../Constants";
import { PaletteInterface, TypographyVariantsInterface } from "./StyleInterface";
import { getClientSpecificThemeOptions } from "./Utils";

export type ThemeMode = "light" | "dark";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    drawer: string;
  }
}

declare module "@mui/material/styles" {
  interface Palette extends PaletteInterface {}

  interface PaletteOptions extends PaletteInterface {}

  interface TypographyVariants extends TypographyVariantsInterface {}

  interface TypographyVariantsOptions extends TypographyVariantsInterface {}
}

// Update the Typography's variant
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    display2xl: true;
    displayxl: true;
    displaylg: true;
    displaymd: true;
    displaysm: true;
    displayxs: true;
    textxl: true;
    textlg: true;
    textmd: true;
    textsm: true;
    textxs: true;
  }
}

export const getDefaultTheme = (isLightMode: boolean) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    mode: isLightMode ? "light" : "dark",
    primary: {
      main: isLightMode ? grayBlue[600] : grayBlue[500],
      light: isLightMode ? grayBlue[400] : grayBlue[700],
      dark: isLightMode ? grayBlue[800] : grayBlue[300],
      contrastText: isLightMode ? "#FFFFFF" : grayBlue[950],
    },
    background: {
      default: isLightMode ? "#FFFFFF" : grayDarkMode[950],
      paper: isLightMode ? "#FFFFFF" : grayDarkMode[950],
    },
    text: {
      primary: isLightMode ? gray[900] : grayDarkMode[50],
      secondary: isLightMode ? gray[700] : grayDarkMode[300], // myfg.secondary
      disabled: isLightMode ? gray[500] : grayDarkMode[500],
      icon: isLightMode ? gray[500] : grayDarkMode[500], // text.disabled
    },
    divider: isLightMode ? gray[200] : gray[700],
    gray,
    mybg: {
      primaryHover: isLightMode ? gray[50] : grayDarkMode[800],
      primarySolid: isLightMode ? gray[900] : grayDarkMode[900],
      secondary: isLightMode ? gray[50] : grayDarkMode[900],
      secondaryHover: isLightMode ? gray[100] : grayDarkMode[800], // mybg.disabled
      secondarySubtle: isLightMode ? gray[25] : grayDarkMode[900],
      secondarySolid: isLightMode ? gray[600] : grayDarkMode[600],
      tertiary: isLightMode ? gray[100] : grayDarkMode[800],
      quarterary: isLightMode ? gray[200] : grayDarkMode[700],
      active: isLightMode ? gray[50] : grayDarkMode[800], // mybg.primaryHover
      disabled: isLightMode ? gray[100] : grayDarkMode[800],
    },
    myfg: {
      primary: isLightMode ? gray[900] : "#FFFFFF",
      secondary: isLightMode ? gray[700] : grayDarkMode[300],
      secondaryHover: isLightMode ? gray[800] : grayDarkMode[200],
      tertiary: isLightMode ? gray[600] : grayDarkMode[400],
      quarterary: isLightMode ? gray[500] : grayDarkMode[400],
      successPrimary: isLightMode ? success[600] : success[500],
      successSecondary: isLightMode ? success[500] : success[400],
      errorPrimary: isLightMode ? error[600] : error[500],
      errorSecondary: isLightMode ? error[500] : error[400],
      warningPrimary: isLightMode ? warning[600] : warning[500],
      warningSecondary: isLightMode ? warning[500] : warning[400],
    },
    mytext: {
      primaryOnBrand: isLightMode ? base.white : grayDarkMode[50],
      tertiary: isLightMode ? gray[600] : grayDarkMode[300],
      quarterary: isLightMode ? gray[500] : grayDarkMode[400], // myfg.quarterary
      placeholder: isLightMode ? gray[500] : grayDarkMode[500], // myborder.primary
      placeholderSubtle: isLightMode ? gray[300] : grayDarkMode[700], // myborder.primary
    },
    myborder: {
      primary: isLightMode ? gray[300] : grayDarkMode[700],
      secondary: isLightMode ? gray[200] : grayDarkMode[800],
      tertiary: isLightMode ? gray[100] : grayDarkMode[800], // mybg.disabled
      disabled: isLightMode ? gray[300] : grayDarkMode[700],
      disabledSubtle: isLightMode ? gray[200] : grayDarkMode[800],
    },
    mybuttonfg: {
      primary: isLightMode ? "#FFFFFF" : "#FFFFFF",
      secondary: isLightMode ? gray[700] : grayDarkMode[300],
    },
    mybuttonbg: {
      primary: isLightMode ? grayBlue[600] : grayBlue[600],
      primaryHover: isLightMode ? grayBlue[700] : grayBlue[700],
      secondary: isLightMode ? "#FFFFFF" : grayBlue[900],
      secondaryHover: isLightMode ? grayBlue[50] : grayBlue[800],
    },
    mybuttonborder: {
      primary: isLightMode ? grayBlue[600] : grayBlue[600],
      secondary: isLightMode ? gray[300] : gray[700],
    },
    utility: {
      error: {
        50: isLightMode ? error[50] : error[950],
        100: isLightMode ? error[100] : error[900],
        200: isLightMode ? error[200] : error[800],
        300: isLightMode ? error[300] : error[700],
        400: isLightMode ? error[400] : error[600],
        500: isLightMode ? error[500] : error[500],
        600: isLightMode ? error[600] : error[400],
        700: isLightMode ? error[700] : error[300],
      },
      warning: {
        50: isLightMode ? warning[50] : warning[950],
        100: isLightMode ? warning[100] : warning[900],
        200: isLightMode ? warning[200] : warning[800],
        300: isLightMode ? warning[300] : warning[700],
        400: isLightMode ? warning[400] : warning[600],
        500: isLightMode ? warning[500] : warning[500],
        600: isLightMode ? warning[600] : warning[400],
        700: isLightMode ? warning[700] : warning[300],
      },
      success: {
        50: isLightMode ? success[50] : success[950],
        100: isLightMode ? success[100] : success[900],
        200: isLightMode ? success[200] : success[800],
        300: isLightMode ? success[300] : success[700],
        400: isLightMode ? success[400] : success[600],
        500: isLightMode ? success[500] : success[500],
        600: isLightMode ? success[600] : success[400],
        700: isLightMode ? success[700] : success[300],
      },
      pink: {
        50: isLightMode ? pink[50] : pink[950],
        100: isLightMode ? pink[100] : pink[900],
        200: isLightMode ? pink[200] : pink[800],
        300: isLightMode ? pink[300] : pink[700],
        400: isLightMode ? pink[400] : pink[600],
        500: isLightMode ? pink[500] : pink[500],
        600: isLightMode ? pink[600] : pink[400],
        700: isLightMode ? pink[700] : pink[300],
      },
      blue: {
        50: isLightMode ? blue[50] : blue[950],
        100: isLightMode ? blue[100] : blue[900],
        200: isLightMode ? blue[200] : blue[800],
        300: isLightMode ? blue[300] : blue[700],
        400: isLightMode ? blue[400] : blue[600],
        500: isLightMode ? blue[500] : blue[500],
        600: isLightMode ? blue[600] : blue[400],
        700: isLightMode ? blue[700] : blue[300],
      },
    },
  },
  typography: {
    fontFamily: '"inter"',
    fontWeightThin: 100,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
    fontWeightBlack: 900,
    display2xl: {
      fontSize: "4.5rem",
      lineHeight: 1.25,
    },
    displayxl: {
      fontSize: "3.75rem",
      lineHeight: 1.2,
    },
    displaylg: {
      fontSize: "3rem",
      lineHeight: 1.25,
    },
    displaymd: {
      fontSize: "2.25rem",
      lineHeight: 1.222,
    },
    displaysm: {
      fontSize: "1.875rem",
      lineHeight: 1.222,
    },
    displayxs: {
      fontSize: "1.5rem",
      lineHeight: 1.333,
    },
    textxl: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
    },
    textlg: {
      fontSize: "1.125rem",
      lineHeight: 1.556,
    },
    textmd: {
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    textsm: {
      fontSize: "0.875rem",
      lineHeight: 1.429,
    },
    textxs: {
      fontSize: "0.75rem",
      lineHeight: 1.5,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          lineHeight: 1.429,
          fontWeight: 600,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.875rem",
          lineHeight: 1.429,
          fontWeight: 600,
          color: isLightMode ? gray[700] : grayDarkMode[300],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // height: "2.5rem",
          "& .MuiOutlinedInput-input": {
            paddingTop: "8px", // Adjust padding for the input element to center it within the new height
            paddingBottom: "8px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
        },
        groupLabel: {
          fontWeight: "bold",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          DEFAULT_BORDER_RADIUS,
        },
        notchedOutline: {
          border: `1px solid ${isLightMode ? gray[300] : grayDarkMode[700]}`, // myborder.primary
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          lineHeight: 1.429,
          color: isLightMode ? gray[600] : grayDarkMode[300], // mytext.tertiary
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: isLightMode ? gray[300] : grayDarkMode[700], // myborder.primary
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: isLightMode ? gray[300] : grayDarkMode[700], // myborder.primary
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "1rem",
        },
        containedPrimary: {
          height: "2.5rem",
          borderRadius: DEFAULT_BORDER_RADIUS,
          fontSize: "1rem",
        },
        outlinedPrimary: {
          height: "2.5rem",
          borderRadius: DEFAULT_BORDER_RADIUS,
          fontSize: "1rem",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: DEFAULT_BORDER_RADIUS,
          // border: `1px solid ${gray[200]}`,
          "& .MuiTouchRipple-root .MuiTouchRipple-child": {
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: DEFAULT_BORDER_RADIUS,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "0.5rem",
          // color: isLightMode ? gray[500] : grayDarkMode[400], // myfg.quarterary
          // "&.Mui-selected": { color: isLightMode ? grayBlue[600] : grayDarkMode[300] }, // MuiTabs.indicator
        },
      },
    },
    // MuiTabs: {
    //   styleOverrides: {
    //     root: {
    //       borderBottom: `0.5px solid ${isLightMode ? gray[200] : grayDarkMode[700]}`, // myborder.secondary
    //     },
    //     indicator: {
    //       backgroundColor: isLightMode ? grayBlue[600] : grayDarkMode[300],
    //     },
    //   },
    // },
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${isLightMode ? gray[200] : grayDarkMode[700]}`, // myborder.secondary
          backgroundImage: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: isLightMode ? gray[900] : grayDarkMode[900], // mybg.primarySolid
        },
        arrow: {
          color: isLightMode ? gray[900] : grayDarkMode[900], // mybg.primarySolid
        },
      },
    },
    // MuiPopover: {
    //   styleOverrides: {
    //     paper: { boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);" },
    //   },
    // },
  },
});

const getTheme = (options: any, mode: ThemeMode) => createTheme(_.merge(_.cloneDeep(getDefaultTheme(mode === "light")), options));

const getCleintSpeicifTheme = (client: string, mode: ThemeMode) => getTheme(getClientSpecificThemeOptions(client, mode === "light"), mode);

export default getCleintSpeicifTheme;
