import { ContentWrapper, Hcharts } from "../../../../../components";
import { ChartType, QueryResult, QueryResultColumn } from "../../../../../utils/Types";
import { getHchartPointFormatExtra } from "../../../../../utils/Helper";

const Section = ({ data, legends, selectedAxis }: { data: QueryResult; legends: string[]; selectedAxis: { xAxis?: QueryResultColumn; yAxis?: QueryResultColumn } }) => {
  const EXCLUDE_COLUMNS = ["MOB_NO"];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");
  const optionsOfYAxis = optionsOfXAxis;

  const dataToDisplay = data?.rows;

  return (
    <ContentWrapper>
      <Hcharts
        type={ChartType.SCATTER_PLOT}
        title="xAxisLabel vs yAxisLabel"
        axisSwitch={{
          optionsOfXAxis,
          optionsOfYAxis,
          defaultXAxisName: (selectedAxis.xAxis || (optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis[0] : undefined))?.name,
          defaultYAxisName: (selectedAxis.yAxis || (optionsOfYAxis && optionsOfYAxis.length > 1 ? optionsOfYAxis[1] : undefined))?.name,
        }}
        data={legends.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        pointFormatExtra={getHchartPointFormatExtra(data?.columns?.filter(i => ["MOB_NO"].includes(i.name)) || [])}
        height={400}
      />
    </ContentWrapper>
  );
};

export default Section;
