import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IsAdminViewState {
  value: boolean;
  status: "idle" | "loading" | "failed";
}

const initialState: IsAdminViewState = {
  value: false,
  status: "idle",
};

export const isAdminViewSlice = createSlice({
  name: "isAdminView",
  initialState,
  reducers: {
    setIsAdminView: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setIsAdminView } = isAdminViewSlice.actions;

export const selectIsAdminView = (state: RootState) => state.isAdminView.value;

export default isAdminViewSlice.reducer;
