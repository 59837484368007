import { useEffect } from "react";
import Loading from "../Loading";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logout } from "../../utils/Helper";
import { selectZitadelOrg } from "../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    logout({ dispatch, zitadelOrg });
  }, []);
  return <Loading />;
};

export default Page;
