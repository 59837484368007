import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult, QueryResultColumn } from "../../../../../../utils/Types";
import { Chip, ContentWrapper, DialogWrapper, Hcharts, MinimalTable } from "../../../../../../components";
import { Divider, Stack, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../../../components/Chart/CustomChartTraceColors";
import { useState } from "react";
import { isNotEmpty, isDarkMode, getHchartPointFormatExtra } from "../../../../../../utils/Helper";
import _ from "lodash";
import AnimalHealthCheckboxes from "./AnimalHealthCheckboxes";
import { generateChartTraceColors } from "../../../../../../components/Chart/Utils";
import Threshold, { ThresholdItem } from "./Threshold";
import Benchmark from "./Benchmark";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../../../../../../utils/Constants";
import { formatPercentile } from "../HistogramTrend";

export const getAllDiseases = (data: QueryResult) => {
  const allDiseases: string[] = [];
  data.rows?.forEach(row => {
    row.DISEASE_DESCRIPTIONS.forEach((disease: string) => {
      if (disease.trim() && !allDiseases.includes(disease.trim())) {
        allDiseases.push(disease);
      }
    });
  });
  return allDiseases.sort();
};

const OverlayDialog = ({
  data,
  picWithFullInfo,
  averages,
  averagesGlobal,
  percentiles,
  onCancel,
}: {
  data: QueryResult;
  picWithFullInfo: LooseObject;
  averages: LooseObject;
  averagesGlobal: LooseObject;
  percentiles: QueryResult;
  onCancel: (values: any) => void;
}) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");
  const optionsOfYAxis = optionsOfXAxis;
  const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>(
    optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis.find(i => i.name === "LMY_PERCENTAGE_OF") : undefined
  );
  const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>(optionsOfYAxis && optionsOfYAxis.length > 1 ? optionsOfXAxis.find(i => i.name === "HSCW_KG") : undefined);

  const [diseasesSelected, setDiseasesSelected] = useState<string[]>([]);
  const [thresholds, setThresholds] = useState<ThresholdItem[]>([]);
  const [benchmarkSelected, setBenchmarkSelected] = useState<string[]>(["Lot Avg", "Global Avg"]);

  const allDiseases = getAllDiseases(data);
  const diseaseColors = generateChartTraceColors({ quantity: allDiseases.length, seedColorHex: "#c62828" });

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const getMarkerColor = (point: LooseObject, traceIndex: number) => {
    let markerColor = getCustomChartTraceColors("GMP", isDarkMode(theme))![traceIndex];
    if (thresholds.length > 0) {
      const thresholdValue = percentiles.rows?.find(o => o.PERCENTILE === thresholds[0].percentile)?.[thresholds[0].trait];

      let isGreyOutMarker = false;
      if (isNotEmpty(thresholdValue)) {
        const pointValue = point[thresholds[0].trait === "LMY" ? "LMY_PERCENTAGE_OF" : "HSCW_KG"];
        isGreyOutMarker = formatPercentile(thresholds[0].percentile).includes("Top") ? pointValue < thresholdValue : pointValue > thresholdValue;
      }

      if (isGreyOutMarker) {
        markerColor = "#D0D5DD";
      }
    }
    if (point.DISEASE_DESCRIPTIONS && point.DISEASE_DESCRIPTIONS.length > 0) {
      point.DISEASE_DESCRIPTIONS.forEach((o: string) => {
        if (diseasesSelected.includes(o)) {
          markerColor = diseaseColors[allDiseases.indexOf(o)];
        }
      });
    }
    return markerColor;
  };

  const benchmarkColors = ["#66BB6A", "#E91E63"];

  type AverageType = {
    GLQ: any;
    HSCW_KG: any;
    IMF_PERCENTAGE_OF: any;
    LMY_PERCENTAGE_OF: any;
  };

  const transferredAverages: AverageType = {
    GLQ: averages.AVG_GLQ,
    HSCW_KG: averages.AVG_HSCW_KG,
    IMF_PERCENTAGE_OF: averages.AVG_IMF_PERCENTAGE_OF,
    LMY_PERCENTAGE_OF: averages.AVG_LMY_PERCENTAGE_OF,
  };

  const transferredAveragesGlobal: AverageType = {
    GLQ: averagesGlobal.AVG_GLQ,
    HSCW_KG: averagesGlobal.AVG_HSCW_KG,
    IMF_PERCENTAGE_OF: averagesGlobal.AVG_IMF_PERCENTAGE_OF,
    LMY_PERCENTAGE_OF: averagesGlobal.AVG_LMY_PERCENTAGE_OF,
  };

  const columns = [
    { accessorKey: "ID", header: "ID", minSize: 50 },
    { accessorKey: "GLQ", header: "GLQ" },
    { accessorKey: "HSCW_KG", header: "HSCW Kg" },
    { accessorKey: "IMF_PERCENTAGE_OF", header: "IMF %" },
    { accessorKey: "LMY_PERCENTAGE_OF", header: "LMY %" },
  ];

  return (
    <DialogWrapper onCancel={onCancel} title="Full Report">
      <Stack
        spacing={2}
        sx={theme => ({
          width: { xs: theme.breakpoints.values.xs - DEFAULT_SPACING_MOBILE * 8, sm: `calc(100vw - ${DEFAULT_SPACING * 2})`, md: "90vw", lg: "80vw", xl: "60vw" },
        })}
      >
        <Grid container spacing={1}>
          <Grid>
            <Chip label="Carcase Based" />
          </Grid>
          <Grid>
            <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} />
          </Grid>
          <Grid>
            <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
          </Grid>
          <Grid>
            <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: 1.5 }} />
        <Grid container>
          <Grid xs={8} md={9} lg={10}>
            <Stack spacing={2}>
              <ContentWrapper>
                <Hcharts
                  type={ChartType.SCATTER_PLOT}
                  title="xAxisLabel vs yAxisLabel"
                  axisSwitch={{
                    optionsOfXAxis,
                    optionsOfYAxis,
                    defaultXAxisName: "LMY_PERCENTAGE_OF",
                    defaultYAxisName: "HSCW_KG",
                    exposeXAxis: setXAxis,
                    exposeYAxis: setYAxis,
                  }}
                  data={allBrands.map((o, index) => {
                    const traceData = dataToDisplay?.filter(i => i.BRAND === o).map(i => ({ ...i, color: getMarkerColor(i, index) })) || [];
                    benchmarkSelected.forEach(benchmark => {
                      if (benchmark === "Lot Avg" && isNotEmpty(averages)) {
                        if (xAxis?.name && yAxis?.name) {
                          traceData.push({
                            x: transferredAverages[xAxis.name as keyof AverageType],
                            y: transferredAverages[yAxis.name as keyof AverageType],
                            color: benchmarkColors[0],
                          });
                        }
                      }
                      if (benchmark === "Global Avg" && isNotEmpty(averagesGlobal)) {
                        if (xAxis?.name && yAxis?.name) {
                          traceData.push({
                            x: transferredAveragesGlobal[xAxis.name as keyof AverageType],
                            y: transferredAveragesGlobal[yAxis.name as keyof AverageType],
                            color: benchmarkColors[1],
                          });
                        }
                      }
                    });
                    return {
                      data: traceData,
                      name: o,
                    };
                  })}
                  height={450}
                  pointFormatExtra={getHchartPointFormatExtra(data.columns?.filter(i => ["PIC", "LOT_REFERENCE"].includes(i.name)) || [])}
                  traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
                  annotations={
                    xAxis?.name === "LMY_PERCENTAGE_OF" && yAxis?.name === "HSCW_KG"
                      ? [
                          {
                            draggable: "",
                            shapes: [
                              {
                                type: "path",
                                points: [
                                  // bottom-left corner, bottom-right, top-right, top-let, back to bottom-left
                                  {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: 59,
                                    y: 24,
                                  },
                                  {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: 66,
                                    y: 24,
                                  },
                                  {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: 66,
                                    y: 32,
                                  },
                                  {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: 59,
                                    y: 32,
                                  },
                                  {
                                    xAxis: 0,
                                    yAxis: 0,
                                    x: 59,
                                    y: 24,
                                  },
                                ],
                                strokeWidth: 0,
                                fill: "rgba(171, 239, 198, 0.3)", // Fill color with transparency
                              },
                            ],
                            zIndex: -1, // Ensure the annotation is behind the chart series
                          },
                        ]
                      : undefined
                  }
                />
              </ContentWrapper>
              {benchmarkSelected.length > 0 ? (
                <ContentWrapper>
                  <MinimalTable
                    columns={columns}
                    data={benchmarkSelected.map(i => {
                      if (i === "Lot Avg" && isNotEmpty(averages)) {
                        return { ID: "Lot Avg", ...transferredAverages };
                      }
                      if (i === "Global Avg" && isNotEmpty(averagesGlobal)) {
                        return { ID: "Global Avg", ...transferredAveragesGlobal };
                      }
                      return <></>;
                    })}
                  />
                </ContentWrapper>
              ) : undefined}
            </Stack>
          </Grid>
          <Grid xs={4} md={3} lg={2}>
            <Stack spacing={2} ml={2}>
              <AnimalHealthCheckboxes allDiseases={allDiseases} diseaseColors={diseaseColors} handleUpdate={setDiseasesSelected} />
              <Benchmark benchmarkColors={benchmarkColors} handleUpdate={setBenchmarkSelected} />
              <Threshold handleUpdate={setThresholds} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </DialogWrapper>
  );
};

export default OverlayDialog;
