import Grid from "@mui/material/Unstable_Grid2";
import { DateFormat, DateRange, QueryResult, QueryResultColumn } from "../../../../../utils/Types";
import { Chip, DialogWrapper } from "../../../../../components";
import ScatterPlot from "./ScatterPlot";
import Histogram from "./Histogram";

const MobBasedDialog = ({
  data,
  legends,
  dateRange,
  selectedAxis,
  onCancel,
}: {
  data: QueryResult;
  legends: string[];
  dateRange: DateRange;
  selectedAxis: { xAxis?: QueryResultColumn; yAxis?: QueryResultColumn };
  onCancel: (values: any) => void;
}) => {
  return (
    <DialogWrapper onCancel={onCancel} title="Mob Analysis" minWidth={{ sm: "80vw", md: "60vw", lg: "50vw" }}>
      <Grid container direction="column" spacing={2} width="100%">
        <Grid xs={12}>
          <Chip size="medium" label={`Date range ${dateRange.from.format(DateFormat.SHORT)} - ${dateRange.to.format(DateFormat.SHORT)}`} />
        </Grid>
        <Grid xs={12}>
          <ScatterPlot data={data} legends={legends} selectedAxis={selectedAxis} />
        </Grid>
        <Grid xs={12}>
          <Histogram data={data} legends={legends} selectedAxis={selectedAxis} />
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default MobBasedDialog;
