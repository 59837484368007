import { InputAdornment } from "@mui/material";
import { FormInputCategory } from "../../utils/Types";
import Form from "../Form";
import SvgIcon from "../SvgIcon";
import _ from "lodash";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";

const SortBySelect = ({ columnsInterested, handleChange }: { columnsInterested?: MRT_ColumnDef<MRT_RowData, any>[]; handleChange: (v: any) => void }) => (
  <Form
    isHorizontal
    inputs={[
      {
        name: "sortBy",
        category: FormInputCategory.SELECT,
        options: _.sortBy(
          (columnsInterested?.map(i => ({ ...i, label: i.header + " Desc", value: i.accessorKey + "_Desc" })) || []).concat(
            columnsInterested?.map(i => ({ ...i, label: i.header + " Asc", value: i.accessorKey + "_Asc" })) || []
          ),
          "label"
        ),
        placeholder: "Sort by",
        gridLayout: { xs: 9, sm: 6, md: 4, lg: 3 },
        exposeValue: handleChange,
        inputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon name="SwitchVertical" strokeWidth={1.5} />
            </InputAdornment>
          ),
        },
      },
    ]}
  />
);

export default SortBySelect;
