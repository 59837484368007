import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Dashboard from "./Dashboard";
import Producer from "./Producer";
import { useAppSelector } from "../../redux/hooks";
import { selectIsAdminView } from "../../redux/reducers/isAdminViewSlice";

export const PRODUCER = "Producer";

const Page = () => {
  const isAdminView = useAppSelector(selectIsAdminView);

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isAdminView) {
    return (
      <Stack spacing={3} flex={1}>
        <Stack direction="row" justifyContent="space-between">
          <Tabs value={tab} onChange={handleChange}>
            <Tab label="Dashboard" />
          </Tabs>
        </Stack>
        <Dashboard />
      </Stack>
    );
  }

  return <Producer />;
};

export default Page;
