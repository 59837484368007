import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { DateFormatServer, DateRange, FormInputCategory, LooseObject, PkgName, QueryResultColumn, QueryResultTable, QueryType } from "../../../utils/Types";
import { selectUser } from "../../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import _ from "lodash";
import { getCurrentLocation, getCurrentOrg, getDataFromDataPool, getDateRangeOptions, getOrgFromOrgIdString, prepareQueryResultForTable } from "../../../utils/Helper";
import { Stack } from "@mui/material";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import moment from "moment";
import { ContentWrapper, DateRangeSelect, Dialog, Link, NoDataView, Spinner, Table } from "../../../components";
import { ProbeStatusChip, getOverallStatus } from "../Dashboard/ProbeStatus";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import DeviceDetails from "./Detail";
import { DATE_RANGE_OPTION_KEYS, DateRangeOptionKey } from "../../../components/DateRangeSelect";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResultTable>();
  const [groupedDailyReportsByProbe, setGroupedDailyReportsByProbe] = useState<LooseObject>();
  const [probe, setProbe] = useState<string>();
  const [dateSelected, setDateSelected] = useState<DateRangeOptionKey>("3-m");
  const [dateRange, setDateRange] = useState<DateRange>();
  const [openForDialogDeviceDetails, setOpenForDialogDeviceDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // set default date range
    const dateRangeOptions = getDateRangeOptions(DATE_RANGE_OPTION_KEYS);
    const datePoint = dateRangeOptions.find(i => i.key === dateSelected)?.value;

    if (datePoint) {
      const newDateRange = { from: moment().add(-datePoint.n, datePoint.u), to: moment() };
      setDateRange(newDateRange);
    }
  }, []);

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [dateRange]);

  const fetchData = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `MEQPROBE_EOD_RAW('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}','${getOrgFromOrgIdString(
            getCurrentOrg(orgs)?.idString!
          )}', '${getCurrentLocation(orgs)}')`,
          isFunction: true,
          pkg: PkgName.MEQ_PROBE_LAMB,
          columnsNotInterested: ["LOCATION"],
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        const columns = dataFromDataPool?.columns
          ?.filter(i => !["END_OF_PRODUCTION_CHECKS", "SYSTEM_CHECKS", "USER_PERFORMANCE"].includes(i.name))
          .concat([{ name: "ATTENTION_REQ", label: "Attention Req", type: "string" } as QueryResultColumn]);
        const rows = dataFromDataPool?.rows?.map(i => ({ ...i, ATTENTION_REQ: getOverallStatus(i.END_OF_PRODUCTION_CHECKS.MATERIAL_CALIBRATION.NEEDLE_STATUS) }));

        const queryResultFromDataPool = prepareQueryResultForTable({ data: { ...dataFromDataPool, columns, rows }, org: getCurrentOrg(orgs) });
        setData(queryResultFromDataPool);

        const sortedRowsByDate = _.orderBy(queryResultFromDataPool?.tableRows || [], o => moment(o.EOD_DATE, DateFormatServer.SHORT), "desc");
        const groupByProbe: LooseObject = _.groupBy(sortedRowsByDate, "CHAIN_NUMBER");
        setGroupedDailyReportsByProbe(groupByProbe);

        if (groupByProbe) {
          setProbe(Object.keys(groupByProbe).sort()?.[0]);
        }
      });
      setLoading(false);
    }
  }, 500);

  if (loading || !data) {
    return <Spinner />;
  }

  if (data && (data.numRows || 0) <= 0) {
    return <NoDataView />;
  }

  const tableColumns: MRT_ColumnDef<MRT_RowData, any>[] = data?.tableColumns
    .filter(i => i.accessorKey !== "CHAIN_NUMBER")
    .map(i => {
      switch (i.accessorKey) {
        case "ATTENTION_REQ":
          return { ...i, Cell: ({ cell }: { cell: any }) => <ProbeStatusChip status={cell.row.original.ATTENTION_REQ} /> };
        case "EOD_DATE_FORMATTED":
          return {
            ...i,
            Cell: ({ cell }: { cell: any }) => (
              <Link
                onClick={() => {
                  setCurrentItem(cell.row.original);
                  setOpenForDialogDeviceDetails(true);
                }}
              >
                {cell.row.original.EOD_DATE_FORMATTED}
              </Link>
            ),
          };
        default:
          return i;
      }
    });

  const tableRows = probe && groupedDailyReportsByProbe ? groupedDailyReportsByProbe[probe] : [];

  return (
    <Stack spacing={3}>
      <ContentWrapper>
        <DateRangeSelect
          existingInputs={[
            {
              name: "probe",
              category: FormInputCategory.SELECT,
              options: Object.keys(groupedDailyReportsByProbe || {}).map(i => ({ label: `Probe ${i}`, value: i })) || [],
              defaultValue: probe,
              size: "small",
              gridLayout: { xs: 12, md: 3, lg: 2 },
              exposeValue: setProbe,
            },
          ]}
          defaultValue={dateSelected}
          onDateSelectedChange={setDateSelected}
          onDateRangeChange={setDateRange}
        />
      </ContentWrapper>
      <Table
        loading={loading}
        columns={tableColumns}
        data={tableRows}
        enableTopToolbar={false}
        initialState={{ pagination: { pageSize: 20, pageIndex: 0 }, sorting: [{ id: "EOD_DATE_FORMATTED", desc: true }] }}
      />

      {data && groupedDailyReportsByProbe && probe && currentItem && (
        <Dialog open={openForDialogDeviceDetails} onClose={() => setOpenForDialogDeviceDetails(false)} isTransparent>
          <DeviceDetails
            data={{ ...data, tableColumns: tableColumns.map(i => (i.accessorKey === "EOD_DATE_FORMATTED" ? { ...i, Cell: undefined } : { ...i })) }}
            groupedDailyReportsByProbe={groupedDailyReportsByProbe}
            defaultProbe={probe}
            onCancel={() => setOpenForDialogDeviceDetails(false)}
            defaultDate={currentItem.EOD_DATE_FORMATTED}
            orgs={orgs}
          />
        </Dialog>
      )}
    </Stack>
  );
};

export default Page;
