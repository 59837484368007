import { Stack, Typography } from "@mui/material";

const EmptyCell = ({ text }: { text: string }) => {
  return (
    <Stack height="100%" justifyContent="center">
      <Typography variant="textxs" color="mytext.placeholderSubtle">
        {text}
      </Typography>
    </Stack>
  );
};

export default EmptyCell;
