import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory, LabelValuePair } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { SnackbarContext } from "../../../utils/Contexts";
import { logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const UpdateRoles = ({ row, onCancel, handleSubmit }: { row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [allRoles, setAllRoles] = useState<LabelValuePair[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadAllRoles();
  }, []);

  const loadAllRoles = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "administration/GetAllRoles",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LabelValuePair[];
          setAllRoles(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">{`Update Roles of ${item.displayName}`}</Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit({ id: item.zitadelId, ...data })}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "roles",
                label: "Roles",
                category: FormInputCategory.SELECT,
                options: allRoles,
                defaultValue: item.roles,
                multiple: true,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default UpdateRoles;
