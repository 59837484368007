import { Chip, ContentWrapper, Hcharts } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import _ from "lodash";
import { Stack, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../../components/Chart/CustomChartTraceColors";
import { getHchartPointFormatExtra, isDarkMode } from "../../../../../utils/Helper";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";

const Section = ({ data, picWithFullInfo, loading }: { data: QueryResult; picWithFullInfo: LooseObject; loading: boolean }) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfXAxis = _.orderBy(
    data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number" && (i.name === "LMY_PERCENTAGE_OF" || i.name === "IMF_PERCENTAGE_OF")) || [],
    "name",
    "desc"
  );
  const optionsOfYAxis = optionsOfXAxis;

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.SCATTER_PLOT}
        title="GLQ 5+"
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        axisSwitch={{
          optionsOfXAxis,
          optionsOfYAxis,
          defaultXAxisName: "LMY_PERCENTAGE_OF",
          defaultYAxisName: "IMF_PERCENTAGE_OF",
        }}
        hideAxisSwitch
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        pointFormatExtra={getHchartPointFormatExtra(data.columns?.filter(i => ["PIC", "LOT_REFERENCE"].includes(i.name)) || [])}
        traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        subCharts={[{ type: ChartType.SCATTER_PLOT }, { type: ChartType.HISTOGRAM, title: "xAxisLabel" }]}
        height={555}
      />
    </ContentWrapper>
  );
};

export default Section;
