import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Dashboard from "./Dashboard";
import SummaryOfImages from "./SummaryOfImages";
import SupplierSummaryOfImages from "./Supplier/SummaryOfImages";
import { useAppSelector } from "../../redux/hooks";
import { selectIsAdminView } from "../../redux/reducers/isAdminViewSlice";
import { selectOrgs } from "../../redux/reducers/orgsSlice";
import { ORGS_HAVING_SUPPLIER_VIEW } from "../../utils/Constants";
import { getOrgFromOrgIdString } from "../../utils/Helper";

const Page = () => {
  const isAdminView = useAppSelector(selectIsAdminView);

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const orgs = useAppSelector(selectOrgs);
  const currentOrg = orgs.find(i => i?.isCurrent);
  const isCurrentOrgHavingSupplierView = currentOrg && ORGS_HAVING_SUPPLIER_VIEW.includes(getOrgFromOrgIdString(currentOrg.idString));

  return (
    <Stack spacing={3}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Summary of images" />
        {/* <Tab label="Dashboard" /> */}
      </Tabs>
      {tab === 1 ? <Dashboard /> : !isAdminView && isCurrentOrgHavingSupplierView ? <SupplierSummaryOfImages /> : <SummaryOfImages />}
    </Stack>
  );
};

export default Page;
