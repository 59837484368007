import { List, ListItem, ListItemText, Stack, Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { LabelValuePair } from "../utils/Types";
import { getRandomString } from "../utils/Helper";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";

const LabelValuePairs = ({
  data,
  textVariant = "textsm",
}: {
  data: LabelValuePair[];
  textVariant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
}) => (
  <List disablePadding dense>
    {data.map((i, index) => (
      <ListItem key={`label_value_pair_${index}_${getRandomString()}`} disableGutters sx={{ borderBottomWidth: 1, borderBottomStyle: "solid", borderColor: "myborder.secondary" }}>
        <ListItemText primary={i.label} primaryTypographyProps={{ variant: textVariant }} />
        {typeof i.value === "string" || typeof i.value === "number" ? (
          <Typography variant={textVariant}>{i.value}</Typography>
        ) : typeof i.value === "boolean" ? (
          <Typography variant={textVariant}>{i.value.toString()}</Typography>
        ) : Array.isArray(i.value) ? (
          <Stack>
            {i.value.map(m => (
              <Typography variant={textVariant} key={`label_value_pair_${index}_sub_${getRandomString()}`}>
                {m}
              </Typography>
            ))}
          </Stack>
        ) : (
          <Stack>{i.value}</Stack>
        )}
      </ListItem>
    ))}
  </List>
);

export default LabelValuePairs;
