import Button, { ButtonProps } from ".";

const SmallButton = ({ sx, ...rest }: ButtonProps) => (
  <Button
    sx={{
      px: 1,
      height: "2.25rem",
      fontSize: "0.875rem",
      lineHeight: 1.429,
      ...sx,
    }}
    {...rest}
  />
);

export default SmallButton;
