import { Stack, RadioGroup as MuiRadioGroup, FormControl, InputLabel, ListItemText, Radio, FormControlLabel } from "@mui/material";
import { FormInputItem, LabelValuePair, LooseObject } from "../../utils/Types";
import { HelperText } from "./TextField";

export const SELECT_ALL_VALUE = "select_all";

type Props = {
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  touched: LooseObject;
  errors: LooseObject;
  inputProps?: {};
};

const RadioGroup = ({ item, values, setFieldValue, touched, errors, inputProps, textFieldProps }: Props) => {
  let options: LabelValuePair[] = [];
  if (item.options) {
    options = [...item.options];
  }

  return (
    <Stack alignItems="flex-start" width="100%">
      <FormControl error={errors[item.name] && touched[item.name]} size={item.size} sx={textFieldProps.sx} fullWidth>
        {item.label && (
          <InputLabel
            shrink
            sx={{
              position: "relative",
              transform: "none",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </InputLabel>
        )}
        <MuiRadioGroup
          {...{ ...(inputProps || item.inputProps), notched: false }}
          name={item.name}
          value={
            item.multiple
              ? typeof values[item.name] === "string"
                ? values[item.name].split(",")
                : values[item.name] || []
              : values[item.name] === 0
              ? 0
              : values[item.name] || item.defaultValue || ""
          }
          onChange={(e, v) => {
            setFieldValue(item.name, v);
            item.exposeValue?.(v);
          }}
        >
          {options && Array.isArray(options) && options.length > 0 ? (
            options?.map(o => <FormControlLabel key={o.value} value={o.value} disabled={o.disabled} control={<Radio />} label={o.label} />)
          ) : (
            <ListItemText primary="No options available." sx={{ mx: 2 }} />
          )}
        </MuiRadioGroup>
        <HelperText item={item} touched={touched} errors={errors} />
      </FormControl>
    </Stack>
  );
};

export default RadioGroup;
