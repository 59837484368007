import { IconButton, Tooltip } from "@mui/material";

const ToolButton = ({ title, icon, onClick, buttonProps }: { title: string; icon: any; onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; buttonProps?: any }) => (
  <Tooltip title={title}>
    <IconButton onClick={onClick} {...buttonProps}>
      {icon}
    </IconButton>
  </Tooltip>
);

export default ToolButton;
