// Custom light theme
export const lightTheme = {
  colors: ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
  chart: {
    backgroundColor: "#ffffff",
    style: {
      fontFamily: "'Unica One', sans-serif",
    },
    plotBorderColor: "#606063",
  },
  title: {
    style: {
      color: "#000000",
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
  subtitle: {
    style: {
      color: "#000000",
      fontSize: "16px",
    },
  },
  xAxis: {
    gridLineColor: "#e6e6e6",
    labels: {
      style: {
        color: "#000000",
      },
    },
    lineColor: "#e6e6e6",
    minorGridLineColor: "#f2f2f2",
    tickColor: "#e6e6e6",
    title: {
      style: {
        color: "#333333",
      },
    },
  },
  yAxis: {
    gridLineColor: "#e6e6e6",
    labels: {
      style: {
        color: "#000000",
      },
    },
    lineColor: "#e6e6e6",
    minorGridLineColor: "#f2f2f2",
    tickColor: "#e6e6e6",
    tickWidth: 1,
    title: {
      style: {
        color: "#333333",
      },
    },
  },
  tooltip: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    style: {
      color: "#000000",
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: "#000000",
        style: {
          textOutline: "none",
        },
      },
      marker: {
        lineColor: "#333333",
      },
    },
    boxplot: {
      fillColor: "#ffffff",
    },
    candlestick: {
      lineColor: "#000000",
    },
    errorbar: {
      color: "#000000",
    },
  },
  legend: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    itemStyle: {
      color: "#333333",
    },
    itemHoverStyle: {
      color: "#000000",
    },
    itemHiddenStyle: {
      color: "#c0c0c0",
    },
    title: {
      style: {
        color: "#333333",
      },
    },
  },
  credits: {
    style: {
      color: "#666666",
    },
  },
  labels: {
    style: {
      color: "#333333",
    },
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: "#000000",
    },
    activeDataLabelStyle: {
      color: "#000000",
    },
  },
  navigation: {
    buttonOptions: {
      symbolStroke: "#666666",
      theme: {
        fill: "#ffffff",
      },
    },
  },
  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: "#ffffff",
      stroke: "#cccccc",
      style: {
        color: "#333333",
      },
      states: {
        hover: {
          fill: "#e6e6e6",
          stroke: "#cccccc",
          style: {
            color: "#000000",
          },
        },
        select: {
          fill: "#cccccc",
          stroke: "#000000",
          style: {
            color: "#000000",
          },
        },
      },
    },
    inputBoxBorderColor: "#cccccc",
    inputStyle: {
      backgroundColor: "#ffffff",
      color: "#333333",
    },
    labelStyle: {
      color: "#333333",
    },
  },
  navigator: {
    handles: {
      backgroundColor: "#e6e6e6",
      borderColor: "#cccccc",
    },
    outlineColor: "#cccccc",
    maskFill: "rgba(255, 255, 255, 0.85)",
    series: {
      color: "#7cb5ec",
      lineColor: "#cccccc",
    },
    xAxis: {
      gridLineColor: "#e6e6e6",
    },
  },
  scrollbar: {
    barBackgroundColor: "#e6e6e6",
    barBorderColor: "#e6e6e6",
    buttonArrowColor: "#666666",
    buttonBackgroundColor: "#e6e6e6",
    buttonBorderColor: "#e6e6e6",
    rifleColor: "#333333",
    trackBackgroundColor: "#f2f2f2",
    trackBorderColor: "#f2f2f2",
  },
};

// Custom dark theme
export const darkTheme = {
  colors: ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"],
  chart: {
    backgroundColor: "#2a2a2b",
    style: {
      fontFamily: "'Unica One', sans-serif",
    },
    plotBorderColor: "#606063",
  },
  title: {
    style: {
      color: "#E0E0E3",
      fontSize: "20px",
      fontWeight: "bold",
    },
  },
  subtitle: {
    style: {
      color: "#E0E0E3",
      fontSize: "16px",
    },
  },
  xAxis: {
    gridLineColor: "#1f242f",
    labels: {
      style: {
        color: "#E0E0E3",
      },
    },
    lineColor: "#1f242f",
    minorGridLineColor: "#505053",
    tickColor: "#1f242f",
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  yAxis: {
    gridLineColor: "#1f242f",
    labels: {
      style: {
        color: "#E0E0E3",
      },
    },
    lineColor: "#1f242f",
    minorGridLineColor: "#505053",
    tickColor: "#1f242f",
    tickWidth: 1,
    title: {
      style: {
        color: "#A0A0A3",
      },
    },
  },
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    style: {
      color: "#F0F0F0",
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: "#F0F0F3",
        style: {
          textOutline: "none",
        },
      },
      marker: {
        lineColor: "#333",
      },
    },
    boxplot: {
      fillColor: "#505053",
    },
    candlestick: {
      lineColor: "white",
    },
    errorbar: {
      color: "white",
    },
  },
  legend: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    itemStyle: {
      color: "#E0E0E3",
    },
    itemHoverStyle: {
      color: "#FFF",
    },
    itemHiddenStyle: {
      color: "#606063",
    },
    title: {
      style: {
        color: "#C0C0C0",
      },
    },
  },
  credits: {
    style: {
      color: "#666",
    },
  },
  labels: {
    style: {
      color: "#1f242f",
    },
  },
  drilldown: {
    activeAxisLabelStyle: {
      color: "#F0F0F3",
    },
    activeDataLabelStyle: {
      color: "#F0F0F3",
    },
  },
  navigation: {
    buttonOptions: {
      symbolStroke: "#DDDDDD",
      theme: {
        fill: "#505053",
      },
    },
  },
  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: "#505053",
      stroke: "#000000",
      style: {
        color: "#CCC",
      },
      states: {
        hover: {
          fill: "#1f242f",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
        select: {
          fill: "#000003",
          stroke: "#000000",
          style: {
            color: "white",
          },
        },
      },
    },
    inputBoxBorderColor: "#505053",
    inputStyle: {
      backgroundColor: "#333",
      color: "silver",
    },
    labelStyle: {
      color: "silver",
    },
  },
  navigator: {
    handles: {
      backgroundColor: "#666",
      borderColor: "#AAA",
    },
    outlineColor: "#CCC",
    maskFill: "rgba(255,255,255,0.1)",
    series: {
      color: "#7798BF",
      lineColor: "#A6C7ED",
    },
    xAxis: {
      gridLineColor: "#505053",
    },
  },
  scrollbar: {
    barBackgroundColor: "#808083",
    barBorderColor: "#808083",
    buttonArrowColor: "#CCC",
    buttonBackgroundColor: "#606063",
    buttonBorderColor: "#606063",
    rifleColor: "#FFF",
    trackBackgroundColor: "#404043",
    trackBorderColor: "#404043",
  },
};
