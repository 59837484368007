import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";

const Benchmark = ({ benchmarkColors, handleUpdate }: { benchmarkColors: string[]; handleUpdate: (v: string[]) => void }) => {
  const [benchmarkSelected, setBenchmarkSelected] = useState<string[]>(["Lot Avg", "Global Avg"]);

  return (
    <Stack>
      <Typography fontWeight="semiBold">Benchmark</Typography>
      <FormGroup>
        {["Lot Avg", "Global Avg"].map((benchmark, index) => (
          <FormControlLabel
            sx={{ ".MuiFormControlLabel-label": { fontWeight: 500 } }}
            key={benchmark}
            control={
              <Checkbox
                sx={{ py: 0.5 }}
                onChange={v => {
                  let newSelected = [...benchmarkSelected];
                  let isSelectedChanged = false;
                  if (v.target.checked) {
                    if (!newSelected.includes(benchmark)) {
                      newSelected.push(benchmark);
                      isSelectedChanged = true;
                    }
                  } else {
                    if (newSelected.includes(benchmark)) {
                      newSelected = newSelected.filter(i => i !== benchmark);
                      isSelectedChanged = true;
                    }
                  }
                  if (isSelectedChanged) {
                    setBenchmarkSelected(newSelected);
                    handleUpdate(newSelected);
                  }
                }}
                checked={benchmarkSelected.includes(benchmark)}
              />
            }
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Stack sx={{ bgcolor: benchmarkColors[index], width: 8, height: 8, borderRadius: 4 }} />
                <Typography variant="textsm">{benchmark}</Typography>
              </Stack>
            }
          />
        ))}
      </FormGroup>
    </Stack>
  );
};

export default Benchmark;
