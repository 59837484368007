import { Stack, Dialog, Grow } from "@mui/material";
import { ReactNode } from "react";

const ChartContainer = ({ children, isFullScreen, exitFullScreen }: { children: ReactNode; isFullScreen: boolean; exitFullScreen: () => void }) => {
  return (
    <Stack display="flex" justifyContent="center" alignItems="center" width="100%">
      {children}
      <Dialog fullScreen open={isFullScreen} onClose={exitFullScreen} TransitionComponent={Grow}>
        <Stack sx={{ p: 2, width: "100%", height: "100%" }}>{children}</Stack>
      </Dialog>
    </Stack>
  );
};

export default ChartContainer;
