import { Popover, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import StatusChips from "../StatusChips";
import Tiles from "./Tiles";
import TableView from "./TableView";
import moment, { Moment } from "moment";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Button, RoundIconButton, Spinner, WeekPicker } from "../../../components";
import { DateFormat, LooseObject } from "../../../utils/Types";
import { SnackbarContext } from "../../../utils/Contexts";
import { getCsvConfig, logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import _ from "lodash";
import { download, generateCsv } from "export-to-csv";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

export const DateFormatWeekOnly = "[Week] w, YYYY";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(true);

  const [bookings, setBookings] = useState<LooseObject[]>([]);
  const [agreements, setAgreements] = useState<LooseObject[]>([]);
  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] = useState<Moment>(moment().startOf("isoWeek")); //eslint-disable-line
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const dateFormat = "ddd " + DateFormat.DAY_AND_MONTH;

  useEffect(() => {
    fetchBookings();
    fetchSupplyAgreements();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => {
      fetchBookings.cancel();
      fetchSupplyAgreements.cancel();
    };
  }, [firstDayOfCurrentWeek]);

  const fetchBookings = _.debounce(async () => {
    await postToServer({
      action: "gmp/GetBookings",
      params: { year: firstDayOfCurrentWeek.year(), week: firstDayOfCurrentWeek.week() },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setBookings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
  }, 500);

  const fetchSupplyAgreements = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "gmp/GetSupplyAgreements",
      params: { year: firstDayOfCurrentWeek.year(), week: firstDayOfCurrentWeek.week() },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];

          setAgreements(serverData.map((i, index) => ({ ...i, id: index + 1 })));
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const handleExportData = () => {
    const agreementsMergedWithBookings = agreements.map(agreement => {
      const booking = bookings.find(b => b._id === agreement.bookingId);
      return booking
        ? {
            ...agreement,
            vendorEmail: booking.vendorEmail,
            vendorFirstName: booking.vendorFirstName,
            vendorLastName: booking.vendorLastName,
            vendorBusinessName: booking.vendorBusinessName,
            status: booking.status,
          }
        : { ...agreement };
    });

    const csvConfig = getCsvConfig(
      `Lamb-Bookings-${firstDayOfCurrentWeek.format(DateFormatWeekOnly)} (${firstDayOfCurrentWeek.format(dateFormat)} - ${moment(firstDayOfCurrentWeek.valueOf())
        .endOf("isoWeek")
        .format(dateFormat)})`,
      [
        { key: "vendorEmail", displayLabel: "Vendor Email" },
        { key: "vendorFirstName", displayLabel: "Vendor First Name" },
        { key: "vendorLastName", displayLabel: "Vendor Last Name" },
        { key: "vendorBusinessName", displayLabel: "Vendor Business Name" },
        { key: "isAgent", displayLabel: "Agent" },
        { key: "producerFirstName", displayLabel: "Producer First Name" },
        { key: "producerLastName", displayLabel: "Producer Last Name" },
        { key: "producerBusinessName", displayLabel: "Producer Business Name" },
        { key: "pic", displayLabel: "PIC" },
        { key: "agreedQuantity", displayLabel: "No. Head" },
        { key: "breed", displayLabel: "Breed" },
        { key: "estimatedAverageWeightKg", displayLabel: "Estimated Avg Weight (Kg)" },
        { key: "status", displayLabel: "Status" },
        { key: "woolLengthMm", displayLabel: "Wool Length (mm)" },
        { key: "finishingNutrition", displayLabel: "Finishing Nutrition" },
        { key: "ageMonths", displayLabel: "Age (months)" },
        { key: "bloodlines", displayLabel: "Bloodlines" },
        { key: "isMuelsed", displayLabel: "Muelsed" },
        { key: "isMsaAccredited", displayLabel: "MSA Accredited" },
        { key: "isComplyWithLPA", displayLabel: "Comply with LPA" },
        { key: "isGrassFed", displayLabel: "Grass Fed" },
        { key: "isFreeRange", displayLabel: "Free Range" },
        { key: "isAntibioticFree", displayLabel: "Antibiotic Free" },
        { key: "isHormoneAndGrowthPromotantFree", displayLabel: "Hormone and Growth Promotant Free" },
      ]
    );
    const csv = generateCsv(csvConfig)(agreementsMergedWithBookings);
    download(csvConfig)(csv);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Grid container>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 2, border: 1, borderRadius: 3, height: 48, borderColor: "mybuttonborder.secondary" }}
            >
              <Typography variant={isSmallScreen ? "textxs" : "textsm"} fontWeight="semiBold">
                {`${firstDayOfCurrentWeek.format(DateFormatWeekOnly)} (${firstDayOfCurrentWeek.format(dateFormat)} - ${moment(firstDayOfCurrentWeek.valueOf())
                  .endOf("isoWeek")
                  .format(dateFormat)})`}
              </Typography>
              <RoundIconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <CalendarMonthOutlined />
              </RoundIconButton>
            </Stack>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack m={2}>
                <WeekPicker defaultValue={firstDayOfCurrentWeek} onCancel={() => setAnchorEl(null)} onConfirm={v => setFirstDayOfCurrentWeek(v.startOf("isoWeek"))} />
              </Stack>
            </Popover>
          </Grid>
          <Button title="Export to CSV" onClick={handleExportData} />
        </Stack>
      </Grid>
      <Grid>
        <Grid container spacing={1} mb={0.5} justifyContent="space-between">
          <Grid xs={12} sm={6}>
            <Typography variant="textmd" fontWeight="semiBold">
              {firstDayOfCurrentWeek.format("[Week] w, [commencing] ddd DD MMM, YYYY")}
            </Typography>
          </Grid>
          <Grid xs={12} sm={6} display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }} alignItems="center">
            <StatusChips />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Tiles firstDayOfCurrentWeek={firstDayOfCurrentWeek} bookings={bookings} agreements={agreements} />
      </Grid>
      {loading ? (
        <Spinner />
      ) : (
        <Grid>
          <TableView firstDayOfCurrentWeek={firstDayOfCurrentWeek} bookings={bookings} agreements={agreements} />
        </Grid>
      )}
    </Grid>
  );
};

export default Page;
