import { useEffect, useState } from "react";
import { HchartsProps } from "../../utils/Types";
import NoDataView from "../NoDataView";
import Spinner from "../Spinner";
import Charts from "./Charts";
import { getDefaultAxis } from "./ChartComponents/AxisSwitch";

const ChartWrapper = (props: HchartsProps) => {
  const { loading, xAxis: xAxisDefault, yAxis: yAxisDefault, axisSwitch, data } = props;

  const theDefaultXAxis = xAxisDefault || getDefaultAxis({ options: axisSwitch?.optionsOfXAxis, defaultName: axisSwitch?.defaultXAxisName, isY: false });
  const theDefaultYAxis =
    yAxisDefault ||
    getDefaultAxis({
      options: axisSwitch?.optionsOfYAxis,
      defaultName: axisSwitch?.defaultYAxisName,
      isY: true,
      defaultXAxisNameForPreventingDuplicate: theDefaultXAxis?.name,
    });

  const [isLoading, setIsLoading] = useState(loading);
  const [isNoData, setIsNoData] = useState((!theDefaultXAxis && !theDefaultYAxis) || !data || data.length === 0);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIsNoData((!theDefaultXAxis && !theDefaultYAxis) || !data || data.length === 0);
  }, [xAxisDefault, yAxisDefault, axisSwitch, data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isNoData) {
    return <NoDataView />;
  }

  return <Charts {...props} />;
};

export default ChartWrapper;
