import { Stack, Typography } from "@mui/material";
import { DialogWrapper, Spinner } from "../../../../../../components";
import { GridPricingDataType, LooseObject } from "../../../../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import ListOfAgreements from "./ListOfAgreements";
import AgreementForm from "./AgreementForm";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { selectUser } from "../../../../../../redux/reducers/userSlice";
import _ from "lodash";
import { logout, postToServer } from "../../../../../../utils/Helper";
import { SnackbarContext } from "../../../../../../utils/Contexts";
import { selectZitadelOrg } from "../../../../../../redux/reducers/zitadelOrgSlice";

const View = ({ currentBooking, onCancel }: { currentBooking: LooseObject; onCancel: () => void }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingAgreements, setLoadingAgreements] = useState(true);
  const [loadingGridPricing, setLoadingGridPricing] = useState(false);

  const [agreements, setAgreements] = useState<LooseObject[]>([]);
  const [showAgreementForm, setShowAgreementForm] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const [gridPricing, setGridPricing] = useState<GridPricingDataType>();

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSupplyAgreements();
    fetchGridPricing();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => {
      fetchSupplyAgreements.cancel();
      fetchGridPricing.cancel();
    };
  }, []);

  const fetchSupplyAgreements = _.debounce(async () => {
    setLoadingAgreements(true);
    await postToServer({
      action: "gmp/GetMySupplyAgreements",
      params: { bookingId: currentBooking._id },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];

          setAgreements(serverData.map((i, index) => ({ ...i, id: index + 1 })));
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingAgreements(false);
  }, 500);

  const fetchGridPricing = _.debounce(async () => {
    setLoadingGridPricing(true);
    await postToServer({
      action: "gmp/GetGridPricings",
      params: { year: currentBooking.year, week: currentBooking.week },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as GridPricingDataType[];

          setGridPricing(serverData[0]);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGridPricing(false);
  }, 500);

  return (
    <DialogWrapper onCancel={onCancel} title="View Supply Agreements">
      <Stack flex={1} spacing={2}>
        <Typography variant="textmd" fontWeight="semiBold">
          {`${currentBooking.bookingDate} (Booked ${currentBooking.quantity} Head)`}
        </Typography>
        {loadingAgreements ? (
          <Spinner />
        ) : (
          <ListOfAgreements
            currentBooking={currentBooking}
            listOfAgreements={agreements}
            onView={id => {
              setCurrentItem(agreements.find(i => i.id === id));
              setShowAgreementForm(true);
            }}
          />
        )}
        {!loadingGridPricing && showAgreementForm && (
          <AgreementForm currentBooking={currentBooking} agreement={currentItem} gridPricing={gridPricing} onCancel={() => setShowAgreementForm(false)} />
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default View;
