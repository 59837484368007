import moment from "moment";
import { Chip, ContentWrapper, Hcharts } from "../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../utils/Types";
import _ from "lodash";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../components/Chart/CustomChartTraceColors";
import { ALL_LOTS } from "../../../../utils/Constants";
import { isDarkMode } from "../../../../utils/Helper";

const Section = ({ data, picWithFullInfo, loading }: { data: QueryResult; picWithFullInfo: LooseObject; loading: boolean }) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfYAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const theme = useTheme();

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const categories = _.uniq(
    (dataToDisplay || [])
      .map(i => moment(i.KILL_DATE, DateFormatServer.SHORT).startOf("month").add(2, "days")) // has to add extra days to make the x axis show correct month
      .sort((a, b) => a.valueOf() - b.valueOf())
      .map(i => i.format("MMM YYYY"))
  );

  const getTraceData = (brand: string) => {
    const traceData: LooseObject[] = [];
    const dataOfThisBrand: LooseObject[] = dataToDisplay?.filter(i => i.BRAND === brand) || [];

    categories.forEach(month => {
      const dataOfThisMonth = dataOfThisBrand.filter(i => moment(i.KILL_DATE, DateFormatServer.SHORT).startOf("month").add(2, "days").format("MMM YYYY") === month);
      traceData.push(dataOfThisMonth);
    });

    return traceData;
  };

  return (
    <ContentWrapper>
      {categories && (
        <Hcharts
          loading={loading}
          type={ChartType.BOX}
          title="yAxisLabel By Brand and Month"
          header={
            <Stack width="100%" spacing={1}>
              <Grid container width="100%" spacing={1}>
                <Grid>
                  <Chip label="Carcase Based" size="small" />
                </Grid>
                <Grid>
                  <Chip label={`PIC: ${picWithFullInfo.PIC}${picWithFullInfo.businessName && ` | ${picWithFullInfo.businessName}`}`} size="small" />
                </Grid>
                <Grid>
                  <Chip
                    label={`${
                      picWithFullInfo.LOT_REFERENCE === ALL_LOTS ? picWithFullInfo.KILL_DATE : moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)
                    }`}
                    size="small"
                  />
                </Grid>
                <Grid>
                  <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
                </Grid>
              </Grid>
            </Stack>
          }
          xTitle="Month"
          xData={categories}
          axisSwitch={{
            optionsOfYAxis,
          }}
          data={allBrands.map(o => ({
            data: getTraceData(o) || [],
            name: o,
          }))}
          traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        />
      )}
    </ContentWrapper>
  );
};

export default Section;
