import { Stack, Typography } from "@mui/material";
import { Chip, ChipPredefined, ContentWrapper, MinimalTable, SmallButton } from "../../../components";
import { LooseObject } from "../../../utils/Types";
import _ from "lodash";

export const ProbeStatusChip = ({ status }: { status: "Pass" | "Warn" | "Fail" }) => (
  <ChipPredefined label={status} type={status === "Pass" ? "success" : status === "Warn" ? "warning" : "error"} />
);

export const getOverallStatusFromStringArray = (allStatuStrings: string[]) => (allStatuStrings.includes("Fail") ? "Fail" : allStatuStrings.includes("Warn") ? "Warn" : "Pass");

export const getOverallStatus = (needleStatus: LooseObject) => {
  const allStatus = Object.keys(needleStatus).map(k => needleStatus[k]);
  const overallStatus = getOverallStatusFromStringArray(allStatus);
  return overallStatus;
};

const ProbeStatus = ({ title, data, handleViewReport }: { title: string; data: LooseObject; handleViewReport: (v: LooseObject) => void }) => {
  const columns = [
    { accessorKey: "needle", header: "Needle", maxSize: 100 },
    {
      accessorKey: "status",
      header: "Status",
      maxSize: 100,
      Cell: ({ cell }: { cell: any }) => <ProbeStatusChip status={cell.row.original.status} />,
    },
  ];

  const needleStatus: LooseObject = data[0].END_OF_PRODUCTION_CHECKS.MATERIAL_CALIBRATION.NEEDLE_STATUS;

  const rows = Object.keys(needleStatus).map(k => ({ needle: _.startCase(k), status: needleStatus[k] }));

  const allStatus = Object.keys(needleStatus).map(k => needleStatus[k]);
  const overallStatus = getOverallStatus(allStatus);

  return (
    <ContentWrapper spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="textsm" fontWeight="semiBold">
            {title}
          </Typography>
          <Chip label={`Last use: ${data[0].EOD_DATE_FORMATTED}`} />
          <ProbeStatusChip status={overallStatus} />
        </Stack>
        <SmallButton variant="outlined" title="View report" onClick={() => handleViewReport(data[0])} />
      </Stack>
      <MinimalTable columns={columns} data={rows} />
    </ContentWrapper>
  );
};

export default ProbeStatus;
