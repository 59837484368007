import { Chip, ChipPredefined, ContentWrapper, Dialog, Hcharts, Link, Spinner } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import getCustomChartTraceColors from "../../../../../components/Chart/CustomChartTraceColors";
import _ from "lodash";
import RankingDialog from "./RankingDialog";
import { isDarkMode, isNotEmpty } from "../../../../../utils/Helper";
import moment from "moment";

export const getPercentile = (percentiles: LooseObject[] | undefined, trait: "HSCW" | "IMF" | "LMY" | "GLQ", value: number) => {
  let percentile = "";
  if (percentiles && trait && isNotEmpty(value)) {
    const sortedPercentiles = _.orderBy(percentiles, trait);
    let index = 0;
    for (let i = 0; i < sortedPercentiles.length; i++) {
      if (sortedPercentiles[i][trait] <= value) {
        index = i;
      }
    }
    percentile = sortedPercentiles[index].PERCENTILE;
  }
  return percentile;
};

export const formatPercentile = (percentile: string) => {
  let formated = "";
  const purePercentile = percentile.replace("_percentage_of", "");
  const purePercentileArray = purePercentile.split("_");
  if (purePercentileArray.length > 1) {
    const percent = Number(purePercentileArray[1]);
    if (percent > 50) {
      formated = `Bottom ${100 - percent}%`;
    } else {
      formated = `Top ${percent}%`;
    }
  }
  return formated;
};

const Section = ({
  data,
  loading,
  averages,
  picWithFullInfo,
  percentiles,
}: {
  data: QueryResult;
  loading: boolean;
  averages: LooseObject;
  picWithFullInfo: LooseObject;
  percentiles: QueryResult | undefined;
}) => {
  const EXCLUDE_COLUMNS: string[] = [];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const [openForDialog, setOpenForDialog] = useState<boolean>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const percentile = getPercentile(percentiles?.rows, "GLQ", averages.AVG_GLQ);

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        stacking="normal"
        title="xAxisLabel Trend"
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
              </Grid>
            </Grid>
            {!percentiles ? (
              <Spinner size={28} />
            ) : percentiles?.numRows && percentile ? (
              <Grid display="flex" alignItems="center">
                <ChipPredefined
                  type="success"
                  label={
                    <Stack direction="row" spacing={1}>
                      <Typography variant="textxs">{`${formatPercentile(percentile)}of all lots for GLQ score.`}</Typography>
                      <Link onClick={() => setOpenForDialog(true)} sx={theme => ({ textDecoration: "none", borderBottom: `1px solid ${theme.palette.success.main}` })}>
                        <Typography variant="textxs" color="success.main">
                          {isSmallScreen ? "Ranking" : "View Ranking"}
                        </Typography>
                      </Link>
                    </Stack>
                  }
                />
              </Grid>
            ) : undefined}
          </Stack>
        }
        axisSwitch={{
          optionsOfXAxis,
          defaultXAxisName: "HSCW_KG",
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        traceColors={getCustomChartTraceColors("GMP", isDarkMode(theme))}
        subCharts={[{ type: ChartType.HISTOGRAM }, { type: ChartType.SCATTER_PLOT, title: "xAxisLabel vs yAxisLabel", axisSwitch: { optionsOfYAxis: optionsOfXAxis } }]}
        height={452}
      />
      <Dialog open={openForDialog} onClose={() => setOpenForDialog(false)} isTransparent maxWidth="md">
        {percentile && averages && percentiles && (
          <RankingDialog picWithFullInfo={picWithFullInfo} averages={averages} percentiles={percentiles} onCancel={() => setOpenForDialog(false)} />
        )}
      </Dialog>
    </ContentWrapper>
  );
};

export default Section;
