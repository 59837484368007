import { Options } from "highcharts";
import { HISTOGRAM_Y_AXIS_TITLE, HISTOGRAM_Y_AXIS_TITLE_PERCENT } from "../../../utils/Constants";
import { ChartType, HchartsProps, LooseObject, Trace } from "../../../utils/Types";
import { Bin, getBin } from "../Utils";
import { avoidJsFloatingPointPrecisionIssue, isNotEmpty } from "../../../utils/Helper";
import commonOptions from "./CommonOptions";
import _ from "lodash";

const createBins = (data: number[], bin: Bin) => {
  let min = Math.min(...data);
  let max = Math.max(...data);

  if (isNotEmpty(bin?.start)) {
    min = bin?.start!;
  }
  if (isNotEmpty(bin?.end)) {
    max = bin?.end!;
  }

  const bins = [];
  for (let i = min; i <= max + bin.size!; i += bin.size!) {
    bins.push(avoidJsFloatingPointPrecisionIssue(i));
  }
  return bins;
};

const calculateFrequencies = (data: number[], bins: number[]) => {
  const frequencies = Array(bins.length - 1).fill(0);
  data.forEach(value => {
    for (let i = 0; i < bins.length - 1; i++) {
      if (value >= bins[i] && value < bins[i + 1]) {
        frequencies[i]++;
        break;
      }
    }
  });
  return frequencies;
};

const convertToPercentages = (frequencies: number[], total: number) => {
  return frequencies.map(freq => (freq / total) * 100);
};

const formatHistogramData = (percentages: number[], bins: number[], binSize: number) => {
  return percentages.map((percentage, index) => ({
    // Adjust x to be the center of the bin
    x: avoidJsFloatingPointPrecisionIssue(bins[index] + bins[index + 1]) / 2,
    y: percentage,
    binRange: `${avoidJsFloatingPointPrecisionIssue(bins[index])} - ${avoidJsFloatingPointPrecisionIssue(bins[index + 1])}`,
  }));
};

export const histogramChartOptions = (props: HchartsProps) => {
  const {
    type,
    data,
    histnorm,
    stacking,
    xTitle,
    yTitle,
    xAxis,
    yAxisMin,
    yAxisMax,
    xAxisType,
    xCategoryOrder,
    axisSwitch,
    traceColors,
    height,
    zoomingType,
    hideLegend,
    subCharts,
    onSelect,
  } = props;

  let traces: (Trace & { dataFormatted: LooseObject[] })[] = data.map(i => ({ ...i, dataFormatted: i.data }));

  const bin =
    xAxisType === "category"
      ? {}
      : getBin({
          xName: xAxis?.label,
          xData: traces.flatMap(i => i.data.map(o => (xAxis?.name ? o[xAxis.name] : null) as number)),
        });

  let categories: string[] | undefined;

  if (xAxisType === "category" && xAxis?.name) {
    const allUniqCategories = _.uniq(traces.flatMap(i => i.data.map(o => o[xAxis.name])));
    const sortedCategories =
      xCategoryOrder === "sum descending"
        ? allUniqCategories.sort(
            (a, b) => _.sumBy(traces, trace => trace.data.filter(o => o[xAxis.name] === b).length) - _.sumBy(traces, trace => trace.data.filter(o => o[xAxis.name] === a).length)
          )
        : allUniqCategories.sort();

    categories = sortedCategories;

    traces = traces.map(trace => {
      const frequencies = sortedCategories.map(category => _.countBy(trace.data.map(o => o[xAxis.name]))[category] || 0);
      const total = _.sum(frequencies);
      const percentages = frequencies.map(frequency => (total > 0 ? (frequency / total) * 100 : 0));

      const formatted = sortedCategories.map((category, index) => ({
        x: index,
        y: histnorm === "percent" ? percentages[index] : frequencies[index],
        binRange: category,
      }));

      return { ...trace, dataFormatted: formatted };
    });
  } else {
    if (bin.xbin?.size) {
      const dataX: number[] = traces.flatMap(i => i.data.map(o => (xAxis?.name ? o[xAxis.name] : null) as number));
      const bins = createBins(dataX, bin.xbin);

      traces = traces.map(trace => {
        const frequencies = calculateFrequencies(
          trace.data.map(o => (xAxis?.name ? o[xAxis.name] : null) as number),
          bins
        );
        const total = _.sum(frequencies);
        const percentages = convertToPercentages(frequencies, total);
        const formatted = formatHistogramData(histnorm === "percent" ? percentages : frequencies, bins, bin.xbin?.size!);
        return { ...trace, dataFormatted: formatted };
      });
    }
  }

  const options: Options = {
    ...commonOptions(props),
    chart: {
      type: "column",
      height: height || 500,
      zooming: { type: zoomingType || subCharts ? "x" : undefined },
      events: {
        selection: event => {
          if (onSelect) {
            const xAxisFromEvent = event.xAxis ? event.xAxis[0] : null;
            if (xAxis?.name && xAxisFromEvent) {
              const filteredData = traces.map(trace => ({
                ...trace,
                data: trace.data.filter(d => d[xAxis.name] >= xAxisFromEvent.min && d[xAxis.name] <= xAxisFromEvent.max),
                dataFormatted: trace.dataFormatted.filter(d => d.x >= xAxisFromEvent.min && d.x <= xAxisFromEvent.max),
              }));
              const subChartOptions = subCharts
                ?.filter(subChart => subChart.type !== type)
                .map(subChart => ({
                  ...props,
                  ...subChart,
                  type: ChartType.SCATTER_PLOT,
                  data: filteredData,
                  axisSwitch: { ...axisSwitch, ...subChart.axisSwitch, defaultXAxisName: xAxis?.name },
                  subCharts: undefined,
                  updateFilter: undefined,
                }));

              onSelect?.([
                {
                  ...props,
                  data: filteredData,
                  axisSwitch: { ...axisSwitch, defaultXAxisName: xAxis?.name },
                  subCharts: undefined,
                  updateFilter: undefined,
                },
                ...(subChartOptions || []),
              ]);
            }
            return false;
          }
        },
      },
    },
    xAxis: {
      title: { text: xTitle || xAxis?.label || "" },
      alignTicks: false,
      min: bin.xbin?.start,
      max: bin.xbin?.end,
      type: xAxisType === "category" ? "category" : "linear",
      categories,
    },
    yAxis: {
      title: { text: yTitle || histnorm === "percent" ? HISTOGRAM_Y_AXIS_TITLE_PERCENT : HISTOGRAM_Y_AXIS_TITLE },
      min: yAxisMin,
      max: yAxisMax,
      labels: {
        format: histnorm === "percent" ? "{value}%" : undefined,
      },
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<span style="font-size:0.8em">{point.point.binRange}</span><br/>',
      pointFormat: `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>${histnorm === "percent" ? "{point.y:.1f}%" : "{point.y}"}</b><br/>`,
    },
    plotOptions: {
      column: {
        grouping: true,
        groupPadding: 0.2,
      },
      series: {
        borderWidth: 0,
        stacking,
        animation: false,
      },
    },
    legend: {
      enabled: hideLegend !== undefined ? hideLegend : traces.length !== 1,
    },
    series: traces.map((trace, index) => {
      return {
        name: trace.name,
        type: "column",
        data: _.every(trace.dataFormatted, { y: 0 }) ? [] : trace.dataFormatted,
        color: traceColors?.[index],
      };
    }),
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  return options;
};
