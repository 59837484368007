import { FormHelperText, Stack, Typography } from "@mui/material";
import { Button, Form, Link, Spinner } from "../../../../../../components";
import { FormData, FormInputCategory, FormInputItem, GridPricingDataType, LooseObject, DateFormatServer } from "../../../../../../utils/Types";
import ContentWrapper from "../../../../../../components/ContentWrapper";
import GridPricing from "../../../../GridPricing";
import moment from "moment";
import { DateFormatBookings } from "../../../..";
import { useEffect, useState } from "react";
import { delay } from "../../../../../../utils/Helper";
import VendorType from "../../../CalendarView/TableCalendar/VendorType";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../../../../../../utils/Constants";

const AgreementForm = ({
  currentBooking,
  agreement,
  agreementSequence,
  gridPricing,
  pics,
  onConfirm,
  onCancel,
}: {
  currentBooking: LooseObject;
  agreement: LooseObject | undefined;
  agreementSequence: number;
  gridPricing?: GridPricingDataType;
  pics: string[];
  onConfirm: (v: FormData) => void;
  onCancel: () => void;
}) => {
  const [pageContent, setPageContent] = useState<JSX.Element>();

  useEffect(() => {
    loadContent();
  }, [agreement]);

  const loadContent = async () => {
    setPageContent(undefined);
    await delay(500);

    const inputs: FormInputItem[] = [
      {
        name: "picOfAutocomplete",
        label: "PIC",
        category: FormInputCategory.AUTO_COMPLETE,
        options: pics.map(i => ({ label: i, value: i })),
        defaultValue: agreement?.pic ? { label: agreement?.pic, value: agreement?.pic } : { label: "", value: "" },
        getOptionLabel: (option: any) => option.label,
        inputProps: {
          style: { textTransform: "uppercase" },
        },
        autoCompleteShowInputValue: true,
      },
      { name: "agreedDeliveryDate", label: "Agreed Delivery Date", category: FormInputCategory.TEXT_FIELD, defaultValue: currentBooking.bookingDate, disabled: true },
      { name: "agreedQuantity", label: "Agreed Quantity", category: FormInputCategory.TEXT_FIELD, defaultValue: agreement?.agreedQuantity || "" },
      {
        name: "breed",
        label: "Breed",
        category: FormInputCategory.SELECT,
        options: ["Poll Dorset", "Shedder", "XB", "2nd Cross", "Composite", "Merino"]
          .map(i => ({ label: i, value: i }))
          .concat([{ label: "Other (please specify under bloodlines)", value: "Other" }]),
        defaultValue: agreement?.breed || "",
      },
      {
        name: "estimatedAverageWeightKg",
        label: "Estimated Average Weight (kg)",
        category: FormInputCategory.SELECT,
        options: Array.from(Array(31).keys()).map(i => ({ label: i + 40, value: i + 40 })),
        defaultValue: agreement?.estimatedAverageWeightKg || "",
      },
      {
        name: "woolLengthMm",
        label: "Wool Length (mm)",
        category: FormInputCategory.SELECT,
        options: Array.from(Array(17).keys()).map(i => ({ label: i * 5, value: i * 5 })),
        defaultValue: agreement?.woolLengthMm || "",
      },
      {
        name: "finishingNutrition",
        label: "Finishing Nutrition",
        category: FormInputCategory.SELECT,
        options: [
          "Feedlot",
          "Lucerne",
          "Lucerne & clover",
          "Lucerne & pasture mix",
          "Grazing canola",
          "Grazing crop (other)",
          "Lucerne & feeder",
          "Pasture & feeder",
          "Lucerne, clover & feeder",
          "Grazing crop and feeder",
          "Stubbles & feeder",
          "Clover",
          "Hay & pellets/grain",
          "Improved pasture",
          "Native pasture",
          "Pellet",
        ].map(i => ({ label: i, value: i })),
        defaultValue: agreement?.finishingNutrition || "",
      },
      {
        name: "ageMonths",
        label: "Age (months)",
        category: FormInputCategory.SELECT,
        options: Array.from(Array(10).keys()).map(i => ({ label: i + 4, value: i + 4 })),
        defaultValue: agreement?.ageMonths || "",
      },
      { name: "bloodlines", label: "Bloodlines", category: FormInputCategory.TEXT_FIELD, defaultValue: agreement?.bloodlines || "" },
      {
        name: "isMuelsed",
        label: "Have these lambs been muelsed?",
        category: FormInputCategory.SELECT,
        options: [
          { label: "Yes", value: "true" },
          { label: "No", value: "false" },
        ],
        defaultValue: agreement?.isMuelsed?.toString(),
      },
      { name: "isMsaAccredited", label: "Meat Standards Australia Accredited?", category: FormInputCategory.CHECK_BOX, defaultValue: agreement?.isMsaAccredited },
      {
        name: "isComplyWithLPA",
        label: "Do you comply with the LPA program rules and standards?",
        category: FormInputCategory.CHECK_BOX,
        defaultValue: agreement?.isComplyWithLPA,
      },
      { name: "isGrassFed", label: "Grass Fed", category: FormInputCategory.CHECK_BOX, helperText: "No access to grain", defaultValue: agreement?.isGrassFed },
      {
        name: "isFreeRange",
        label: "Free Range",
        category: FormInputCategory.CHECK_BOX,
        helperText: "Livestock have not been confined for the purpose of intensive feeding for production and have had continuous access to graze in open pasture.",
        defaultValue: agreement?.isFreeRange,
      },
      {
        name: "isAntibioticFree",
        label: "Antibiotic Free",
        category: FormInputCategory.CHECK_BOX,
        helperText: "Sheep have never been treated with antibiotics, including low level (prophylactic) or therapeutic level doses, sulphonamides, ionophores or coccidiostats.",
        defaultValue: agreement?.isAntibioticFree,
      },
      {
        name: "isHormoneAndGrowthPromotantFree",
        label: "Hormone and Growth Promotant Free",
        category: FormInputCategory.CHECK_BOX,
        helperText: (
          <Stack spacing={1} sx={{ mx: 3.8, mt: -1 }}>
            <FormHelperText>Sheep have never been treated with hormonal growth promotants (HGPs). HGP means a veterinary chemical product that:</FormHelperText>
            <FormHelperText>
              a) Contains a substance that is, or a mixture of substances that are, responsible for oestrogenic, androgenic, gestagenic or thyrostatic activity to enhance growth or
              production in cattle; and
            </FormHelperText>
            <FormHelperText>
              b) Is registered for use for this purpose in Australia. Other allowable statements include: No Hormones administered, raised without added Hormones.
            </FormHelperText>
          </Stack>
        ),
        defaultValue: agreement?.isHormoneAndGrowthPromotantFree,
      },
      {
        name: "gridPricing",
        category: FormInputCategory.COMPONENT,
        component: gridPricing ? (
          <GridPricing data={gridPricing} />
        ) : (
          <ContentWrapper sx={{ maxWidth: 250 }}>
            <Typography textAlign="center">No grid pricing available.</Typography>
          </ContentWrapper>
        ),
      },
      {
        name: "isTocAgreed",
        label: (
          <Stack>
            <Typography
              variant="textsm"
              sx={{
                position: "relative",
                transform: "none",
                textOverflow: "unset",
                whiteSpace: "normal",
                wordBreak: "keep-all",
                my: 1.5,
              }}
            >
              {`Disclaimer: By clicking here, I agree to the Gundagai Lamb Grid provided and the attached `}
              <Link
                href="files/gmp/GMP-terms-and-conditions.pdf"
                target="_blank"
                variant="textsm"
                color="text.primary"
                sx={theme => ({
                  textDecoration: "none",
                  borderBottom: `1px solid ${theme.palette.text.primary}`,
                  ":hover": { color: "text.secondary", borderBottom: `1px solid ${theme.palette.text.secondary}` },
                })}
              >
                Terms and Conditions
              </Link>
              .
            </Typography>
          </Stack>
        ),
        category: FormInputCategory.CHECK_BOX,
        defaultValue: agreement?.isTocAgreed,
      },
    ];

    if (currentBooking.isAgent) {
      inputs.splice(
        1,
        0,
        ...[
          { name: "producerFirstName", label: "First Name of Producer", category: FormInputCategory.TEXT_FIELD, defaultValue: agreement?.producerFirstName || "" },
          { name: "producerLastName", label: "Last Name of Producer", category: FormInputCategory.TEXT_FIELD, defaultValue: agreement?.producerLastName || "" },
          { name: "producerBusinessName", label: "Trading Name of Producer", category: FormInputCategory.TEXT_FIELD, defaultValue: agreement?.producerBusinessName || "" },
        ]
      );
    }

    setPageContent(
      <Stack spacing={2} maxWidth={theme => ({ xs: theme.breakpoints.values.sm - DEFAULT_SPACING_MOBILE * 8 * 7, md: theme.breakpoints.values.sm - DEFAULT_SPACING * 8 * 3 })}>
        <Stack direction="row" spacing={2}>
          <Typography variant="textmd">Agreement {agreement?.id || agreementSequence}</Typography>
          <VendorType type={currentBooking.isAgent ? "Agent" : "Producer"} />
        </Stack>
        <Typography variant="textmd" fontWeight="semiBold">
          {currentBooking.bookingDate}
        </Typography>
        <Form
          onSubmit={v => {
            if (v.picOfAutocomplete?.value?.trim()) {
              onConfirm({
                ...v,
                _id: agreement?._id,
                id: agreement?.id || agreementSequence,
                pic: v.picOfAutocomplete?.value?.trim().toUpperCase(),
                agreedDeliveryDate: moment(currentBooking.bookingDate, DateFormatBookings).format(DateFormatServer.SHORT),
              });
            }
          }}
          buttonText="Confirm"
          buttonFullWidth={false}
          extraButton={<Button title="Cancel" variant="outlined" onClick={onCancel} />}
          inputs={inputs}
        />
      </Stack>
    );
  };

  return <ContentWrapper>{pageContent ? pageContent : <Spinner />}</ContentWrapper>;
};

export default AgreementForm;
