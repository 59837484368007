import { Stack, Select as MuiSelect, MenuItem, FormControl, InputLabel, ListItemText, Checkbox, Box, Chip } from "@mui/material";
import { FormInputItem, LabelValuePair, LooseObject } from "../../utils/Types";
import { useState } from "react";
import { HelperText } from "./TextField";

export const SELECT_ALL_VALUE = "select_all";

type Props = {
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  handleChange: {
    /** Classic React change handler, keyed by input name */
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void;
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: LooseObject;
  errors: LooseObject;
  inputProps?: {};
};

const Select = ({ item, values, handleChange, handleBlur, touched, errors, inputProps, textFieldProps }: Props) => {
  const [isSelectAllSelected, setIsSelectAllSelected] = useState(false);

  let options: LabelValuePair[] = [];
  if (item.options) {
    options = [...item.options];
  }
  if (options && Array.isArray(options) && options.length > 0 && item.selectAll) {
    options = [{ label: "Select All", value: SELECT_ALL_VALUE }].concat(options);
  }

  return (
    <Stack alignItems="flex-start" width="100%">
      <FormControl error={errors[item.name] && touched[item.name]} size={item.size} sx={textFieldProps.sx} fullWidth>
        {item.label && (
          <InputLabel
            shrink
            sx={{
              position: "relative",
              transform: "none",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </InputLabel>
        )}
        <MuiSelect
          {...{ ...(inputProps || item.inputProps), notched: false }}
          label={item.label}
          displayEmpty
          name={item.name}
          value={
            item.multiple
              ? typeof values[item.name] === "string"
                ? values[item.name].split(",")
                : values[item.name] || []
              : values[item.name] === 0
              ? 0
              : values[item.name] || item.defaultValue || ""
          }
          onChange={v => {
            if (item.multiple && item.selectAll) {
              if (v.target.value.includes(SELECT_ALL_VALUE)) {
                // select all
                if (!isSelectAllSelected) {
                  setIsSelectAllSelected(true);
                  const values = options.map(i => i.value);
                  v.target.value = values;
                } else {
                  // deselect selectAll
                  setIsSelectAllSelected(false);
                  const values = v.target.value.filter((i: string) => i !== SELECT_ALL_VALUE);
                  v.target.value = values;
                }
              } else {
                const values = v.target.value;
                // deselect all
                if (!values.includes(SELECT_ALL_VALUE) && isSelectAllSelected) {
                  v.target.value = [];
                  setIsSelectAllSelected(false);
                }
              }
            }

            handleChange(v);
            item.exposeValue?.(v.target.value);
          }}
          renderValue={selected =>
            item.placeholder && selected === "" ? (
              item.placeholder
            ) : item.multiple ? (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected
                  .filter((i: string) => item.multiple && ((item.selectAll && i !== SELECT_ALL_VALUE) || !item.selectAll))
                  .map((value: any) => (
                    <Chip key={value} label={options?.find(o => o.value === value)?.label} size="small" />
                  ))}
              </Box>
            ) : (
              options?.find(o => o.value === selected)?.label
            )
          }
          onBlur={handleBlur}
          error={errors[item.name] && touched[item.name]}
          required={item.required}
          multiline={item.multiline}
          minRows={3}
          maxRows={7}
          multiple={item.multiple}
          disabled={item.disabled}
        >
          {options && Array.isArray(options) && options.length > 0 ? (
            options?.map(o => (
              <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
                {item.multiple && <Checkbox checked={(typeof values[item.name] === "string" ? values[item.name].split(",") : values[item.name] || []).indexOf(o.value) > -1} />}
                <ListItemText primary={o.label} secondary={o.description} />
              </MenuItem>
            ))
          ) : (
            <ListItemText primary="No options available." sx={{ mx: 2 }} />
          )}
        </MuiSelect>
        <HelperText item={item} touched={touched} errors={errors} />
      </FormControl>
    </Stack>
  );
};

export default Select;
