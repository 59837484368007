import { FormControl, InputLabel, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

import { DateFormat, FormInputItem, LooseObject } from "../../utils/Types";

export type DatePickerProps = {
  label?: string;
  name?: string;
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  minDate?: any;
  maxDate?: any;
  inputProps?: {};
  format?: string;
  showFloatingLabel?: boolean;
};

const DatePicker = ({ label, name, item, values, setFieldValue, minDate, maxDate, textFieldProps, format, showFloatingLabel }: DatePickerProps) => (
  <Stack alignItems="flex-start" width="100%">
    <FormControl size={item.size} sx={textFieldProps.sx} fullWidth>
      {(label || item.label) && (
        <InputLabel
          shrink
          sx={{
            position: "relative",
            transform: "none",
            marginBottom: "5px",
          }}
        >
          {label || item.label}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          {...textFieldProps}
          // label={showFloatingLabel && (label || item.label)}
          disablePast={item.disablePast}
          disableFuture={item.disableFuture}
          value={values[name || item.name] === undefined ? null : values[name || item.name]}
          onChange={v => {
            setFieldValue(name || item.name, v);
            item.exposeValue?.(v);
          }}
          minDate={minDate}
          maxDate={maxDate}
          format={format || DateFormat.SHORT}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              position: "relative",
              transform: "none",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              transform: "none",
              top: "0",
            },
            ...textFieldProps?.sx,
          }}
        />
      </LocalizationProvider>
    </FormControl>
  </Stack>
);

export default DatePicker;
