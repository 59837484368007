import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";

const AnimalHealthCheckboxes = ({ allDiseases, diseaseColors, handleUpdate }: { allDiseases: string[]; diseaseColors: string[]; handleUpdate: (v: string[]) => void }) => {
  const [diseasesSelected, setDiseasesSelected] = useState<string[]>([]);

  return (
    <Stack>
      <Typography fontWeight="semiBold">Animal Health</Typography>
      <FormGroup>
        {allDiseases.map((disease, index) => (
          <FormControlLabel
            sx={{ ".MuiFormControlLabel-label": { fontWeight: 500 } }}
            key={disease}
            control={
              <Checkbox
                sx={{ py: 0.5 }}
                onChange={v => {
                  let newSelected = [...diseasesSelected];
                  let isSelectedChanged = false;
                  if (v.target.checked) {
                    if (!newSelected.includes(disease)) {
                      newSelected.push(disease);
                      isSelectedChanged = true;
                    }
                  } else {
                    if (newSelected.includes(disease)) {
                      newSelected = newSelected.filter(i => i !== disease);
                      isSelectedChanged = true;
                    }
                  }
                  if (isSelectedChanged) {
                    setDiseasesSelected(newSelected);
                    handleUpdate(newSelected);
                  }
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Stack sx={{ bgcolor: diseaseColors[index], width: 8, height: 8, borderRadius: 4 }} />
                <Typography variant="textsm">{disease}</Typography>
              </Stack>
            }
          />
        ))}
      </FormGroup>
    </Stack>
  );
};

export default AnimalHealthCheckboxes;
