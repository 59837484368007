import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { FormData, FormInputCategory } from "../../../utils/Types";

const Add = ({ loading, onCancel, handleSubmit }: { loading: boolean; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">
            Add a new role
          </Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit(data)}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "roleKey",
                label: "Key",
                category: FormInputCategory.TEXT_FIELD,
                helperText: `The key consists of Client, Location, and Role strings, where each string's words are joined with underscores and the three strings are joined with hyphens.`,
              },
              {
                name: "displayName",
                label: "Display Name",
                category: FormInputCategory.TEXT_FIELD,
              },
              {
                name: "description",
                label: "Description",
                category: FormInputCategory.TEXT_FIELD,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Add;
