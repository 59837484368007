import { Box, Stack, SxProps } from "@mui/material";
import { Formik, FormikProps } from "formik";
import Grid from "@mui/material/Unstable_Grid2";
import ValidationSchema from "./ValidationSchema";
import { FormInputCategory, FormInputItem, LooseObject } from "../../utils/Types";
import ServerMessage from "../ServerMessage";
import { Message } from "../../utils/Types";
import TextField from "./TextField";
import { isNotEmpty } from "../../utils/Helper";
import Autocomplete from "./Autocomplete";
import Select from "./Select";
import DatePicker from "./DatePicker";
import Checkbox from "./Checkbox";
import SmallButton from "../Button/SmallButton";
import Button, { ButtonProps } from "../Button";
import RadioGroup from "./RadioGroup";
import { ReactNode, useImperativeHandle, forwardRef, useRef } from "react";
import DateRangePicker from "./DateRangePicker";

export const SELECT_ALL_VALUE = "select_all";

type ButtonSize = "tiny" | "small" | "medium" | "large";

const FormItemWrapper = ({ children, item }: { children: JSX.Element; item?: FormInputItem }) => (
  <Grid xs={12} {...item?.gridLayout} sx={{ display: "flex" }}>
    {children}
  </Grid>
);

const SubmitButton = ({ title, size, ...rest }: { size: ButtonSize } & ButtonProps) => {
  switch (size) {
    case "small":
      return <SmallButton title={title} {...rest} />;

    default:
      return <Button title={title} {...rest} />;
  }
};

type FormProps = {
  loading?: boolean;
  message?: Message;
  onSubmit?: (values: any) => void;
  inputs: FormInputItem[];
  buttonText?: string;
  buttonVariant?: "contained" | "outlined" | "text";
  buttonSx?: SxProps;
  buttonGridLayout?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
  buttonDisabled?: boolean;
  extraButton?: ReactNode;
  itemSx?: SxProps;
  isHorizontal?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  format?: string;
  buttonFullWidth?: boolean;
  buttonSize?: "small" | "medium" | "large";
  noValidation?: boolean;
};

const Form = forwardRef(
  (
    {
      loading,
      message,
      onSubmit,
      inputs,
      buttonText,
      buttonVariant,
      buttonSx,
      buttonGridLayout,
      buttonDisabled,
      extraButton,
      itemSx = {},
      isHorizontal,
      size,
      fullWidth = true,
      format,
      buttonFullWidth,
      buttonSize = "large",
      noValidation,
    }: FormProps,
    ref
  ) => {
    const formikRef = useRef<FormikProps<any>>(null);

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        formikRef.current?.resetForm({ values: getInitialValues(true) });
      },
    }));

    const getInitialValues = (empty = false) => {
      const values: LooseObject = {};
      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i += 1) {
          switch (inputs[i].category) {
            case FormInputCategory.TEXT_FIELD:
              values[inputs[i].name] = empty ? "" : inputs[i].defaultValue || "";
              break;
            case FormInputCategory.CHECK_BOX:
              values[inputs[i].name] = empty ? false : inputs[i].defaultValue || false;
              break;
            case FormInputCategory.SWITCH:
              values[inputs[i].name] = empty ? false : inputs[i].defaultValue || false;
              break;
            case FormInputCategory.NUMBER_RANGE_INPUT:
              values[`${inputs[i].name}From`] = empty ? "" : isNotEmpty(inputs[i].defaultValue?.from) ? inputs[i].defaultValue?.from : "";
              values[`${inputs[i].name}To`] = empty ? "" : isNotEmpty(inputs[i].defaultValue?.to) ? inputs[i].defaultValue?.to : "";
              break;
            case FormInputCategory.DATE_PICKER:
              values[inputs[i].name] = empty ? null : inputs[i].defaultValue || null;
              break;
            case FormInputCategory.DATE_RANGE_PICKER:
              values[`${inputs[i].name}From`] = empty ? null : inputs[i].defaultValue?.from || null;
              values[`${inputs[i].name}To`] = empty ? null : inputs[i].defaultValue?.to || null;
              break;
            case FormInputCategory.SELECT:
              values[inputs[i].name] = empty ? (inputs[i].multiple ? [] : "") : inputs[i].defaultValue === 0 ? 0 : inputs[i].defaultValue || (inputs[i].multiple ? [] : "");
              break;
            case FormInputCategory.AUTO_COMPLETE:
              values[inputs[i].name] = empty ? (inputs[i].multiple ? [] : "") : inputs[i].defaultValue || (inputs[i].multiple ? [] : "");
              break;
            default:
              values[inputs[i].name] = empty ? "" : inputs[i].defaultValue || "";
              break;
          }
        }
      }
      return values;
    };

    const textFieldProps = {
      autoComplete: "new-password",
      fullWidth,
      size,
      sx: itemSx,
    };

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        onSubmit={values => {
          const optimisedValues = { ...values };
          Object.keys(optimisedValues).forEach(k => {
            const input = inputs.find(i => i.name === k);
            // remove SELECT_ALL_VALUE
            if (input?.multiple && input?.selectAll) {
              optimisedValues[k] = optimisedValues[k].filter((i: string) => i !== SELECT_ALL_VALUE);
            }
          });
          onSubmit?.(optimisedValues);
        }}
        validationSchema={noValidation ? undefined : ValidationSchema(inputs)}
      >
        {props => {
          const { values, touched, errors, isValid, handleChange, handleBlur, handleSubmit, setFieldValue } = props;
          return (
            <form onSubmit={handleSubmit} style={isHorizontal ? { position: "relative", display: "flex", flexDirection: "row" } : { textAlign: "center" }}>
              {inputs && Array.isArray(inputs) && inputs.length > 0 && (
                <Grid container width="100%" height="100%" rowSpacing={2} columnSpacing={isHorizontal ? 2 : 0} textAlign="left" alignItems={isHorizontal ? "flex-end" : undefined}>
                  {inputs.map(item => {
                    let input;
                    switch (item.category) {
                      case FormInputCategory.COMPONENT:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <Box width="100%">{item.component}</Box>
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.TEXT_FIELD:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <TextField
                              item={item}
                              values={values}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.SELECT:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <Select
                              item={item}
                              values={values}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.RADIO_GROUP:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <RadioGroup
                              item={item}
                              values={values}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.AUTO_COMPLETE:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <Autocomplete
                              item={item}
                              values={values}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                              loading={item.loading}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.DATE_PICKER:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <DatePicker
                              item={item}
                              values={values}
                              setFieldValue={setFieldValue}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                              format={format}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.DATE_RANGE_PICKER:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <DateRangePicker
                              item={item}
                              values={values}
                              setFieldValue={setFieldValue}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                              format={format}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      case FormInputCategory.CHECK_BOX:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <Checkbox
                              item={item}
                              values={values}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                              textFieldProps={{
                                ...textFieldProps,
                                ...item.textFieldProps,
                              }}
                            />
                          </FormItemWrapper>
                        );
                        break;

                      default:
                        input = (
                          <FormItemWrapper key={item.name} item={item}>
                            <Box />
                          </FormItemWrapper>
                        );
                    }
                    return input;
                  })}

                  {buttonText && (
                    <FormItemWrapper key={"submit_button"} item={{ name: "", gridLayout: buttonGridLayout } as FormInputItem}>
                      <Stack
                        direction="row"
                        spacing={2}
                        width={(buttonFullWidth !== undefined ? buttonFullWidth : isHorizontal ? undefined : true) ? "100%" : undefined}
                        sx={{ my: isHorizontal ? undefined : buttonSize === "large" ? 3 : buttonSize === "small" ? 1 : 2 }}
                      >
                        <SubmitButton
                          title={buttonText}
                          loading={loading}
                          variant={buttonVariant || "contained"}
                          type="submit"
                          disabled={!isValid || buttonDisabled}
                          sx={{ ...buttonSx }}
                          size={buttonSize}
                        />
                        {extraButton}
                      </Stack>
                    </FormItemWrapper>
                  )}
                  {message && message.text && <ServerMessage message={message} />}
                </Grid>
              )}
            </form>
          );
        }}
      </Formik>
    );
  }
);

export default Form;
