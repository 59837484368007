import { Menu, MenuItem } from "@mui/material";

const Actions = ({
  anchorEl,
  open,
  onClose,
  toggleFullScreen,
  handleReset,
  handleSettings,
}: {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  toggleFullScreen?: () => void;
  handleReset?: () => void;
  handleSettings?: () => void;
}) => (
  <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
    {/* {handleReset && (
      <MenuItem
        onClick={() => {
          onClose();
          handleReset();
          // updateFilter({ x: null, y: null });
        }}
      >
        Reset
      </MenuItem>
    )} */}
    {handleSettings && (
      <MenuItem
        onClick={() => {
          onClose();
          handleSettings();
        }}
      >
        Settings
      </MenuItem>
    )}
    {toggleFullScreen && (
      <MenuItem
        onClick={() => {
          onClose();
          toggleFullScreen();
        }}
      >
        Toggle full screen
      </MenuItem>
    )}
  </Menu>
);

export default Actions;
