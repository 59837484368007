import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { FormData, FormInputCategory, LabelValuePair } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { logout, postToServer } from "../../../utils/Helper";
import _ from "lodash";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Add = ({ loading, onCancel, handleSubmit }: { loading: boolean; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingZitadelOrgs, setLoadingZitadelOrgs] = useState(false);
  const [allZitadelOrgs, setAllZitadelOrgs] = useState<string[]>([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [allRoles, setAllRoles] = useState<LabelValuePair[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadAllRoles();
    loadAllZitadelOrgs();
  }, []);

  const loadAllRoles = _.debounce(async () => {
    setLoadingRoles(true);
    await postToServer({
      action: "administration/GetAllRoles",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LabelValuePair[];
          setAllRoles(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingRoles(false);
  }, 500);

  const loadAllZitadelOrgs = _.debounce(async () => {
    setLoadingZitadelOrgs(true);
    await postToServer({
      action: "administration/GetAllZitadelOrgs",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as string[];
          setAllZitadelOrgs(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingZitadelOrgs(false);
  }, 500);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">
            Add a new user
          </Typography>
          <Form
            loading={loading || loadingZitadelOrgs || loadingRoles}
            onSubmit={data => handleSubmit(data)}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "zitadelOrgIdString",
                label: "Zitadel Orgnisation",
                category: FormInputCategory.SELECT,
                options: allZitadelOrgs.map(i => ({ label: i, value: i } as LabelValuePair)),
                helperText: "This user will only be able to login via this Zitadel Organisation. Can't be changed later",
              },
              {
                name: "email",
                label: "Email (This will be the login username)",
                category: FormInputCategory.TEXT_FIELD,
                type: "email",
              },
              {
                name: "firstName",
                label: "First Name",
                category: FormInputCategory.TEXT_FIELD,
              },
              {
                name: "lastName",
                label: "Last Name",
                category: FormInputCategory.TEXT_FIELD,
              },
              {
                name: "roles",
                label: "Roles",
                category: FormInputCategory.SELECT,
                options: allRoles,
                multiple: true,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Add;
