import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { logout, postToServer } from "../../../utils/Helper";
import { DateFormat, LooseObject, FormData, LabelValuePair } from "../../../utils/Types";
import { Center, ChipPredefined, DetailPanel, Dialog, SmallButton, Table } from "../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ToggleFullScreenButton } from "material-react-table";
import Edit from "./Edit";
import Add from "./Add";
import UpdateRoles from "./UpdateRoles";
import validator from "validator";
import ToggleUserState from "./ToggleUserState";
import Delete from "./Delete";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<LooseObject[]>([]);
  const [openDialogForAdd, setOpenDialogForAdd] = useState<boolean>(false);
  const [openDialogForEdit, setOpenDialogForEdit] = useState<boolean>(false);
  const [openDialogForUpdateRoles, setOpenDialogForUpdateRoles] = useState<boolean>(false);
  const [openDialogForToggleUserState, setOpenDialogForToggleUserState] = useState<boolean>(false);
  const [openDialogForDelete, setOpenDialogForDelete] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<MRT_Row<MRT_RowData>>();

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "administration/GetAllUsers",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setData(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "email", header: "Email" },
    { accessorKey: "displayName", header: "Name" },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }: { cell: any }) => <ChipPredefined type={cell.row.original.isValid ? "success" : "error"} label={cell.row.original.isValid ? "Active" : "Deactivated"} />,
    },
    { accessorKey: "rolesList", header: "Roles" },
  ];

  const dataToDisplay = _.sortBy(data, "idString");
  const rows: MRT_RowData[] = dataToDisplay.map(i => ({
    ...i,
    rolesList: i.roles?.join(", "),
    lastAccessAt: moment(i.lastAccessAt).format(DateFormat.DEFAULT),
    createdAt: moment(i.createdAt).format(DateFormat.DEFAULT),
    updatedAt: moment(i.updatedAt).format(DateFormat.DEFAULT),
  }));

  const handleAdd = async ({ zitadelOrgIdString, email, firstName, lastName, roles }: FormData) => {
    if (zitadelOrgIdString && email && validator.isEmail(email) && firstName && lastName && roles) {
      setLoading(true);
      await postToServer({
        action: "administration/AddUser",
        params: { zitadelOrgId: zitadelOrgIdString, email, firstName, lastName, roles },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForAdd(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleEdit = async ({ id, firstName, lastName }: FormData) => {
    if (id && firstName && lastName) {
      setLoading(true);
      await postToServer({
        action: "administration/UpdateUser",
        params: { id, firstName, lastName },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleUpdateRoles = async ({ id, roles }: FormData) => {
    if (id && roles) {
      setLoading(true);
      await postToServer({
        action: "administration/UpdateRoles",
        params: { id, roles },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForUpdateRoles(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleToggleUserState = async ({ id, action }: { id: string; action: "deactivate" | "activate" }) => {
    if (id && action) {
      setLoading(true);
      await postToServer({
        action: "administration/ToggleUserState",
        params: { id, action },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForToggleUserState(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (id) {
      setLoading(true);
      await postToServer({
        action: "administration/DeleteUser",
        params: { id },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForDelete(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Table
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ row }) => ({ sx: { bgcolor: row.original.isValid === false ? "error.main" : undefined } })}
        enableRowSelection
        enableSelectAll={false}
        enableMultiRowSelection={false}
        renderTopToolbar={({ table }) => {
          return (
            <Stack direction="row" justifyContent="space-between" p={2}>
              <Typography variant="textmd" fontWeight="semiBold" alignSelf="center">
                All Users
              </Typography>
              <Center direction="row" spacing={2}>
                <SmallButton title="Add" variant="outlined" onClick={() => setOpenDialogForAdd(true)} />
                <SmallButton
                  title="Edit"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForEdit(true);
                  }}
                />
                <SmallButton
                  title="Update Roles"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForUpdateRoles(true);
                  }}
                />
                <SmallButton
                  title="Activate/Deactivate"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForToggleUserState(true);
                  }}
                />
                <SmallButton
                  title="Delete"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForDelete(true);
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToggleFullScreenButton table={table} />
                </Stack>
              </Center>
            </Stack>
          );
        }}
        enableExpandAll={false}
        renderDetailPanel={({ row }) => {
          const item = row.original;
          const excludeKeys = columns.map(i => i.accessorKey).concat(["_id", "__v"]);
          const labelValuePairs: LabelValuePair[] = Object.keys(item)
            .filter(i => !excludeKeys.includes(i))
            .map(i => ({ label: _.startCase(i), value: item[i] }));
          return <DetailPanel data={labelValuePairs} />;
        }}
        initialState={{ pagination: { pageSize: 20, pageIndex: 0 } }}
      />
      <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
        <Add loading={loading} handleSubmit={handleAdd} onCancel={() => setOpenDialogForAdd(false)} />
      </Dialog>
      <Dialog open={openDialogForEdit} onClose={() => setOpenDialogForEdit(false)} isTransparent>
        {currentItem && <Edit loading={loading} row={currentItem} handleSubmit={handleEdit} onCancel={() => setOpenDialogForEdit(false)} />}
      </Dialog>
      <Dialog open={openDialogForUpdateRoles} onClose={() => setOpenDialogForUpdateRoles(false)} isTransparent>
        {currentItem && <UpdateRoles row={currentItem} handleSubmit={handleUpdateRoles} onCancel={() => setOpenDialogForUpdateRoles(false)} />}
      </Dialog>
      <Dialog open={openDialogForToggleUserState} onClose={() => setOpenDialogForToggleUserState(false)} isTransparent>
        {currentItem && <ToggleUserState loading={loading} row={currentItem} handleSubmit={handleToggleUserState} onCancel={() => setOpenDialogForToggleUserState(false)} />}
      </Dialog>
      <Dialog open={openDialogForDelete} onClose={() => setOpenDialogForDelete(false)} isTransparent>
        {currentItem && <Delete loading={loading} row={currentItem} handleSubmit={handleDelete} onCancel={() => setOpenDialogForDelete(false)} />}
      </Dialog>
    </Stack>
  );
};

export default Page;
