import { Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../../utils/Contexts";
import { DateFormatServer, GmpBookingStatus, LooseObject } from "../../../../utils/Types";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/reducers/userSlice";
import { getCurrentOrg, getDateFormat, logout, postToServer } from "../../../../utils/Helper";
import _ from "lodash";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import moment from "moment";
import { selectOrgs } from "../../../../redux/reducers/orgsSlice";
import { Dialog, SmallButton, Table } from "../../../../components";
import { DateFormatBookings } from "../..";
import { StatusChip } from "../../StatusChips";
import Complete from "./SupplyAgreement/Complete";
import View from "./SupplyAgreement/View";
import { gray } from "../../../../utils/Colors";
import { selectZitadelOrg } from "../../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [bookings, setBookings] = useState<LooseObject[]>([]);
  const [openDialogForComplete, setOpenDialogForComplete] = useState<boolean>(false);
  const [openDialogForView, setOpenDialogForView] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const currentOrg = getCurrentOrg(orgs);

  const theme = useTheme();

  useEffect(() => {
    fetchBookings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchBookings.cancel();
  }, []);

  const fetchBookings = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "gmp/GetMyBookings",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setBookings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "bookingDate", header: "Booking Date" },
    { accessorKey: "quantity", header: "Head" },
    { accessorKey: "bookedBy", header: "Booked By", accessorFn: row => `${row.bookedByFirstName} ${row.bookedByLastName}` },
    { accessorKey: "status", header: "Status", Cell: ({ renderedCellValue, row }) => <StatusChip label={renderedCellValue} textLabel={row.original.status} /> },
  ];

  const rows: MRT_RowData[] = bookings.map(i => ({
    ...i,
    createdAt: moment(i.createdAt).format(getDateFormat(currentOrg, "default")),
    updatedAt: moment(i.updatedAt).format(getDateFormat(currentOrg, "default")),
    bookingDate: moment(i.bookingDate, DateFormatServer.SHORT).format(DateFormatBookings),
  }));

  const handleComplete = async ({ bookingId, supplyAgreements }: { bookingId: string; supplyAgreements: LooseObject[] }) => {
    setLoading(true);
    await postToServer({
      action: "gmp/CompleteSupplyAgreements",
      params: { bookingId, supplyAgreements },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setOpenDialogForComplete(false);
          const serverData = response.serverData as LooseObject[];
          setBookings(serverData);
        }
        snackbar.open(response.message);
      }
    });
    setLoading(false);
  };

  return (
    <Stack mt={2}>
      <Stack spacing={2}>
        <Typography variant="textmd" fontWeight="semiBold">
          My Bookings
        </Typography>
        <Table
          loading={loading}
          columns={columns}
          data={rows}
          enableRowActions
          enableSorting={false}
          enableHiding={false}
          enableFullScreenToggle={false}
          positionActionsColumn="last"
          renderRowActions={({ row }) => (
            <Stack alignItems="flex-start">
              {row.original.status === GmpBookingStatus.Assigned && (
                <SmallButton
                  variant="contained"
                  title="Complete"
                  onClick={() => {
                    setCurrentItem(row.original);
                    setOpenDialogForComplete(true);
                  }}
                  sx={{ fontSize: "0.8125rem", borderRadius: "0.3rem", height: "1.5rem", p: 2, width: 80 }}
                />
              )}
              {row.original.status === GmpBookingStatus.Completed && (
                <SmallButton
                  variant="outlined"
                  title="View"
                  color="inherit"
                  onClick={() => {
                    setCurrentItem(row.original);
                    setOpenDialogForView(true);
                  }}
                  sx={{ fontSize: "0.8125rem", borderRadius: "0.3rem", height: "1.2rem", p: 2, width: 80, borderColor: theme.palette.mode === "light" ? gray[300] : gray[700] }}
                />
              )}
            </Stack>
          )}
        />
      </Stack>
      <Dialog open={openDialogForComplete} onClose={() => setOpenDialogForComplete(false)} isTransparent maxWidth="xs">
        {currentItem && <Complete loading={loading} currentBooking={currentItem} handleSubmit={handleComplete} onCancel={() => setOpenDialogForComplete(false)} />}
      </Dialog>
      <Dialog open={openDialogForView} onClose={() => setOpenDialogForView(false)} isTransparent maxWidth="xs">
        {currentItem && <View currentBooking={currentItem} onCancel={() => setOpenDialogForView(false)} />}
      </Dialog>
    </Stack>
  );
};

export default Page;
