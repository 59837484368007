import { rose } from "../Colors";

export const getClientSpecificThemeOptions = (client: string, isLightMode: boolean) => {
  let options = {};
  switch (client) {
    case "GMP":
      options = {
        palette: {
          mode: isLightMode ? "light" : "dark",
          primary: {
            main: isLightMode ? "#FF8C99" : rose[500],
            light: isLightMode ? rose[200] : rose[700],
            dark: isLightMode ? rose[800] : rose[300],
            contrastText: isLightMode ? "#FFFFFF" : rose[950],
          },
          mybuttonbg: {
            primary: isLightMode ? rose[600] : rose[600],
            primaryHover: isLightMode ? rose[700] : rose[700],
            secondary: isLightMode ? "#FFFFFF" : rose[900],
            secondaryHover: isLightMode ? rose[50] : rose[800],
          },
          mybuttonborder: {
            primary: isLightMode ? rose[600] : rose[600],
          },
        },
      };
      break;
  }
  return options;
};
