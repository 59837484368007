import { Popover, Stack, Typography } from "@mui/material";
import { useState, MouseEvent } from "react";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { LooseObject } from "../../utils/Types";

const TraitListWithPopover = ({ item, columns, hiddenColumnNames }: { item: LooseObject; columns: MRT_ColumnDef<MRT_RowData, any>[]; hiddenColumnNames: string[] }) => {
  const traitsInterested = columns.filter(i => i.accessorKey && !hiddenColumnNames.includes(i.accessorKey));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="space-between" spacing={0.5}>
      <Stack>
        {traitsInterested.slice(0, 2).map((i: any) => (
          <Stack direction="row" key={i.accessorKey} spacing={1} alignItems="center">
            <Typography variant="textxs">{i.header}:</Typography>
            <Typography variant="textxs">{item[i.accessorKey]}</Typography>
          </Stack>
        ))}
        {traitsInterested.length > 2 && (
          <Stack>
            <Typography variant="textxs" onClick={handleClick} sx={{ textDecoration: "underline", cursor: "pointer" }}>
              More
            </Typography>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Stack p={1}>
                {traitsInterested.map((i: any) => (
                  <Stack direction="row" key={i.accessorKey} spacing={1} alignItems="center">
                    <Typography variant="textxs">{i.header}:</Typography>
                    <Typography variant="textxs">{item[i.accessorKey]}</Typography>
                  </Stack>
                ))}
              </Stack>
            </Popover>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default TraitListWithPopover;
