import { Container, Stack, TextField, Typography, useTheme } from "@mui/material";
import { DialogWrapper, Form, Spinner } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory, FormInputItem, LooseObject } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { SnackbarContext } from "../../../utils/Contexts";
import { isNotEmpty, logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { getUniqSortedAndGroupedPicsFromDb } from "./Add";
import { ALL, DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../../../utils/Constants";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { GMP_ROLE } from "../../GmpLambBookings";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const AssignGmpPics = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  const snackbar = useContext(SnackbarContext);
  const [loadingGmpPics, setLoadingGmpPics] = useState(false);
  const [allGmpPics, setAllGmpPics] = useState<LooseObject[]>([]);
  const [inputs, setInputs] = useState<FormInputItem[]>([]);

  const picOptions = getUniqSortedAndGroupedPicsFromDb(allGmpPics);
  const defaultValue = picOptions.filter(i => item.gmpPics?.includes(i.value));

  const [gmpPicsSelected, setGmpPicsSelected] = useState<LooseObject[]>(defaultValue || []);
  const [gmpPicsSelectedWithLots, setGmpPicsSelectedWithLots] = useState<{ pic: string; lots: string[] }[]>(item.gmpPicsWithLots || []);

  const theme = useTheme();
  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchAllGmpPics();
  }, []);

  const fetchAllGmpPics = _.debounce(async () => {
    setLoadingGmpPics(true);
    await postToServer({
      action: "gmp/LoadPics",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setAllGmpPics(response.serverData as LooseObject[]);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGmpPics(false);
  }, 500);

  useEffect(() => {
    let formInputs: FormInputItem[] = [
      {
        name: "gmpPics",
        label: "PICs",
        category: FormInputCategory.AUTO_COMPLETE,
        options: picOptions,
        loading: loadingGmpPics,
        groupBy: (option: any) => option.businessName,
        getOptionLabel: (option: any) => option.label,
        multiple: true,
        defaultValue,
        exposeValue: setGmpPicsSelected,
        autoCompleteFilterOptions: createFilterOptions({
          matchFrom: "any",
          stringify: (option: LooseObject) => option.businessName + option.label,
        }),
      },
    ];
    if (allGmpPics.length > 0) {
      setGmpPicsSelected(defaultValue);
      setInputs(formInputs);
    }
  }, [allGmpPics]);

  let gmpPicsSelectedWithLotsTemprary: { pic: string; lots: string[] }[] = [...gmpPicsSelectedWithLots];

  useEffect(() => {
    if (allGmpPics.length > 0 && item.rolesList?.includes(GMP_ROLE.AGENT)) {
      if (gmpPicsSelected.length > 0) {
        const commonPics = _.intersection(
          gmpPicsSelected.map((i: LooseObject) => i.value),
          gmpPicsSelectedWithLotsTemprary.map(i => i.pic)
        );
        // remove non-existing pics from newGmpPicsSelectedWithLots
        gmpPicsSelectedWithLotsTemprary = gmpPicsSelectedWithLotsTemprary.filter(i => commonPics.includes(i.pic));
        // set new pic's lots as empty array
        gmpPicsSelected.forEach((i: LooseObject) => {
          if (!commonPics.includes(i.value)) {
            gmpPicsSelectedWithLotsTemprary = [...gmpPicsSelectedWithLotsTemprary.filter(o => o.pic !== i.value), { pic: i.value, lots: [] }];
          }
        });
        setGmpPicsSelectedWithLots([...gmpPicsSelectedWithLotsTemprary]);

        setInputs(prev => [
          ...prev.filter(i => i.name !== "picLotsInput-helperText"),
          {
            name: "picLotsInput-helperText",
            category: FormInputCategory.COMPONENT,
            component: (
              <Stack spacing={2} p={2} sx={theme => ({ border: `1px solid ${theme.palette.myborder.secondary}`, borderRadius: 2 })}>
                <Typography variant="textsm" fontWeight="semiBold">
                  Assign Lots to PICs
                </Typography>
                <Typography variant="textsm">Please separate multiple lots with comma. Type only the keyword "All" if you prefer to assign all lots of a PIC. </Typography>
                {gmpPicsSelected.map(i => (
                  <TextField
                    key={`picLotsInput-${i.value}`}
                    label={`${i.label} Lots`}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{ ".MuiInputLabel-root": { fontSize: "1rem" } }}
                    defaultValue={item.gmpPicsWithLots?.find((o: LooseObject) => o.pic === i.value)?.lots?.join(", ")}
                    onChange={v => {
                      const newValue = {
                        pic: i.value,
                        lots: v.target.value?.toLowerCase().indexOf("all") > -1 ? [ALL] : v.target.value?.split(",").map((i: string) => i.toLowerCase().trim()),
                      };

                      gmpPicsSelectedWithLotsTemprary = [...gmpPicsSelectedWithLotsTemprary.filter(o => o.pic !== i.value), newValue];
                      setGmpPicsSelectedWithLots([...gmpPicsSelectedWithLotsTemprary]);
                    }}
                  />
                ))}
              </Stack>
            ),
          },
        ]);
      } else {
        gmpPicsSelectedWithLotsTemprary = [];
        setGmpPicsSelectedWithLots([]);
        setInputs(prev => [...prev.filter(i => i.name !== "picLotsInput-helperText")]);
      }
    }
  }, [gmpPicsSelected]);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2} width={{ xs: theme.breakpoints.values.xs - DEFAULT_SPACING_MOBILE * 8, sm: theme.breakpoints.values.sm - DEFAULT_SPACING * 8 }}>
          <Typography variant="textlg" fontWeight="semiBold">{`Assign PICs to ${item.displayName}`}</Typography>
          {allGmpPics.length === 0 ? (
            <Spinner />
          ) : (
            <Form
              loading={loading || loadingGmpPics}
              onSubmit={data => {
                handleSubmit({ id: item.zitadelId, ...data, gmpPics: gmpPicsSelected, gmpPicsWithLots: gmpPicsSelectedWithLots });
              }}
              buttonText="Submit"
              buttonSize="small"
              buttonDisabled={
                gmpPicsSelected.length === 0 ||
                (gmpPicsSelectedWithLots.length > 0 && gmpPicsSelectedWithLots.map(i => i.lots.filter(o => isNotEmpty(o)).length > 0).includes(false))
              }
              buttonFullWidth={false}
              inputs={inputs}
            />
          )}
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default AssignGmpPics;
