import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory } from "../../../utils/Types";

const Edit = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">{`Update ${item.pic}`}</Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit({ id: item._id, ...data })}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "pic",
                label: "PIC",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.pic,
              },
              {
                name: "businessName",
                label: "Business Name",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.businessName,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Edit;
