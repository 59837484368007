import { ContentWrapper, Hcharts } from "../../../../../components";
import { ChartType, QueryResult, QueryResultColumn } from "../../../../../utils/Types";

const Section = ({ data, legends, selectedAxis }: { data: QueryResult; legends: string[]; selectedAxis: { xAxis?: QueryResultColumn; yAxis?: QueryResultColumn } }) => {
  const EXCLUDE_COLUMNS = ["MOB_NO"];
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const dataToDisplay = data?.rows;

  return (
    <ContentWrapper>
      <Hcharts
        type={ChartType.HISTOGRAM}
        histnorm="percent"
        title="xAxisLabel By Brand"
        axisSwitch={{
          optionsOfXAxis,
          defaultXAxisName: (selectedAxis.xAxis || (optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis[0] : undefined))?.name,
        }}
        data={legends.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        height={400}
      />
    </ContentWrapper>
  );
};

export default Section;
