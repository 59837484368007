import { Options } from "highcharts";
import { HchartsProps } from "../../../utils/Types";

const commonOptions = (chartProps: HchartsProps): Partial<Options> => {
  const { xTitle, yTitle, xAxis, yAxis } = chartProps;

  return {
    boost: {
      useGPUTranslations: true,
      usePreallocated: true,
    },
    title: {
      text: "",
    },
    xAxis: {
      title: { text: xTitle || xAxis?.label || "" },
    },
    yAxis: {
      title: { text: yTitle || yAxis?.label || "" },
    },
    accessibility: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    noData: { style: { fontWeight: "500", fontSize: "14px" } },
  };
};

export default commonOptions;
