import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgressIndicator, Tile, TileContentText } from "../../../components";
import { DateFormatServer, DateRange, QueryResult, QueryType } from "../../../utils/Types";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import moment from "moment";
import { getCurrentOrg, getDataFromDataPool, isNotEmpty, optimiseQueryResult } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import { ALL, ALL_LOTS, ALL_PICS } from "../../../utils/Constants";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Tiles = ({ pic, lot, dateRange, isLotIncludedInPic }: { pic: string; lot: string | number; dateRange?: DateRange; isLotIncludedInPic: boolean }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<QueryResult>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNotEmpty(pic) && isLotIncludedInPic) {
      fetchData();
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [pic, lot, dateRange]);

  const fetchData = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `GMP_GET_AGG_CARCASES('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}', '${pic === ALL_PICS ? ALL : pic}', '${
            lot === ALL_LOTS ? ALL : lot
          }')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(optimiseQueryResult(dataFromDataPool));
      });
      setLoading(false);
    }
  }, 500);

  const averages = data?.rows && (data?.numRows || 0) > 0 && (data?.columns?.length || 0) > 2 ? data?.rows?.[0] : {};

  return !loading && isNotEmpty(averages) ? (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="Avg HSCW Kg"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(averages.AVG_HSCW_KG) ? `${averages.AVG_HSCW_KG}kg` : "N/A"} />
            </Stack>
          }
        />
      </Grid>

      <Grid xs={12} md>
        <Tile
          title="Avg IMF %"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(averages.AVG_IMF_PERCENTAGE_OF) ? `${averages.AVG_IMF_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={averages.AVG_IMF_PERCENTAGE_OF} maxValue={10} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="AVG LMY %"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(averages.AVG_LMY_PERCENTAGE_OF) ? `${averages.AVG_LMY_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={averages.AVG_LMY_PERCENTAGE_OF} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg GLQ"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(averages.AVG_GLQ) ? averages.AVG_GLQ : "N/A"} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  ) : undefined;
};

export default Tiles;
