import { Chip, ContentWrapper, Hcharts } from "../../../../components";
import { ChartType, QueryResult } from "../../../../utils/Types";
import _ from "lodash";
import { EXCLUDE_COLUMNS } from "./HistogramByBrand";

const Section = ({ data, loading }: { data: QueryResult; loading: boolean }) => {
  const optionsOfXAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");
  const optionsOfYAxis = optionsOfXAxis;

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.SCATTER_PLOT}
        title="xAxisLabel vs yAxisLabel"
        titleExtra={<Chip label="Carcase Based" color="info" size="small" />}
        axisSwitch={{
          optionsOfXAxis,
          optionsOfYAxis,
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        subCharts={[{ type: ChartType.SCATTER_PLOT }, { type: ChartType.HISTOGRAM, title: "xAxisLabel" }]}
      />
    </ContentWrapper>
  );
};

export default Section;
