import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { LabelValuePair } from "../../utils/Types";
import { Stack, Typography } from "@mui/material";
import { getRandomString } from "../../utils/Helper";

const DetailPanel = ({ data }: { data: LabelValuePair[] }) => {
  const lengthOfLabels = data.map(i => i.label.length) as number[];
  const maxLengthOfLabels = Math.max(...lengthOfLabels);
  const width = maxLengthOfLabels * 10;

  return (
    <Grid container direction="column" spacing={1}>
      {data.map((i, index) => (
        <Grid container direction="row" key={`detail_panel_${index}_${getRandomString()}`}>
          <Grid width={width}>
            <Typography variant="textsm" fontWeight="semiBold">
              {i.label}
            </Typography>
          </Grid>
          <Grid maxWidth={`calc(100% - ${width}px)`}>
            {typeof i.value === "string" || typeof i.value === "number" ? (
              <Typography variant="textsm">{i.value}</Typography>
            ) : typeof i.value === "boolean" ? (
              <Typography variant="textsm">{i.value.toString()}</Typography>
            ) : Array.isArray(i.value) ? (
              <Stack>
                {i.value.map(m => (
                  <Typography variant="textsm" key={`label_value_pair_${index}_sub_${getRandomString()}`}>
                    {m}
                  </Typography>
                ))}
              </Stack>
            ) : (
              <Stack>{i.value}</Stack>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default DetailPanel;
