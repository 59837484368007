import { Container, Stack, Typography } from "@mui/material";
import { Center, DialogWrapper, Form, Spinner } from "../../../components";
import { FormData, FormInputCategory, LabelValuePair, LooseObject, PkgName } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { SnackbarContext } from "../../../utils/Contexts";
import { logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import PageId from "../../../utils/PageId";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

export const allPages = Object.values(PageId).filter(
  i => ![PageId.MAIN_ENTRY, PageId.NOT_FOUND, PageId.LOADING, PageId.LOGIN, PageId.LOGOUT, PageId.AUTH_CALLBACK, PageId.MANAGEMENT, PageId.ADMINISTRATION].includes(i)
);

const Add = ({ data, loading, onCancel, handleSubmit }: { data: LooseObject[]; loading: boolean; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingAllRoles, setLoadingAllRoles] = useState(false);
  const [allRoles, setAllRoles] = useState<LabelValuePair[]>();

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loadAllRoles();
  }, []);

  const loadAllRoles = _.debounce(async () => {
    setLoadingAllRoles(true);
    await postToServer({
      action: "administration/GetAllRoles",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LabelValuePair[];
          setAllRoles(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingAllRoles(false);
  }, 500);

  const rolesHavePermissions = data.map(o => o.role);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        {!allRoles || loadingAllRoles ? (
          <Center spacing={2} p={2}>
            <Spinner />
            <Typography>Loading roles</Typography>
          </Center>
        ) : (
          <Stack flex={1} py={3} spacing={2}>
            <Typography variant="textlg" fontWeight="semiBold">
              Add a new permission
            </Typography>
            <Form
              loading={loading}
              onSubmit={data => handleSubmit(data)}
              buttonText="Submit"
              buttonSize="small"
              buttonFullWidth={false}
              inputs={[
                {
                  name: "role",
                  label: "Role",
                  category: FormInputCategory.SELECT,
                  options: allRoles.filter(i => !rolesHavePermissions.includes(i.value)),
                  helperText: "Only can assign permissions to newly created role. If you cannot find desired role here, it means you can find it in the All Permisions table.",
                },
                {
                  name: "pkgs",
                  label: "Packages",
                  category: FormInputCategory.SELECT,
                  options: Object.values(PkgName)
                    .filter(i => ![PkgName.MANAGEMENT, PkgName.ADMINISTRATION].includes(i))
                    .map(o => ({ label: o, value: o })),
                  multiple: true,
                },
                {
                  name: "pages",
                  label: "Pages",
                  category: FormInputCategory.SELECT,
                  options: allPages.map(o => ({ label: o, value: o })),
                  multiple: true,
                  helperText: "Please choose only the pages belong to the packages you selected. The pages will start with the package names.",
                },
                {
                  name: "description",
                  label: "Description",
                  category: FormInputCategory.TEXT_FIELD,
                },
                {
                  name: "isWrite",
                  category: FormInputCategory.CHECK_BOX,
                  label: "Writable",
                },
              ]}
            />
          </Stack>
        )}
      </Container>
    </DialogWrapper>
  );
};

export default Add;
