import { Box, Stack } from "@mui/material";
import { SIDEBAR_WIDTH } from "./index";
import Logo from "./Logo";
import LeftSidebarItem from "./LeftSidebarItem";
import { isStringEqualIgnoreCase } from "../../utils/Helper";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../../utils/Constants";
import { NavItem, NavSection } from "../../utils/Types";

export const getCurrentNavSection = (pathname: string) => (pathname.split("/").length > 1 ? _.startCase(pathname.split("/")[1]) : "");

const Sidebar = ({ permittedSections, permittedPages }: { permittedSections: NavSection[]; permittedPages: NavItem[] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentNavSection = getCurrentNavSection(location.pathname);

  return (
    <Stack width={SIDEBAR_WIDTH} height="100%" sx={{ borderRightWidth: 1, borderRightColor: "myborder.secondary", borderRightStyle: "solid" }}>
      <Stack m={DEFAULT_SPACING} width={SIDEBAR_WIDTH - DEFAULT_SPACING * 8 * 2}>
        <Box>
          <Logo size={32} />
        </Box>
      </Stack>
      <Stack display="flex" justifyContent="space-between" flex={1}>
        <Stack spacing={DEFAULT_SPACING_MOBILE}>
          {permittedSections.map(i => (
            <LeftSidebarItem
              key={i.name}
              icon={i.icon}
              displayName={i.name}
              isActive={isStringEqualIgnoreCase(currentNavSection, _.startCase(i.name))}
              navigate={navigate}
              permittedPages={permittedPages}
              sectionName={i.name}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
