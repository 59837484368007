import { Message, PkgName } from "./Types";
import type { FeatureCollection, Point } from "geojson";

export const PROJECT_TITLE = "MEQ Insights";
export const COPY_RIGHT = `Copyright © MEQ ${new Date().getFullYear()}`;

export const SUCCESS_CODE = 700;

export const DEFAULT_SPACING = 3;
export const DEFAULT_SPACING_MOBILE = 1.5;
export const DEFAULT_BORDER_RADIUS = "0.5rem";

export const DEFAULT_MESSAGE: Message = { text: null, type: "error" } as const;

export const SUPER_ADMIN = "AllOrgs-SuperAdmin";
export const SUPER_USER = "AllOrgs-SuperUser";
export const ADMIN = "Admin"; // manage all resources for the org

export const WORDS_EXCLUDING_FROM_PAGE_TITLE = ["GMP", "Alliance"];

export const QUERY_DATA_SOURCE_ENDPOINT = "GetDataSource";
export const DATA_POOL_ITEM_EXPIRED_IN_MILLISECONDS = 60 * 60 * 1000; // 60 minutes
export const DEFAULT_NUMBER_OF_BINS = 8;

export const MAIN_ENTRY_PAGE = "/main";

export const CHART_DATA_COUNT_THRESHHOLD = 1000;
export const HISTOGRAM_Y_AXIS_TITLE = "Count";
export const HISTOGRAM_Y_AXIS_TITLE_PERCENT = "Percentage %";
export const PERCENTAGE_OF = "Percentage Of";
export const INCREASE_SYMBOL = "▲";
export const DECREASE_SYMBOL = "▼";

export const UPPER_CASE_TERMS = ["IMF", "AUS", "MSA", "PIC", "GLQ", "RFID", "MEQ", "HDW", "EOD", "HSCW", "LMY"];

export const ALL = "All";
export const ALL_ORGS = "AllOrgs";
export const ALL_LOCATIONS = "AllLocations";
export const ALL_PKGS = "AllPKGs";
export const ALL_PAGES = "AllPages";
export const ALL_COMPONENTS = "AllComponents";
export const ALL_BRANDS = "All Brands";
export const ALL_SUPPLIERS = "All Suppliers";
export const ALL_MOBS = "All Mobs";
export const ALL_PICS = "All PICs";
export const ALL_LOTS = "All Lots";
export const FORMATTED = "FORMATTED"; // indicate a data from snowflake is formatted
export const ALLIANCE_ALL_LOCATIONS = "Alliance_AllLocations";

export const ORGS_HAVING_SUPPLIER_VIEW = ["GMP"];
export const PKGS_HAVING_SUPPLIER_VIEW = [PkgName.ALLIANCE_DATA_ANALYSIS, PkgName.MEQ_CAMERA, PkgName.GMP_LAMB_DATA_ANALYSIS];

// export const MAP_STYLE = "mapbox://styles/mapbox/dark-v11";
export const MAP_STYLE = "mapbox://styles/benshackleton/clq06poer00kb01r807pq5s0t";

export const EMPTY_FEATURE_COLLECTION: FeatureCollection<Point> = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [144.9631, -37.8136], //Melbourne
      },
      properties: {},
    },
  ],
};
