import { ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import SvgIcon from "../SvgIcon";
import { NavItem } from "../../utils/Types";
import { optimisePageTitle } from "../../utils/Helper";

const SidebarItem = ({
  icon,
  displayName,
  isActive,
  navigate,
  permittedPages,
  sectionName,
  hideChevron,
  closeSidebar,
}: {
  icon: string;
  displayName: string;
  isActive: boolean;
  navigate: any;
  permittedPages: NavItem[];
  sectionName: string;
  hideChevron?: boolean;
  closeSidebar: () => void;
}) => (
  <ListItemButton
    onClick={() => {
      const pagesInThisSection = permittedPages.filter(page => page.category === sectionName);
      if (pagesInThisSection.length > 0) {
        closeSidebar();
        navigate(pagesInThisSection[0].to);
      }
    }}
    selected={isActive}
    dense
  >
    <ListItemIcon sx={{ minWidth: 36 }}>
      <SvgIcon name={icon} />
    </ListItemIcon>
    <ListItemText>
      <Typography variant="textsm" fontWeight="semiBold">
        {optimisePageTitle(displayName)}
      </Typography>
    </ListItemText>
    {!hideChevron && (
      <ListItemIcon sx={{ minWidth: 24 }}>
        <SvgIcon name="CheveronRight" />
      </ListItemIcon>
    )}
  </ListItemButton>
);

export default SidebarItem;
