import { Divider, InputLabel, Stack, Typography } from "@mui/material";
import { Chip, DialogWrapper, Form } from "../../../../../components";
import { DateFormatServer, FormInputCategory, GmpBookingStatus, LooseObject } from "../../../../../utils/Types";
import VendorType from "./VendorType";
import { StatusChip } from "../../../StatusChips";
import { DateFormatBookings, GMP_ROLE } from "../../..";
import { useState } from "react";
import moment from "moment";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectUser } from "../../../../../redux/reducers/userSlice";
import _ from "lodash";

const Add = ({
  loading,
  weeklyLimit,
  cell,
  bookings,
  handleSubmit,
  onCancel,
}: {
  loading: boolean;
  weeklyLimit: number;
  cell: any;
  bookings: LooseObject[];
  handleSubmit: (v: any) => void;
  onCancel: () => void;
}) => {
  const [status, setStatus] = useState<GmpBookingStatus>();

  const user = useAppSelector(selectUser);

  const bookingsOfThisWeek = bookings.filter(i => i.year === moment(Number(cell.column.id)).year() && i.week === moment(Number(cell.column.id)).week());
  const totalAgreedQuantity = _.sum(bookingsOfThisWeek.map(i => i.quantity));

  return (
    <DialogWrapper onCancel={onCancel} maxWidth={{ sm: 500 }}>
      <Stack spacing={2} minHeight={545}>
        <Stack spacing={2}>
          <Typography variant="textsm" fontWeight="semiBold">
            AVAILABILITY
          </Typography>
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>
              <Typography variant="displayxs" fontWeight="semiBold">
                {weeklyLimit}
              </Typography>
              <Typography>Limit / Week</Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="displayxs" fontWeight="semiBold">
                {weeklyLimit - totalAgreedQuantity}
              </Typography>
              <Typography>Space / Week</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Form
          loading={loading}
          onSubmit={data => handleSubmit({ ...data, vendorEmail: cell.row.original.email })}
          buttonText={status === GmpBookingStatus.Completed ? "Complete on behalf" : "Submit"}
          buttonSize="small"
          buttonFullWidth={false}
          inputs={[
            {
              name: "vendor",
              category: FormInputCategory.COMPONENT,
              component: (
                <Stack direction="row" spacing={1} alignItems="center">
                  <InputLabel>{`${cell.row.original.firstName} ${cell.row.original.lastName}`}</InputLabel>
                  <VendorType type={cell.row.original.roles?.includes(GMP_ROLE.AGENT) ? "Agent" : "Producer"} />
                </Stack>
              ),
            },
            {
              name: "quantity",
              label: "Head",
              category: FormInputCategory.TEXT_FIELD,
            },
            {
              name: "status",
              label: "Status",
              category: FormInputCategory.SELECT,
              options: (Object.keys(GmpBookingStatus) as Array<keyof typeof GmpBookingStatus>)
                .filter(i => ["NotApplicable", "Scheduled"].includes(i))
                .map(i => ({
                  label: <StatusChip label={GmpBookingStatus[i]} />,
                  value: GmpBookingStatus[i],
                })),
              exposeValue: v => setStatus(v),
            },
            {
              name: "bookingDate",
              label: "Booking date",
              category: FormInputCategory.SELECT,
              options: Array.from(Array(7).keys()).map(i => {
                const dayOfThisWeek = moment(Number(cell.column.id)).startOf("isoWeek").add(i, "days");
                return {
                  label: dayOfThisWeek.format(DateFormatBookings),
                  value: dayOfThisWeek.format(DateFormatServer.SHORT),
                };
              }),
            },
            {
              name: "bookedBy",
              category: FormInputCategory.COMPONENT,
              component: (
                <Stack spacing={1}>
                  <InputLabel>Booked by</InputLabel>
                  <Chip label={user.profile.name || ""} />
                </Stack>
              ),
            },
          ]}
        />
      </Stack>
    </DialogWrapper>
  );
};

export default Add;
