import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { ThemeModeContext } from "../utils/Contexts";
import { IconButton, Tooltip } from "@mui/material";

const ThemeModeSwitch = ({ size = "medium" }: { size?: "small" | "inherit" | "large" | "medium" }) => {
  const theme = useTheme();
  const themeMode = useContext(ThemeModeContext);

  return (
    <Tooltip title="Toggle dark mode">
      <IconButton onClick={themeMode.toggleThemeMode}>{theme.palette.mode === "dark" ? <Brightness7 fontSize={size} /> : <Brightness4 fontSize={size} />}</IconButton>
    </Tooltip>
  );
};

export default ThemeModeSwitch;
