import { CloseOutlined } from "@mui/icons-material";
import { Box, Chip as MuiChip, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { ReactNode } from "react";

export type ChipProps = {
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  variant?: "filled" | "outlined";
  size?: "small" | "medium";
  label: string | JSX.Element | ReactNode;
  sx?: SxProps<Theme>;
  tooltipTitle?: string;
  onDelete?: () => void;
};

const Chip = ({ color, variant, size, label, sx, tooltipTitle, onDelete }: ChipProps) =>
  tooltipTitle ? (
    <Tooltip title={tooltipTitle}>
      <Box>
        <MuiChip
          variant={variant || "outlined"}
          color={color}
          label={label}
          size={size || "small"}
          sx={color ? sx : { bgcolor: "mybg.secondary", borderColor: "myborder.secondary", ...sx }}
          onDelete={onDelete}
          deleteIcon={<CloseOutlined />}
        />
      </Box>
    </Tooltip>
  ) : (
    <Box>
      <MuiChip
        variant={variant || "outlined"}
        color={color}
        label={label}
        size={size || "small"}
        sx={color ? sx : { bgcolor: "mybg.secondary", borderColor: "myborder.secondary", ...sx }}
        onDelete={onDelete}
        deleteIcon={<CloseOutlined />}
      />
    </Box>
  );

export type ChipPredefinedProps = ChipProps & { type: "error" | "warning" | "success" | "successContained" };

export const ChipPredefined = ({ type, sx, ...rest }: ChipPredefinedProps) => {
  switch (type) {
    case "error":
      return <Chip color="error" size="small" sx={{ color: "utility.error.700", bgcolor: "utility.error.50", borderColor: "utility.error.200", ...sx }} {...rest} />;
    case "warning":
      return <Chip color="warning" size="small" sx={{ color: "utility.warning.700", bgcolor: "utility.warning.50", borderColor: "utility.warning.200", ...sx }} {...rest} />;
    case "success":
      return <Chip color="success" size="small" sx={{ color: "utility.success.700", bgcolor: "utility.success.50", borderColor: "utility.success.200", ...sx }} {...rest} />;
    case "successContained":
      return (
        <Chip
          variant="filled"
          color="success"
          size="small"
          sx={{ color: "mytext.primaryOnBrand", bgcolor: "utility.success.500", borderColor: "utility.success.700", ...sx }}
          {...rest}
        />
      );
    default:
      return <Stack />;
  }
};

export default Chip;
