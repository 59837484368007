import { Typography } from "@mui/material";
import Center from "./Center";

const NoRecords = () => (
  <Center height="100%" width="100%">
    <Typography>No records to display</Typography>
  </Center>
);

export default NoRecords;
