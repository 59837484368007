const getCustomChartTraceColors = (client: string, isDarkMode: boolean) => {
  let traceColors: string[] | undefined = undefined;
  switch (client) {
    case "GMP":
      traceColors = isDarkMode ? ["#7A4147", "#EF6C00", "#FF7043", "#EF5350"] : ["#2C040F", "#F6AE58", "#F97066", "#F04438"];
      break;
  }

  return traceColors;
};

export default getCustomChartTraceColors;
