import Grid from "@mui/material/Unstable_Grid2";
import { Chip, ChipPredefined, CircularProgressIndicator, ContentWrapper, DialogWrapper, Form, LabelValuePairs } from "../../../../components";
import { FormInputCategory, FormInputItem, LooseObject, Org, QueryResultTable } from "../../../../utils/Types";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { DEFAULT_SPACING } from "../../../../utils/Constants";
import _ from "lodash";
import { getCurrentOrg, getDateFormat, getRandomString, isMeqUser } from "../../../../utils/Helper";
import moment from "moment";
import { ProbeStatusChip } from "../../Dashboard/ProbeStatus";
import { useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/reducers/userSlice";

const DeviceDetails = ({
  data,
  groupedDailyReportsByProbe,
  defaultProbe,
  defaultDate,
  orgs,
  onCancel,
}: {
  data: QueryResultTable;
  groupedDailyReportsByProbe: LooseObject;
  defaultProbe: string;
  defaultDate: string;
  orgs: Org[];
  onCancel: () => void;
}) => {
  const [probe, setProbe] = useState<string>(defaultProbe);
  const [dateSelected, setDateSelected] = useState<string>(defaultDate);
  const [inputs, setInputs] = useState<FormInputItem[]>([]);
  const theme = useTheme();
  const user = useAppSelector(selectUser);

  const dataToDisplay = groupedDailyReportsByProbe[probe].find((i: any) => i.EOD_DATE_FORMATTED === dateSelected);

  useEffect(() => {
    setInputs([
      {
        name: "probe",
        category: FormInputCategory.SELECT,
        options: Object.keys(groupedDailyReportsByProbe || {}).map(i => ({ label: `Probe ${i}`, value: i })) || [],
        defaultValue: defaultProbe,
        size: "small",
        gridLayout: { xs: 6, sm: 5, md: 4, lg: 3 },
        exposeValue: v => {
          setProbe(v);

          const availableDates: string[] = groupedDailyReportsByProbe[v].map((i: LooseObject) => i.EOD_DATE_FORMATTED);
          const defaultDateWithUpdateProbe = availableDates.includes(defaultDate) ? defaultDate : availableDates.length > 0 ? availableDates[0] : "";
          setDateSelected(defaultDateWithUpdateProbe);
        },
      },
      {
        name: "dateSelected",
        category: FormInputCategory.SELECT,
        options: groupedDailyReportsByProbe[probe].map((i: any) => ({ label: i.EOD_DATE_FORMATTED, value: i.EOD_DATE_FORMATTED })),
        defaultValue: dateSelected,
        size: "small",
        gridLayout: { xs: 6, sm: 5, md: 4, lg: 3 },
        exposeValue: setDateSelected,
      },
    ]);
  }, [probe]);

  return (
    <DialogWrapper onCancel={onCancel} maxWidth={960}>
      <Typography variant="textxl" fontWeight="semiBold" sx={{ px: 2, pt: 1 }}>
        End of Production Reports
      </Typography>
      <Grid container spacing={DEFAULT_SPACING}>
        <Grid xs={12}>
          <ContentWrapper>
            <Form isHorizontal inputs={inputs} />
          </ContentWrapper>
        </Grid>
        <Grid xs={12} md={7}>
          <ContentWrapper>
            <Stack spacing={DEFAULT_SPACING}>
              <Stack spacing={DEFAULT_SPACING}>
                <Typography variant="textsm" fontWeight="semiBold">
                  Probe statistics
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="textsm" fontWeight="semiBold">
                    Profile
                  </Typography>
                  <LabelValuePairs
                    data={data?.tableColumns.map(i => ({
                      label: i.header,
                      value: i.accessorKey === "ATTENTION_REQ" ? <ProbeStatusChip status={dataToDisplay?.[i.accessorKey!]} /> : dataToDisplay?.[i.accessorKey!],
                    }))}
                  />
                </Stack>
                <Typography variant="textsm" fontWeight="semiBold">
                  End of Production Checks
                </Typography>
                {dataToDisplay?.END_OF_PRODUCTION_CHECKS?.SYSTEM_BACKGROUND && (
                  <Stack spacing={2}>
                    <Typography variant="textsm" fontWeight="semiBold">
                      System Background
                    </Typography>
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Chip label={_.startCase(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.SYSTEM_BACKGROUND?.USER || "")} />
                        <Typography variant="textsm">
                          {moment(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.SYSTEM_BACKGROUND?.TIME).format(getDateFormat(getCurrentOrg(orgs), "default_with_timezone"))}
                        </Typography>
                      </Stack>
                      <Divider />
                      <LabelValuePairs
                        data={Object.keys(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.SYSTEM_BACKGROUND?.NEEDLE_STATUS).map(k => ({
                          label: _.startCase(k),
                          value: <ProbeStatusChip status={dataToDisplay?.END_OF_PRODUCTION_CHECKS?.SYSTEM_BACKGROUND?.NEEDLE_STATUS[k]} />,
                        }))}
                      />
                    </Stack>
                  </Stack>
                )}
                {dataToDisplay?.END_OF_PRODUCTION_CHECKS?.MATERIAL_CALIBRATION && (
                  <Stack spacing={2}>
                    <Typography variant="textsm" fontWeight="semiBold">
                      Material Calibration
                    </Typography>
                    <Stack spacing={1}>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Chip label={_.startCase(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.MATERIAL_CALIBRATION?.USER || "")} />
                        <Typography variant="textsm">
                          {moment(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.MATERIAL_CALIBRATION?.TIME).format(getDateFormat(getCurrentOrg(orgs), "default_with_timezone"))}
                        </Typography>
                      </Stack>
                      <Divider />
                      <LabelValuePairs
                        data={Object.keys(dataToDisplay?.END_OF_PRODUCTION_CHECKS?.MATERIAL_CALIBRATION?.NEEDLE_STATUS).map(k => ({
                          label: _.startCase(k),
                          value: <ProbeStatusChip status={dataToDisplay?.END_OF_PRODUCTION_CHECKS?.MATERIAL_CALIBRATION?.NEEDLE_STATUS[k]} />,
                        }))}
                      />
                    </Stack>
                  </Stack>
                )}
                {user?.profile?.email && isMeqUser(user?.profile?.email) && (
                  <Stack spacing={2}>
                    <Typography variant="textsm" fontWeight="semiBold">
                      System Checks
                    </Typography>
                    {dataToDisplay?.SYSTEM_CHECKS?.map((i: LooseObject) => (
                      <Stack key={`system_checks_${getRandomString()}`} spacing={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Chip label={i.USER ? _.startCase(i.USER) : ""} />
                          <Typography variant="textsm">{moment(i.TIME).format(getDateFormat(getCurrentOrg(orgs), "default_with_timezone"))}</Typography>
                        </Stack>
                        <Divider />
                        <LabelValuePairs data={Object.keys(i.NEEDLE_STATUS).map(k => ({ label: _.startCase(k), value: <ProbeStatusChip status={i.NEEDLE_STATUS[k]} /> }))} />
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </ContentWrapper>
        </Grid>
        <Grid xs={12} md={5}>
          <Stack spacing={DEFAULT_SPACING}>
            {dataToDisplay?.USER_PERFORMANCE?.map((i: LooseObject) => {
              const color = i.PROBING_SCORE && i.PROBING_SCORE > 90 ? "success" : i.PROBING_SCORE && i.PROBING_SCORE > 85 ? "warning" : "error";
              return (
                <ContentWrapper key={`user_performance_${getRandomString()}`}>
                  <Stack spacing={2}>
                    <Chip label={`User: ${i.USERNAME ? _.startCase(i.USERNAME) : ""}`} />
                    <Stack direction="row" justifyContent="space-between">
                      <Stack spacing={2}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                          <Typography variant="displaymd" fontWeight="semiBold" color={theme.palette.utility[color][700]}>
                            {`${i.PROBING_SCORE}%`}
                          </Typography>
                          <CircularProgressIndicator
                            value={i.PROBING_SCORE}
                            size={32}
                            thickness={11}
                            borderWidth={8}
                            color={theme.palette.utility[color][700]}
                            borderColor={theme.palette.utility[color][300]}
                          />
                        </Stack>
                        <ChipPredefined label="Scan Score above 50" type={color} />
                      </Stack>
                      <Stack spacing={2}>
                        <Typography variant="displaymd" fontWeight="semiBold">
                          {i.CARCASSES_PROBED}
                        </Typography>
                        <Typography variant="textsm">Carcasses Probed</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </ContentWrapper>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default DeviceDetails;
