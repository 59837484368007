import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Tile, TileContentText, Chip } from "../../../components";
import { GmpBookingStatus, LooseObject } from "../../../utils/Types";
import _ from "lodash";
import { Moment } from "moment";
import { DateFormatBookingsLabel } from "../Bookings/Tiles";

const Tiles = ({ firstDayOfCurrentWeek, bookings, agreements }: { firstDayOfCurrentWeek: Moment; bookings: LooseObject[]; agreements: LooseObject[] }) => {
  const currentWeek = firstDayOfCurrentWeek.format(DateFormatBookingsLabel);
  const agreementsMergedWithBookings: LooseObject[] = agreements.map(agreement => {
    const booking = bookings.find(b => b._id === agreement.bookingId);
    return booking
      ? {
          ...agreement,
          status: booking.status,
        }
      : { ...agreement };
  });

  const totalNumberOfHeads = _.sumBy(agreementsMergedWithBookings, "agreedQuantity");
  const totalEstWeightOfEachAgreement = agreementsMergedWithBookings.map(i => i.estimatedAverageWeightKg * i.agreedQuantity);
  const estimatedAverageWeightKg = totalNumberOfHeads > 0 ? (_.sum(totalEstWeightOfEachAgreement) / totalNumberOfHeads).toFixed(0) : 0;
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="No. of head"
          content={
            <Stack spacing={1}>
              <TileContentText text={totalNumberOfHeads} />
              <Chip label={currentWeek} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Live weight avg"
          content={
            <Stack spacing={1}>
              <TileContentText text={`${estimatedAverageWeightKg} Kg`} />
              <Chip label={currentWeek} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Supply agreements"
          content={
            <Stack spacing={1}>
              <TileContentText text={`${agreementsMergedWithBookings.filter(i => i.status === GmpBookingStatus.Completed).length} / ${agreementsMergedWithBookings.length}`} />
              <Chip label={GmpBookingStatus.Completed} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Tiles;
