import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { getCurrentOrg, getDateFormat, getOrgFromOrgIdString, logout, postToServer } from "../../../utils/Helper";
import { LooseObject, FormData } from "../../../utils/Types";
import { Center, Dialog, SmallButton, Table } from "../../../components";
import moment from "moment";
import { MRT_ColumnDef, MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ToggleFullScreenButton } from "material-react-table";
import Edit from "./Edit";
import Add from "./Add";
import UpdateRoles from "./UpdateRoles";
import validator from "validator";
import Delete from "./Delete";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import AssignGmpPics from "./AssignGmpPics";
import { GMP_ROLE } from "../../GmpLambBookings";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<LooseObject[]>([]);
  const [openDialogForAdd, setOpenDialogForAdd] = useState<boolean>(false);
  const [openDialogForEdit, setOpenDialogForEdit] = useState<boolean>(false);
  const [openDialogForUpdateRoles, setOpenDialogForUpdateRoles] = useState<boolean>(false);
  const [openDialogForAssignGmpPics, setOpenDialogForAssignGmpPics] = useState<boolean>(false);
  const [openDialogForDelete, setOpenDialogForDelete] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<MRT_Row<MRT_RowData>>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const currentOrg = getCurrentOrg(orgs);

  const orgShort = getOrgFromOrgIdString(currentOrg?.idString || "");

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "management/users/GetAllUsersOfOrg",
      params: { orgIdString: currentOrg?.idString },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setData(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "email", header: "Email" },
    { accessorKey: "displayName", header: "Name" },
    { accessorKey: "rolesList", header: "Roles" },
    { accessorKey: "createdAt", header: "Created At" },
  ];

  if (orgShort === "GMP") {
    columns.splice(columns.length - 1, 0, {
      accessorKey: "gmpPics",
      header: "PICs",
      accessorFn: row =>
        row.rolesList.includes(GMP_ROLE.AGENT) ? row.gmpPicsWithLots?.map((i: LooseObject) => `${i.pic}[${i.lots?.join(", ")}]`).join(", ") : row.gmpPics?.join(", "),
    });
  }

  const dataToDisplay = _.sortBy(data, "idString");
  const rows: MRT_RowData[] = dataToDisplay.map(i => ({
    ...i,
    rolesList: i.roles?.join(", "),
    createdAt: moment(i.createdAt).format(getDateFormat(currentOrg, "default")),
  }));

  const handleAdd = async ({ email, firstName, lastName, role, gmpBusinessName, gmpPics, gmpPicsWithLots }: FormData) => {
    if (email && validator.isEmail(email) && firstName && lastName) {
      setLoading(true);
      await postToServer({
        action: "management/users/AddUser",
        params: {
          orgIdString: currentOrg?.idString,
          email,
          firstName,
          lastName,
          roles: [role],
          gmpBusinessName,
          gmpPics: gmpPics?.map((i: LooseObject) => i.value),
          gmpPicsWithLots,
        },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForAdd(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleEdit = async ({ id, firstName, lastName }: FormData) => {
    if (id && firstName && lastName) {
      setLoading(true);
      await postToServer({
        action: "management/users/UpdateUser",
        params: { orgIdString: currentOrg?.idString, id, firstName, lastName },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleUpdateRoles = async ({ id, role, gmpBusinessName }: FormData) => {
    if (id && role) {
      setLoading(true);
      await postToServer({
        action: "management/users/UpdateRoles",
        params: { orgIdString: currentOrg?.idString, id, roles: [role], gmpBusinessName },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForUpdateRoles(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (id) {
      setLoading(true);
      await postToServer({
        action: "management/users/DeleteUser",
        params: { orgIdString: currentOrg?.idString, id },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForDelete(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleAssignGmpPics = async ({ id, gmpPics, gmpPicsWithLots }: FormData) => {
    if (id && gmpPics) {
      setLoading(true);
      await postToServer({
        action: "management/users/AssignGmpPics",
        params: { orgIdString: currentOrg?.idString, id, gmpPics: gmpPics?.map((i: LooseObject) => i.value), gmpPicsWithLots },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForAssignGmpPics(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Table
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ row }) => ({ sx: { bgcolor: row.original.isValid === false ? "error.main" : undefined } })}
        enableRowSelection
        enableSelectAll={false}
        enableMultiRowSelection={false}
        renderTopToolbar={({ table }) => {
          return (
            <Stack direction="row" justifyContent="space-between" p={2} gap={1} sx={{ flexWrap: "wrap" }}>
              <Typography variant="textmd" fontWeight="semiBold" alignSelf="center">
                User List
              </Typography>
              <Center direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
                <SmallButton title="Invite" variant="outlined" onClick={() => setOpenDialogForAdd(true)} />
                <SmallButton
                  title="Edit"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForEdit(true);
                  }}
                />
                <SmallButton
                  title="Update Role"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForUpdateRoles(true);
                  }}
                />
                {orgShort === "GMP" ? (
                  <SmallButton
                    title="Assign PICs"
                    variant="outlined"
                    disabled={
                      table.getSelectedRowModel().flatRows.length !== 1 ||
                      table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub ||
                      (table.getSelectedRowModel().flatRows.length === 1 &&
                        !(
                          table.getSelectedRowModel().flatRows[0].original.roles.includes(GMP_ROLE.AGENT) ||
                          table.getSelectedRowModel().flatRows[0].original.roles.includes(GMP_ROLE.PRODUCER)
                        ))
                    }
                    onClick={() => {
                      setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                      setOpenDialogForAssignGmpPics(true);
                    }}
                  />
                ) : undefined}
                <SmallButton
                  title="Delete"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1 || table.getSelectedRowModel().flatRows[0].original.zitadelId === user.profile.sub}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForDelete(true);
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToggleFullScreenButton table={table} />
                </Stack>
              </Center>
            </Stack>
          );
        }}
      />
      <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
        <Add loading={loading} handleSubmit={handleAdd} onCancel={() => setOpenDialogForAdd(false)} />
      </Dialog>
      <Dialog open={openDialogForEdit} onClose={() => setOpenDialogForEdit(false)} isTransparent>
        {currentItem && <Edit loading={loading} row={currentItem} handleSubmit={handleEdit} onCancel={() => setOpenDialogForEdit(false)} />}
      </Dialog>
      <Dialog open={openDialogForUpdateRoles} onClose={() => setOpenDialogForUpdateRoles(false)} isTransparent>
        {currentItem && <UpdateRoles loading={loading} row={currentItem} handleSubmit={handleUpdateRoles} onCancel={() => setOpenDialogForUpdateRoles(false)} />}
      </Dialog>
      <Dialog open={openDialogForAssignGmpPics} onClose={() => setOpenDialogForAssignGmpPics(false)} isTransparent>
        {currentItem && <AssignGmpPics loading={loading} row={currentItem} handleSubmit={handleAssignGmpPics} onCancel={() => setOpenDialogForAssignGmpPics(false)} />}
      </Dialog>
      <Dialog open={openDialogForDelete} onClose={() => setOpenDialogForDelete(false)} isTransparent>
        {currentItem && <Delete loading={loading} row={currentItem} handleSubmit={handleDelete} onCancel={() => setOpenDialogForDelete(false)} />}
      </Dialog>
    </Stack>
  );
};

export default Page;
