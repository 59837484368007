import { Stack, Typography } from "@mui/material";
import { DialogWrapper, Spinner } from "../../../../../../components";
import { FormData, GridPricingDataType, LooseObject } from "../../../../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import ListOfAgreements from "./ListOfAgreements";
import AgreementForm from "./AgreementForm";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { selectUser } from "../../../../../../redux/reducers/userSlice";
import _ from "lodash";
import { logout, postToServer } from "../../../../../../utils/Helper";
import { SnackbarContext } from "../../../../../../utils/Contexts";
import { selectZitadelOrg } from "../../../../../../redux/reducers/zitadelOrgSlice";

const Complete = ({
  loading,
  currentBooking,
  onCancel,
  handleSubmit,
}: {
  loading: boolean;
  currentBooking: LooseObject;
  onCancel: () => void;
  handleSubmit: ({ bookingId, supplyAgreements }: { bookingId: string; supplyAgreements: LooseObject[] }) => void;
}) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingAgreements, setLoadingAgreements] = useState(true);
  const [loadingGridPricing, setLoadingGridPricing] = useState(false);
  const [loadingPics, setLoadingPics] = useState(false);

  const [agreements, setAgreements] = useState<LooseObject[]>([]);
  const [agreementSequence, setAgreementSequence] = useState<number>(0);
  const [showAgreementForm, setShowAgreementForm] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const [gridPricing, setGridPricing] = useState<GridPricingDataType>();
  const [pics, setPics] = useState<string[]>([]);

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSupplyAgreements();
    fetchGridPricing();
    fetchPics();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => {
      fetchSupplyAgreements.cancel();
      fetchGridPricing.cancel();
      fetchPics.cancel();
    };
  }, []);

  const fetchSupplyAgreements = _.debounce(async () => {
    setLoadingAgreements(true);
    await postToServer({
      action: "gmp/GetMySupplyAgreements",
      params: { bookingId: currentBooking._id },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];

          setAgreements(serverData.map((i, index) => ({ ...i, id: index + 1 })));
          setAgreementSequence(serverData.length);
          if (serverData.length === 0) {
            setAgreementSequence(prev => prev + 1);
            setShowAgreementForm(true);
          }
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingAgreements(false);
  }, 500);

  const fetchGridPricing = _.debounce(async () => {
    setLoadingGridPricing(true);
    await postToServer({
      action: "gmp/GetGridPricings",
      params: { year: currentBooking.year, week: currentBooking.week },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as GridPricingDataType[];

          setGridPricing(serverData[0]);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGridPricing(false);
  }, 500);

  const fetchPics = _.debounce(async () => {
    setLoadingPics(true);
    await postToServer({
      action: "gmp/GetPicsFromUser",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setPics(response.serverData as string[]);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingPics(false);
  }, 500);

  const handleComplete = (v: FormData) => {
    let updatedAgreements = [...agreements.filter(i => i.pic !== v.pic && i.id !== v.id), v];

    setAgreements(updatedAgreements);
    setShowAgreementForm(false);
  };

  return (
    <DialogWrapper onCancel={onCancel} title="Supply Agreement">
      <Stack flex={1} spacing={2}>
        <Typography variant="textmd" fontWeight="semiBold">
          {`${currentBooking.bookingDate} (Booked ${currentBooking.quantity} Head)`}
        </Typography>
        {loadingAgreements ? (
          <Spinner />
        ) : (
          <ListOfAgreements
            loading={loading}
            currentBooking={currentBooking}
            listOfAgreements={agreements}
            onSubmit={() => handleSubmit({ bookingId: currentBooking._id, supplyAgreements: agreements })}
            onAddAnother={() => {
              setAgreementSequence(prev => prev + 1);
              setCurrentItem(undefined);
              setShowAgreementForm(true);
            }}
            onEdit={id => {
              setCurrentItem(agreements.find(i => i.id === id));
              setShowAgreementForm(true);
            }}
            onDelete={id => {
              setAgreements(prev => prev.filter(i => i.id !== id));
            }}
            shouldShowButtons={!showAgreementForm}
          />
        )}
        {!loadingGridPricing && !loadingPics && showAgreementForm && (
          <AgreementForm
            currentBooking={currentBooking}
            agreement={currentItem}
            agreementSequence={agreementSequence}
            gridPricing={gridPricing}
            pics={pics}
            onConfirm={handleComplete}
            onCancel={() => setShowAgreementForm(false)}
          />
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default Complete;
