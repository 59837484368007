import Grid from "@mui/material/Unstable_Grid2";
import Charts from "./Charts";
import { DateFormat, DateFormatServer, DateRange, FormInputCategory, FormInputItem, LooseObject, QueryResult, QueryType } from "../../../utils/Types";
import { ContentWrapper, Dialog, NoDataView, SmallButton, Spinner } from "../../../components";
import { useContext, useEffect, useRef, useState } from "react";
import { getCurrentOrg, getDataFromDataPool, getDateRangeOptions, isNotEmpty, optimiseQueryResult, postToServer, prepareQueryResultForTable } from "../../../utils/Helper";
import moment from "moment";
import { SnackbarContext } from "../../../utils/Contexts";
import DateRangeSelect, { DATE_RANGE_OPTION_KEYS, DateRangeOptionKey } from "../../../components/DateRangeSelect";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import _ from "lodash";
import Tiles from "./Tiles";
import { ALL, ALL_LOTS, ALL_PICS } from "../../../utils/Constants";
import { checkIsLotIncludedinPic, getLotAndDateOptions, getPicsWithBusinessNames, getUniqSortedAndGroupedPics } from "../Producer/Dashboard";
import { createFilterOptions } from "@mui/material/Autocomplete";
import RawDataTable from "./RawDataTable";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

export const EXCLUDE_COLUMNS: string[] = ["BODY_NUMBER"];

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [loadingPics, setLoadingPics] = useState(false);

  const [data, setData] = useState<QueryResult>();
  const [dateSelected, setDateSelected] = useState<DateRangeOptionKey>("1-m");
  const [dateRange, setDateRange] = useState<DateRange>();
  const [allPics, setAllPics] = useState<LooseObject[]>([]);
  const [pic, setPic] = useState<string>(ALL_PICS);
  const [lot, setLot] = useState<string>(ALL_LOTS);
  const [openDialogForRawData, setOpenDialogForRawData] = useState<boolean>(false);

  const [inputs, setInputs] = useState<FormInputItem[]>([]);

  const formRef = useRef<{ resetForm: () => void }>(null);

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // set default date range
    const dateRangeOptions = getDateRangeOptions(DATE_RANGE_OPTION_KEYS);
    const datePoint = dateRangeOptions.find(i => i.key === dateSelected)?.value;

    if (datePoint) {
      const newDateRange = { from: moment().add(-datePoint.n, datePoint.u), to: moment() };
      setDateRange(newDateRange);
    }
  }, []);

  useEffect(() => {
    // load all pic
    fetchAllPics();

    return () => fetchAllPics.cancel();
  }, [dateRange]);

  const fetchAllPics = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoadingPics(true);
      await postToServer({
        action: "gmp/LoadPics",
        params: {},
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(async response => {
        if (response.message.type === "success" && response.serverData) {
          const picsWithBusinessNames = response.serverData as LooseObject[];
          await getDataFromDataPool({
            dataPool,
            params: {
              id: getCurrentOrg(orgs)?.idString,
              type: QueryType.ORDINARY_QUERY,
              view: `GMP_LIST_PICS('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}')`,
              isFunction: true,
            },
            token: user.access_token,
            dispatch,
            zitadelOrg,
            snackbar,
          }).then(dataFromDataPool => {
            const picsFromDataPool = optimiseQueryResult(dataFromDataPool);
            if (picsFromDataPool?.rows) {
              const picsWithBusinessName = getPicsWithBusinessNames(picsFromDataPool?.rows, picsWithBusinessNames);

              setAllPics(picsWithBusinessName);
            }
          });
        } else {
          snackbar.open(response.message);
        }
      });

      setLoadingPics(false);
    }
  }, 500);

  const isLotIncludedInPic = checkIsLotIncludedinPic(allPics, pic, lot);

  useEffect(() => {
    if (isNotEmpty(pic) && isLotIncludedInPic) {
      fetchData();
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [pic, lot, dateRange]);

  const fetchData = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `GMP_GET_INDIVIDUAL_CARCASES('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}', '${
            pic === ALL_PICS ? ALL : pic
          }', '${lot === ALL_LOTS ? ALL : lot}')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(
          optimiseQueryResult({
            ...dataFromDataPool,
            columns: dataFromDataPool?.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)),
          })
        );
      });
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (pic) {
      // reset lot
      setLot(ALL_LOTS);
    }
  }, [pic]);

  useEffect(() => {
    if (pic) {
      handleReset();

      const picOptions = [{ label: ALL_PICS, value: ALL_PICS, businessName: "" }].concat(getUniqSortedAndGroupedPics(allPics));
      const lotAndDateOptions = [{ label: ALL_LOTS, value: ALL_LOTS }].concat(getLotAndDateOptions(allPics, pic));

      setInputs([
        {
          name: "pic",
          category: FormInputCategory.AUTO_COMPLETE,
          options: picOptions,
          defaultValue: picOptions.find(i => i.value === pic),
          gridLayout: { xs: 12, md: 4, lg: 3 },
          exposeValue: v => setPic(v?.value),
          loading: loadingPics,
          groupBy: (option: any) => option.businessName,
          getOptionLabel: (option: any) => option.label,
          showDefaultValueWhenEmpty: true,
          autoCompleteShowSearchIcon: true,
          autoCompleteFilterOptions: createFilterOptions({
            matchFrom: "any",
            stringify: (option: LooseObject) => option.businessName + option.label,
          }),
        },
        {
          name: "lotAndDate",
          category: FormInputCategory.AUTO_COMPLETE,
          options: lotAndDateOptions,
          defaultValue: lotAndDateOptions[0],
          gridLayout: { xs: 12, md: 5, lg: 4, xl: 3 },
          exposeValue: v => setLot(v?.value),
          loading: loadingPics,
          groupBy: (option: any) => option.dateFormated,
          getOptionLabel: (option: any) =>
            `${option.dateFormated === undefined ? "" : option.dateFormated + " - "}${option.label}${option.numberOfLambs === undefined ? "" : ` (${option.numberOfLambs} Head)`}`,
          showDefaultValueWhenEmpty: true,
        },
      ]);
    }
  }, [allPics, pic]);

  const picWithFullInfo =
    pic === ALL_PICS
      ? {
          PIC: ALL_PICS,
          LOT_REFERENCE: ALL_LOTS,
          NUM_GL_LAMBS: data?.numRows,
          KILL_DATE: `${dateRange?.from.format(DateFormat.SHORT)} - ${dateRange?.to.format(DateFormat.SHORT)}`,
          businessName: "",
        }
      : lot === ALL_LOTS
      ? {
          PIC: pic,
          LOT_REFERENCE: ALL_LOTS,
          NUM_GL_LAMBS: data?.numRows,
          KILL_DATE: `${dateRange?.from.format(DateFormat.SHORT)} - ${dateRange?.to.format(DateFormat.SHORT)}`,
          businessName: allPics.find(i => i.PIC === pic)?.businessName,
        }
      : allPics.find(i => i.PIC.trim() === pic && i.LOT_REFERENCE === lot);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <ContentWrapper>
          <Grid container spacing={2}>
            <Grid xs={12} md={10} xl={11}>
              <DateRangeSelect ref={formRef} existingInputs={inputs} defaultValue={dateSelected} onDateSelectedChange={setDateSelected} onDateRangeChange={setDateRange} />
            </Grid>
            <Grid xs={12} md={2} xl={1} display="flex" justifyContent="flex-end">
              <SmallButton title="Show Raw Data" sx={{ width: 125 }} onClick={() => setOpenDialogForRawData(true)} />
            </Grid>
          </Grid>
        </ContentWrapper>
      </Grid>
      <Grid>
        <Tiles pic={pic} lot={lot} dateRange={dateRange} isLotIncludedInPic={!!isLotIncludedInPic} />
      </Grid>
      <Grid>{loading || !data ? <Spinner /> : !data.numRows ? <NoDataView /> : picWithFullInfo && <Charts data={data} picWithFullInfo={picWithFullInfo} loading={loading} />}</Grid>
      <Dialog open={openDialogForRawData} onClose={() => setOpenDialogForRawData(false)} isTransparent>
        <RawDataTable
          loading={loading}
          data={prepareQueryResultForTable({
            data: { ...data, rows: data?.rows?.filter(i => (pic === ALL_PICS ? true : i.PIC === pic)).filter(i => (lot === ALL_LOTS ? true : i.LOT_REFERENCE === lot)) },
            org: getCurrentOrg(orgs),
          })}
          onCancel={() => setOpenDialogForRawData(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default Page;
