import { MouseEventHandler } from "react";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const HelperTextWithLink = ({ text, to, linkTitle, onClickLink }: { text: string; to: string; linkTitle?: string; onClickLink?: MouseEventHandler<HTMLAnchorElement> }) => (
  <Stack direction="row" spacing={1}>
    <Typography color="mytext.tertiary">{text}</Typography>
    <Link to={to} onClick={onClickLink}>
      <Typography color="mytext.tertiary" sx={{ textDecoration: "underline", cursor: "pointer" }}>
        {linkTitle || "Click here"}
      </Typography>
    </Link>
  </Stack>
);

export default HelperTextWithLink;
