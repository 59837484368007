import Grid from "@mui/material/Unstable_Grid2";
import { DateRange, QueryResult } from "../../../../utils/Types";
import HistogramByBrand from "./HistogramByBrand";
import BoxPlotByBrandAndMonth from "./BoxPlotByBrandAndMonth";
import ScatterPlot from "./ScatterPlot";
import ScatterPlotMobBased from "./ScatterPlotMobBased";

const Charts = ({ loading, data, location, dateRange }: { loading: boolean; data: QueryResult; location: string; dateRange: DateRange }) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <HistogramByBrand data={data} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <BoxPlotByBrandAndMonth data={data} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlot data={data} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlotMobBased location={location} dateRange={dateRange} />
      </Grid>
    </Grid>
  );
};

export default Charts;
