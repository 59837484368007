import { FormControl, FormControlLabel, Checkbox as MuiCheckbox, Stack } from "@mui/material";
import { FormInputItem, LooseObject } from "../../utils/Types";
import _ from "lodash";
import { HelperText } from "./TextField";

type Props = {
  label?: string;
  name?: string;
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  touched: LooseObject;
  errors: LooseObject;
  inputProps?: {};
};

const Checkbox = ({ label, name, item, textFieldProps, values, setFieldValue, touched, errors }: Props) => {
  return (
    <Stack alignItems="flex-start" width="100%">
      <FormControl error={errors[item.name] && touched[item.name]} size={item.size} sx={textFieldProps.sx} fullWidth>
        <FormControlLabel
          control={
            <MuiCheckbox
              {..._.omit(textFieldProps, ["fullWidth"])}
              name={name}
              checked={values[name || item.name] === undefined ? item.defaultValue : values[name || item.name]}
              onChange={(e, v) => {
                setFieldValue(item.name, v);
                item.exposeValue?.(v);
              }}
              required={item.required}
              size={item.size}
              disabled={item.disabled}
            />
          }
          label={label || item.label}
        />
        <HelperText item={item} touched={touched} errors={errors} sx={{ mx: 3.8, mt: -1 }} />
      </FormControl>
    </Stack>
  );
};

export default Checkbox;
