import Grid from "@mui/material/Unstable_Grid2";
import { LooseObject, QueryResult } from "../../../../../utils/Types";
import HistogramTrend from "./HistogramTrend";
import ScatterPlot from "./ScatterPlot";
import ScatterPlotGLQ5 from "./ScatterPlotGLQ5";
import HistogramHealth from "./HistogramHealth";

const Charts = ({
  loading,
  data,
  pic,
  lot,
  date,
  picWithFullInfo,
  averages,
  averagesGlobal,
  percentiles,
}: {
  loading: boolean;
  data: QueryResult;
  pic: string;
  lot: string;
  date: string;
  picWithFullInfo: LooseObject;
  averages: LooseObject;
  averagesGlobal: LooseObject;
  percentiles: QueryResult | undefined;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlot data={data} loading={loading} averages={averages} averagesGlobal={averagesGlobal} picWithFullInfo={picWithFullInfo} percentiles={percentiles} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlotGLQ5 data={data} picWithFullInfo={picWithFullInfo} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <HistogramTrend data={data} loading={loading} averages={averages} picWithFullInfo={picWithFullInfo} percentiles={percentiles} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <HistogramHealth data={data} loading={loading} picWithFullInfo={picWithFullInfo} />
      </Grid>
    </Grid>
  );
};

export default Charts;
