enum PageId {
  MAIN_ENTRY = "MAIN_ENTRY",
  NO_PKGS = "NO_PKGS",
  NOT_FOUND = "NOT_FOUND",
  LOADING = "LOADING",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  AUTH_CALLBACK = "AUTH_CALLBACK",
  ADMINISTRATION = "ADMINISTRATION",
  MEQ_CAMERA = "MEQ_CAMERA",
  GMP_LAMB_DATA_ANALYSIS = "GMP_LAMB_DATA_ANALYSIS",
  GMP_LAMB_BOOKINGS = "GMP_LAMB_BOOKINGS",
  ALLIANCE_DATA_ANALYSIS = "ALLIANCE_DATA_ANALYSIS",
  MEQ_PROBE_LAMB = "MEQ_PROBE_LAMB",
  MANAGEMENT = "MANAGEMENT",
}

export default PageId;
