import { Stack } from "@mui/material";
import { DialogWrapper, Spinner, Table } from "../../../components";
import { QueryResultTable } from "../../../utils/Types";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { generateCsv, download, ColumnHeader } from "export-to-csv";
import { getCsvConfig } from "../../../utils/Helper";

const RawDataTable = ({ loading, data, onCancel }: { loading: boolean; data: QueryResultTable | undefined; onCancel: (values: any) => void }) => {
  const optimisedData: QueryResultTable | undefined = data
    ? {
        ...data,
        tableRows: data?.tableRows.map(i => ({
          ...i,
          GLQ: i.GLQ?.toFixed(2),
          HSCW_KG: i.HSCW_KG?.toFixed(1),
          IMF_PERCENTAGE_OF: i.IMF_PERCENTAGE_OF?.toFixed(1),
          LMY_PERCENTAGE_OF: i.LMY_PERCENTAGE_OF?.toFixed(1),
        })),
      }
    : undefined;

  const handleExportData = () => {
    const csvConfig = getCsvConfig(
      "carcases",
      optimisedData?.tableColumns.map(i => ({ key: i.accessorKey, displayLabel: i.header } as ColumnHeader))
    );
    const csv = generateCsv(csvConfig)(
      optimisedData?.tableRows.map(i => {
        const optimisedData = { ...i };
        optimisedData.DISEASE_DESCRIPTIONS = i.DISEASE_DESCRIPTIONS?.join(", ");
        delete optimisedData.KILL_DATE;
        return optimisedData;
      }) || []
    );
    download(csvConfig)(csv);
  };

  return (
    <DialogWrapper onCancel={onCancel}>
      <Stack width={{ xs: "100%", md: "90vw", lg: "80vw" }}>
        {!optimisedData?.tableColumns ? (
          <Spinner />
        ) : (
          <Table
            loading={loading}
            columns={
              optimisedData?.tableColumns.map(i =>
                i.accessorKey === "DISEASE_DESCRIPTIONS" ? ({ ...i, accessorFn: row => row.DISEASE_DESCRIPTIONS.join(", ") } as MRT_ColumnDef<MRT_RowData, any>) : { ...i }
              ) || []
            }
            data={optimisedData?.tableRows || []}
            enableExpandAll={false}
            initialState={{ pagination: { pageSize: 20, pageIndex: 0 }, sorting: [{ id: "KILL_DATE_FORMATTED", desc: true }] }}
            handleExportDataToCsv={handleExportData}
          />
        )}
      </Stack>
    </DialogWrapper>
  );
};

export default RawDataTable;
