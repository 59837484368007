import { Box, Stack, Tooltip } from "@mui/material";
import SvgIcon from "../SvgIcon";
import RoundIconButton from "../Button/RoundIconButton";
import { NavItem } from "../../utils/Types";
import { optimisePageTitle } from "../../utils/Helper";

const SidebarItem = ({
  icon,
  displayName,
  isActive,
  permittedPages,
  sectionName,
  navigate,
}: {
  icon: string;
  displayName: string;
  isActive: boolean;
  navigate: any;
  permittedPages: NavItem[];
  sectionName: string;
}) => (
  <Stack alignItems="center">
    <Tooltip title={optimisePageTitle(displayName)} arrow placement="right">
      <Box>
        <RoundIconButton
          sx={{ width: 48, height: 48, border: 0, bgcolor: isActive ? "mybg.active" : undefined }}
          onClick={() => {
            const pagesInThisSection = permittedPages.filter(page => page.category === sectionName);
            if (pagesInThisSection.length > 0) {
              navigate(pagesInThisSection[0].to);
            }
          }}
        >
          <SvgIcon name={icon} />
        </RoundIconButton>
      </Box>
    </Tooltip>
  </Stack>
);

export default SidebarItem;
