import { Card, CardContent, CardHeader, SxProps, Typography } from "@mui/material";
import SvgIcon from "../SvgIcon";
import RoundIconButton from "../Button/RoundIconButton";
import { DEFAULT_BORDER_RADIUS } from "../../utils/Constants";

const Tile = ({
  title,
  content,
  showAction,
  minWidth = 220,
  contentSx,
}: {
  title?: string;
  content: JSX.Element;
  showAction?: boolean;
  minWidth?: number;
  contentSx?: SxProps;
}) => (
  <Card sx={{ minWidth, boxShadow: "none", borderRadius: DEFAULT_BORDER_RADIUS }}>
    {title && (
      <CardHeader
        title={
          <Typography variant="textmd" fontWeight="semiBold">
            {title}
          </Typography>
        }
        action={
          showAction ? (
            <RoundIconButton size="small">
              <SvgIcon name="MoreVert" strokeWidth={1.67} />
            </RoundIconButton>
          ) : undefined
        }
      />
    )}
    <CardContent sx={contentSx || { py: 0 }}>{content}</CardContent>
  </Card>
);

export default Tile;
