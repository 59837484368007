import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { DateFormatServer, PkgName, QueryResultTable, QueryType } from "../../../utils/Types";
import { selectUser } from "../../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import _ from "lodash";
import { getCameraOwnerStringFromOrgIdString, getCurrentOrg, getDataFromDataPool, prepareQueryResultForTable } from "../../../utils/Helper";
import { Spinner, Table } from "../../../components";
import { Stack } from "@mui/material";
import ListOfImages from "./ListOfImages";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import moment from "moment";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResultTable>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `MEQCAMERA_DAILY_SUMMARY_METRIC('2020-01-01', '${moment().format(DateFormatServer.SHORT)}', '${getCameraOwnerStringFromOrgIdString(getCurrentOrg(orgs)?.idString)}')`, // start date, end date, plant code
        isFunction: true,
        pkg: PkgName.MEQ_CAMERA,
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      setData(prepareQueryResultForTable({ data: dataFromDataPool, org: getCurrentOrg(orgs) }));
    });
    setLoading(false);
  }, 500);

  return (
    <Stack spacing={3}>
      {!data?.tableColumns ? (
        <Spinner />
      ) : (
        <Table
          loading={loading}
          title="Summary of images by date"
          columns={data?.tableColumns || []}
          data={data?.tableRows || []}
          enableExpandAll={false}
          renderDetailPanel={({ row }) => <ListOfImages row={row} />}
          initialState={{ pagination: { pageSize: 20, pageIndex: 0 }, sorting: [{ id: "PHOTO_DATE_FORMATTED", desc: true }] }}
        />
      )}
    </Stack>
  );
};

export default Page;
