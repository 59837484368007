import { Stack, Button as MuiButton, Divider } from "@mui/material";
import _ from "lodash";
import { Org } from "../../../utils/Types";
import Logo from "../../../components/Nav/Logo";
import LoginPageLayout from "./LoginPageLayout";
import { User } from "oidc-client-ts";
// import { ArrowBack } from "@mui/icons-material";
// import { Button } from "../../../components";

type Props = {
  user?: User;
  orgs: Org[];
  orgsV1: Org[];
  onSelectOrg: (orgIdString?: string) => void;
  closeDialog: () => void;
};

const SelectOrg = ({ user, orgs, orgsV1, onSelectOrg, closeDialog }: Props) => {
  const redirectToV1 = (id?: string) => {
    if (id && user?.access_token && process.env.REACT_APP_INSIGHTS_V1) {
      window.location.href = `${process.env.REACT_APP_INSIGHTS_V1}/login-v2?orgId=${id}&accessToken=${user.access_token}`;
    }
  };

  return (
    <Stack height="100%" alignItems="center">
      <Stack flex={1} display={{ xs: "none", sm: "flex" }} />
      <Stack flex={5} sx={{ p: 3, width: "100%" }}>
        <LoginPageLayout
          icon={<Logo size={48} />}
          title="Thank you"
          subtitle="Please select an organisation to continue."
          content={
            <Stack spacing={2} alignItems="center" width="100%" maxHeight="80vh" overflow="scroll">
              {_.orderBy(orgs, "displayName").map((o: Org, index: number) => (
                <MuiButton
                  key={o?.idString}
                  variant="outlined"
                  fullWidth
                  size="large"
                  sx={{
                    borderColor: "mybuttonborder.secondary",
                    color: "mybuttonfg.secondary",
                    maxWidth: 400,
                  }}
                  onClick={() => onSelectOrg(o?.idString)}
                >
                  {o?.displayName}
                </MuiButton>
              ))}
              {orgs.length > 0 && orgsV1.length > 0 && (
                <Stack width="100%" maxWidth={400} pt={3} pb={1}>
                  <Divider>Orgnisations from V1</Divider>
                </Stack>
              )}
              {_.orderBy(orgsV1, "displayName").map((o: Org, index: number) => (
                <MuiButton
                  key={o?.idString}
                  variant="outlined"
                  fullWidth
                  size="large"
                  sx={{
                    borderColor: "mybuttonborder.secondary",
                    color: "mybuttonfg.secondary",
                    maxWidth: 400,
                  }}
                  onClick={() => redirectToV1(o?.idString)}
                >
                  {o?.displayName}
                </MuiButton>
              ))}
            </Stack>
          }
          // relatedAction={<Button title="Back to login" variant="text" startIcon={<ArrowBack fontSize="small" />} onClick={() => closeDialog()} />}
        />
      </Stack>
    </Stack>
  );
};

export default SelectOrg;
