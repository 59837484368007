import Grid from "@mui/material/Unstable_Grid2";
import Charts from "./Charts";
import { DateFormat, DateFormatServer, FormInputCategory, FormInputItem, LooseObject, QueryResult, QueryType } from "../../../../utils/Types";
import { ContentWrapper, Dialog, Form, NoDataView, SmallButton, Spinner } from "../../../../components";
import { useContext, useEffect, useRef, useState } from "react";
import {
  getCurrentOrg,
  getDataFromDataPool,
  isNotEmpty,
  optimiseQueryResult,
  postToServer,
  prepareQueryResultForTable,
  getOrgFromRole,
  getCurrentLocation,
} from "../../../../utils/Helper";
import moment from "moment";
import { SnackbarContext } from "../../../../utils/Contexts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../../redux/reducers/dataPoolSlice";
import _ from "lodash";
import { ALL_LOCATIONS, ALLIANCE_ALL_LOCATIONS, SUPER_ADMIN, SUPER_USER } from "../../../../utils/Constants";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { selectZitadelOrg } from "../../../../redux/reducers/zitadelOrgSlice";
import { selectRoles } from "../../../../redux/reducers/rolesSlice";
import LocationCoordinates from "../../../../utils/LocationCoordinates";
import Tiles from "./Tiles";
import RawDataTable from "../../../AllianceDataAnalysis/Dashboard/RawDataTable";
import ImageAnalysis from "./ImageAnalysis";
import GridReport from "./GridReport";

export const getUniqSortedAndGroupedSuppliers = (suppliers: LooseObject[]) =>
  _.orderBy(
    _.uniqBy(suppliers, "SUPPLIER_NO")
      .filter(i => i.fullName)
      .map(i => ({ label: i.SUPPLIER_NO?.toString(), value: i.SUPPLIER_NO, fullName: i.fullName })) || [],
    "fullName"
  ).concat(
    _.uniqBy(suppliers, "SUPPLIER_NO")
      .filter(i => !i.fullName)
      .map(i => ({ label: i.SUPPLIER_NO?.toString(), value: i.SUPPLIER_NO, fullName: "Other" })) || []
  );

export const getMobAndDateOptions = (data: LooseObject[] | undefined) => {
  const uniqMobAndDate = _.uniqBy(
    _.map(
      data?.filter(i => isNotEmpty(i.MOB) && isNotEmpty(i.KILL_DATE)),
      ({ MOB, KILL_DATE }) => ({ MOB, KILL_DATE })
    ),
    item => `${item.MOB}-${item.KILL_DATE}`
  );
  const mobAndDateOptions = _.orderBy(uniqMobAndDate || [], "KILL_DATE", "desc").map(i => ({
    label: i.MOB?.toString(),
    value: i.MOB,
    date: i.KILL_DATE,
    dateFormated: moment(i.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT),
    head: data?.filter(o => o.MOB === i.MOB && o.KILL_DATE === i.KILL_DATE).length || 0,
  }));

  return mobAndDateOptions;
};

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(true);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [loadingAverages, setLoadingAverages] = useState(true);
  const [loadingGridReport, setLoadingGridReport] = useState(true);

  const [data, setData] = useState<QueryResult>();
  const [allSuppliers, setAllSuppliers] = useState<LooseObject[]>([]);
  const [averages, setAverages] = useState<QueryResult>();
  const [gridReport, setGridReport] = useState<QueryResult>();

  const [supplier, setSupplier] = useState<string>("");
  const [mob, setMob] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [inputs, setInputs] = useState<FormInputItem[]>([]);
  const [openDialogForRawData, setOpenDialogForRawData] = useState<boolean>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const formRef = useRef<{ resetForm: () => void }>(null);

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  const currentLocation = getCurrentLocation(orgs);
  const allLocations: string[] | undefined =
    LocationCoordinates.find(i => i.client === getCurrentOrg(orgs)?.idString)
      ?.["locations"]?.filter(i => ["Levin", "Mataura"].includes(i.name))
      .map(i => i.name) || [];

  const roles = useAppSelector(selectRoles);
  const currentRole = roles.find(i => i?.isCurrent);
  const isSuperUsers = currentRole && [SUPER_ADMIN, SUPER_USER].includes(currentRole.role);
  const isAllLocationUser = getOrgFromRole(currentRole?.role || "") === ALLIANCE_ALL_LOCATIONS;
  const isAllowedToSeeAllLocations = isSuperUsers || isAllLocationUser;

  const [location, setLocation] = useState(isAllowedToSeeAllLocations ? "Levin" : currentLocation);

  useEffect(() => {
    // load all supplier
    fetchAllSuppliers();

    return () => fetchAllSuppliers.cancel();
  }, [location]);

  const fetchAllSuppliers = _.debounce(async () => {
    setLoadingSuppliers(true);
    await postToServer({
      action: "alliance/GetSuppliersForUser",
      params: { location, isBeef: true },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.message.type === "success" && response.serverData) {
        const suppliers = response.serverData as LooseObject[];

        setAllSuppliers(suppliers);
        if (suppliers.length > 0) {
          setSupplier(suppliers[0].SUPPLIER_NO);
        }
      } else {
        snackbar.open(response.message);
      }
    });
    setLoadingSuppliers(false);
  }, 500);

  useEffect(() => {
    if (isNotEmpty(location) && isNotEmpty(supplier)) {
      fetchData();
    } else {
      setData({}); // to prevent loading all the time
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [location, supplier]);

  const fetchData = _.debounce(async () => {
    if (location && supplier) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `ALLIANCE_BEEF_GET_INDIVIDUAL_DATA_FOR_FARMER('2020-01-01', '${moment().format(DateFormatServer.SHORT)}', '${location}', ${supplier}, 'All')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(optimiseQueryResult(dataFromDataPool));
      });

      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    if (isNotEmpty(location) && isNotEmpty(supplier) && isNotEmpty(mob) && isNotEmpty(date)) {
      fetchAverages();
    } else {
      setData({}); // to prevent loading all the time
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchAverages.cancel();
  }, [mob, date]);

  const fetchAverages = _.debounce(async () => {
    if (location && supplier && mob && date) {
      setLoadingAverages(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `ALLIANCE_BEEF_GET_AGG_ON_SUPPLIER_MOB('${date}', '${date}', '${location}', ${supplier}, '${mob}')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setAverages(optimiseQueryResult(dataFromDataPool));
      });
      setLoadingAverages(false);

      setLoadingGridReport(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `ALLIANCE_BEEF_GET_GRID_REPORT('${date}', '${location}', ${supplier}, ${mob})`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setGridReport(optimiseQueryResult(dataFromDataPool));
      });
      setLoadingGridReport(false);
    }
  }, 500);

  useEffect(() => {
    if (supplier) {
      const supplierOptions = getUniqSortedAndGroupedSuppliers(allSuppliers);
      const mobAndDateOptions = getMobAndDateOptions(data?.rows);

      if (mobAndDateOptions.length > 0) {
        handleReset();

        setMob(mobAndDateOptions[0].value);
        setDate(mobAndDateOptions[0].date);

        let updatedInputs: FormInputItem[] =
          isAllowedToSeeAllLocations && currentLocation === ALL_LOCATIONS
            ? [
                {
                  name: "location",
                  category: FormInputCategory.SELECT,
                  options: allLocations ? allLocations.map(i => ({ label: i, value: i })) : [],
                  defaultValue: location,
                  gridLayout: { xs: 12, md: 3, lg: 2 },
                  exposeValue: setLocation,
                },
              ]
            : [];

        updatedInputs = updatedInputs.concat([
          {
            name: "supplier",
            category: FormInputCategory.AUTO_COMPLETE,
            options: supplierOptions,
            defaultValue: supplierOptions.find(i => i.value === supplier),
            gridLayout: { xs: 12, md: 3, lg: 2 },
            exposeValue: v => setSupplier(v?.value),
            loading: loadingSuppliers,
            groupBy: (option: any) => option.fullName,
            getOptionLabel: (option: any) => option.label,
            showDefaultValueWhenEmpty: true,
            autoCompleteShowSearchIcon: true,
            autoCompleteFilterOptions: createFilterOptions({
              matchFrom: "any",
              stringify: (option: LooseObject) => option.fullName + option.label,
            }),
          },
          {
            name: "mobAndDate",
            category: FormInputCategory.AUTO_COMPLETE,
            options: mobAndDateOptions,
            defaultValue: mobAndDateOptions[0],
            gridLayout: { xs: 12, md: 6, lg: 4, xl: 3 },
            exposeValue: v => {
              setMob(v?.value);
              setDate(v?.date);
            },
            loading: loadingSuppliers || loading,
            groupBy: (option: any) => option.dateFormated,
            getOptionLabel: (option: any) => `${option.dateFormated} - Mob# ${option.label} - (${option.head} Head)`,
            showDefaultValueWhenEmpty: true,
          },
        ]);

        setInputs(updatedInputs);
      }
    }
  }, [loadingSuppliers, loading, data]);

  const avgs: LooseObject = averages?.rows && (averages?.numRows || 0) > 0 && (averages?.columns?.length || 0) > 2 ? averages?.rows?.[0] : {};

  const supplierWithFullInfo: LooseObject = {
    ...allSuppliers.find(i => i.SUPPLIER_NO === supplier),
    MOB: mob,
    KILL_DATE: date,
    head: data?.rows?.filter(o => o.MOB === mob && o.KILL_DATE === date).length || 0,
  };

  const rankedImages: LooseObject[] = [];
  const sortedImages = _.orderBy(
    data?.rows?.filter(i => i.MOB === mob && i.KILL_DATE === date).filter(i => isNotEmpty(i.MARBLING)),
    "MARBLING",
    "desc"
  );

  if (sortedImages.length > 0) {
    rankedImages.push(sortedImages[0]);
  }
  if (sortedImages.length > 2) {
    rankedImages.push(sortedImages[Math.floor(sortedImages.length / 2)]);
  }
  if (sortedImages.length > 1) {
    rankedImages.push(sortedImages[sortedImages.length - 1]);
  }

  return (
    <Grid container direction="column" spacing={2}>
      {inputs.length > 0 && (
        <Grid>
          <ContentWrapper>
            <Grid container spacing={2}>
              <Grid xs={12} md={10} xl={11}>
                <Form isHorizontal={!isSmallScreen} ref={formRef} inputs={inputs} />
              </Grid>
              <Grid xs={12} md={2} xl={1} display="flex" justifyContent="flex-end">
                <SmallButton title="Show Raw Data" sx={{ width: 125 }} onClick={() => setOpenDialogForRawData(true)} />
              </Grid>
            </Grid>
          </ContentWrapper>
        </Grid>
      )}
      {!loadingAverages && averages?.numRows ? (
        <Grid>
          <Tiles data={avgs} />
        </Grid>
      ) : undefined}
      <Grid>
        {loading || !data ? (
          <Spinner />
        ) : !data.numRows ? (
          <NoDataView />
        ) : (
          date &&
          supplierWithFullInfo && (
            <>
              <ImageAnalysis
                data={prepareQueryResultForTable({ data: { ...data, rows: data?.rows?.filter(i => i.MOB === mob && i.KILL_DATE === date) }, org: getCurrentOrg(orgs) })}
                rankedImages={rankedImages}
                supplierWithFullInfo={supplierWithFullInfo}
              />{" "}
              <Stack width="100%" height={16} />
              <Charts data={data} supplierWithFullInfo={supplierWithFullInfo} loading={loading} />
            </>
          )
        )}
      </Grid>
      {loading ? undefined : (
        <Grid>
          <Stack>
            <GridReport data={gridReport} loading={loadingGridReport} supplierWithFullInfo={supplierWithFullInfo} />
          </Stack>
        </Grid>
      )}
      <Dialog open={openDialogForRawData} onClose={() => setOpenDialogForRawData(false)} isTransparent>
        <RawDataTable
          loading={loading}
          data={prepareQueryResultForTable({ data: { ...data, rows: data?.rows?.filter(i => i.MOB === mob && i.KILL_DATE === date) }, org: getCurrentOrg(orgs) })}
          onCancel={() => setOpenDialogForRawData(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default Page;
