import { useContext, useEffect, useState } from "react";
import { Chip, ContentWrapper, Dialog, Hcharts } from "../../../../components";
import { ChartType, DateFormatServer, DateRange, QueryResult, QueryResultColumn, QueryType, SelectedChartData } from "../../../../utils/Types";
import _ from "lodash";
import { SnackbarContext } from "../../../../utils/Contexts";
import moment from "moment";
import { getCurrentOrg, getDataFromDataPool, getHchartPointFormatExtra, optimiseQueryResult, isNotEmpty } from "../../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectUser } from "../../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../../redux/reducers/orgsSlice";
import MobBasedDialog from "./MobBasedDialog";
import { selectDataPool } from "../../../../redux/reducers/dataPoolSlice";
import { selectZitadelOrg } from "../../../../redux/reducers/zitadelOrgSlice";

const Section = ({ location, dateRange }: { location: string; dateRange: DateRange }) => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResult>();

  const EXCLUDE_COLUMNS = ["MOB_NO"];
  const optionsOfXAxis = data?.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");
  const optionsOfYAxis = optionsOfXAxis;
  const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>();
  const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>();

  const [selectedDataForDialog, setSelectedDataForDialog] = useState<QueryResult>();
  const [openForDialog, setOpenForDialog] = useState<boolean>(false);

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setXAxis(optionsOfXAxis && optionsOfXAxis.length > 0 ? optionsOfXAxis[0] : undefined);
    setYAxis(optionsOfYAxis && optionsOfYAxis.length > 1 ? optionsOfYAxis[1] : undefined);
  }, [data]);

  useEffect(() => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      fetchData();
    }

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [location, dateRange]);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `ALLIANCE_LAMB_GET_AGG_MOB_BRAND_DATA('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(DateFormatServer.SHORT)}', '${location}')`,
        isFunction: true,
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      if (dataFromDataPool) {
        const serverData = {
          ...dataFromDataPool,
          rows: dataFromDataPool.rows,
        };
        setData(optimiseQueryResult(serverData));
      }
    });
    setLoading(false);
  }, 500);

  const dataToDisplay = data?.rows;
  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const updateFilter = (v: SelectedChartData) => {
    let selectedRows = data?.rows ? [...data?.rows] : undefined;
    if (v && selectedRows) {
      if (v.xAxis && xAxis?.name) {
        if (isNotEmpty(v.xAxis!.min)) {
          selectedRows = selectedRows?.filter(i => {
            if (i[xAxis.name] >= v.xAxis!.min) {
              return true;
            } else {
              return false;
            }
          });
        }
        if (isNotEmpty(v.xAxis!.max)) {
          selectedRows = selectedRows?.filter(i => {
            if (i[xAxis.name] <= v.xAxis!.max) {
              return true;
            } else {
              return false;
            }
          });
        }
      }
      if (v.yAxis && yAxis?.name) {
        if (isNotEmpty(v.yAxis!.min)) {
          selectedRows = selectedRows?.filter(i => {
            if (i[yAxis.name] >= v.yAxis!.min) {
              return true;
            } else {
              return false;
            }
          });
        }
        if (isNotEmpty(v.yAxis!.max)) {
          selectedRows = selectedRows?.filter(i => {
            if (i[yAxis.name] <= v.yAxis!.max) {
              return true;
            } else {
              return false;
            }
          });
        }
      }
      if (selectedRows && selectedRows.length > 0) {
        const selectedData = { ...data, rows: selectedRows };
        setSelectedDataForDialog(selectedData);
        setOpenForDialog(true);
      }
    }
  };

  return (
    <ContentWrapper height="100%" justifyContent="center" alignItems="center">
      <Hcharts
        loading={loading}
        type={ChartType.SCATTER_PLOT}
        title="xAxisLabel vs yAxisLabel (Total)"
        titleExtra={<Chip label="Mob Based" color="success" size="small" />}
        axisSwitch={{
          optionsOfXAxis,
          optionsOfYAxis,
          exposeXAxis: setXAxis,
          exposeYAxis: setYAxis,
        }}
        data={allBrands.map(o => ({
          data: dataToDisplay?.filter(i => i.BRAND === o) || [],
          name: o,
        }))}
        pointFormatExtra={getHchartPointFormatExtra(data?.columns?.filter(i => ["MOB_NO"].includes(i.name)) || [])}
        updateFilter={updateFilter}
      />
      <Dialog open={openForDialog} onClose={() => setOpenForDialog(false)} isTransparent maxWidth="lg">
        {selectedDataForDialog && (
          <MobBasedDialog data={selectedDataForDialog} legends={allBrands} dateRange={dateRange} selectedAxis={{ xAxis, yAxis }} onCancel={() => setOpenForDialog(false)} />
        )}
      </Dialog>
    </ContentWrapper>
  );
};

export default Section;
