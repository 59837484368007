import { TextField, Typography, useTheme } from "@mui/material";
import { pink } from "../../../utils/Colors";
import { Center, Chip } from "../../../components";
import { useState } from "react";
import { isNotEmpty } from "../../../utils/Helper";
import validator from "validator";

const Cell = ({ cell, isEditMode, handleChange }: { cell: any; isEditMode?: boolean; handleChange?: (v: any) => void }) => {
  const [helperText, setHelperText] = useState<string>();

  const theme = useTheme();

  const lightColorCells = [
    "1_lmyCategory2",
    "2_lmyCategory2",
    "3_lmyCategory2",
    "4_lmyCategory2",
    "0_lmyCategory3",
    "4_lmyCategory3",
    "1_lmyCategory4",
    "2_lmyCategory4",
    "3_lmyCategory4",
    "4_lmyCategory4",
  ];
  const mediumColorCells = ["1_lmyCategory3", "2_lmyCategory3", "3_lmyCategory3"];
  const darkColorCells: string[] = [];

  const bgColor = lightColorCells.includes(cell.id)
    ? theme.palette.utility.error[100]
    : mediumColorCells.includes(cell.id)
    ? theme.palette.utility.error[200]
    : darkColorCells.includes(cell.id)
    ? theme.palette.utility.error[300]
    : theme.palette.utility.error[50];

  return (
    <Center sx={{ height: 58, width: "100%" }} justifyContent="center" bgcolor={bgColor}>
      {isEditMode ? (
        <Center direction="row" spacing={0.5}>
          <Typography fontSize="0.8125rem">$</Typography>
          <TextField
            error={helperText !== undefined}
            size="small"
            type="number"
            sx={theme => ({
              maxWidth: 50,
              height: 26,
              bgcolor: theme.palette.background.default,
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                px: 0.8,
                height: 26,
                "& .MuiInputBase-input": {
                  padding: 0,
                  height: 26,
                  display: "flex",
                  alignItems: "center",
                  fontSize: "0.8125rem",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mode === "light" ? pink[200] : pink[800],
                },
              },
              "& .MuiFormHelperText-root": {
                marginTop: 0,
                marginLeft: 0,
                paddingLeft: 0,
                fontSize: "0.75rem",
                textAlign: "center",
                width: "100%",
              },
            })}
            defaultValue={typeof cell.getValue() === "number" ? cell.getValue().toFixed(2) : cell.getValue()}
            helperText={helperText}
            onChange={v => {
              if (!v.target.value) {
                setHelperText("Required");
                handleChange?.({ rowNumber: cell.row.original.rowNumber, [cell.column.id]: "" });
              } else if (!validator.isNumeric(v.target.value) || Number(v.target.value) <= 0) {
                setHelperText("Invalid");
                handleChange?.({ rowNumber: cell.row.original.rowNumber, [cell.column.id]: "" });
              } else {
                setHelperText(undefined);
                handleChange?.({ rowNumber: cell.row.original.rowNumber, [cell.column.id]: isNotEmpty(v.target.value) ? Number(v.target.value) : v.target.value });
              }
            }}
          />
        </Center>
      ) : (
        <Chip
          label={`$${typeof cell.getValue() === "number" ? cell.getValue().toFixed(2) : cell.getValue()}`}
          sx={{
            color: theme.palette.mode === "light" ? pink[700] : pink[200],
            borderColor: theme.palette.mode === "light" ? pink[200] : pink[800],
            bgcolor: theme.palette.mode === "light" ? pink[50] : pink[950],
          }}
        />
      )}
    </Center>
  );
};

export default Cell;
