import { DEFAULT_NUMBER_OF_BINS } from "../../utils/Constants";

const hsl2Hex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const hexToHSL = (H: string) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = parseInt("0x" + H[1] + H[1]);
    g = parseInt("0x" + H[2] + H[2]);
    b = parseInt("0x" + H[3] + H[3]);
  } else if (H.length === 7) {
    r = parseInt("0x" + H[1] + H[2]);
    g = parseInt("0x" + H[3] + H[4]);
    b = parseInt("0x" + H[5] + H[6]);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // return 'hsl(' + h + ',' + s + '%,' + l + '%)';
  return [h, s, l];
};

export const generateChartTraceColors = ({ quantity, seedColorHex }: { quantity: number; seedColorHex: string }) => {
  const [h, s, l] = hexToHSL(seedColorHex);
  const colors = [seedColorHex];

  if (quantity === 1) {
    return colors;
  }

  const hRange = 360 / (quantity + 2); // Adjust hue range dynamically based on quantity
  const sRange = { min: 40, max: 100 }; // Adjust saturation range
  const lRange = { min: 20, max: 80 }; // Adjust lightness range

  const hStep = Math.trunc(hRange);
  const sStep = Math.trunc((sRange.max - sRange.min) / (quantity - 1)); // Adjust saturation step
  const lStep = Math.trunc((lRange.max - lRange.min) / (quantity - 1)); // Adjust lightness step

  for (let i = 1; i < quantity; i++) {
    let hLocal = h + i * hStep;
    if (hLocal >= 360) {
      hLocal -= 360;
    }
    let sLocal = s + (i - 1) * sStep; // Adjust saturation calculation
    if (sLocal > sRange.max) {
      sLocal = sRange.max;
    } else if (sLocal < sRange.min) {
      sLocal = sRange.min;
    }
    let lLocal = l + (i - 1) * lStep; // Adjust lightness calculation
    if (lLocal > lRange.max) {
      lLocal = lRange.max;
    } else if (lLocal < lRange.min) {
      lLocal = lRange.min;
    }
    colors.push(hsl2Hex(hLocal, sLocal, lLocal));
  }

  return colors;
};

export type Bin = { start?: number; end?: number; size?: number };

export const getBin = ({ xName, yName, xData, yData }: { xName?: string; yName?: string; xData?: number[]; yData?: number[] }): { xbin?: Bin; ybin?: Bin } => {
  const binSizes = [
    { name: "IMF %", bin: { start: 2, end: 7, size: 0.5 } },
    { name: "Hotweight Kg", bin: { start: 15, end: 30, size: 2 } },
    { name: "HSCW Kg", bin: { start: 15, end: 30, size: 2 } },
    { name: "Viascan Yield %", bin: { start: 45, end: 65, size: 2.5 } },
    { name: "Viascan Gr", bin: { start: 0, end: 20, size: 2 } },
    { name: "LMY %", bin: { start: 50, end: 70, size: 1 } },
    { name: "GLQ", bin: { start: 1, end: 10, size: 0.2 } },
  ];

  const findBin = (name: string | undefined): Bin | undefined => {
    return binSizes.find(b => b.name === name)?.bin;
  };

  const calculateDefaultBin = (data: number[] | undefined): Bin => {
    if (!data || data.length === 0) return { start: 0, end: 10, size: 1 };

    const min = Math.min(...data);
    const max = Math.max(...data);
    const buffer = (max - min) * 0.05; // Add 5% buffer to each side

    // Handle the case where all data points are zero
    if (min === 0 && max === 0) {
      return { start: -1, end: 1, size: 0.25 };
    }

    // Handle the case where there is only one data point
    if (data.length === 1) {
      const value = data[0];
      const range = 1; // Define a range around the single value

      return { start: value - range / 2, end: value + range / 2, size: range / 4 };
    }

    const start = Math.floor(min - buffer);
    const end = Math.ceil(max + buffer);
    const size = Math.ceil((end - start) / DEFAULT_NUMBER_OF_BINS);

    return { start, end, size };
  };

  return {
    xbin: findBin(xName) || calculateDefaultBin(xData),
    ybin: findBin(yName) || calculateDefaultBin(yData),
  };
};
