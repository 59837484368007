import { DialogProps, Dialog as MuiDialog, useMediaQuery, useTheme } from "@mui/material";

const Dialog = ({ open, onClose, fullScreen, isTransparent, ...rest }: DialogProps & { isTransparent?: boolean }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiDialog
      open={open}
      PaperProps={{
        sx: {
          bgcolor: isTransparent ? "transparent" : "",
          backgroundImage: "none",
          boxShadow: isTransparent ? "none" : "",
          // padding: 8,
          maxWidth: "100%",
        },
      }}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose?.(event, reason);
      }}
      fullScreen={fullScreen !== undefined ? fullScreen : isSmallScreen ? true : false}
      {...rest}
    />
  );
};

export default Dialog;
