import { TextField, Stack, Autocomplete as MuiAutoComplete, FormControl, InputLabel, CircularProgress, InputAdornment } from "@mui/material";
import { FormInputItem, LooseObject } from "../../utils/Types";
import _ from "lodash";
import { HelperText } from "./TextField";
import React from "react";
import SvgIcon from "../SvgIcon";

type Props = {
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleBlur: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void;
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: LooseObject;
  errors: LooseObject;
  inputProps?: {};
  loading?: boolean;
};

const AutoComplete = ({ item, values, setFieldValue, handleBlur, touched, errors, inputProps, textFieldProps, loading }: Props) => {
  const [inputValue, setInputValue] = React.useState("");

  return (
    <Stack alignItems="flex-start" width="100%">
      <FormControl error={errors[item.name] && touched[item.name]} size={item.size} sx={textFieldProps.sx} fullWidth>
        {item.label && (
          <InputLabel
            shrink
            sx={{
              position: "relative",
              transform: "none",
              marginBottom: "5px",
            }}
          >
            {item.label}
          </InputLabel>
        )}
        <MuiAutoComplete
          {..._.omit(textFieldProps, ["autoComplete"])}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={item.placeholder || "Type to search"}
              InputProps={{
                ...params.InputProps,
                startAdornment: item.autoCompleteShowSearchIcon ? (
                  <InputAdornment position="start">
                    <SvgIcon name="SearchSm" strokeWidth={1.6} />
                  </InputAdornment>
                ) : (
                  params.InputProps.startAdornment
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                ...params.inputProps,
                ...item.inputProps,
              }}
            />
          )}
          disableClearable
          value={values[item.name] || (item.showDefaultValueWhenEmpty ? item.defaultValue : item.multiple ? [] : "")}
          onChange={(e, v) => {
            setFieldValue(item.name, v);
            item.exposeValue?.(v);
          }}
          inputValue={inputValue}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
            item.autoCompleteExposeInputValue?.(newInputValue);
            if (item.autoCompleteShowInputValue) {
              setFieldValue(item.name, { label: newInputValue, value: newInputValue || "" });
              item.exposeValue?.({ label: newInputValue, value: newInputValue || "" });
            }
          }}
          options={item.options as readonly any[]}
          renderOption={item.autoCompleteRenderOption}
          groupBy={item.groupBy}
          getOptionLabel={item.getOptionLabel}
          filterOptions={item.autoCompleteFilterOptions}
          freeSolo
          forcePopupIcon
          loading={loading}
          limitTags={2}
          multiple={item.multiple}
          disableCloseOnSelect={item.multiple}
          size={item.size}
        />
        <HelperText item={item} touched={touched} errors={errors} />
      </FormControl>
    </Stack>
  );
};

export default AutoComplete;
