import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Dashboard from "./Dashboard";
// import SummaryOfData from "./SummaryOfData";

const Page = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Stack spacing={3}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Dashboard" />
        {/* <Tab label="Summary of data" /> */}
      </Tabs>
      {/* {tab === 0 ? <Dashboard /> : <SummaryOfData />} */}
      <Dashboard />
    </Stack>
  );
};

export default Page;
