import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Role } from "../../utils/Types";

export interface RolesState {
  value: Role[];
  status: "idle" | "loading" | "failed";
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem("roles");
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + " in localStorage roles");
}

const initialState: RolesState = {
  value: initialValue as Role[],
  status: "idle",
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setRoles } = rolesSlice.actions;

export const selectRoles = (state: RootState) => state.roles.value;

export default rolesSlice.reducer;
