import Grid from "@mui/material/Unstable_Grid2";
import { LooseObject, QueryResult } from "../../../../utils/Types";
import HistogramByBrand from "./HistogramByBrand";
import BoxPlotByBrandAndMonth from "./BoxPlotByBrandAndMonth";
import ScatterPlot from "./ScatterPlot";
import ScatterPlotGLQ5 from "./ScatterPlotGLQ5";

const Charts = ({ loading, data, picWithFullInfo }: { loading: boolean; data: QueryResult; picWithFullInfo: LooseObject }) => {
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlot data={data} picWithFullInfo={picWithFullInfo} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <ScatterPlotGLQ5 data={data} picWithFullInfo={picWithFullInfo} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <HistogramByBrand data={data} picWithFullInfo={picWithFullInfo} loading={loading} />
      </Grid>
      <Grid xs={12} md={6} xl={4}>
        <BoxPlotByBrandAndMonth data={data} picWithFullInfo={picWithFullInfo} loading={loading} />
      </Grid>
    </Grid>
  );
};

export default Charts;
