import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ZitadelOrg } from "../../utils/Types";

export interface ZitadelOrgState {
  value: ZitadelOrg;
  status: "idle" | "loading" | "failed";
}

let initialValue = {};
try {
  const initialValueString = localStorage.getItem("zitadelOrg");
  if (initialValueString) {
    initialValue = JSON.parse(initialValueString);
  }
} catch (error: any) {
  console.log(error.message + " in localStorage zitadelOrg");
}

const initialState: ZitadelOrgState = {
  value: initialValue as ZitadelOrg,
  status: "idle",
};

export const zitadelOrgSlice = createSlice({
  name: "zitadelOrg",
  initialState,
  reducers: {
    setZitadelOrg: (state, action) => {
      state.value = action.payload.payload;
    },
  },
});

export const { setZitadelOrg } = zitadelOrgSlice.actions;

export const selectZitadelOrg = (state: RootState) => state.zitadelOrg.value;

export default zitadelOrgSlice.reducer;
