import { Stack, Container } from "@mui/material";
import { ReactNode } from "react";
import { Footer } from "./PageContainer";
import { DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../utils/Constants";

const PublicPageContainner = ({ children }: { children: ReactNode }) => {
  return (
    <Container sx={{ minWidth: 360, height: "100%", pb: { xs: DEFAULT_SPACING_MOBILE / 3, md: DEFAULT_SPACING / 3 } }}>
      <Stack height="100%" alignItems="center">
        <Stack flex={1} display={{ xs: "none", sm: "flex" }} />
        <Stack flex={5} sx={{ p: 3, width: "100%" }}>
          {children}
        </Stack>
        <Footer />
      </Stack>
    </Container>
  );
};

export default PublicPageContainner;
