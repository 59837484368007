import Grid from "@mui/material/Unstable_Grid2";
import Tiles from "./Tiles";
import Charts from "./Charts";
import { DateFormatServer, DateRange, FormInputCategory, QueryResult, QueryType } from "../../../utils/Types";
import { ContentWrapper, NoDataView, Spinner } from "../../../components";
import { useContext, useEffect, useState } from "react";
import { getCurrentLocation, getCurrentOrg, getDataFromDataPool, getDateRangeOptions, optimiseQueryResult } from "../../../utils/Helper";
import moment from "moment";
import { SnackbarContext } from "../../../utils/Contexts";
import DateRangeSelect, { DATE_RANGE_OPTION_KEYS, DateRangeOptionKey } from "../../../components/DateRangeSelect";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import _ from "lodash";
import LocationCoordinates from "../../../utils/LocationCoordinates";
import { ALL, ALL_LOCATIONS } from "../../../utils/Constants";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

export const EXCLUDE_COLUMNS: string[] = [];

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResult>();
  const [dateSelected, setDateSelected] = useState<DateRangeOptionKey>("1-m");
  const [dateRange, setDateRange] = useState<DateRange>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  const currentLocation = getCurrentLocation(orgs);

  const allLocations: string[] | undefined =
    currentLocation === ALL_LOCATIONS
      ? [ALL].concat(LocationCoordinates.find(i => i.client === getCurrentOrg(orgs)?.idString)?.["locations"]?.map(i => i.name) || [])
      : [currentLocation];
  const [location, setLocation] = useState<string>(allLocations ? allLocations[0] : "");

  useEffect(() => {
    // set default date range
    const dateRangeOptions = getDateRangeOptions(DATE_RANGE_OPTION_KEYS);
    const datePoint = dateRangeOptions.find(i => i.key === dateSelected)?.value;

    if (datePoint) {
      const newDateRange = { from: moment().add(-datePoint.n, datePoint.u), to: moment() };
      setDateRange(newDateRange);
    }
  }, []);

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, [location, dateRange]);

  const fetchData = _.debounce(async () => {
    if (dateRange && moment.isMoment(dateRange.from) && moment.isMoment(dateRange.to)) {
      setLoading(true);
      await getDataFromDataPool({
        dataPool,
        params: {
          id: getCurrentOrg(orgs)?.idString,
          type: QueryType.ORDINARY_QUERY,
          view: `ALLIANCE_LAMB_GET_INDIVIDUAL_CARCASE_DATA('${dateRange.from.format(DateFormatServer.SHORT)}', '${dateRange.to.format(
            DateFormatServer.SHORT
          )}', '${location}', 'All', 'All')`,
          isFunction: true,
        },
        token: user.access_token,
        dispatch,
        zitadelOrg,
        snackbar,
      }).then(dataFromDataPool => {
        setData(optimiseQueryResult(dataFromDataPool));
      });
      setLoading(false);
    }
  }, 500);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <ContentWrapper>
          <DateRangeSelect
            existingInputs={
              currentLocation === ALL_LOCATIONS
                ? [
                    {
                      name: "location",
                      category: FormInputCategory.SELECT,
                      options: allLocations ? allLocations.map(i => ({ label: i === ALL ? _.startCase(ALL_LOCATIONS) : i, value: i })) : [],
                      defaultValue: location,
                      gridLayout: { xs: 12, md: 3, lg: 2 },
                      exposeValue: setLocation,
                    },
                  ]
                : []
            }
            defaultValue={dateSelected}
            onDateSelectedChange={setDateSelected}
            onDateRangeChange={setDateRange}
          />
        </ContentWrapper>
      </Grid>
      {!loading && data?.numRows ? (
        <Grid>
          <Tiles data={data} />
        </Grid>
      ) : undefined}
      <Grid>
        {loading || !data ? <Spinner /> : !data.numRows ? <NoDataView /> : dateRange && <Charts data={data} location={location} dateRange={dateRange} loading={loading} />}
      </Grid>
    </Grid>
  );
};

export default Page;
