import { Typography } from "@mui/material";
import Center from "./Center";
import ContentWrapper from "./ContentWrapper";
import SvgIcon from "./SvgIcon";
import Button from "./Button";

const PageContentComingSoon = ({ icon, title, description }: { icon?: string; title?: string; description?: string }) => (
  <ContentWrapper>
    <Center>
      <Center minHeight="50vh" maxWidth={400} spacing={3}>
        <SvgIcon name={icon || "ChartBreakoutSquare"} />
        <Center spacing={2} textAlign="center">
          <Typography variant="textmd" fontWeight="semiBold">
            {title || "Coming Soon"}
          </Typography>
          {description && <Typography color="mytext.tertiary">{description}</Typography>}
        </Center>
        {description && <Button variant="contained" title="Coming Soon" disabled />}
      </Center>
    </Center>
  </ContentWrapper>
);

export default PageContentComingSoon;
