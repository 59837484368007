import {
  Administration,
  Camera,
  GmpLambBookings,
  Loading,
  Login,
  Main,
  NotFound,
  AllianceLambDataAnalysis,
  MeqProbeLamb,
  Management,
  Logout,
  GmpLambDataAnalysis,
  NoPkgs,
} from "../pages";
import { MAIN_ENTRY_PAGE } from "./Constants";
import PageId from "./PageId";
import { NavItem, NavSection, PkgName } from "./Types";

export const NAV_SECTIONS: NavSection[] = [
  { name: PkgName.ADMINISTRATION, icon: "Tool", requiredPkgs: [PkgName.ADMINISTRATION] },
  { name: PkgName.GMP_LAMB_DATA_ANALYSIS, icon: "BarChartSquare", requiredPkgs: [PkgName.GMP_LAMB_DATA_ANALYSIS] },
  { name: PkgName.GMP_LAMB_BOOKINGS, icon: "ClipboardMinus", requiredPkgs: [PkgName.GMP_LAMB_BOOKINGS] },
  { name: PkgName.ALLIANCE_DATA_ANALYSIS, icon: "BarChartSquare", requiredPkgs: [PkgName.ALLIANCE_DATA_ANALYSIS] },
  { name: PkgName.MEQ_PROBE_LAMB, icon: "LocationMark", requiredPkgs: [PkgName.MEQ_PROBE_LAMB] },
  { name: PkgName.MEQ_CAMERA, icon: "Camera", requiredPkgs: [PkgName.MEQ_CAMERA] },
  { name: PkgName.MANAGEMENT, icon: "Users", requiredPkgs: [] },
];

export const NAV_PAGES: NavItem[] = [
  {
    id: PageId.ADMINISTRATION,
    title: "Administration",
    to: "/administration",
    page: <Administration />,
    category: PkgName.ADMINISTRATION,
  },
  {
    id: PageId.GMP_LAMB_DATA_ANALYSIS,
    title: "GMP Lamb Data Analysis",
    to: "/gmp-lamb-data-analysis",
    page: <GmpLambDataAnalysis />,
    category: PkgName.GMP_LAMB_DATA_ANALYSIS,
  },
  {
    id: PageId.GMP_LAMB_BOOKINGS,
    title: "GMP Lamb Booking",
    to: "/gmp-lamb-bookings",
    page: <GmpLambBookings />,
    category: PkgName.GMP_LAMB_BOOKINGS,
  },
  {
    id: PageId.ALLIANCE_DATA_ANALYSIS,
    title: "MEQ Probe Data Analytics",
    to: "/alliance-data-analysis",
    page: <AllianceLambDataAnalysis />,
    category: PkgName.ALLIANCE_DATA_ANALYSIS,
  },
  {
    id: PageId.MEQ_PROBE_LAMB,
    title: "MEQ Probe Lamb",
    to: "/meq-probe-lamb",
    page: <MeqProbeLamb />,
    category: PkgName.MEQ_PROBE_LAMB,
  },
  {
    id: PageId.MEQ_CAMERA,
    title: "MEQ Camera",
    to: "/meq-camera",
    page: <Camera />,
    category: PkgName.MEQ_CAMERA,
  },
  {
    id: PageId.MANAGEMENT,
    title: "Management",
    to: "/management/users",
    page: <Management />,
    category: PkgName.MANAGEMENT,
  },
];

export const OTHER_PAGES: NavItem[] = [
  { id: PageId.MAIN_ENTRY, title: "Working ...", to: MAIN_ENTRY_PAGE, page: <Main />, hideTitle: true },
  { id: PageId.NO_PKGS, title: "No Packages", to: "/no-packages", page: <NoPkgs />, hideTitle: true },
  { id: PageId.NOT_FOUND, title: "Not Found", to: "*", page: <NotFound />, hideNav: true, isPublic: true },
  { id: PageId.LOADING, title: "Loading", to: "/loading", page: <Loading />, hideNav: true, isPublic: true },
  { id: PageId.LOGIN, title: "Login", to: "/", page: <Login />, hideNav: true, isPublic: true },
  { id: PageId.AUTH_CALLBACK, title: "Auth Callback", to: "/callback", page: <Login isCallback />, hideNav: true, isPublic: true },
  { id: PageId.LOGOUT, title: "Logout", to: "/logout", page: <Logout />, hideNav: true, isPublic: true },
];
