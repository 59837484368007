import { Chip, ChipPredefined, ContentWrapper, Dialog, Hcharts, Link, Spinner } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { formatPercentile, getPercentile } from "../../../../GmpLambDataAnalysis/Producer/Dashboard/Charts/HistogramTrend";
import RankingDialog from "./RankingDialog";

const Section = ({
  data,
  loading,
  supplierWithFullInfo,
  averages,
  percentiles,
}: {
  data: QueryResult;
  loading: boolean;
  supplierWithFullInfo: LooseObject;
  averages: LooseObject;
  percentiles: QueryResult | undefined;
}) => {
  const xAxis = data.columns?.find(i => i.name === "IMF_PERCENTAGE_OF");

  const [openForDialog, setOpenForDialog] = useState<boolean>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dataToDisplay = data?.rows;

  const percentile = getPercentile(percentiles?.rows, "IMF", averages.AVG_IMF_PERCENTAGE_OF);

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        stacking="normal"
        title="xAxisLabel Trend"
        titleExtra={
          !percentiles ? (
            <Spinner size={28} />
          ) : !percentiles?.numRows ? undefined : percentile ? (
            <Grid display="flex" alignItems="center">
              <ChipPredefined
                type="success"
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="textxs">{`${formatPercentile(percentile)} of all mobs for IMF score.`}</Typography>
                    <Link onClick={() => setOpenForDialog(true)} sx={theme => ({ textDecoration: "none", borderBottom: `1px solid ${theme.palette.success.main}` })}>
                      <Typography variant="textxs" color="success.main">
                        {isSmallScreen ? "Ranking" : "View Ranking"}
                      </Typography>
                    </Link>
                  </Stack>
                }
              />
            </Grid>
          ) : (
            <Grid display="flex" alignItems="center">
              <ChipPredefined
                type="warning"
                label={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="textxs">No IMF ranking data available.</Typography>
                    <Link onClick={() => setOpenForDialog(true)} sx={theme => ({ textDecoration: "none", borderBottom: `1px solid ${theme.palette.warning.main}` })}>
                      <Typography variant="textxs" color="warning.main">
                        {isSmallScreen ? "Ranking" : "View Ranking"}
                      </Typography>
                    </Link>
                  </Stack>
                }
              />
            </Grid>
          )
        }
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`Supplier: ${supplierWithFullInfo.SUPPLIER_NO} | ${supplierWithFullInfo.fullName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        xAxis={xAxis}
        data={[
          {
            data: dataToDisplay || [],
            name: "Carcase",
          },
        ]}
        subCharts={[
          { type: ChartType.HISTOGRAM },
          { type: ChartType.SCATTER_PLOT, title: "xAxisLabel vs yAxisLabel", xAxis, yAxis: data.columns?.find(i => i.name === "HOTWEIGHT_KG") },
        ]}
        height={492}
      />
      <Dialog open={openForDialog} onClose={() => setOpenForDialog(false)} isTransparent maxWidth="md">
        {averages && percentiles && (
          <RankingDialog supplierWithFullInfo={supplierWithFullInfo} averages={averages} percentiles={percentiles} onCancel={() => setOpenForDialog(false)} />
        )}
      </Dialog>
    </ContentWrapper>
  );
};

export default Section;
