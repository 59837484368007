import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Dashboard from "./Dashboard";
// import SummaryOfData from "./SummaryOfData";
import Device from "./Device";
import { useAppSelector } from "../../redux/hooks";
import { selectOrgs } from "../../redux/reducers/orgsSlice";
import { getCurrentLocation } from "../../utils/Helper";
import { ALL_LOCATIONS } from "../../utils/Constants";
import AllLocations from "./AllLocations";

const Page = () => {
  const orgs = useAppSelector(selectOrgs);

  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const currentLocation = getCurrentLocation(orgs);

  if (currentLocation === ALL_LOCATIONS) {
    return <AllLocations />;
  }

  return (
    <Stack spacing={3}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Dashboard" />
        {/* <Tab label="Summary of data" /> */}
        <Tab label="Device" />
      </Tabs>
      {/* {tab === 0 ? <Dashboard /> : tab === 1 ? <SummaryOfData /> : <Device />} */}
      {tab === 0 ? <Dashboard /> : <Device />}
    </Stack>
  );
};

export default Page;
