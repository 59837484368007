import * as React from "react";
import moment, { Moment } from "moment";
import { styled } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import Button from "./Button";
import { Stack } from "@mui/material";

interface CustomPickerDayProps extends PickersDayProps<Moment> {
  isSelected: boolean;
  isHovered: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop => prop !== "isSelected" && prop !== "isHovered",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered, day }) => ({
  borderRadius: 0,
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(isHovered && {
    backgroundColor: theme.palette.primary[theme.palette.mode],
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
  }),
  ...(day.day() === 0 && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(day.day() === 6 && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

const isInSameWeek = (dayA: Moment, dayB: Moment | null | undefined) => {
  if (!dayB) {
    return false;
  }

  const startOfWeek = dayB.clone().startOf("week");
  const endOfWeek = dayB.clone().endOf("week");

  return dayA.isSameOrAfter(startOfWeek) && dayA.isSameOrBefore(endOfWeek);
};

const day = (
  props: PickersDayProps<Moment> & {
    selectedDay?: Moment | null;
    hoveredDay?: Moment | null;
  }
) => {
  const { day, selectedDay, hoveredDay, ...other } = props;

  return (
    <CustomPickersDay {...other} day={day} sx={{ px: 2.5 }} disableMargin selected={false} isSelected={isInSameWeek(day, selectedDay)} isHovered={isInSameWeek(day, hoveredDay)} />
  );
};

const WeekPicker = ({ defaultValue, onConfirm, onCancel }: { defaultValue?: Moment; onConfirm: (value: Moment) => void; onCancel?: () => void }) => {
  const [hoveredDay, setHoveredDay] = React.useState<Moment | null>(null);
  const [value, setValue] = React.useState<Moment>(defaultValue || moment());

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      localeText={{
        calendarWeekNumberHeaderText: "Week",
        calendarWeekNumberText: weekNumber => `${weekNumber}`,
      }}
    >
      <DateCalendar
        value={value}
        onChange={newValue => setValue(newValue)}
        showDaysOutsideCurrentMonth
        displayWeekNumber
        slots={{ day }}
        slotProps={{
          day: ownerState =>
            ({
              selectedDay: value,
              hoveredDay,
              onPointerEnter: () => setHoveredDay(moment(ownerState.day)),
              onPointerLeave: () => setHoveredDay(null),
            } as any),
        }}
      />
      <Stack direction="row" spacing={1} sx={{ alignSelf: "flex-end" }}>
        {onCancel && <Button variant="text" title="Cancel" sx={{ p: 0.5 }} onClick={() => onCancel?.()} />}
        <Button
          variant="text"
          title="Apply"
          sx={{ p: 0.5 }}
          onClick={() => {
            onConfirm(value);
            onCancel?.();
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default WeekPicker;
