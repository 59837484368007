import { Stack, Typography } from "@mui/material";
import { SmallButton, SvgIcon } from "../../../components";
import { GridPricingDataType } from "../../../utils/Types";
import GridPricing from "../GridPricing";
import { useEffect, useState } from "react";
import { isValidGmpGridPricingData } from "../../../utils/Helper";
import ToolButton from "../../../components/Button/ToolButton";

const Edit = ({
  loadingInitial,
  data,
  isSuccess,
  handleSubmit,
}: {
  loadingInitial: boolean;
  data: GridPricingDataType;
  isSuccess: boolean;
  handleSubmit: (v: GridPricingDataType) => void;
}) => {
  const [gridPricingData, setGridPricingData] = useState<GridPricingDataType>(data);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState(loadingInitial);

  useEffect(() => {
    setLoading(loadingInitial);
  }, [loadingInitial]);

  useEffect(() => {
    if (isSuccess === true) {
      setIsEditMode(false);
    }
  }, [isSuccess]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        {isEditMode ? (
          <Stack direction="row" spacing={2}>
            <SmallButton
              variant="contained"
              title="Save"
              loading={loading}
              onClick={() => {
                if (isValidGmpGridPricingData(gridPricingData)) {
                  setLoading(true);
                  handleSubmit(gridPricingData);
                } else {
                  setErrorMessage("Please check your input.");
                }
              }}
            />
            <SmallButton
              variant="outlined"
              title="Cancel"
              onClick={() => {
                setIsEditMode(false);
                setGridPricingData(data);
              }}
            />
          </Stack>
        ) : (
          <ToolButton title="Edit" icon={<SvgIcon name="Edit" />} onClick={() => setIsEditMode(true)} />
        )}
      </Stack>
      <GridPricing
        data={gridPricingData}
        isEditMode={isEditMode}
        exposeGridPricingData={v => {
          setErrorMessage("");
          setGridPricingData(v);
        }}
      />
      {errorMessage && (
        <Typography color="error" textAlign="center">
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

export default Edit;
