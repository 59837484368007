import { CircularProgress } from "@mui/material";
import { useAppSelector } from "../redux/hooks";
import { selectRoles } from "../redux/reducers/rolesSlice";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ADMIN, ALL_LOCATIONS, SUPER_ADMIN, SUPER_USER } from "../utils/Constants";
import { selectOrgs } from "../redux/reducers/orgsSlice";
import { NAV_PAGES } from "../utils/NavItems";
import { delay, getLocationFromOrgIdString, getOrgFromOrgIdString, getShortRoleFromRole } from "../utils/Helper";
import { Center } from "../components";
import { NavItem, Permission, PkgName } from "../utils/Types";
import PageId from "../utils/PageId";
import { ClientContext } from "../utils/Contexts";

// This is the entry page of the protected routes.
// It definies which page should show as the first page to the login user.
const Page = () => {
  const navigate = useNavigate();
  const roles = useAppSelector(selectRoles);
  const orgs = useAppSelector(selectOrgs);
  const currentRole = roles.find(i => i?.isCurrent);
  const currentOrg = orgs.find(i => i?.isCurrent);

  const clientOverides = useContext(ClientContext);

  useEffect(() => {
    if (currentOrg && currentRole) {
      clientOverides.set(getOrgFromOrgIdString(currentOrg.idString));

      const currentLocation = getLocationFromOrgIdString(currentOrg.idString);

      // make sure currentOrg has the pkg
      const isCurrentOrgHasPkg = (navItem: NavItem) => (navItem.category && currentOrg.pkgs.includes(navItem.category)) || false;

      // make sure permission include the pageId
      const isPermitted = ({ permission, pageId }: { permission?: Permission; pageId: PageId }): boolean => permission?.pages.map(o => o.id).includes(pageId) || false;

      // make sure location matches
      const isLocationMatched = (navItem: NavItem): boolean => (navItem.location ? currentLocation === navItem.location : true);

      const AllNavPages = NAV_PAGES.filter(i => (i.category === PkgName.ADMINISTRATION ? currentRole?.role === SUPER_ADMIN : true)); // only show administration for super admin

      let permittedPages = AllNavPages.filter(i => isPermitted({ permission: currentRole.permission, pageId: i.id }) && isLocationMatched(i));

      // let permittedPages = NAV_PAGES.filter(i => currentRole.permission?.pages.map(o => o.id).includes(i.id) && (i.location ? currentLocation === i.location : true));

      if (currentRole.role === SUPER_ADMIN || currentRole.role === SUPER_USER) {
        permittedPages = AllNavPages.filter(i => isCurrentOrgHasPkg(i) && isLocationMatched(i));
      } else if (currentRole.role.includes(ALL_LOCATIONS)) {
        permittedPages = AllNavPages.filter(i => isCurrentOrgHasPkg(i) && isLocationMatched(i));
      }

      if (currentRole.role === SUPER_ADMIN || getShortRoleFromRole(currentRole.role) === ADMIN) {
        permittedPages = permittedPages.concat(AllNavPages.filter(i => i.category === PkgName.MANAGEMENT));
      }

      if (permittedPages && permittedPages.length > 0) {
        delay(1000).then(() => {
          navigate(permittedPages[0].to);
        });
      } else {
        navigate("/no-packages");
      }
    }
  }, []);

  // window.location.reload();

  // return (
  //   <Backdrop open>
  //     <CircularProgress size={50} color="inherit" />
  //   </Backdrop>
  // );

  return (
    <Center minHeight="50%">
      <CircularProgress size={50} color="inherit" />
    </Center>
  );
};

export default Page;
