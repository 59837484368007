import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ContentWrapper, RoundIconButton } from "../../../../../../components";
import { LooseObject } from "../../../../../../utils/Types";
import _ from "lodash";
import { Visibility } from "@mui/icons-material";

const ListOfAgreements = ({ currentBooking, listOfAgreements, onView }: { currentBooking: LooseObject; listOfAgreements: LooseObject[]; onView: (id: string) => void }) => {
  const totalSupplied = _.sum(listOfAgreements.map(i => Number(i.agreedQuantity)));

  return (
    <Grid container direction="column" rowSpacing={2}>
      {listOfAgreements.length > 0 ? (
        listOfAgreements.map((i, index) => (
          <Grid key={`agreement${index + 1}`}>
            <ContentWrapper>
              <Stack direction="row" justifyContent="space-between">
                <Stack>
                  <Typography variant="textmd" fontWeight="semiBold">
                    {currentBooking.bookingDate}
                  </Typography>
                  <Typography variant="textsm" fontWeight="semiBold" mt={2}>{`Agreement ${i.id}`}</Typography>
                  <Typography variant="textsm">{`${i.producerFirstName} ${i.producerLastName} (${i.producerBusinessName})`}</Typography>
                  <Typography variant="textsm">{`PIC: ${i.pic}`}</Typography>
                  <Typography variant="textsm">{`Quantity: ${i.agreedQuantity}`}</Typography>
                  {i.isMuelsed && <Typography variant="textsm">Muelsed: Yes</Typography>}
                  {i.isMsaAccredited && <Typography variant="textsm">MSA Accredited: Yes</Typography>}
                  {i.isComplyWithLPA && <Typography variant="textsm">Comply with LPA: Yes</Typography>}
                  {i.isGrassFed && <Typography variant="textsm">Grass Fed: Yes</Typography>}
                  {i.isFreeRange && <Typography variant="textsm">Free Range: Yes</Typography>}
                  {i.isAntibioticFree && <Typography variant="textsm">Antibiotic Free: Yes</Typography>}
                  {i.isHormoneAndGrowthPromotantFree && <Typography variant="textsm">Hormone and Growth Promotant Free: Yes</Typography>}
                </Stack>
                <Stack justifyContent="space-between">
                  <RoundIconButton onClick={() => onView(i.id)}>
                    <Visibility />
                  </RoundIconButton>
                </Stack>
              </Stack>
            </ContentWrapper>
          </Grid>
        ))
      ) : (
        <Grid>
          <ContentWrapper>
            <Stack>
              <Typography>No supply agreements yet.</Typography>
            </Stack>
          </ContentWrapper>
        </Grid>
      )}
      <Grid>
        <Stack spacing={2}>
          {listOfAgreements.length > 0 && (
            <Typography variant="textsm" color={totalSupplied > currentBooking.quantity ? "error" : undefined}>{`Total Supplied: ${totalSupplied} Head`}</Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ListOfAgreements;
