import { CircularProgress, CircularProgressProps } from "@mui/material";
import Center from "./Center";

const Spinner = (props: CircularProgressProps) => {
  return (
    <Center>
      <CircularProgress {...props} />
    </Center>
  );
};

export default Spinner;
