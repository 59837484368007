import { Link as MuiLink } from "@mui/material";
import { styled } from "@mui/material/styles";

const Link = styled(MuiLink)(({ theme }) => ({
  textDecoration: "underline",
  cursor: "pointer",
  color: theme.palette.text.secondary,
}));

export default Link;
