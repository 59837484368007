import { faker } from "@faker-js/faker";
import { getRandomInt } from "./Helper";
import { DateFormat, GmpBookingStatus } from "./Types";
import moment from "moment";

export const mockWeeklyCalendar = Array.from(Array(30).keys()).map(i => ({
  id: i,
  VENDOR: faker.person.fullName(),
  COMPANY: faker.airline.airline().name,
  TYPE: getRandomInt(0, 2) === 0 ? "Agent" : "Producer",
  GLQ_AVG: faker.number.float({ min: 0, max: 10, fractionDigits: 1 }) + "%",
  "2024_03_04": { value: "400", status: "Not Applicable" },
  "2024_03_11": { value: "400", status: "Scheduled" },
  "2024_02_19": { value: "400", status: "Assigned" },
  "2024_02_26": { value: "400", status: "Completed" },
}));

export const mockWeeklyOverview = Array.from(Array(30).keys()).map(i => ({
  id: i,
  VENDOR: faker.person.fullName(),
  COMPANY: faker.airline.airline().name,
  TYPE: getRandomInt(0, 2) === 0 ? "Agent" : "Producer",
  PRODUCER: faker.person.fullName(),
  TRADING_NAME: faker.company.name(),
  PIC: "NC" + faker.string.numeric({ length: 6 }),
  PERCENT_OF_GLQ_5_PLUS: faker.number.float({ min: 0, max: 30, fractionDigits: 1 }) + "%",
  NUMBER_OF_HEAD: faker.number.int({ min: 100, max: 1000 }),
  BOOKED_BY: faker.person.fullName(),
  STATUS: [GmpBookingStatus.NotApplicable, GmpBookingStatus.Scheduled, GmpBookingStatus.Assigned, GmpBookingStatus.Completed][faker.number.int({ min: 0, max: 3 })],
  LIVE_WEIGHT_KG: faker.number.int({ min: 10, max: 100 }),
  DEAD_WEIGHT_KG: faker.number.int({ min: 10, max: 50 }),
  BREED: "Breed",
  CATEGORY: faker.number.int({ min: 3, max: 20 }) + " months",
  FREE_RANGE: ["Y", "N"][faker.number.int({ min: 0, max: 1 })],
  MSA_ACCREDITED: ["Y", "N"][faker.number.int({ min: 0, max: 1 })],
  SKIN: ["Y", "N"][faker.number.int({ min: 0, max: 1 })],
}));

export const getGeoData = Array.from(Array(600).keys()).map(i => ({
  id: i,
  name: faker.location.city(),
  longitude: faker.location.longitude(),
  latitude: faker.location.latitude(),
}));

export const getProbeStatus = Array.from(Array(3).keys()).map(i => ({
  needle: `Needle ${i + 1}`,
  status: ["Pass", "Warning"][faker.number.int({ min: 0, max: 1 })],
}));

export const getDailyReports = Array.from(Array(30).keys()).map(i => ({
  createdAt: moment()
    .add(-(i + 1), "days")
    .format(DateFormat.SHORT),
  count: faker.number.int({ min: 100, max: 500 }),
}));

export const viewChartsMinimalTableData = [
  { GRADE: "Y1 (target)", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "Y1 (other)", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "Y2", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "P", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "TF", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "Other", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
  { GRADE: "Total", CARCASES: faker.number.int({ min: 100, max: 500 }), PERCENTAGE: faker.number.int({ min: 0, max: 100 }) },
];
