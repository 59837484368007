import { Typography } from "@mui/material";
import { DialogWrapper } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";

const ToggleUserState = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (id: string) => void }) => {
  const item = row.original;

  return (
    <DialogWrapper loading={loading} onCancel={onCancel} title={`Confirm to delete permissions for role ${item.role}`} displayCancelButton onConfirm={() => handleSubmit(item._id)}>
      <Typography>{`Are you sure you want to delete permissions for role ${item.role}?`}</Typography>
    </DialogWrapper>
  );
};

export default ToggleUserState;
