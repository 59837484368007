import { Container, Stack, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { MRT_Row, MRT_RowData } from "material-react-table";
import { FormData, FormInputCategory, PkgName } from "../../../utils/Types";
import { allPages } from "./Add";

const Edit = ({ loading, row, onCancel, handleSubmit }: { loading: boolean; row: MRT_Row<MRT_RowData>; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const item = row.original;

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">{`Update Role ${item.role}`}</Typography>
          <Form
            loading={loading}
            onSubmit={data => handleSubmit({ id: item._id, ...data })}
            buttonText="Submit"
            buttonSize="small"
            buttonFullWidth={false}
            inputs={[
              {
                name: "role",
                label: "Role",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.role,
                disabled: true,
              },
              {
                name: "pkgs",
                label: "Packages",
                category: FormInputCategory.SELECT,
                options: Object.values(PkgName)
                  .filter(i => ![PkgName.MANAGEMENT, PkgName.ADMINISTRATION].includes(i))
                  .map(o => ({ label: o, value: o })),
                defaultValue: item.pkgs,
                multiple: true,
              },
              {
                name: "pages",
                label: "Pages",
                category: FormInputCategory.SELECT,
                options: allPages.map(o => ({ label: o, value: o })),
                defaultValue: item.pages ? item.pages.map((i: any) => i?.id) : [],
                multiple: true,
                helperText: "Please choose only the pages belong to the packages you selected. The pages will start with the package names.",
              },
              {
                name: "description",
                label: "Description",
                category: FormInputCategory.TEXT_FIELD,
                defaultValue: item.description,
              },
              {
                name: "isWrite",
                category: FormInputCategory.CHECK_BOX,
                label: "Writable",
                defaultValue: item.isWrite,
              },
            ]}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Edit;
