import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgressIndicator, Tile, TileContentText } from "../../../components";
import { QueryResult } from "../../../utils/Types";
import _ from "lodash";

const Tiles = ({ data }: { data: QueryResult }) => {
  const hscwAvg = (_.sumBy(data.rows, "HOTWEIGHT_KG") / (data.rows?.length || 1)).toFixed(2);
  const imfAvg = (_.sumBy(data.rows, "IMF_PERCENTAGE_OF") / (data.rows?.length || 1)).toFixed(2);
  const yieldAvg = (_.sumBy(data.rows, "VIASCAN_YIELD_PERCENTAGE_OF") / (data.rows?.length || 1)).toFixed(2);
  const grAvg = (_.sumBy(data.rows, "VIASCAN_GR") / (data.rows?.length || 1)).toFixed(2);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="HSCW Avg"
          content={
            <Stack spacing={1}>
              <TileContentText text={`${hscwAvg}kg`} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Viascan Gr Avg"
          content={
            <Stack spacing={1}>
              <TileContentText text={grAvg} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="IMF Avg"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={`${imfAvg}%`} />
              <CircularProgressIndicator value={Number(imfAvg)} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Yield Avg"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={`${yieldAvg}%`} />
              <CircularProgressIndicator value={Number(yieldAvg)} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  );
};

export default Tiles;
