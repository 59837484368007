import HighchartsReact from "highcharts-react-official";
import * as HighchartsOriginal from "highcharts";
import { ChartType, HchartsProps, LooseObject, QueryResultColumn } from "../../utils/Types";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import HighchartsBoost from "highcharts/modules/boost";
import { generateChartTraceColors } from "./Utils";
import { useEffect, useState } from "react";
import Dialog from "../Dialog";
import SubChartDialog from "./ChartComponents/SubChartDialog";
import AxisSwitch, { getDefaultAxis } from "./ChartComponents/AxisSwitch";
import ChartContainer from "./ChartComponents/ChartContainer";
import SvgIcon from "../SvgIcon";
import Actions from "./ChartComponents/Actions";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAnnotations from "highcharts/modules/annotations";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import { isDarkMode } from "../../utils/Helper";
import { darkTheme, lightTheme } from "./CustomTheme";
import { histogramChartOptions } from "./ChartOptions/Histogram";
import { scatterChartOptions } from "./ChartOptions/Scatter";
import { columnChartOptions } from "./ChartOptions/Column";
import { boxPlotChartOptions } from "./ChartOptions/BoxPlot";

HighchartsMore(HighchartsOriginal);
HighchartsAnnotations(HighchartsOriginal);
noDataToDisplay(HighchartsOriginal);

// Initialize the boost module
if (typeof HighchartsBoost === "function") {
  HighchartsBoost(HighchartsOriginal);
}

const Charts = (props: HchartsProps) => {
  const theme = useTheme();

  const { type, title, titleExtra, xAxis: xAxisDefault, yAxis: yAxisDefault, header, axisSwitch, hideAxisSwitch, data, traceColors, subCharts, onSelect, hideToolButtons } = props;

  const [chartKey, setChartKey] = useState(0); // Change the key to force re-render

  const theDefaultXAxis = xAxisDefault || getDefaultAxis({ options: axisSwitch?.optionsOfXAxis, defaultName: axisSwitch?.defaultXAxisName, isY: false });
  const [xAxis, setXAxis] = useState<QueryResultColumn | undefined>(theDefaultXAxis);
  const [yAxis, setYAxis] = useState<QueryResultColumn | undefined>(
    yAxisDefault ||
      getDefaultAxis({
        options: axisSwitch?.optionsOfYAxis,
        defaultName: axisSwitch?.defaultYAxisName,
        isY: true,
        defaultXAxisNameForPreventingDuplicate: theDefaultXAxis?.name,
      })
  );

  const [openForSubChartDialog, setOpenForSubChartDialog] = useState<boolean>(false);
  const [arrayOfSubChartOptions, setArrayOfSubChartOptions] = useState<HchartsProps[]>();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null);
  const openActions = Boolean(anchorElActions);

  const defaultTraceColors = generateChartTraceColors({
    quantity: data.length,
    seedColorHex: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main,
  });

  useEffect(() => {
    const customTheme = isDarkMode(theme) ? darkTheme : lightTheme;
    HighchartsOriginal.setOptions({
      ...customTheme,
      chart: {
        ...customTheme.chart,
        backgroundColor: theme.palette.background.default,
        plotBorderColor: "transparent",
      },
      legend: {
        ...customTheme.legend,
        backgroundColor: theme.palette.background.default,
      },
    });

    setChartKey(prevKey => prevKey + 1);
  }, [isDarkMode(theme)]);

  let chartOptions: LooseObject = {};

  switch (type) {
    case ChartType.BAR_CHART:
      chartOptions = columnChartOptions({
        ...props,
        xAxis,
        traceColors: traceColors || defaultTraceColors,
        onSelect: subCharts
          ? subChartOptionsWithSelectedData => {
              setArrayOfSubChartOptions(subChartOptionsWithSelectedData);
              setOpenForSubChartDialog(true);
            }
          : onSelect,
      });
      break;
    case ChartType.HISTOGRAM:
      chartOptions = histogramChartOptions({
        ...props,
        xAxis,
        traceColors: traceColors || defaultTraceColors,
        onSelect: subCharts
          ? subChartOptionsWithSelectedData => {
              setArrayOfSubChartOptions(subChartOptionsWithSelectedData);
              setOpenForSubChartDialog(true);
            }
          : onSelect,
      });
      break;
    case ChartType.SCATTER_PLOT:
      chartOptions = scatterChartOptions({
        ...props,
        xAxis,
        yAxis,
        traceColors: traceColors || defaultTraceColors,
        onSelect: subCharts
          ? subChartOptionsWithSelectedData => {
              setArrayOfSubChartOptions(subChartOptionsWithSelectedData);
              setOpenForSubChartDialog(true);
            }
          : onSelect,
      });
      break;
    case ChartType.BOX:
      chartOptions = boxPlotChartOptions({
        ...props,
        xAxis,
        yAxis,
        traceColors: traceColors || defaultTraceColors,
      });
      break;
  }

  return (
    <ChartContainer isFullScreen={isFullScreen} exitFullScreen={() => setIsFullScreen(false)}>
      <Stack spacing={1} width="100%">
        <Stack direction="row" justifyContent={title ? "space-between" : "flex-end"} alignItems="center" width="100%">
          {typeof title === "string" ? (
            <Typography variant="textmd" fontWeight="semiBold" textAlign="center">
              {title?.replaceAll("xAxisLabel", xAxis?.label || "").replaceAll("yAxisLabel", yAxis?.label || "")}
            </Typography>
          ) : (
            title
          )}

          <Stack direction="row" spacing={1} alignItems="center">
            {titleExtra}
            {!hideToolButtons && (
              <IconButton size="small" sx={{ border: 0 }} onClick={event => setAnchorElActions(event.currentTarget)}>
                <SvgIcon name="MoreVert" strokeWidth={1.6} />
              </IconButton>
            )}
          </Stack>

          <Actions anchorEl={anchorElActions} open={openActions} onClose={() => setAnchorElActions(null)} toggleFullScreen={() => setIsFullScreen(!isFullScreen)} />
        </Stack>
        {header}
        {!hideAxisSwitch && axisSwitch && (
          <AxisSwitch {...axisSwitch} defaultXAxisName={xAxis?.name} defaultYAxisName={yAxis?.name} handleChartStateChangeXAxis={setXAxis} handleChartStateChangeYAxis={setYAxis} />
        )}
        <HighchartsReact key={chartKey} highcharts={HighchartsOriginal} options={chartOptions} />
        {subCharts && arrayOfSubChartOptions && (
          <Dialog open={openForSubChartDialog} onClose={() => setOpenForSubChartDialog(false)} isTransparent maxWidth="lg">
            <SubChartDialog arrayOfSubChartOptions={arrayOfSubChartOptions} onCancel={() => setOpenForSubChartDialog(false)} />
          </Dialog>
        )}
      </Stack>
    </ChartContainer>
  );
};

export default Charts;
