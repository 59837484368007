import { ReactNode, forwardRef, useMemo, useState } from "react";
import { Box, Link, Slide, Snackbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { COPY_RIGHT, DEFAULT_MESSAGE, DEFAULT_SPACING, DEFAULT_SPACING_MOBILE } from "../utils/Constants";
import { useDocumentTitle } from "../hooks";
import NavBar from "./Nav";
import { Message } from "../utils/Types";
import { SnackbarContext } from "../utils/Contexts";
import { getPkgFromUrl } from "../utils/Helper";
import Center from "./Center";

export const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Center direction="row" color="text.disabled" spacing={isSmallScreen ? 0.2 : 1} mt={{ xs: DEFAULT_SPACING_MOBILE, md: DEFAULT_SPACING }}>
      <Typography variant={isSmallScreen ? "textxs" : "textsm"}>{COPY_RIGHT}</Typography>
      <Typography variant={isSmallScreen ? "textxs" : "textsm"}>|</Typography>
      <Link
        href="files/Terms_and_Conditions.pdf"
        target="_blank"
        variant={isSmallScreen ? "textxs" : "textsm"}
        color="text.disabled"
        sx={{ textDecoration: "underline", ":hover": { color: "text.secondary" } }}
      >
        Terms & Conditions
      </Link>
      <Typography variant={isSmallScreen ? "textxs" : "textsm"}>|</Typography>
      <Link
        href="files/Privacy_Policy.pdf"
        target="_blank"
        variant={isSmallScreen ? "textxs" : "textsm"}
        color="text.disabled"
        sx={{ textDecoration: "underline", ":hover": { color: "text.secondary" } }}
      >
        Privacy Policy
      </Link>
    </Center>
  );
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PageProps {
  title: string;
  children?: ReactNode;
  hideTitle?: boolean;
  hideNav?: boolean;
}

const PageContainer = ({ title, children, hideTitle, hideNav }: PageProps) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState(DEFAULT_MESSAGE);

  const location = useLocation();
  const pkg = getPkgFromUrl(location.pathname);

  useDocumentTitle(pkg ? `${title} | ${pkg}` : title);

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const snackbar = useMemo(
    () => ({
      open: (m: Message) => {
        setMessage(m);
        setOpenSnackbar(true);
      },
      close: () => setOpenSnackbar(false),
    }),
    []
  );

  return (
    <>
      <SnackbarContext.Provider value={snackbar}>
        <Box height="100vh">
          {hideNav ? <Box height="100%">{children}</Box> : <NavBar title={hideTitle ? undefined : title}>{children}</NavBar>}
          {message?.text && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              TransitionComponent={Slide}
              autoHideDuration={5000}
              open={openSnackbar}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity={message.type} sx={{ width: "100%" }}>
                {message.text}
              </Alert>
            </Snackbar>
          )}
        </Box>
      </SnackbarContext.Provider>
    </>
  );
};

export default PageContainer;
