import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Bookings from "./Bookings";
import WeeklyOverview from "./WeeklyOverview";
import Admin from "./Admin";
import { useAppSelector } from "../../redux/hooks";
import { selectRoles } from "../../redux/reducers/rolesSlice";
import { ADMIN, SUPER_ADMIN, SUPER_USER } from "../../utils/Constants";
import { getShortRoleFromRole } from "../../utils/Helper";
import ProducerBookings from "./Bookings/Producer";

export enum GMP_ROLE {
  ADMIN = "GMP-Admin",
  AGENT = "GMP-Agent",
  PRODUCER = "GMP-Producer",
}

export const DateFormatBookings = "ddd MMM D, [Week] w, YYYY";

const Page = () => {
  const [tab, setTab] = useState(0);

  const roles = useAppSelector(selectRoles);
  const currentRole = roles.find(i => i?.isCurrent);
  const isSuperUsers = currentRole && [SUPER_ADMIN, SUPER_USER].includes(currentRole.role);

  const isAllowedToSeeAdminView = (currentRole && getShortRoleFromRole(currentRole.role) === ADMIN) || isSuperUsers;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isAllowedToSeeAdminView) {
    return (
      <Stack spacing={3}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Bookings" />
          <Tab label="Weekly Overview" />
          <Tab label="Admin" />
        </Tabs>
        {tab === 1 ? <WeeklyOverview /> : tab === 2 ? <Admin /> : <Bookings />}
      </Stack>
    );
  }

  return <ProducerBookings />;
};

export default Page;
