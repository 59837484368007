import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../../utils/Types";
import { Chip, CircularProgressIndicator, ContentWrapper, DialogWrapper, Hcharts, Tile, TileContentText } from "../../../../../../components";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { formatPercentile, getPercentile } from "../HistogramTrend";
import _ from "lodash";
import { HISTOGRAM_Y_AXIS_TITLE_PERCENT } from "../../../../../../utils/Constants";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import getCustomChartTraceColors from "../../../../../../components/Chart/CustomChartTraceColors";
import { isDarkMode } from "../../../../../../utils/Helper";

const getPercentileRange = (percentiles: LooseObject[] | undefined, percentile: string, trait: string) => {
  let range = "";
  if (percentiles && percentile && trait) {
    const sortedPercentiles = _.orderBy(percentiles, trait);
    const percentileRow = percentiles.find(i => i.PERCENTILE === percentile);
    if (percentileRow) {
      const index = sortedPercentiles.map(i => i.PERCENTILE).indexOf(percentileRow.PERCENTILE);
      if (index === 0) {
        range = `< ${percentileRow[trait]}`;
      } else if (index === sortedPercentiles.length - 1) {
        range = `> ${percentileRow[trait]}`;
      } else {
        range = `${sortedPercentiles[index][trait]} - ${sortedPercentiles[index + 1][trait]}`;
      }
    }
  }
  return range;
};

const RankingDialog = ({
  picWithFullInfo,
  averages,
  percentiles,
  onCancel,
}: {
  picWithFullInfo: LooseObject;
  averages: LooseObject;
  percentiles: QueryResult;
  onCancel: (values: any) => void;
}) => {
  const theme = useTheme();
  const percentileGlq = getPercentile(percentiles?.rows, "GLQ", averages.AVG_GLQ);
  const percentileImf = getPercentile(percentiles?.rows, "IMF", averages.AVG_IMF_PERCENTAGE_OF);
  const percentileLmy = getPercentile(percentiles?.rows, "LMY", averages.AVG_LMY_PERCENTAGE_OF);

  const getNumberFromPercentile = (percentile: string) => 100 - (percentile ? Number(percentile.replace("top_", "").replace("_percentage_of", "")) : 0);

  let chartData: LooseObject[] = [
    { percentage: getNumberFromPercentile(percentileGlq) },
    { percentage: getNumberFromPercentile(percentileImf) },
    { percentage: getNumberFromPercentile(percentileLmy) },
  ];

  const chartBarColors = getCustomChartTraceColors("GMP", isDarkMode(theme));
  if ((chartBarColors?.length || 0) > 2) {
    chartData = chartData.map((i, index) => ({ ...i, color: chartBarColors?.[index] }));
  }

  return (
    <DialogWrapper onCancel={onCancel} title="Ranking GLQ Score and IMF" minWidth={{ sm: "80vw", md: "60vw", lg: "50vw" }}>
      <Stack spacing={2}>
        <Grid container spacing={1}>
          <Grid>
            <Chip label={`PIC: ${picWithFullInfo.PIC} | ${picWithFullInfo.businessName}`} />
          </Grid>
          <Grid>
            <Chip label={`${moment(picWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
          </Grid>
          <Grid>
            <Chip label={`Lot: ${picWithFullInfo.LOT_REFERENCE} | Head: ${picWithFullInfo.NUM_GL_LAMBS}`} size="small" />
          </Grid>
        </Grid>
        <Divider sx={{ borderWidth: 1.5 }} />
        <Stack direction="row" spacing={2}>
          <Tile
            minWidth={222}
            title="GLQ Score"
            content={
              <Stack spacing={2} direction="row">
                <TileContentText text={averages.AVG_GLQ} />
                <CircularProgressIndicator value={averages.AVG_GLQ} maxValue={10} size={40} borderWidth={10} hidePercentage />
              </Stack>
            }
          />
          <Stack spacing={1}>
            <Chip label={formatPercentile(percentileGlq)} />
            <Typography variant="textsm">{`This lot is at the ${formatPercentile(percentileGlq).toLowerCase()} of lots for the GLQ Score.`}</Typography>
            <Typography variant="textsm">{`Producers in the ${formatPercentile(percentileGlq).toLowerCase()} had an average GLQ Score of ${getPercentileRange(
              percentiles?.rows,
              percentileGlq,
              "GLQ"
            )}.`}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ borderWidth: 1.5 }} />
        <Stack direction="row" spacing={2}>
          <Tile
            minWidth={222}
            title="IMF %"
            content={
              <Stack spacing={2} direction="row">
                <TileContentText text={averages.AVG_IMF_PERCENTAGE_OF + "%"} />
                <CircularProgressIndicator value={averages.AVG_IMF_PERCENTAGE_OF} maxValue={10} size={40} borderWidth={10} />
              </Stack>
            }
          />
          <Stack spacing={1}>
            <Chip label={formatPercentile(percentileImf)} />
            <Typography variant="textsm">{`This lot is at the ${formatPercentile(percentileImf).toLowerCase()} of lots for the Intramuscular Fat.`}</Typography>
            <Typography variant="textsm">{`Producers in the ${formatPercentile(percentileImf).toLowerCase()} had an average Intramuscular Fat of ${getPercentileRange(
              percentiles?.rows,
              percentileImf,
              "IMF"
            )}.`}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{ borderWidth: 1.5 }} />
        <ContentWrapper>
          <Hcharts
            type={ChartType.BAR_CHART}
            title=""
            xTitle=""
            yTitle={HISTOGRAM_Y_AXIS_TITLE_PERCENT}
            xData={["GLQ", "IMF", "LMY"]}
            valueSuffix="%"
            yAxis={{ name: "percentage", label: HISTOGRAM_Y_AXIS_TITLE_PERCENT, type: "number", scale: null }}
            yAxisMin={0}
            yAxisMax={100}
            data={[
              {
                name: "Percentage",
                data: chartData,
                showInLegend: false,
              },
            ]}
            height={300}
          />
        </ContentWrapper>
      </Stack>
    </DialogWrapper>
  );
};

export default RankingDialog;
