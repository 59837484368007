import { Stack } from "@mui/material";
import { Map } from "../../../../../components";
import { MAP_STYLE } from "../../../../../utils/Constants";

const MapView = ({ data }: { data: any[] }) => {
  return (
    <Stack minHeight={522} width="100%" height="100%">
      <Map
        mapStyle={MAP_STYLE}
        initialViewState={{
          zoom: 4,
          longitude: 173,
          latitude: -42,
        }}
        data={data}
        showPins
      />
    </Stack>
  );
};

export default MapView;
