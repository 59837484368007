import { Container, Stack, TextField, Typography } from "@mui/material";
import { DialogWrapper, Form } from "../../../components";
import { FormData, FormInputCategory, FormInputItem, LabelValuePair, LooseObject } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { SnackbarContext } from "../../../utils/Contexts";
import { getCurrentOrg, getOrgFromOrgIdString, isNotEmpty, logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import { ALL } from "../../../utils/Constants";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { GMP_ROLE } from "../../GmpLambBookings";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

export const getUniqSortedAndGroupedPicsFromDb = (pics: LooseObject[]) =>
  _.orderBy(pics.filter(i => i.businessName).map(i => ({ label: i.pic, value: i.pic, businessName: i.businessName })) || [], "businessName").concat(
    pics.filter(i => !i.businessName).map(i => ({ label: i.pic, value: i.pic, businessName: "Other" })) || []
  );

const Add = ({ loading, onCancel, handleSubmit }: { loading: boolean; onCancel: () => void; handleSubmit: (v: FormData) => void }) => {
  const snackbar = useContext(SnackbarContext);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingGmpPics, setLoadingGmpPics] = useState(false);
  const [loadingGmpBusinessNames, setLoadingGmpBusinessNames] = useState(false);
  const [allRoles, setAllRoles] = useState<LabelValuePair[]>([]);
  const [allGmpPics, setAllGmpPics] = useState<LooseObject[]>([]);
  const [allGmpBusinessNames, setAllGmpBusinessNames] = useState<string[]>([] as string[]);
  const [roleSelected, setRoleSelected] = useState<string>("");
  const [gmpBusinessName, setGmpBusinessName] = useState<string>("");
  const [inputs, setInputs] = useState<FormInputItem[]>([]);

  const [gmpPicsSelected, setGmpPicsSelected] = useState<LooseObject[]>([]);
  const [gmpPicsSelectedWithLots, setGmpPicsSelectedWithLots] = useState<{ pic: string; lots: string[] }[]>([]);

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  const currentOrg = getCurrentOrg(orgs);

  const orgShort: string = getOrgFromOrgIdString(currentOrg?.idString || "");

  useEffect(() => {
    loadAllRoles();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => loadAllRoles.cancel();
  }, []);

  useEffect(() => {
    if (orgShort === "GMP") {
      fetchAllGmpPics();

      // Cleanup or clear any pending debounced function calls if necessary
      return () => fetchAllGmpPics.cancel();
    }
  }, []);

  useEffect(() => {
    if (orgShort === "GMP") {
      fetchAllGmpBusinessNames();

      // Cleanup or clear any pending debounced function calls if necessary
      return () => fetchAllGmpBusinessNames.cancel();
    }
  }, []);

  const loadAllRoles = _.debounce(async () => {
    setLoadingRoles(true);
    await postToServer({
      action: "management/users/GetAllRoles",
      params: { orgIdString: getCurrentOrg(orgs)?.idString },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LabelValuePair[];
          setAllRoles(_.orderBy(serverData, "label"));
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingRoles(false);
  }, 500);

  const fetchAllGmpPics = _.debounce(async () => {
    setLoadingGmpPics(true);
    await postToServer({
      action: "gmp/LoadPics",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setAllGmpPics(response.serverData as LooseObject[]);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGmpPics(false);
  }, 500);

  const fetchAllGmpBusinessNames = _.debounce(async () => {
    setLoadingGmpBusinessNames(true);
    await postToServer({
      action: "gmp/GetAllBusinessNames",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setAllGmpBusinessNames(((response.serverData as string[]) || []).sort());
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGmpBusinessNames(false);
  }, 500);

  const handleSubmitForm = (v: FormData) => {
    const { email, role } = v;
    if (orgShort === "GMP" && email && !email.endsWith("@gmpgundagai.com.au") && role === GMP_ROLE.ADMIN) {
      snackbar.open({ type: "error", text: "This user is not allowed to be admin" });
    } else {
      handleSubmit(v);
    }
  };

  const picOptions = getUniqSortedAndGroupedPicsFromDb(allGmpPics);

  useEffect(() => {
    let formInputs: FormInputItem[] = [
      {
        name: "email",
        label: "Email (This will act as username)",
        category: FormInputCategory.TEXT_FIELD,
        type: "email",
      },
      {
        name: "firstName",
        label: "First Name",
        category: FormInputCategory.TEXT_FIELD,
      },
      {
        name: "lastName",
        label: "Last Name",
        category: FormInputCategory.TEXT_FIELD,
      },
      {
        name: "role",
        label: "Role",
        category: FormInputCategory.SELECT,
        options: allRoles,
        exposeValue: setRoleSelected,
      },
    ];

    if (orgShort === "GMP" && (roleSelected === GMP_ROLE.AGENT || roleSelected === GMP_ROLE.PRODUCER)) {
      formInputs = formInputs.concat([
        {
          name: "gmpBusinessName",
          label: "Business Name",
          category: FormInputCategory.AUTO_COMPLETE,
          options: allGmpBusinessNames.map(i => ({ label: i, value: i })),
          loading: loadingGmpBusinessNames,
          exposeValue: v => v && setGmpBusinessName(v.value),
          autoCompleteExposeInputValue: setGmpBusinessName,
        },
        {
          name: "gmpPics",
          label: "PICs",
          category: FormInputCategory.AUTO_COMPLETE,
          options: picOptions,
          loading: loadingGmpPics,
          groupBy: (option: any) => option.businessName,
          getOptionLabel: (option: any) => option.label,
          multiple: true,
          exposeValue: setGmpPicsSelected,
          autoCompleteFilterOptions: createFilterOptions({
            matchFrom: "any",
            stringify: (option: LooseObject) => option.businessName + option.label,
          }),
        },
      ]);
    }
    setInputs(formInputs);
  }, [allRoles, allGmpPics, roleSelected, allGmpBusinessNames]);

  let gmpPicsSelectedWithLotsTemprary: { pic: string; lots: string[] }[] = [...gmpPicsSelectedWithLots];

  useEffect(() => {
    if (roleSelected === GMP_ROLE.AGENT) {
      if (gmpPicsSelected.length > 0) {
        const commonPics = _.intersection(
          gmpPicsSelected.map((i: LooseObject) => i.value),
          gmpPicsSelectedWithLotsTemprary.map(i => i.pic)
        );
        // remove non-existing pics from newGmpPicsSelectedWithLots
        gmpPicsSelectedWithLotsTemprary = gmpPicsSelectedWithLotsTemprary.filter(i => commonPics.includes(i.pic));
        // set new pic's lots as empty array
        gmpPicsSelected.forEach((i: LooseObject) => {
          if (!commonPics.includes(i.value)) {
            gmpPicsSelectedWithLotsTemprary = [...gmpPicsSelectedWithLotsTemprary.filter(o => o.pic !== i.value), { pic: i.value, lots: [] }];
          }
        });
        setGmpPicsSelectedWithLots([...gmpPicsSelectedWithLotsTemprary]);

        setInputs(prev => [
          ...prev.filter(i => i.name !== "picLotsInput-helperText"),
          {
            name: "picLotsInput-helperText",
            category: FormInputCategory.COMPONENT,
            component: (
              <Stack spacing={2} p={2} sx={theme => ({ border: `1px solid ${theme.palette.myborder.secondary}`, borderRadius: 2 })}>
                <Typography variant="textsm" fontWeight="semiBold">
                  Assign Lots to PICs
                </Typography>
                <Typography variant="textsm">Please separate multiple lots with comma. Type only the keyword "All" if you prefer to assign all lots of a PIC. </Typography>
                {gmpPicsSelected.map(i => (
                  <TextField
                    key={`picLotsInput-${i.value}`}
                    label={`${i.label} Lots`}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    sx={{ ".MuiInputLabel-root": { fontSize: "1rem" } }}
                    onChange={v => {
                      const newValue = {
                        pic: i.value,
                        lots: v.target.value?.toLowerCase().indexOf("all") > -1 ? [ALL] : v.target.value?.split(",").map((i: string) => i.toLowerCase().trim()),
                      };

                      gmpPicsSelectedWithLotsTemprary = [...gmpPicsSelectedWithLotsTemprary.filter(o => o.pic !== i.value), newValue];
                      setGmpPicsSelectedWithLots([...gmpPicsSelectedWithLotsTemprary]);
                    }}
                  />
                ))}
              </Stack>
            ),
          },
        ]);
      } else {
        gmpPicsSelectedWithLotsTemprary = [];
        setGmpPicsSelectedWithLots([]);
        setInputs(prev => [...prev.filter(i => i.name !== "picLotsInput-helperText")]);
      }
    }
  }, [roleSelected, gmpPicsSelected]);

  return (
    <DialogWrapper onCancel={onCancel}>
      <Container maxWidth="sm">
        <Stack flex={1} py={3} spacing={2}>
          <Typography variant="textlg" fontWeight="semiBold">
            Invite a new user
          </Typography>
          <Form
            loading={loading || loadingRoles || loadingGmpPics}
            onSubmit={data => handleSubmitForm({ ...data, gmpPicsWithLots: gmpPicsSelectedWithLots, gmpBusinessName })}
            buttonText="Submit"
            buttonSize="small"
            buttonDisabled={
              !roleSelected ||
              (gmpPicsSelectedWithLots.length > 0 && gmpPicsSelectedWithLots.map(i => i.lots.filter(o => isNotEmpty(o)).length > 0).includes(false)) ||
              (orgShort === "GMP" && (roleSelected === GMP_ROLE.AGENT || roleSelected === GMP_ROLE.PRODUCER) && gmpPicsSelected.length === 0)
            }
            buttonFullWidth={false}
            inputs={inputs}
          />
        </Stack>
      </Container>
    </DialogWrapper>
  );
};

export default Add;
