import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../../utils/Contexts";
import { DateFormatServer, LooseObject, PkgName, QueryResultTable, QueryType } from "../../../../utils/Types";
import { selectUser } from "../../../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectOrgs } from "../../../../redux/reducers/orgsSlice";
import _ from "lodash";
import { getCurrentOrg, getDataFromDataPool, prepareQueryResultForTable } from "../../../../utils/Helper";
import { Stack, Typography, useTheme } from "@mui/material";
import { selectDataPool } from "../../../../redux/reducers/dataPoolSlice";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ContentWrapper, Link, MinimalTable, Spinner } from "../../../../components";
import MapView from "./MapView";
import { MRT_RowData } from "material-react-table";
import { DEFAULT_SPACING } from "../../../../utils/Constants";
import LocationCoordinates, { LocationCoordinate } from "../../../../utils/LocationCoordinates";
import { selectOrg } from "../../../../components/Nav/SwitchOrg";
import { useNavigate } from "react-router-dom";
import { ProbeStatusChip, getOverallStatus, getOverallStatusFromStringArray } from "../../Dashboard/ProbeStatus";
import { selectZitadelOrg } from "../../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResultTable>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const currentOrg = getCurrentOrg(orgs)?.idString;

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await getDataFromDataPool({
      dataPool,
      params: {
        id: currentOrg,
        type: QueryType.ORDINARY_QUERY,
        view: `MEQPROBE_EOD_RAW('${moment().add(-30, "days").format(DateFormatServer.SHORT)}', '${moment().format(DateFormatServer.SHORT)}', '${currentOrg?.split("_")[0]}','All')`,
        isFunction: true,
        pkg: PkgName.MEQ_PROBE_LAMB,
        columnsNotInterested: ["SYSTEM_CHECKS", "USER_PERFORMANCE"],
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      setData(prepareQueryResultForTable({ data: dataFromDataPool, org: getCurrentOrg(orgs) }));
    });
    setLoading(false);
  }, 500);

  if (loading || !data) {
    return <Spinner />;
  }

  const sortedRowsByDate = _.orderBy(data?.tableRows || [], o => moment(o.EOD_DATE, DateFormatServer.SHORT), "desc");

  const groupedRowsByLocation: LooseObject = _.groupBy(sortedRowsByDate, "LOCATION");

  const columns = [
    {
      accessorKey: "location",
      header: "Location",
      Cell: ({ cell }: { cell: any }) => (
        <Link
          onClick={() => {
            const toOrg = orgs.find(i => i?.idString.includes(cell.row.original.location));
            selectOrg({ orgIdString: toOrg?.idString, orgs, user, zitadelOrg, dispatch, navigate });
          }}
        >
          {_.startCase(cell.row.original.location)}
        </Link>
      ),
    },
    { accessorKey: "probe", header: "Probe ID" },
    { accessorKey: "lastUse", header: "Last Date Used" },
    { accessorKey: "status", header: "Status", Cell: ({ cell }: { cell: any }) => <ProbeStatusChip status={cell.row.original.status} /> },
    { accessorKey: "lastCount", header: "Last Count" },
  ];

  let rows: MRT_RowData[] = [];

  Object.keys(groupedRowsByLocation).forEach(k => {
    const groupedRowByProbe: LooseObject = _.groupBy(groupedRowsByLocation[k], "CHAIN_NUMBER");
    const rowsForLocation: MRT_RowData[] = Object.keys(groupedRowByProbe).map(probeK => ({
      location: k,
      probe: probeK,
      lastUse: groupedRowByProbe[probeK][0].PRODUCTION_ENDED_FORMATTED,
      status: getOverallStatus(groupedRowByProbe[probeK][0].END_OF_PRODUCTION_CHECKS.MATERIAL_CALIBRATION.NEEDLE_STATUS),
      lastCount: groupedRowByProbe[probeK][0].CARCASSES_PROBED,
    }));

    rows = rows.concat(_.orderBy(rowsForLocation, "probe", "asc"));
  });

  const dataForMap: (LocationCoordinate & { pinColor: string })[] = [];

  if (currentOrg) {
    _.uniq(rows.map(i => _.startCase(i.location))).forEach(i => {
      const client = LocationCoordinates.find(o => o.client === currentOrg);
      if (client) {
        const clientLocation = client.locations.find(o => o.name === i);
        const allProbeStatusOfClientLocation = rows.filter(row => row.location === i).map(o => o.status);
        const status = getOverallStatusFromStringArray(allProbeStatusOfClientLocation);
        const pinColor = status === "Pass" ? theme.palette.utility.success[700] : status === "Warn" ? theme.palette.utility.warning[700] : theme.palette.utility.error[700];
        if (clientLocation) {
          dataForMap.push({ ...clientLocation, pinColor });
        }
      }
    });
  }

  return (
    <Grid container direction="column" rowSpacing={2}>
      <ContentWrapper>
        <Grid container spacing={DEFAULT_SPACING}>
          <Grid xs={12} sm={6} md={4}>
            <MapView data={dataForMap} />
          </Grid>
          <Grid xs={12} sm={6} md={8}>
            <Stack spacing={2}>
              <Typography variant="textmd" fontWeight="semiBold">
                All Probes
              </Typography>
              <MinimalTable columns={columns} data={rows} />
            </Stack>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Grid>
  );
};

export default Page;
