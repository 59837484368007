import { Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import { logout, postToServer } from "../../../utils/Helper";
import { LooseObject, FormData, DateFormat, LabelValuePair } from "../../../utils/Types";
import { Center, DetailPanel, Dialog, SmallButton, Table } from "../../../components";
import { MRT_ColumnDef, MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ToggleFullScreenButton } from "material-react-table";
import Edit from "./Edit";
import Add from "./Add";
import moment from "moment";
import Delete from "./Delete";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<LooseObject[]>([]);
  const [openDialogForAdd, setOpenDialogForAdd] = useState<boolean>(false);
  const [openDialogForEdit, setOpenDialogForEdit] = useState<boolean>(false);
  const [openDialogForDelete, setOpenDialogForDelete] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<MRT_Row<MRT_RowData>>();

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await postToServer({
      action: "administration/GetAllPermissions",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setData(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoading(false);
  }, 500);

  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    { accessorKey: "role", header: "Role" },
    { accessorKey: "pkgsList", header: "Packages", maxSize: 200 },
    { accessorKey: "pagesList", header: "Pages" },
  ];

  const dataToDisplay = _.orderBy(data, ["createdAt"], ["desc"]);
  const rows: MRT_RowData[] = dataToDisplay.map(i => ({
    ...i,
    pkgsList: i.pkgs.join(", "),
    pagesList: i.pages.map((o: any) => `${o.id}:[${o.components.join(", ")}]`).join(", "),
    createdAt: moment(i.createdAt).format(DateFormat.DEFAULT),
    updatedAt: moment(i.updatedAt).format(DateFormat.DEFAULT),
  }));

  const handleAdd = async ({ role, pkgs, pages, description, isWrite }: FormData) => {
    if (role && pkgs && Array.isArray(pkgs) && pages && Array.isArray(pages)) {
      setLoading(true);
      await postToServer({
        action: "administration/AddPermission",
        params: { role, pkgs, pages, description, isWrite },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForAdd(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleEdit = async ({ id, role, pkgs, pages, description, isWrite }: FormData) => {
    if (id && role && pkgs && Array.isArray(pkgs) && pages && Array.isArray(pages)) {
      setLoading(true);
      await postToServer({
        action: "administration/UpdatePermission",
        params: { id, role, pkgs, pages, description, isWrite },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForEdit(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    if (id) {
      setLoading(true);
      await postToServer({
        action: "administration/DeletePermission",
        params: { id },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setData(serverData);
            setOpenDialogForDelete(false);
          }
          snackbar.open(response.message);
        }
      });
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Table
        loading={loading}
        columns={columns}
        data={rows}
        muiTableBodyRowProps={({ row }) => ({ sx: { bgcolor: row.original.isValid === false ? "error.main" : undefined } })}
        enableRowSelection
        enableSelectAll={false}
        enableMultiRowSelection={false}
        renderTopToolbar={({ table }) => {
          return (
            <Stack direction="row" justifyContent="space-between" p={2}>
              <Typography variant="textmd" fontWeight="semiBold" alignSelf="center">
                All Permissions
              </Typography>
              <Center direction="row" spacing={2}>
                <SmallButton title="Add" variant="outlined" onClick={() => setOpenDialogForAdd(true)} />
                <SmallButton
                  title="Edit"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForEdit(true);
                  }}
                />
                <SmallButton
                  title="Delete"
                  variant="outlined"
                  disabled={table.getSelectedRowModel().flatRows.length !== 1}
                  onClick={() => {
                    setCurrentItem(table.getSelectedRowModel().flatRows[0]);
                    setOpenDialogForDelete(true);
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <MRT_GlobalFilterTextField table={table} />
                  <MRT_ToggleFullScreenButton table={table} />
                </Stack>
              </Center>
            </Stack>
          );
        }}
        enableExpandAll={false}
        renderDetailPanel={({ row }) => {
          const item = row.original;
          const excludeKeys = columns.map(i => i.accessorKey).concat(["_id", "__v", "pages"]);
          const labelValuePairs: LabelValuePair[] = Object.keys(item)
            .filter(i => !excludeKeys.includes(i))
            .map(i => ({ label: _.startCase(i), value: item[i] }));
          return <DetailPanel data={labelValuePairs} />;
        }}
      />
      <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
        <Add data={data} loading={loading} handleSubmit={handleAdd} onCancel={() => setOpenDialogForAdd(false)} />
      </Dialog>
      <Dialog open={openDialogForEdit} onClose={() => setOpenDialogForEdit(false)} isTransparent>
        {currentItem && <Edit loading={loading} row={currentItem} handleSubmit={handleEdit} onCancel={() => setOpenDialogForEdit(false)} />}
      </Dialog>
      <Dialog open={openDialogForDelete} onClose={() => setOpenDialogForDelete(false)} isTransparent>
        {currentItem && <Delete loading={loading} row={currentItem} handleSubmit={handleDelete} onCancel={() => setOpenDialogForDelete(false)} />}
      </Dialog>
    </Stack>
  );
};

export default Page;
