import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Chip } from "../../../../components";
import { ExpandMore } from "@mui/icons-material";
import { Moment } from "moment";
import DataTable from "./DataTable";
import moment from "moment";
import { useState } from "react";
import { DateFormatBookings } from "../..";
import { DateFormatServer, LooseObject } from "../../../../utils/Types";
import _ from "lodash";

const CalendarView = ({ firstDayOfCurrentWeek, bookings, agreements }: { firstDayOfCurrentWeek: Moment; bookings: LooseObject[]; agreements: LooseObject[] }) => {
  const daysOfThisWeek = Array.from(Array(7).keys()).map(i => moment(firstDayOfCurrentWeek.valueOf()).startOf("isoWeek").add(i, "days"));

  const [expanded, setExpanded] = useState<string | false>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(() => {
  //   const indexOfToday = daysOfThisWeek.map(i => i.valueOf()).indexOf(moment().startOf("day").valueOf());
  //   const indexOfDefaultPanel = indexOfToday > -1 ? indexOfToday : 0;
  //   setExpanded(`panel${indexOfDefaultPanel}`);
  // }, [firstDayOfCurrentWeek]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Stack mt={2}>
      {daysOfThisWeek.map((i, index) => {
        const agreementsOfCurrentDay = agreements.filter(agreement => agreement.agreedDeliveryDate === i.format(DateFormatServer.SHORT));
        const agreementsOfCurrentDayMergedWithBookings = agreementsOfCurrentDay.map(agreement => {
          const booking = bookings.find(b => b._id === agreement.bookingId);
          return booking
            ? {
                ...agreement,
                vendorEmail: booking.vendorEmail,
                vendorFirstName: booking.vendorFirstName,
                vendorLastName: booking.vendorLastName,
                vendorBusinessName: booking.vendorBusinessName,
                status: booking.status,
              }
            : { ...agreement };
        });
        return (
          <Accordion
            disableGutters
            key={`panel${index}`}
            slotProps={{ transition: { unmountOnExit: true } }}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                "&.MuiAccordionSummary-root": {
                  width: isSmallScreen ? 330 : 420,
                  // justifyContent: "space-between",
                },
                // "& .MuiAccordionSummary-content": {
                //   flexGrow: 0, // Prevent the content from expanding
                // },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  marginLeft: 1,
                },
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" width="100%">
                <Typography variant="textsm" fontWeight="semiBold">{`${isSmallScreen ? "" : "Slaughter date: "}${i.format(DateFormatBookings)}`}</Typography>
                <Chip label={_.sumBy(agreementsOfCurrentDay, "agreedQuantity")} />
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: "mybg.secondary", p: 0 }}>
              <DataTable agreements={agreementsOfCurrentDayMergedWithBookings} />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
};

export default CalendarView;
