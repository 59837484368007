import { Chip, ContentWrapper, Hcharts } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/material";
import { getHchartPointFormatExtra } from "../../../../../utils/Helper";
import moment from "moment";

const Section = ({ data, loading, supplierWithFullInfo }: { data: QueryResult; loading: boolean; supplierWithFullInfo: LooseObject }) => {
  const dataToDisplay = data?.rows;
  const xAxis = data.columns?.find(i => i.name === "LMY_PERCENTAGE_OF");
  const yAxis = data.columns?.find(i => i.name === "IMF_PERCENTAGE_OF");

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.SCATTER_PLOT}
        title="xAxisLabel vs yAxisLabel"
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`Supplier: ${supplierWithFullInfo.SUPPLIER_NO} | ${supplierWithFullInfo.fullName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        xAxis={xAxis}
        yAxis={yAxis}
        data={[{ data: dataToDisplay || [], name: "Carcase" }]}
        pointFormatExtra={getHchartPointFormatExtra(data.columns?.filter(i => ["SUPPLIER_NO", "MOB"].includes(i.name)) || [])}
        subCharts={[{ type: ChartType.SCATTER_PLOT }, { type: ChartType.HISTOGRAM, title: "xAxisLabel" }]}
      />
    </ContentWrapper>
  );
};

export default Section;
