import { useCallback, useState } from "react";
import { LooseObject, QueryResultTable } from "../../../../utils/Types";
import _ from "lodash";
import { FORMATTED } from "../../../../utils/Constants";
import { Center, Chip, CopyButton, Dialog, ImageDetails, SmallButton, Spinner, Table } from "../../../../components";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { MRT_GlobalFilterTextField, MRT_Row, MRT_RowData, MRT_ShowHideColumnsButton, MRT_ToggleFullScreenButton } from "material-react-table";
import AllImages from "./AllImages";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { hiddenColumnNames, idKey, imgUrlKey } from "../../../BeefDataAnalysis/Supplier/Dashboard/ImageAnalysis/AllImages";

const Page = ({ row, data, supplierWithFullInfo }: { row: MRT_Row<MRT_RowData>; data: QueryResultTable; supplierWithFullInfo: LooseObject }) => {
  const [tableRows, setTableRows] = useState<MRT_Row<any>[]>();
  const [width, setWidth] = useState();
  const [openForDialogAllImages, setOpenForDialogAllImages] = useState(false);
  const [openForDialogImageDetails, setOpenForDialogImageDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>({});

  const theme = useTheme();

  const stackRef = useCallback((node: any) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const setTableRowsWithDebounce = _.debounce(async (flatRows: MRT_Row<any>[]) => {
    if (flatRows) {
      setTableRows(flatRows.map(i => i.original));
    }
  }, 500);

  return (
    <Stack ref={stackRef}>
      <Stack spacing={3} width={width === undefined ? "auto" : width}>
        {!data?.tableColumns ? (
          <Spinner />
        ) : (
          <Table
            title={`List of images ${row.original["KILL_DATE_" + FORMATTED]}`}
            columns={data?.tableColumns || []}
            data={data?.tableRows || []}
            renderTopToolbar={({ table }) => {
              return (
                <Box sx={{ display: "flex", gap: 1, p: 2, justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="textmd" fontWeight="semiBold">
                    {`List of images ${row.original["KILL_DATE_" + FORMATTED]}`}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <SmallButton title="View All Images" variant="contained" onClick={() => setOpenForDialogAllImages(true)} />
                    <MRT_GlobalFilterTextField table={table} />
                    <Box>
                      <MRT_ShowHideColumnsButton table={table} />
                      <MRT_ToggleFullScreenButton table={table} />
                    </Box>
                  </Box>
                </Box>
              );
            }}
            muiTableBodySx={{
              '& tr:nth-of-type(4n+3):not([data-selected="true"]):not([data-pinned="true"]) > td': { backgroundColor: `${theme.palette.background.default} !important` },
            }}
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                setCurrentItem(row.original);
                setOpenForDialogImageDetails(true);
              },
              sx: {
                cursor: "pointer",
              },
            })}
            exposeRowModel={faltRows => setTableRowsWithDebounce(faltRows)}
            enableRowNumbers
            initialState={{ sorting: [{ id: "KILL_DATE_FORMATTED", desc: true }] }}
          />
        )}
        <Dialog open={openForDialogAllImages} onClose={() => setOpenForDialogAllImages(false)} isTransparent fullScreen>
          <AllImages row={row} data={data} supplierWithFullInfo={supplierWithFullInfo} tableRows={tableRows || []} onCancel={() => setOpenForDialogAllImages(false)} />
        </Dialog>
        <Dialog open={openForDialogImageDetails} onClose={() => setOpenForDialogImageDetails(false)} isTransparent>
          <ImageDetails
            initialItem={currentItem}
            columns={data?.tableColumns || []}
            idKey={idKey}
            imgUrlKey={imgUrlKey}
            rows={tableRows || []}
            hiddenColumnNames={hiddenColumnNames}
            header={image => (
              <Stack>
                <Grid container spacing={1}>
                  <Grid>
                    <Chip label={image?.KILL_DATE_FORMATTED} />
                  </Grid>
                  <Grid>
                    <Center direction="row" spacing={1}>
                      <Chip label={image?.[idKey]} />
                      <CopyButton text={image?.[idKey]} color={theme.palette.text.secondary} size={18} sx={{ width: 23, height: 23, pt: 2 }} title="Copy Barcode" />
                    </Center>
                  </Grid>
                </Grid>
              </Stack>
            )}
            onCancel={() => setOpenForDialogImageDetails(false)}
          />
        </Dialog>
      </Stack>
    </Stack>
  );
};

export default Page;
