import moment from "moment";
import { Chip, ContentWrapper, Hcharts } from "../../../../components";
import { ChartType, DateFormatServer, LooseObject, QueryResult } from "../../../../utils/Types";
import _ from "lodash";
import { EXCLUDE_COLUMNS } from "./HistogramByBrand";

const Section = ({ data, loading }: { data: QueryResult; loading: boolean }) => {
  const optionsOfYAxis = data.columns?.filter(i => !EXCLUDE_COLUMNS.includes(i.name)).filter(i => i.type === "number");

  const dataToDisplay = data?.rows;

  const allBrands = _.uniq(dataToDisplay?.map(i => i.BRAND)).sort();

  const categories = _.uniq(
    (dataToDisplay || [])
      .map(i => moment(i.BHEAD_DATE, DateFormatServer.SHORT).startOf("month").add(2, "days")) // has to add extra days to make the x axis show correct month
      .sort((a, b) => a.valueOf() - b.valueOf())
      .map(i => i.format("MMM YYYY"))
  );

  const getTraceData = (brand: string) => {
    const traceData: LooseObject[] = [];
    const dataOfThisBrand: LooseObject[] = dataToDisplay?.filter(i => i.BRAND === brand) || [];

    categories.forEach(month => {
      const dataOfThisMonth = dataOfThisBrand.filter(i => moment(i.BHEAD_DATE, DateFormatServer.SHORT).startOf("month").add(2, "days").format("MMM YYYY") === month);
      traceData.push(dataOfThisMonth);
    });

    return traceData;
  };

  return (
    <ContentWrapper>
      {categories && (
        <Hcharts
          loading={loading}
          type={ChartType.BOX}
          title="yAxisLabel By Brand and Month"
          titleExtra={<Chip label="Carcase Based" color="info" size="small" />}
          xTitle="Month"
          xData={categories}
          axisSwitch={{
            optionsOfYAxis,
          }}
          data={allBrands.map(o => ({
            data: getTraceData(o) || [],
            name: o,
          }))}
        />
      )}
    </ContentWrapper>
  );
};

export default Section;
