import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { LooseObject } from "../../utils/Types";
import { ContentWrapper, DialogWrapper, LabelValuePairs, NoImagePlaceholder, SvgIcon } from "../../components";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ReactNode, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";

const ImageDetails = ({
  initialItem,
  columns,
  hiddenColumnNames,
  idKey,
  imgUrlKey,
  rows,
  header,
  onCancel,
}: {
  initialItem: LooseObject;
  columns: MRT_ColumnDef<MRT_RowData, any>[];
  hiddenColumnNames: string[];
  rows: MRT_RowData[];
  idKey: string;
  imgUrlKey: string;
  header?: (currentItem: LooseObject) => ReactNode;
  onCancel: (values: any) => void;
}) => {
  const traitsInterested = columns.filter(i => i.accessorKey && !hiddenColumnNames.includes(i.accessorKey));
  const theme = useTheme();

  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    const initalIndex = rows.map(i => i[idKey]).indexOf(initialItem?.[idKey]);

    if (initalIndex !== undefined && initalIndex >= 0) {
      setCurrentIndex(initalIndex);
    }
  }, [initialItem, rows]);

  useHotkeys(
    "ArrowLeft",
    () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    },
    [currentIndex]
  );

  useHotkeys(
    "ArrowRight",
    () => {
      if (currentIndex < rows.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    },
    [currentIndex]
  );

  const currentItem = rows[currentIndex];

  return (
    <DialogWrapper onCancel={onCancel}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="textxl" fontWeight="semiBold">
          Image details
        </Typography>
        <Stack direction="row" spacing={1}>
          <Tooltip title="Prev Image">
            <Box>
              <IconButton
                onClick={() => {
                  if (currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
                disabled={currentIndex <= 0}
              >
                <SvgIcon name="CheveronLeft" color={currentIndex <= 0 ? theme.palette.myborder.disabled : undefined} />
              </IconButton>
            </Box>
          </Tooltip>
          <Tooltip title="Next Image">
            <Box>
              <IconButton
                onClick={() => {
                  if (currentIndex < rows.length - 1) {
                    setCurrentIndex(currentIndex + 1);
                  }
                }}
                disabled={currentIndex >= rows.length - 1}
              >
                <SvgIcon name="CheveronRight" color={currentIndex >= rows.length - 1 ? theme.palette.myborder.disabled : undefined} />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack spacing={2} sx={{ bgcolor: "mybg.secondary", border: 1, borderColor: "myborder.secondary", borderRadius: 1, p: 2 }}>
        {header?.(currentItem)}
        <Stack>
          <Grid container spacing={2}>
            <Grid>
              <Box>
                {currentItem?.[imgUrlKey] ? (
                  <img
                    onDragStart={(e: any) => e.preventDefault()} // prevent image drag
                    src={"https://" + currentItem?.[imgUrlKey] + process.env.REACT_APP_IMAGE_SAS_TOKEN}
                    alt="item"
                    loading="lazy"
                    width="100%"
                    height="100%"
                    object-fit="cover"
                    style={{ borderRadius: "8px", minHeight: "75vh", maxHeight: "76vh" }}
                  />
                ) : (
                  <NoImagePlaceholder bgcolor="rgba(0, 0, 0, 0.3)" minHeight={500} minWidth={300} />
                )}
              </Box>
            </Grid>
            <Grid sm={6} md={2} width="100%">
              <ContentWrapper minWidth={280} width="100%">
                <LabelValuePairs data={traitsInterested.map(i => ({ label: i.header, value: i.accessorKey ? currentItem?.[i.accessorKey] : "" }))} />
              </ContentWrapper>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </DialogWrapper>
  );
};

export default ImageDetails;
