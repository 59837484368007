import { Stack } from "@mui/material";
import VendorType from "../../Bookings/CalendarView/TableCalendar/VendorType";
import { StatusChip } from "../../StatusChips";
import { Table } from "../../../../components";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";

const showBooleanValue = (value: boolean) => (value === true ? "Yes" : value === false ? "No" : value);

const DataTable = ({ agreements }: { agreements: MRT_RowData[] }) => {
  const columns: MRT_ColumnDef<MRT_RowData, any>[] = [
    {
      accessorKey: "vendor",
      header: "Vendor",
      accessorFn: (row: any) => `${row.vendorFirstName} ${row.vendorLastName}\n${row.vendorBusinessName}`,
      Cell: ({ renderedCellValue }) => <Stack style={{ whiteSpace: "pre-line" }}>{renderedCellValue}</Stack>,
      maxSize: 120,
    },
    {
      accessorKey: "isAgent",
      header: "Type",
      accessorFn: row => (row.isAgent ? "Agent" : "Producer"),
      Cell: ({ renderedCellValue, row }) => <VendorType type={row.original.isAgent ? "Agent" : "Producer"} label={renderedCellValue} />,
      maxSize: 80,
    },
    {
      accessorKey: "producer",
      header: "Producer",
      accessorFn: (row: any) => `${row.producerFirstName} ${row.producerLastName}\n${row.producerBusinessName}`,
      Cell: ({ renderedCellValue }) => <Stack style={{ whiteSpace: "pre-line" }}>{renderedCellValue}</Stack>,
      maxSize: 120,
    },
    { accessorKey: "pic", header: "PIC", maxSize: 100 },
    { accessorKey: "agreedQuantity", header: "No. Head", maxSize: 80 },
    { accessorKey: "breed", header: "Breed", maxSize: 100 },
    { accessorKey: "estimatedAverageWeightKg", header: "Estimated Avg Weight (Kg)", maxSize: 110 },
    {
      accessorKey: "status",
      header: "Status",
      maxSize: 120,
      Cell: ({ renderedCellValue, row }) => <StatusChip label={renderedCellValue} textLabel={row.original.STATUS} sx={{ fontSize: "0.75rem" }} />,
    },
    { accessorKey: "woolLengthMm", header: "Wool Length (mm)", maxSize: 90 },
    { accessorKey: "finishingNutrition", header: "Finishing Nutrition", maxSize: 140 },
    { accessorKey: "ageMonths", header: "Age (months)", maxSize: 70 },
    { accessorKey: "bloodlines", header: "Bloodlines", maxSize: 100 },
    { accessorKey: "isMuelsed", header: "Muelsed", accessorFn: row => showBooleanValue(row.isMuelsed), maxSize: 70 },
    { accessorKey: "isMsaAccredited", header: "MSA Accredited", accessorFn: row => showBooleanValue(row.isMsaAccredited), maxSize: 80 },
    { accessorKey: "isComplyWithLPA", header: "Comply with LPA", accessorFn: row => showBooleanValue(row.isComplyWithLPA), maxSize: 80 },
    { accessorKey: "isGrassFed", header: "Grass Fed", accessorFn: row => showBooleanValue(row.isGrassFed), maxSize: 50 },
    { accessorKey: "isFreeRange", header: "Free Range", accessorFn: row => showBooleanValue(row.isFreeRange), maxSize: 60 },
    { accessorKey: "isAntibioticFree", header: "Antibiotic Free", accessorFn: row => showBooleanValue(row.isAntibioticFree), maxSize: 80 },
    {
      accessorKey: "isHormoneAndGrowthPromotantFree",
      header: "Hormone and Growth Promotant Free",
      accessorFn: row => showBooleanValue(row.isHormoneAndGrowthPromotantFree),
      maxSize: 150,
    },
    { accessorKey: "nvdNumber", header: "NVD Number", maxSize: 100 },
  ];

  const rows = agreements;

  return (
    <Stack
      width="100%"
      sx={{
        // maxWidth: { xs: `calc(100vw - ${DEFAULT_SPACING_MOBILE * 8 * 4}px)`, md: `calc(100vw - ${SIDEBAR_WIDTH + DEFAULT_SPACING * 8 * 4}px)` },
        maxWidth: { xs: `calc(100vw - 40px)`, md: `calc(100vw - 144px)` },
      }}
    >
      <Table
        columns={columns}
        data={rows}
        enableBottomToolbar={false}
        enablePagination={false}
        enableSorting={false}
        muiTableContainerProps={{ sx: { maxHeight: "430px" } }}
        layoutMode="grid"
        muiTableBodySx={{ '& td[data-pinned="true"]:before': { boxShadow: "none" } }}
        muiTableHeadCellSx={{
          fontSize: "0.75rem",
          borderWidth: 0.5,
          lineHeight: 1.5,
          textOverflow: "unset",
          "& .Mui-TableHeadCell-Content-Wrapper": {
            textOverflow: "unset",
            whiteSpace: "normal",
          },
        }}
        muiTableBodyCellSx={{ fontSize: "0.75rem", borderWidth: 0.5, lineHeight: 1.5 }}
        muiTableHeadCellProps={{ align: "center" }}
        muiTableBodyCellProps={{ align: "center" }}
        positionGlobalFilter="left"
      />
    </Stack>
  );
};

export default DataTable;
