import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Orgnisations from "./Orgnisations";
import Users from "./Users";
import Roles from "./Roles";
import Permissions from "./Permissions";
import ActionLog from "./ActionLog";

const Page = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Stack spacing={3}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Organisations" />
        <Tab label="Users" />
        <Tab label="Roles" />
        <Tab label="Permissions" />
        <Tab label="Action Log" />
      </Tabs>
      {tab === 0 ? <Orgnisations /> : tab === 1 ? <Users /> : tab === 2 ? <Roles /> : tab === 3 ? <Permissions /> : <ActionLog />}
    </Stack>
  );
};

export default Page;
