import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { DateFormatServer, LooseObject, PkgName, QueryResultColumn, QueryResultTable, QueryType } from "../../../utils/Types";
import { selectUser } from "../../../redux/reducers/userSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectOrgs } from "../../../redux/reducers/orgsSlice";
import _ from "lodash";
import { getCurrentLocation, getCurrentOrg, getDataFromDataPool, getDateFormat, getOrgFromOrgIdString, getRandomString, prepareQueryResultForTable } from "../../../utils/Helper";
import { Stack } from "@mui/material";
import { selectDataPool } from "../../../redux/reducers/dataPoolSlice";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
// import Tiles from "./Tiles";
import ProbeStatus, { ProbeStatusChip, getOverallStatus } from "./ProbeStatus";
import DailyReports from "./DailyReports";
import { Dialog, Link, NoDataView, Spinner } from "../../../components";
import DeviceDetails from "../Device/Detail";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<QueryResultTable>();
  const [openForDialogDeviceDetails, setOpenForDialogDeviceDetails] = useState(false);
  const [currentItem, setCurrentItem] = useState<LooseObject>();

  const user = useAppSelector(selectUser);
  const orgs = useAppSelector(selectOrgs);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dataPool = useAppSelector(selectDataPool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchData();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchData.cancel();
  }, []);

  const fetchData = _.debounce(async () => {
    setLoading(true);
    await getDataFromDataPool({
      dataPool,
      params: {
        id: getCurrentOrg(orgs)?.idString,
        type: QueryType.ORDINARY_QUERY,
        view: `MEQPROBE_EOD_RAW('${moment().add(-3, "months").format(DateFormatServer.SHORT)}', '${moment().format(DateFormatServer.SHORT)}', '${getOrgFromOrgIdString(
          getCurrentOrg(orgs)?.idString!
        )}', '${getCurrentLocation(orgs)}')`,
        isFunction: true,
        pkg: PkgName.MEQ_PROBE_LAMB,
        columnsNotInterested: ["LOCATION"],
      },
      token: user.access_token,
      dispatch,
      zitadelOrg,
      snackbar,
    }).then(dataFromDataPool => {
      const columns = dataFromDataPool?.columns
        ?.filter(i => !["END_OF_PRODUCTION_CHECKS", "SYSTEM_CHECKS", "USER_PERFORMANCE"].includes(i.name))
        .concat([{ name: "ATTENTION_REQ", label: "Attention Req", type: "string" } as QueryResultColumn]);
      const rows = dataFromDataPool?.rows?.map(i => ({ ...i, ATTENTION_REQ: getOverallStatus(i.END_OF_PRODUCTION_CHECKS.MATERIAL_CALIBRATION.NEEDLE_STATUS) }));

      const queryResultFromDataPool = prepareQueryResultForTable({ data: { ...dataFromDataPool, columns, rows }, org: getCurrentOrg(orgs) });
      setData(queryResultFromDataPool);
    });
    setLoading(false);
  }, 500);

  if (loading || !data) {
    return <Spinner />;
  }

  const sortedRowsByDate = _.orderBy(data?.tableRows || [], o => moment(o.EOD_DATE, DateFormatServer.SHORT), "desc");

  const groupedDailyReportsByProbe: LooseObject = _.groupBy(sortedRowsByDate, "CHAIN_NUMBER");

  const probeStatus: LooseObject[] = _.orderBy(
    Object.keys(groupedDailyReportsByProbe).map(k => ({ id: k, ...groupedDailyReportsByProbe[k] })),
    "id",
    "asc"
  );

  if (data && (data.numRows || 0) <= 0) {
    return <NoDataView />;
  }

  const tableColumns: MRT_ColumnDef<MRT_RowData, any>[] = data?.tableColumns
    .filter(i => i.accessorKey !== "CHAIN_NUMBER")
    .map(i => {
      switch (i.accessorKey) {
        case "ATTENTION_REQ":
          return { ...i, Cell: ({ cell }: { cell: any }) => <ProbeStatusChip status={cell.row.original.ATTENTION_REQ} /> };
        case "EOD_DATE_FORMATTED":
          return {
            ...i,
            Cell: ({ cell }: { cell: any }) => (
              <Link
                onClick={() => {
                  setCurrentItem(cell.row.original);
                  setOpenForDialogDeviceDetails(true);
                }}
              >
                {cell.row.original.EOD_DATE_FORMATTED}
              </Link>
            ),
          };
        case "PRODUCTION_STARTED_FORMATTED":
          return {
            ...i,
            accessorFn: row => moment(row.PRODUCTION_STARTED_FORMATTED, getDateFormat(getCurrentOrg(orgs), "default")).format(getDateFormat(getCurrentOrg(orgs), "day_and_time")),
          };
        case "PRODUCTION_ENDED_FORMATTED":
          return {
            ...i,
            accessorFn: row => moment(row.PRODUCTION_ENDED_FORMATTED, getDateFormat(getCurrentOrg(orgs), "default")).format(getDateFormat(getCurrentOrg(orgs), "day_and_time")),
          };
        default:
          return i;
      }
    });

  return (
    <Grid container direction="column" spacing={2}>
      {/* <Grid>
        <Tiles />
      </Grid> */}
      <Grid>
        <Grid container spacing={2}>
          <Grid md>
            <Stack spacing={2}>
              {probeStatus.map(i => (
                <ProbeStatus
                  key={`probe_${i.id}_${getRandomString()}`}
                  title={`Probe ${i.id}`}
                  data={i}
                  handleViewReport={v => {
                    setCurrentItem(v);
                    setOpenForDialogDeviceDetails(true);
                  }}
                />
              ))}
            </Stack>
          </Grid>
          <Grid md>
            <Grid>
              <DailyReports
                title="Daily Reports"
                data={groupedDailyReportsByProbe}
                handleViewReport={v => {
                  setCurrentItem(v);
                  setOpenForDialogDeviceDetails(true);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {data && groupedDailyReportsByProbe && currentItem && (
          <Dialog open={openForDialogDeviceDetails} onClose={() => setOpenForDialogDeviceDetails(false)} isTransparent>
            <DeviceDetails
              data={{ ...data, tableColumns: tableColumns.map(i => (i.accessorKey === "EOD_DATE_FORMATTED" ? { ...i, Cell: undefined } : { ...i })) }}
              groupedDailyReportsByProbe={groupedDailyReportsByProbe}
              defaultProbe={currentItem.CHAIN_NUMBER?.toString()}
              onCancel={() => setOpenForDialogDeviceDetails(false)}
              defaultDate={currentItem.EOD_DATE_FORMATTED}
              orgs={orgs}
            />
          </Dialog>
        )}
      </Grid>
    </Grid>
  );
};

export default Page;
