import { Options, SeriesOptionsType, color } from "highcharts";
import { HchartsProps, LooseObject, Trace } from "../../../utils/Types";
import commonOptions from "./CommonOptions";
import { avoidJsFloatingPointPrecisionIssue, sampleData } from "../../../utils/Helper";

const getOffsetFactor = (seriesCount: number): number => {
  if (seriesCount === 2) return 0.3;
  if (seriesCount === 3) return 0.2;
  if (seriesCount === 4) return 0.15;
  return 0.1; // Default offset factor for other series counts
};

const calculateBoxPlotStats = (
  data: number[][],
  tracesIndex: number,
  seriesCount: number
): { boxData: [number, number, number, number, number][]; outliers: { x: number; y: number }[] } => {
  const boxData: [number, number, number, number, number][] = [];
  const outliers: { x: number; y: number }[] = [];

  const calculateStats = (arr: number[], index: number) => {
    arr.sort((a, b) => a - b);
    const low = arr[0];
    const high = arr[arr.length - 1];

    const median = (array: number[]) => {
      const mid = Math.floor(array.length / 2);
      return avoidJsFloatingPointPrecisionIssue(array.length % 2 !== 0 ? array[mid] : (array[mid - 1] + array[mid]) / 2);
    };

    const medianValue = median(arr);
    const q1Value = median(arr.slice(0, Math.floor(arr.length / 2)));
    const q3Value = median(arr.slice(Math.ceil(arr.length / 2)));
    const iqr = q3Value - q1Value;

    // Calculate low and high whiskers
    const lowWhisker = avoidJsFloatingPointPrecisionIssue(Math.max(low, q1Value - 1.5 * iqr));
    const highWhisker = avoidJsFloatingPointPrecisionIssue(Math.min(high, q3Value + 1.5 * iqr));

    // Calculate outliers with offset
    const offsetFactor = getOffsetFactor(seriesCount);
    const offset = (tracesIndex - (seriesCount - 1) / 2) * offsetFactor; // Calculate offset based on the series index and total series count
    arr.forEach(value => {
      if (value < lowWhisker || value > highWhisker) {
        outliers.push({ x: index + offset, y: value });
      }
    });

    boxData.push([lowWhisker, q1Value, medianValue, q3Value, highWhisker]);
  };

  data.forEach((set, index) => calculateStats(set, index));

  return { boxData, outliers };
};

export const boxPlotChartOptions = (props: HchartsProps) => {
  const { data, xData, xTitle, yTitle, xAxis, yAxis, traceColors, height, zoomingType, hideLegend, subCharts } = props;

  const traces: Trace[] = data.map(i => ({ ...i }));
  const seriesCount = traces.length;

  const getSeries = () => {
    let series: SeriesOptionsType[] = [];
    traces.forEach((i, index) => {
      const stats = calculateBoxPlotStats(
        sampleData(i.data).map(point => (yAxis?.name ? point.map((o: LooseObject) => o[yAxis.name]) : null)),
        index,
        seriesCount
      );
      const traceColor = traceColors?.[index] || "#000000";
      const fillColor = color(traceColor).brighten(0.15).get(); // Make the color lighter

      series = series.concat([
        {
          name: i.name,
          type: "boxplot",
          data: stats.boxData,
          color: traceColors?.[index],
          fillColor,
        },
        {
          name: i.name + " Outliers",
          type: "scatter",
          data: stats.outliers,
          showInLegend: false,
          color: traceColors?.[index],
          tooltip: { pointFormat: `${yAxis?.label || ""}: {point.y}` },
          linkedTo: i.name, // Link outliers to the respective boxplot series
          marker: { radius: xData && xData.length * traces.length > 6 ? 2 : 3 },
        },
      ] as SeriesOptionsType[]);
    });
    return series;
  };

  const options: Options = {
    ...commonOptions(props),
    chart: {
      type: "boxplot",
      height: height || 500,
      zooming: { type: zoomingType || subCharts ? "x" : undefined }, // set type to undefined to disable zooming
    },
    xAxis: {
      categories: xData,
      title: { text: xTitle || xAxis?.label || "" },
    },
    yAxis: {
      title: { text: yTitle || yAxis?.label || "" },
    },
    legend: {
      enabled: hideLegend !== undefined ? hideLegend : traces.length !== 1,
    },
    series: getSeries(),
  };

  return options;
};
