import { createContext } from "react";
import { Message } from "./Types";

export const ThemeModeContext = createContext({
  toggleThemeMode: () => {},
});

export const SnackbarContext = createContext({
  open: (m: Message) => {},
  close: () => {},
});

// for custom theme
export const ClientContext = createContext({
  set: (client: string) => {},
});
