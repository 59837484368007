import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CircularProgressIndicator, Tile, TileContentText } from "../../../../components";
import { LooseObject } from "../../../../utils/Types";
import { isNotEmpty } from "../../../../utils/Helper";

const Tiles = ({ data }: { data: LooseObject }) => {
  return isNotEmpty(data) ? (
    <Grid container spacing={2}>
      <Grid xs={12} md>
        <Tile
          title="Avg HSCW Kg"
          content={
            <Stack spacing={1}>
              <TileContentText text={isNotEmpty(data.AVG_HSCW) ? `${data.AVG_HSCW}kg` : "N/A"} />
            </Stack>
          }
        />
      </Grid>

      <Grid xs={12} md>
        <Tile
          title="Avg Marbling"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(data.AVG_MARBLING) ? data.AVG_MARBLING : "N/A"} />
              <CircularProgressIndicator value={data.AVG_MARBLING} maxValue={9} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg Marbling 2+"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(data.MARBLING_2_PLUS_PERCENTAGE_OF) ? `${data.MARBLING_2_PLUS_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={data.MARBLING_2_PLUS_PERCENTAGE_OF} maxValue={100} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
      <Grid xs={12} md>
        <Tile
          title="Avg Marbling 4+"
          content={
            <Stack spacing={2} direction="row">
              <TileContentText text={isNotEmpty(data.MARBLING_4_PLUS_PERCENTAGE_OF) ? `${data.MARBLING_4_PLUS_PERCENTAGE_OF}%` : "N/A"} />
              <CircularProgressIndicator value={data.MARBLING_4_PLUS_PERCENTAGE_OF} maxValue={100} size={40} borderWidth={10} />
            </Stack>
          }
        />
      </Grid>
    </Grid>
  ) : undefined;
};

export default Tiles;
