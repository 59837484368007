import { ExpandMore } from "@mui/icons-material";
import { Typography, Popover, MenuList, MenuItem, ListItemText } from "@mui/material";
import { useState } from "react";
import RoundIconButton from "../Button/RoundIconButton";
import { Org } from "../../utils/Types";
import Chip from "../Chip";
import Center from "../Center";

const SwichView = ({ isAdminViewNow, currentOrg, toggleIsAdminView }: { isAdminViewNow: boolean; currentOrg?: Org; toggleIsAdminView: () => void }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const chipLabelColor = isAdminViewNow ? "primary" : "error";
  const chipBgColor = isAdminViewNow ? "primary.50" : "utility.warning.50";
  const chipBorderColor = isAdminViewNow ? "primary.200" : "utility.error.200";
  const expandMoreIcon = isAdminViewNow ? "primary.main" : "error.main";

  return (
    <Center direction="row">
      <Chip
        label={
          <Center direction="row" spacing={0.5}>
            <Typography variant="textxs">{`${isAdminViewNow ? "Admin" : currentOrg?.idString === "GMP" ? "Producer" : "Supplier"} View`}</Typography>
            <RoundIconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ width: 16, height: 16 }}>
              <ExpandMore sx={{ color: expandMoreIcon }} />
            </RoundIconButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ mt: 1 }}
            >
              <MenuList dense sx={{ minWidth: 200 }}>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    toggleIsAdminView();
                  }}
                >
                  <ListItemText>
                    <Typography variant="textxs" fontWeight="semiBold">
                      {`Switch to ${!isAdminViewNow ? "Admin" : currentOrg?.idString === "GMP" ? "Producer" : "Supplier"}  View`}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </MenuList>
            </Popover>
          </Center>
        }
        color={chipLabelColor}
        size="small"
        sx={{ color: chipLabelColor, bgcolor: chipBgColor, borderColor: chipBorderColor }}
      />
    </Center>
  );
};

export default SwichView;
