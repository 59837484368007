import { Stack, Tooltip, Typography } from "@mui/material";
import { DateFormatServer, GmpBookingStatus, LooseObject } from "../../../../../utils/Types";
import { Center } from "../../../../../components";
import { sortArrayByAnotherArray } from "../../../../../utils/Helper";
import moment from "moment";
import { DateFormatBookings } from "../../..";

const Stripe = ({ text, status }: { text: string; status?: GmpBookingStatus }) => {
  let color = undefined;
  let bgcolor = undefined;
  switch (status) {
    case GmpBookingStatus.NotApplicable:
      color = "utility.error.700";
      bgcolor = "utility.error.50";
      break;
    case GmpBookingStatus.Scheduled:
      color = "utility.warning.700";
      bgcolor = "utility.warning.50";
      break;
    case GmpBookingStatus.Assigned:
      color = "utility.success.700";
      bgcolor = "utility.success.50";
      break;
    case GmpBookingStatus.Completed:
      color = "mytext.primaryOnBrand";
      bgcolor = "utility.success.500";
      break;
  }
  return (
    <Stack color={color} bgcolor={bgcolor} height="100%" justifyContent="center">
      <Typography variant="textxs">{text}</Typography>
    </Stack>
  );
};

const FilledCell = ({ cell, bookings, handleClick }: { cell: any; bookings: LooseObject[]; handleClick: (v: any) => void }) => {
  return (
    <Center height="100%" justifyContent="center" spacing="1px">
      {sortArrayByAnotherArray(bookings, "status", [GmpBookingStatus.NotApplicable, GmpBookingStatus.Scheduled, GmpBookingStatus.Assigned, GmpBookingStatus.Completed]).map(
        booking => (
          <Stack key={`lamb-bookings-cell-stripe-${cell.column.id}-${booking.createdAt.toString()}`} width="100%" height="100%">
            <Tooltip title={`${moment(booking.bookingDate, DateFormatServer.SHORT).format(DateFormatBookings)} - ${booking.status} - Head: ${booking.quantity}`}>
              <Stack
                width="100%"
                height="100%"
                onClick={() => {
                  const cellWithSupplyAgreement = { ...cell };
                  cellWithSupplyAgreement.row.original = {
                    ...cellWithSupplyAgreement.row.original,
                    bookingId: booking._id,
                    bookingDate: booking.bookingDate,
                    quantity: booking.quantity,
                    status: booking.status,
                    bookedByFirstName: booking.bookedByFirstName,
                    bookedByLastName: booking.bookedByLastName,
                  };
                  handleClick(cellWithSupplyAgreement);
                }}
              >
                <Stripe text={booking.quantity} status={booking.status} />
              </Stack>
            </Tooltip>
          </Stack>
        )
      )}
    </Center>
  );
};

export default FilledCell;
