import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Users from "./Users";
import GmpPics from "./GmpPics";
import { getCurrentOrg, getOrgFromOrgIdString } from "../../utils/Helper";
import { useAppSelector } from "../../redux/hooks";
import { selectOrgs } from "../../redux/reducers/orgsSlice";

const Page = () => {
  const [tab, setTab] = useState(0);

  const orgs = useAppSelector(selectOrgs);

  const currentOrg = getCurrentOrg(orgs);

  const orgShort = getOrgFromOrgIdString(currentOrg?.idString || "");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Stack spacing={3}>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="Users" />
        {orgShort === "GMP" ? <Tab label="PICs" /> : undefined}
      </Tabs>
      {tab === 0 ? <Users /> : tab === 1 && orgShort === "GMP" ? <GmpPics /> : undefined}
    </Stack>
  );
};

export default Page;
