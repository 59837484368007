import { Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Dashboard from "./Dashboard";
import { Center, Dialog, SmallButton } from "../../../components";
import { InfoOutlined } from "@mui/icons-material";
import Glossary from "./Glossary";

const Page = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const [openDialogForGlossary, setOpenDialogForGlossary] = useState<boolean>(false);

  return (
    <Stack spacing={3} flex={1}>
      <Stack direction="row" justifyContent="space-between">
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Producer Dashboard" />
        </Tabs>
        <Center direction="row" spacing={2}>
          <SmallButton
            variant="contained"
            title="Glossary"
            startIcon={<InfoOutlined />}
            sx={{
              py: 0.9,
              height: 30,
              bgcolor: "mybuttonbg.secondary",
              color: "mybuttonfg.secondary",
              borderColor: "myborder.secondary",
              borderWidth: "1px",
              borderStyle: "solid",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "mybuttonbg.secondaryHover",
                boxShadow: "none",
              },
            }}
            onClick={() => setOpenDialogForGlossary(true)}
          />
        </Center>
      </Stack>
      <Dashboard />
      <Dialog open={openDialogForGlossary} onClose={() => setOpenDialogForGlossary(false)} isTransparent>
        <Glossary onCancel={() => setOpenDialogForGlossary(false)} />
      </Dialog>
    </Stack>
  );
};

export default Page;
