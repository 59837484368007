import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { HchartsProps } from "../../../utils/Types";
import DialogWrapper from "../../DialogWrapper";
import ContentWrapper from "../../ContentWrapper";
import Hcharts from "../Charts";

const SubChartDialog = ({ arrayOfSubChartOptions, onCancel }: { arrayOfSubChartOptions: HchartsProps[]; onCancel: () => void }) => {
  return (
    <DialogWrapper onCancel={onCancel} minWidth={{ sm: "80vw", md: "60vw", lg: "50vw" }}>
      <Grid container direction="column" spacing={2} width="100%">
        {arrayOfSubChartOptions.map((chartOptions, index) => (
          <Grid xs={12} key={`subchart_${chartOptions.type}_${index}}`}>
            <ContentWrapper>
              <Hcharts {...chartOptions} />
            </ContentWrapper>
          </Grid>
        ))}
      </Grid>
    </DialogWrapper>
  );
};

export default SubChartDialog;
