import { MRT_RowData } from "material-react-table";
import Table, { TableProps } from ".";

const MinimalTable = <TData extends MRT_RowData>({ ...rest }: TableProps<TData>) => {
  return (
    <Table
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableSorting={false}
      muiTablePaperSx={{ border: 0 }}
      initialState={{ showGlobalFilter: false }}
      enablePagination={false}
      {...rest}
    />
  );
};

export default MinimalTable;
