import { Typography, SvgIcon as MuiSvgIcon, useTheme, SxProps, Theme } from "@mui/material";
import { ReactComponent as MeqLogo } from "../assets/icons/meq-logo.svg";
import { ReactComponent as CheveronRight } from "../assets/icons/chevron-right.svg";
import { ReactComponent as CheveronLeft } from "../assets/icons/chevron-left.svg";
import { ReactComponent as CheveronUp } from "../assets/icons/chevron-up.svg";
import { ReactComponent as CheveronDown } from "../assets/icons/chevron-down.svg";
import { ReactComponent as CheveronRightDouble } from "../assets/icons/chevron-right-double.svg";
import { ReactComponent as CheveronLeftDouble } from "../assets/icons/chevron-left-double.svg";
import { ReactComponent as CheveronUpDouble } from "../assets/icons/chevron-up-double.svg";
import { ReactComponent as CheveronDownDouble } from "../assets/icons/chevron-down-double.svg";
import { ReactComponent as CheveronSelectorHorizontal } from "../assets/icons/chevron-selector-horizontal.svg";
import { ReactComponent as CheveronSelectorVertical } from "../assets/icons/chevron-selector-vertical.svg";
import { ReactComponent as Logout } from "../assets/icons/log-out.svg";
import { ReactComponent as Key } from "../assets/icons/key.svg";
import { ReactComponent as Mail } from "../assets/icons/mail.svg";
import { ReactComponent as LockKeyHole } from "../assets/icons/lock-keyhole-square.svg";
import { ReactComponent as ClipboardMinus } from "../assets/icons/clipboard-minus.svg";
import { ReactComponent as Menu } from "../assets/icons/menu.svg";
import { ReactComponent as Settings } from "../assets/icons/settings.svg";
import { ReactComponent as Users } from "../assets/icons/users.svg";
import { ReactComponent as MoreVert } from "../assets/icons/more-vert.svg";
import { ReactComponent as Column } from "../assets/icons/column.svg";
import { ReactComponent as FullScreen } from "../assets/icons/full-screen.svg";
import { ReactComponent as FullScreenExit } from "../assets/icons/full-screen-exit.svg";
import { ReactComponent as CheckCircle } from "../assets/icons/check-circle.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as OutlinedCheckCircle } from "../assets/icons/outlined-check-circle.svg";
import { ReactComponent as OutlinedExclamationMarkCircle } from "../assets/icons/outlined-exclamation-mark-circle.svg";
import { ReactComponent as Edit } from "../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../assets/icons/trash.svg";
import { ReactComponent as BarChartSquare } from "../assets/icons/bar-chart-square.svg";
import { ReactComponent as LocationMark } from "../assets/icons/location-mark.svg";
import { ReactComponent as MarkerPin } from "../assets/icons/marker-pin.svg";
import { ReactComponent as Refresh } from "../assets/icons/refresh.svg";
import { ReactComponent as Tool } from "../assets/icons/tool.svg";
import { ReactComponent as ChartBreakoutSquare } from "../assets/icons/chart-breakout-square.svg";
import { ReactComponent as Camera } from "../assets/icons/camera.svg";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { ReactComponent as SwitchVertical } from "../assets/icons/switch-vertical.svg";
import { ReactComponent as ArrowNarrowUp } from "../assets/icons/arrow-narrow-up.svg";
import { ReactComponent as ArrowNarrowDown } from "../assets/icons/arrow-narrow-down.svg";
import { ReactComponent as SearchSm } from "../assets/icons/search-sm.svg";
import { ReactComponent as AlertCircle } from "../assets/icons/alert-circle.svg";
import { ReactComponent as SwitchHorizontal } from "../assets/icons/switch-horizontal.svg";

export type SvgIconProps = { name: string; size?: number | string; fill?: string; color?: string; strokeWidth?: number | string; sx?: SxProps<Theme> };

const SvgIcon = ({ name, size, fill, color, strokeWidth, sx }: SvgIconProps) => {
  const theme = useTheme();

  let Icon: any;
  switch (name) {
    case "MeqLogo":
      Icon = MeqLogo;
      break;

    case "CheveronRight":
      Icon = CheveronRight;
      break;

    case "CheveronLeft":
      Icon = CheveronLeft;
      break;

    case "CheveronUp":
      Icon = CheveronUp;
      break;

    case "CheveronDown":
      Icon = CheveronDown;
      break;

    case "CheveronRightDouble":
      Icon = CheveronRightDouble;
      break;

    case "CheveronLeftDouble":
      Icon = CheveronLeftDouble;
      break;

    case "CheveronUpDouble":
      Icon = CheveronUpDouble;
      break;

    case "CheveronDownDouble":
      Icon = CheveronDownDouble;
      break;

    case "CheveronSelectorHorizontal":
      Icon = CheveronSelectorHorizontal;
      break;

    case "CheveronSelectorVertical":
      Icon = CheveronSelectorVertical;
      break;

    case "Logout":
      Icon = Logout;
      break;

    case "Key":
      Icon = Key;
      break;

    case "Mail":
      Icon = Mail;
      break;

    case "LockKeyHole":
      Icon = LockKeyHole;
      break;

    case "ClipboardMinus":
      Icon = ClipboardMinus;
      break;

    case "Menu":
      Icon = Menu;
      break;

    case "Settings":
      Icon = Settings;
      break;

    case "Users":
      Icon = Users;
      break;

    case "MoreVert":
      Icon = MoreVert;
      break;

    case "Column":
      Icon = Column;
      break;

    case "FullScreen":
      Icon = FullScreen;
      break;

    case "FullScreenExit":
      Icon = FullScreenExit;
      break;

    case "CheckCircle":
      Icon = CheckCircle;
      break;

    case "Plus":
      Icon = Plus;
      break;

    case "OutlinedCheckCircle":
      Icon = OutlinedCheckCircle;
      break;

    case "OutlinedExclamationMarkCircle":
      Icon = OutlinedExclamationMarkCircle;
      break;

    case "Edit":
      Icon = Edit;
      break;

    case "Trash":
      Icon = Trash;
      break;

    case "BarChartSquare":
      Icon = BarChartSquare;
      break;

    case "LocationMark":
      Icon = LocationMark;
      break;

    case "MarkerPin":
      Icon = MarkerPin;
      break;

    case "Refresh":
      Icon = Refresh;
      break;

    case "Tool":
      Icon = Tool;
      break;

    case "ChartBreakoutSquare":
      Icon = ChartBreakoutSquare;
      break;

    case "Camera":
      Icon = Camera;
      break;

    case "Copy":
      Icon = Copy;
      break;

    case "SwitchVertical":
      Icon = SwitchVertical;
      break;

    case "ArrowNarrowUp":
      Icon = ArrowNarrowUp;
      break;

    case "ArrowNarrowDown":
      Icon = ArrowNarrowDown;
      break;

    case "SearchSm":
      Icon = SearchSm;
      break;

    case "AlertCircle":
      Icon = AlertCircle;
      break;

    case "SwitchHorizontal":
      Icon = SwitchHorizontal;
      break;

    default:
      Icon = Typography;
      break;
  }

  return (
    <MuiSvgIcon stroke={color || theme.palette.text.secondary} sx={sx}>
      <Icon width={size || "1.5rem"} height={size || "1.5rem"} fill={fill || "none"} strokeWidth={strokeWidth || 2} />
    </MuiSvgIcon>
  );
};

export default SvgIcon;
