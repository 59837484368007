import { TextField as MuiTextField, Stack, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { FormInputItem, LooseObject } from "../../utils/Types";

export const HelperText = ({ item, touched, errors, sx }: { item: FormInputItem; touched: LooseObject; errors: LooseObject; sx?: {} }) =>
  touched[item.name] && errors[item.name] ? (
    <FormHelperText sx={{ ...sx, ...item.helperTextSx }}>{errors[item.name]}</FormHelperText>
  ) : item.helperText && typeof item.helperText === "string" ? (
    <FormHelperText sx={{ ...sx, ...item.helperTextSx }}>{item.helperText}</FormHelperText>
  ) : (
    item.helperText
  );

export type TextFieldProps = {
  label?: string;
  name?: string;
  type?: string;
  item: FormInputItem;
  textFieldProps: LooseObject;
  values: LooseObject;
  handleChange: {
    /** Classic React change handler, keyed by input name */
    (e: React.ChangeEvent<any>): void;
    /** Preact-like linkState. Will return a handleChange function.  */
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void;
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  touched: LooseObject;
  errors: LooseObject;
  inputProps?: {};
};

const TextField = ({ label, name, type, item, values, handleChange, handleBlur, touched, errors, inputProps, textFieldProps }: TextFieldProps) => (
  <Stack alignItems="flex-start" width="100%">
    <FormControl error={errors[item.name] && touched[item.name]} size={item.size} sx={textFieldProps.sx} fullWidth>
      {(label || item.label) && (
        <InputLabel
          shrink
          sx={{
            position: "relative",
            transform: "none",
            marginBottom: "5px",
          }}
        >
          {label || item.label}
        </InputLabel>
      )}
      <MuiTextField
        {...textFieldProps}
        placeholder={item.placeholder}
        name={name || item.name}
        value={values[name || item.name] || ""}
        type={type || item.type} // can be password, search, number
        onWheel={e => (e.target as HTMLElement).blur()}
        InputProps={{ ...(inputProps || item.inputProps), notched: false }}
        inputProps={item.inputProps}
        InputLabelProps={{
          shrink: true,
          sx: {
            position: "relative",
            transform: "none",
            marginBottom: "5px",
          },
        }}
        onChange={v => {
          handleChange(v);
          item.exposeValue?.(v.target.value);
        }}
        onBlur={handleBlur}
        error={errors[name || item.name] && touched[name || item.name]}
        required={item.required}
        multiline={item.multiline}
        minRows={3}
        maxRows={7}
        size={item.size}
        disabled={item.disabled}
      />
      <HelperText item={item} touched={touched} errors={errors} />
    </FormControl>
  </Stack>
);

export default TextField;
