import Grid from "@mui/material/Unstable_Grid2";
import { PageContentComingSoon } from "../../../components";

const Page = () => {
  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid container>
        <Grid xs={12}>
          <PageContentComingSoon />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Page;
