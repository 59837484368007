// import { useTheme } from "@mui/material";
import Logomark from "../../assets/images/Logomark.png";
// import SvgIcon from "../SvgIcon";

const Logo = ({ size = 32 }: { size?: number }) => <img src={Logomark} alt="logo" width={size} height={size} />;

// const Logo = ({ size = 65 }: { size?: number }) => {
//   const theme = useTheme();
//   return <SvgIcon name="MeqLogo" size="1.5rem" color={theme.palette.text.primary} strokeWidth={2} />;
// };

export default Logo;
