export type LocationCoordinate = {
  name: string;
  latitude: number;
  longitude: number;
};

const LocationCoordinates: { client: string; locations: LocationCoordinate[] }[] = [
  {
    client: "Alliance_AllLocations",
    locations: [
      { name: "Dannevirke", latitude: -40.186210992595115, longitude: 176.1126923153446 },
      { name: "Levin", latitude: -40.62383799319477, longitude: 175.25601096652045 },
      { name: "Lorneville", latitude: -46.3443144475876, longitude: 168.31638617116386 },
      { name: "Mataura", latitude: -46.18794303230742, longitude: 168.87268687301693 },
      { name: "Nelson", latitude: -41.33029435914853, longitude: 173.2053185306817 },
      { name: "Pukeuri", latitude: -45.0330031667422, longitude: 171.03203551534457 },
      { name: "Smithfield", latitude: -44.37224428013984, longitude: 171.2469112711638 },
    ],
  },
];

export default LocationCoordinates;
