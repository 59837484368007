import { Chip, ContentWrapper, Hcharts } from "../../../../../components";
import { ChartType, DateFormat, DateFormatServer, LooseObject, QueryResult } from "../../../../../utils/Types";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/material";
import moment from "moment";

const Section = ({ data, loading, supplierWithFullInfo }: { data: QueryResult; loading: boolean; supplierWithFullInfo: LooseObject }) => {
  const xAxis = data.columns?.find(i => i.name === "MARBLING") || { label: "Marbling", name: "MARBLING", scale: 0, type: "number" };

  const dataToDisplay = data?.rows;

  return (
    <ContentWrapper>
      <Hcharts
        loading={loading}
        type={ChartType.HISTOGRAM}
        stacking="normal"
        title="xAxisLabel Histogram"
        header={
          <Stack width="100%" spacing={1}>
            <Grid container width="100%" spacing={1}>
              <Grid>
                <Chip label="Carcase Based" size="small" />
              </Grid>
              <Grid>
                <Chip label={`Supplier: ${supplierWithFullInfo.SUPPLIER_NO} | ${supplierWithFullInfo.fullName}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`${moment(supplierWithFullInfo.KILL_DATE, DateFormatServer.SHORT).format(DateFormat.SHORT)}`} size="small" />
              </Grid>
              <Grid>
                <Chip label={`Mob: ${supplierWithFullInfo.MOB} | Head: ${supplierWithFullInfo.head}`} size="small" />
              </Grid>
            </Grid>
          </Stack>
        }
        xAxis={xAxis}
        data={[
          {
            data: dataToDisplay || [],
            name: "Carcase",
          },
        ]}
      />
    </ContentWrapper>
  );
};

export default Section;
