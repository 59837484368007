import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ContentWrapper, Dialog, Form, NoDataView, SmallButton, Spinner, Table } from "../../../components";
import { FormInputCategory, GridPricingDataType, FormData, LooseObject } from "../../../utils/Types";
import { useContext, useEffect, useState } from "react";
import { SnackbarContext } from "../../../utils/Contexts";
import { logout, postToServer } from "../../../utils/Helper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectUser } from "../../../redux/reducers/userSlice";
import _ from "lodash";
import moment from "moment";
import { MRT_ColumnDef, MRT_RowData } from "material-react-table";
import Add from "./Add";
import Edit from "./Edit";
import { selectZitadelOrg } from "../../../redux/reducers/zitadelOrgSlice";

const Page = () => {
  const snackbar = useContext(SnackbarContext);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [loadingGridPricing, setLoadingGridPricing] = useState(true);
  const [loadingEditGridPricing, setLoadingEditGridPricing] = useState(false);
  const [editGridPricingSuccess, setEditGridPricingSuccess] = useState(false);

  const [settings, setSettings] = useState<LooseObject>();
  const [gridPricings, setGridPricings] = useState<GridPricingDataType[]>();
  const [openDialogForAdd, setOpenDialogForAdd] = useState<boolean>(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const user = useAppSelector(selectUser);
  const zitadelOrg = useAppSelector(selectZitadelOrg);
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchSettings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchSettings.cancel();
  }, []);

  const fetchSettings = _.debounce(async () => {
    setLoadingSettings(true);
    await postToServer({
      action: "gmp/GetSettings",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as LooseObject[];
          setSettings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingSettings(false);
  }, 500);

  useEffect(() => {
    fetchGridPricings();

    // Cleanup or clear any pending debounced function calls if necessary
    return () => fetchGridPricings.cancel();
  }, []);

  const fetchGridPricings = _.debounce(async () => {
    setLoadingGridPricing(true);
    await postToServer({
      action: "gmp/GetGridPricings",
      params: {},
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          const serverData = response.serverData as GridPricingDataType[];
          setGridPricings(serverData);
        } else {
          snackbar.open(response.message);
        }
      }
    });
    setLoadingGridPricing(false);
  }, 500);

  const handleSubmitSettings = async ({ weeklyLimit }: FormData) => {
    if (weeklyLimit && _.isInteger(weeklyLimit)) {
      setLoadingSettings(true);
      await postToServer({
        action: "gmp/UpdateSettings",
        params: { weeklyLimit },
        token: user.access_token,
        zitadelOrgIdString: zitadelOrg?.idString,
      }).then(response => {
        if (response.statusCode === 401) {
          logout({ dispatch, zitadelOrg });
        } else {
          if (response.message.type === "success" && response.serverData) {
            const serverData = response.serverData as LooseObject[];
            setSettings(serverData);
          }
          snackbar.open(response.message);
        }
      });
      setLoadingSettings(false);
    }
  };

  const handleAddGridPricing = async (gridPricingData: GridPricingDataType) => {
    setLoadingGridPricing(true);
    await postToServer({
      action: "gmp/AddGridPricing",
      params: { gridPricingData },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setOpenDialogForAdd(false);
          const serverData = response.serverData as GridPricingDataType[];
          setGridPricings(serverData);
        }
        snackbar.open(response.message);
      }
    });
    setLoadingGridPricing(false);
  };

  const handleEditGridPricing = async (gridPricingData: GridPricingDataType) => {
    setLoadingEditGridPricing(true);
    setEditGridPricingSuccess(false);
    await postToServer({
      action: "gmp/UpdateGridPricing",
      params: { gridPricingData },
      token: user.access_token,
      zitadelOrgIdString: zitadelOrg?.idString,
    }).then(async response => {
      if (response.statusCode === 401) {
        logout({ dispatch, zitadelOrg });
      } else {
        if (response.message.type === "success" && response.serverData) {
          setEditGridPricingSuccess(true);
          const serverData = response.serverData as GridPricingDataType[];
          setGridPricings(serverData);
        }

        snackbar.open(response.message);
      }
    });
    setLoadingEditGridPricing(false);
  };

  const gridPricingsColumns: MRT_ColumnDef<MRT_RowData>[] = [{ accessorKey: "yearAndWeek", header: "Week", minSize: isSmallScreen ? 270 : 350 }];
  const gridPricingsRows: any = gridPricings?.map(i => {
    const firstDayOfWeek = moment().year(i.year).week(i.week).startOf("isoWeek");
    return {
      yearAndWeek: `Week ${i.week} - ${i.week + 1}, ${i.year} (${firstDayOfWeek.format(`ddd MMM DD`)} - ${moment(firstDayOfWeek.valueOf()).add(13, "days").format(`ddd MMM DD`)})`,
      gridPricing: i,
    };
  });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Stack spacing={2}>
          <Typography variant="textmd" fontWeight="semiBold">
            Settings
          </Typography>
          {!settings ? (
            <Spinner />
          ) : (
            <ContentWrapper>
              <Form
                loading={loadingSettings}
                onSubmit={data => handleSubmitSettings(data)}
                buttonText="Update"
                buttonSize="small"
                buttonFullWidth={false}
                inputs={[
                  {
                    name: "weeklyLimit",
                    label: "Weekly limit",
                    category: FormInputCategory.TEXT_FIELD,
                    type: "number",
                    defaultValue: settings?.weeklyLimit,
                    gridLayout: { xs: 12, sm: 6, md: 4, lg: 3, xl: 2 },
                  },
                ]}
              />
            </ContentWrapper>
          )}
        </Stack>
      </Grid>
      <Grid>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="textmd" fontWeight="semiBold">
              Grid Pricing
            </Typography>
            <SmallButton title="Add a New Grid Pricing" onClick={() => setOpenDialogForAdd(true)} />
          </Stack>
          {loadingGridPricing ? (
            <Spinner />
          ) : !gridPricings || gridPricings.length === 0 ? (
            <ContentWrapper>
              <NoDataView />
            </ContentWrapper>
          ) : (
            <Table
              columns={gridPricingsColumns}
              data={gridPricingsRows!}
              enableTableHead={false}
              enableTopToolbar={false}
              enableExpandMultiple
              renderDetailPanel={({ row }) => (
                <Stack sx={{ width: { xs: `calc(100vw - 76px)`, md: `calc(100vw - 180px)` } }} onClick={e => e.stopPropagation()}>
                  <Edit data={row.original.gridPricing} loadingInitial={loadingEditGridPricing} isSuccess={editGridPricingSuccess} handleSubmit={handleEditGridPricing} />
                </Stack>
              )}
              layoutMode="grid-no-grow"
              // muiTableBodyRowProps={({ row, table }) => ({
              //   onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
              // })}
            />
          )}
        </Stack>
      </Grid>
      <Dialog open={openDialogForAdd} onClose={() => setOpenDialogForAdd(false)} isTransparent>
        <Add loading={loadingGridPricing} handleSubmit={handleAddGridPricing} onCancel={() => setOpenDialogForAdd(false)} />
      </Dialog>
    </Grid>
  );
};

export default Page;
