import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

if (process.env.NODE_ENV === "development") {
  const originalWarn = console.warn;
  console.warn = function (...args) {
    const message = args[0];
    if (typeof message === "string") {
      if (
        message.includes("MUI: You have provided an out-of-range value") ||
        message.includes('A props object containing a "key" prop is being spread into JSX') ||
        message.includes('Consider including the "accessibility.js" module')
      ) {
        return;
      }
    }
    originalWarn.apply(console, args);
  };
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
